// src/AnimationView/functions/characters.ts
import { getEngine } from './engineManager';

/**
 * Retourne l'angle actuel du personnage, sous forme de Promise<number>.
 */
export async function getCurrentAngle(id: string): Promise<number> {
    const engine = getEngine();
    const char = engine?.getCharacter(id);
    if (!char) {
        console.warn(`Character with id ${id} not found.`);
        return 0; // Retourne un angle par défaut
    }
    // Supposant que getAngleDirection() soit synchrone, on renvoie direct
    return char.getAngleDirection();
}

/**
 * Tourne le personnage, fonction async Promise<void>.
 * On peut la "await" même si elle n'a pas d'opération réellement asynchrone.
 */
export async function turnCharacter(
    id: string,
    direction: string,
    angle: number
): Promise<void> {
    const engine = getEngine();
    const char = engine?.getCharacter(id);
    if (!char) {
        console.warn(`Character with id ${id} not found.`);
        return;
    }
    if (direction === 'RIGHT') {
        char.addAngle(-angle); // Tourner à droite
    } else if (direction === 'LEFT') {
        char.addAngle(angle); // Tourner à gauche
    } else if (direction === 'BACKWARD') {
        char.flipAngle();
    } else {
        // Si "direction" n'est pas reconnu, on fixe carrément l'angle
        char.setAngleDirection(angle);
    }
}

/**
 * Fait marcher le personnage d'une certaine distance.
 * Rendue async pour permettre `await walkCharacter(...)`.
 */
export async function walkCharacter(id: string, distance: number): Promise<void> {
    const engine = getEngine();
    const char = engine?.getCharacter(id);
    if (!char) {
        console.warn(`Character with id ${id} not found.`);
        return;
    }
    await char.walk(distance);
}

/**
 * Fait sauter le personnage.
 */
export async function jumpCharacter(id: string, distance: number): Promise<void> {
    const engine = getEngine();
    const char = engine?.getCharacter(id);
    if (!char) {
        console.warn(`Character with id ${id} not found.`);
        return;
    }
    await char.jump(distance);
}
