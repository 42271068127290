import React, { useCallback } from "react";
import {
  Drawer,
  Checkbox,
  Button,
  Typography,
  Divider,
  Collapse,
  Space,
} from "antd";
import { useChallengeConfig } from "../../contexts/BlocklyConfigContext";
import {
  BlocklyPhaserConfig,
  ToolboxCategory,
  ToolboxBlock,
} from "../../types/BlocklyTypes";
import { DEFAULT_TOOLBOX_ITEMS } from "../../assets/toolboxitems";

const { Title } = Typography;
const { Panel } = Collapse;

interface AdminToolboxDrawerProps {
  visible: boolean;
  onClose: () => void;
}

const AdminToolboxDrawer: React.FC<AdminToolboxDrawerProps> = ({
  visible,
  onClose,
}) => {
  const { config, setConfig } = useChallengeConfig();

  const isGrouped = config.toolboxGrouped ?? true;

  // Toggle regroupement
  const handleToggleGrouped = useCallback(() => {
    setConfig((prev: BlocklyPhaserConfig) => ({
      ...prev,
      toolboxGrouped: !prev.toolboxGrouped,
    }));
  }, [setConfig]);

  // Liste des noms de catégories actives
  const selectedCategoryNames = config.toolboxCategories.map((cat) => cat.name);

  /**
   * Active / désactive entièrement une catégorie.
   */
  const handleToggleCategory = useCallback(
    (catItem: ToolboxCategory, checked: boolean) => {
      setConfig((prev) => {
        if (!checked) {
          // On retire cette catégorie
          return {
            ...prev,
            toolboxCategories: prev.toolboxCategories.filter(
              (c) => c.name !== catItem.name
            ),
          };
        } else {
          // On l’ajoute
          return {
            ...prev,
            toolboxCategories: [
              ...prev.toolboxCategories,
              {
                name: catItem.name,
                blocks: catItem.blocks, // on reprend la liste par défaut
                color: catItem.color,
              },
            ],
          };
        }
      });
    },
    [setConfig]
  );

  /**
   * Sélection / désélection d’un bloc dans une catégorie.
   */
  const handleToggleBlock = useCallback(
    (catItem: ToolboxCategory, block: ToolboxBlock, checked: boolean) => {
      setConfig((prev) => {
        const cats = [...prev.toolboxCategories];
        const catIndex = cats.findIndex((c) => c.name === catItem.name);
        if (catIndex === -1) return prev;

        const catToUpdate = { ...cats[catIndex] };
        let newBlocks = [...catToUpdate.blocks];

        if (!checked) {
          // on retire
          newBlocks = newBlocks.filter((b) => b.type !== block.type);
        } else {
          // on ajoute
          if (!newBlocks.some((b) => b.type === block.type)) {
            newBlocks.push(block);
          }
        }

        catToUpdate.blocks = newBlocks;
        cats[catIndex] = catToUpdate;
        return { ...prev, toolboxCategories: cats };
      });
    },
    [setConfig]
  );

  /**
   * Modifier la propriété (movable / deletable / editable / updatable) d'un bloc.
   */
  const handleChangeBlockProperty = useCallback(
    (
      catItem: ToolboxCategory,
      block: ToolboxBlock,
      prop: "movable" | "deletable" | "editable" | "updatable",
      value: boolean
    ) => {
      setConfig((prev) => {
        const cats = [...prev.toolboxCategories];
        const catIndex = cats.findIndex((c) => c.name === catItem.name);
        if (catIndex === -1) return prev;

        const catToUpdate = { ...cats[catIndex] };
        const blocksCopy = [...catToUpdate.blocks];

        const bIndex = blocksCopy.findIndex((b) => b.type === block.type);
        if (bIndex === -1) return prev;

        const updatedBlock = { ...blocksCopy[bIndex], [prop]: value };
        blocksCopy[bIndex] = updatedBlock;

        catToUpdate.blocks = blocksCopy;
        cats[catIndex] = catToUpdate;
        return { ...prev, toolboxCategories: cats };
      });
    },
    [setConfig]
  );

  /**
   * Sélectionner tous les blocs d'une catégorie
   */
  const handleSelectAllBlocks = useCallback(
    (catItem: ToolboxCategory) => {
      setConfig((prev) => {
        const cats = [...prev.toolboxCategories];
        const catIndex = cats.findIndex((c) => c.name === catItem.name);
        if (catIndex === -1) return prev;

        const catToUpdate = { ...cats[catIndex] };
        // On reprend l'intégralité des blocs "par défaut" (catItem.blocks)
        catToUpdate.blocks = catItem.blocks;
        cats[catIndex] = catToUpdate;
        return { ...prev, toolboxCategories: cats };
      });
    },
    [setConfig]
  );

  /**
   * Désélectionner tous les blocs d'une catégorie
   */
  const handleDeselectAllBlocks = useCallback(
    (catItem: ToolboxCategory) => {
      setConfig((prev) => {
        const cats = [...prev.toolboxCategories];
        const catIndex = cats.findIndex((c) => c.name === catItem.name);
        if (catIndex === -1) return prev;

        const catToUpdate = { ...cats[catIndex] };
        catToUpdate.blocks = [];
        cats[catIndex] = catToUpdate;
        return { ...prev, toolboxCategories: cats };
      });
    },
    [setConfig]
  );

  /**
   * Sélectionner seulement un bloc (et désélectionner tous les autres).
   */
  const handleSelectOnlyBlock = useCallback(
    (catItem: ToolboxCategory, block: ToolboxBlock) => {
      setConfig((prev) => {
        const cats = [...prev.toolboxCategories];
        const catIndex = cats.findIndex((c) => c.name === catItem.name);
        if (catIndex === -1) return prev;

        const catToUpdate = { ...cats[catIndex] };
        // On ne garde qu'un seul bloc
        catToUpdate.blocks = [{ ...block }];
        cats[catIndex] = catToUpdate;
        return { ...prev, toolboxCategories: cats };
      });
    },
    [setConfig]
  );

  return (
    <Drawer
      title="Configuration de la Toolbox"
      placement="right"
      width={700}
      onClose={onClose}
      open={visible}
    >
      <Title level={5}>Mode d'affichage</Title>
      <Checkbox checked={isGrouped} onChange={handleToggleGrouped}>
        Regrouper par catégorie
      </Checkbox>

      <Divider />

      <Title level={5} style={{ marginBottom: 8 }}>
        Catégories disponibles
      </Title>

      <Collapse accordion>
        {DEFAULT_TOOLBOX_ITEMS.map((catItem) => {
          const isCatSelected = selectedCategoryNames.includes(catItem.name);

          // On récupère la catégorie "actuelle" dans la config
          const catInConfig = config.toolboxCategories.find(
            (c) => c.name === catItem.name
          );

          return (
            <Panel
              header={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    checked={isCatSelected}
                    onChange={(e) =>
                      handleToggleCategory(catItem, e.target.checked)
                    }
                  />
                  <span style={{ marginLeft: 8 }}>
                    <strong>{catItem.name}</strong>
                  </span>
                </div>
              }
              key={catItem.name}
            >
              {isCatSelected && catInConfig && (
                <div style={{ marginLeft: 24 }}>
                  <Space style={{ marginBottom: 8 }}>
                    <Button
                      size="small"
                      onClick={() => handleSelectAllBlocks(catItem)}
                    >
                      Tout sélectionner
                    </Button>
                    <Button
                      size="small"
                      onClick={() => handleDeselectAllBlocks(catItem)}
                    >
                      Tout désélectionner
                    </Button>
                  </Space>

                  {catItem.blocks.map((defaultBlock) => {
                    // Vérif si ce bloc est actif dans la config
                    const isSelectedInConfig = catInConfig.blocks.some(
                      (b) => b.type === defaultBlock.type
                    );

                    // On récupère la "version" courante
                    const blockInConfig =
                      catInConfig.blocks.find(
                        (b) => b.type === defaultBlock.type
                      ) || defaultBlock;

                    return (
                      <div
                        key={defaultBlock.type}
                        style={{
                          marginBottom: 4,
                          display: "flex",
                          alignItems: "flex-start",
                        }}
                      >
                        <Checkbox
                          checked={isSelectedInConfig}
                          onChange={(e) =>
                            handleToggleBlock(
                              catItem,
                              defaultBlock,
                              e.target.checked
                            )
                          }
                        >
                          {defaultBlock.type}
                        </Checkbox>

                        {isSelectedInConfig && (
                          <div style={{ width: '100%', display: "flex", marginLeft: 16, gap: 8, justifyContent: 'flex-end' }}>
                            {/* Movable */}
                            <Checkbox
                              checked={blockInConfig.movable}
                              onChange={(e) =>
                                handleChangeBlockProperty(
                                  catItem,
                                  blockInConfig,
                                  "movable",
                                  e.target.checked
                                )
                              }
                            >
                              Movable
                            </Checkbox>
                            {/* Deletable */}
                            <Checkbox
                              checked={blockInConfig.deletable}
                              onChange={(e) =>
                                handleChangeBlockProperty(
                                  catItem,
                                  blockInConfig,
                                  "deletable",
                                  e.target.checked
                                )
                              }
                            >
                              Deletable
                            </Checkbox>
                            {/* Editable */}
                            <Checkbox
                              checked={blockInConfig.editable}
                              onChange={(e) =>
                                handleChangeBlockProperty(
                                  catItem,
                                  blockInConfig,
                                  "editable",
                                  e.target.checked
                                )
                              }
                            >
                              Editable
                            </Checkbox>
                            {/* Updatable */}
                            {/* <Checkbox
                              checked={blockInConfig.updatable}
                              onChange={(e) =>
                                handleChangeBlockProperty(
                                  catItem,
                                  blockInConfig,
                                  "updatable",
                                  e.target.checked
                                )
                              }
                            >
                              Updatable
                            </Checkbox> */}

                            <Button
                              size="small"
                              onClick={() =>
                                handleSelectOnlyBlock(catItem, blockInConfig)
                              }
                            >
                              Uniquement
                            </Button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </Panel>
          );
        })}
      </Collapse>

      <Divider />
      <div style={{ textAlign: "right" }}>
        <Button onClick={onClose}>Fermer</Button>
      </div>
    </Drawer>
  );
};

export default AdminToolboxDrawer;
