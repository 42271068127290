// defineControlBlocks.ts
import * as Blockly from 'blockly/core';
import { javascriptGenerator, Order } from 'blockly/javascript';

/**
 * Définit des blocs "Contrôle" (if simple, repeat, while, for...) en version simplifiée,
 * sans le mutateur "Multi else-if".
 */
export function defineControlBlocks() {

    /***********************************************
     * 1) controls_if (simplifié : if + else)
     ***********************************************/
    Blockly.Blocks['controls_if'] = {
        init: function () {
            this.setColour(240);
            this.appendValueInput('IF')
                .setCheck('Boolean')
                .appendField('si');
            this.appendStatementInput('DO')
                .appendField('alors');
            // Optionnel : ELSE
            this.appendStatementInput('ELSE')
                .appendField('sinon');
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setTooltip('Si la condition est vraie, exécute le bloc “alors”, sinon exécute le bloc “sinon”.');
            this.setHelpUrl('');
        },
    };

    // Générateur : c'est un bloc statement (pas de sortie).
    javascriptGenerator.forBlock['controls_if'] = function (block, generator) {
        const condition = generator.valueToCode(block, 'IF', Order.NONE) || 'false';
        const branchIf = generator.statementToCode(block, 'DO');
        const branchElse = generator.statementToCode(block, 'ELSE');

        let code = `if (${condition}) {\n${branchIf}}\n`;
        if (branchElse.trim()) {
            code += `else {\n${branchElse}}\n`;
        }
        return code;
    };

    /***********************************************
     * 2) controls_repeat_ext (repeat X times)
     ***********************************************/
    Blockly.Blocks['controls_repeat_ext'] = {
        init: function () {
            this.appendValueInput('TIMES')
                .setCheck('Number')
                .appendField('répéter');
            this.appendDummyInput().appendField('fois');
            this.appendStatementInput('DO')
                .appendField('faire');
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(240);
            this.setTooltip('Répète un certain nombre de fois le bloc de commandes.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['controls_repeat_ext'] = function (block, generator) {
        const repeats = generator.valueToCode(block, 'TIMES', Order.ASSIGNMENT) || '0';
        const branch = generator.statementToCode(block, 'DO');
        const code = `for (let i = 0; i < ${repeats}; i++) {\n${branch}}\n`;
        return code;
    };

    /***********************************************
     * 3) controls_whileUntil
     ***********************************************/
    Blockly.Blocks['controls_whileUntil'] = {
        init: function () {
            this.appendValueInput('BOOL')
                .setCheck('Boolean')
                .appendField(new Blockly.FieldDropdown([
                    ['tant que', 'WHILE'],
                    ['jusqu\'à', 'UNTIL']
                ]), 'MODE');
            this.appendStatementInput('DO')
                .appendField('faire');
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(240);
            this.setTooltip('Répète le bloc tant que la condition est vraie, ou jusqu’à ce que la condition soit vraie.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['controls_whileUntil'] = function (block, generator) {
        const dropdownMode = block.getFieldValue('MODE');
        let conditionCode = generator.valueToCode(block, 'BOOL', Order.NONE) || 'false';
        const branch = generator.statementToCode(block, 'DO') || '';

        if (dropdownMode === 'UNTIL') {
            conditionCode = `!(${conditionCode})`;
        }
        return `while (${conditionCode}) {\n${branch}}\n`;
    };

    /***********************************************
     * 4) controls_for (loop from A to B step 1)
     ***********************************************/
    Blockly.Blocks['controls_for'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('pour')
                .appendField(new Blockly.FieldVariable('i'), 'VAR');
            this.appendValueInput('FROM')
                .setCheck('Number')
                .appendField('de');
            this.appendValueInput('TO')
                .setCheck('Number')
                .appendField('à');
            this.appendStatementInput('DO')
                .appendField('faire');
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(240);
            this.setTooltip('Boucle de i = de ... à ...');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['controls_for'] = function (block, generator) {
        const variable0 = block.getField('VAR')?.getText() || 'i';
        const fromCode = generator.valueToCode(block, 'FROM', Order.ASSIGNMENT) || '0';
        const toCode = generator.valueToCode(block, 'TO', Order.ASSIGNMENT) || '0';
        const branch = generator.statementToCode(block, 'DO') || '';

        // Simple loop from fromCode to toCode (inclusif)
        return `for (let ${variable0} = ${fromCode}; ${variable0} <= ${toCode}; ${variable0}++) {\n${branch}}\n`;
    };

    /***********************************************
     * 5) controls_forEach (boucle for-of sur un array)
     ***********************************************/
    Blockly.Blocks['controls_forEach'] = {
        init: function () {
            this.appendValueInput('LIST')
                .setCheck('Array')
                .appendField('pour chaque')
                .appendField(new Blockly.FieldVariable('item'), 'VAR')
                .appendField('dans la liste');
            this.appendStatementInput('DO')
                .appendField('faire');
            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setColour(240);
            this.setTooltip('Itère sur chaque élément d’un tableau.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['controls_forEach'] = function (block, generator) {
        const variable0 = block.getField('VAR')?.getText() || 'item';
        const listCode = generator.valueToCode(block, 'LIST', Order.ASSIGNMENT) || '[]';
        const branch = generator.statementToCode(block, 'DO') || '';

        return `for (let ${variable0} of ${listCode}) {\n${branch}}\n`;
    };

    /***********************************************
     * 6) controls_flow_statements (break / continue)
     ***********************************************/
    Blockly.Blocks['controls_flow_statements'] = {
        init: function () {
            this.appendDummyInput()
                .appendField(new Blockly.FieldDropdown([
                    ['stop la boucle', 'BREAK'],
                    ['passe la suite (continue)', 'CONTINUE']
                ]), 'FLOW');
            this.setPreviousStatement(true);
            this.setColour(240);
            this.setTooltip('Arrête ou continue la boucle courante.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['controls_flow_statements'] = function (block) {
        const flow = block.getFieldValue('FLOW');
        if (flow === 'BREAK') {
            return 'break;\n';
        } else if (flow === 'CONTINUE') {
            return 'continue;\n';
        }
        return '';
    };

    /***********************************************
 * 7) controls_switch (avec 3 cas + default)
 ***********************************************/
    /***********************************************
     * 7) controls_switch (avec 2 cas + default)
     ***********************************************/
    Blockly.Blocks['controls_switch'] = {
        init: function () {
            this.setColour(240);
            // L'expression à évaluer
            this.appendValueInput('SWITCH')
                .setCheck(null)
                .appendField('switch');

            // Cas 1
            this.appendValueInput('CASE1VAL')
                .setCheck(null)
                .appendField('cas 1');
            this.appendStatementInput('CASE1DO')
                .appendField('faire');

            // Cas 2
            this.appendValueInput('CASE2VAL')
                .setCheck(null)
                .appendField('cas 2');
            this.appendStatementInput('CASE2DO')
                .appendField('faire');

            // Bloc "default"
            this.appendStatementInput('DEFAULTDO')
                .appendField('défaut');

            this.setPreviousStatement(true);
            this.setNextStatement(true);
            this.setTooltip('Exécute les instructions correspondant au cas dont la valeur est égale à celle du switch, sinon exécute le bloc «défaut».');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['controls_switch'] = function (block, generator) {
        const switchValue = generator.valueToCode(block, 'SWITCH', Order.NONE) || "''";

        const case1Value = generator.valueToCode(block, 'CASE1VAL', Order.NONE) || "''";
        const case1Code = generator.statementToCode(block, 'CASE1DO') || '';

        const case2Value = generator.valueToCode(block, 'CASE2VAL', Order.NONE) || "''";
        const case2Code = generator.statementToCode(block, 'CASE2DO') || '';

        const defaultCode = generator.statementToCode(block, 'DEFAULTDO') || '';

        // Construction du code en switch
        let code = `switch (${switchValue}) {\n`;
        code += `  case ${case1Value}:\n${case1Code}    break;\n`;
        code += `  case ${case2Value}:\n${case2Code}    break;\n`;
        code += `  default:\n${defaultCode}\n`;
        code += `}\n`;

        return code;
    };


    /***********************************************
     * controls_switch_dynamic (switch avec N cas)
     ***********************************************/
    Blockly.Blocks['controls_switch_dynamic'] = {
        init: function () {
            this.setColour(240);

            // 1) Champ "SWITCH"
            this.appendValueInput('SWITCH')
                .setCheck(null)
                .appendField('switch');

            // 2) Champ numérique : Nombre de cas
            this.appendDummyInput()
                .appendField('Nombre de cas')
                .appendField(
                    new Blockly.FieldNumber(2, 1, 100, 1, (newValue) => {
                        // Petit cast
                        const n = parseInt(String(newValue), 10);
                        if (!isNaN(n) && n >= 1 && n <= 100) {
                            if ((this as any).caseCount_ !== n) {
                                (this as any).caseCount_ = n;
                                (this as any).updateShape_();
                            }
                        }
                        return newValue;
                    }),
                    'NombreDesCas'
                );

            // Par défaut, on se souvient qu’on a 2 cas
            (this as any).caseCount_ = 2;

            // 3) Input "DEFAULTDO"
            this.appendStatementInput('DEFAULTDO').appendField('défaut');

            this.setPreviousStatement(true);
            this.setNextStatement(true);

            // On appelle updateShape_()
            (this as any).updateShape_();
        },

        mutationToDom: function () {
            const container = document.createElement('mutation');
            // cast en string :
            container.setAttribute('case_count', String((this as any).caseCount_));
            return container;
        },

        domToMutation: function (xmlElement: any) {
            // xmlElement: any ou un type plus précis
            const count = parseInt(xmlElement.getAttribute('case_count'), 10);
            (this as any).caseCount_ = isNaN(count) ? 2 : count;
            (this as any).updateShape_();
        },

        updateShape_: function () {
            // 1) On supprime les inputs existants (sauf SWITCH, NombreDesCas, DEFAULTDO)
            const inputsToRemove: string[] = [];
            for (const input of this.inputList) {
                if (input.name && input.name.startsWith('CASE') && input.name !== 'DEFAULTDO') {
                    inputsToRemove.push(input.name);
                }
            }
            for (const name of inputsToRemove) {
                this.removeInput(name);
            }

            // 2) Recréer les paires (VALUE + STATEMENT) pour chaque cas
            const caseCount = (this as any).caseCount_ || 2;
            for (let i = 1; i <= caseCount; i++) {
                this.appendValueInput(`CASE${i}VAL`)
                    .setCheck(null)
                    .appendField('cas ' + i);

                this.appendStatementInput(`CASE${i}DO`)
                    .appendField('faire');
            }

            // 3) On replace le default en fin
            if (this.getInput('DEFAULTDO')) {
                this.removeInput('DEFAULTDO');
                this.appendStatementInput('DEFAULTDO').appendField('défaut');
            }
        },
    };

    javascriptGenerator.forBlock['controls_switch_dynamic'] = function (block, generator) {
        const switchValue = generator.valueToCode(block, 'SWITCH', Order.NONE) || '0';
        const caseCount = (block as any).caseCount_ || 0;
        const defaultCode = generator.statementToCode(block, 'DEFAULTDO') || '';

        let code = '';

        // 1) Début du switch
        code += `switch (${switchValue}) {\n`;

        // 2) Génération des cases
        for (let i = 1; i <= caseCount; i++) {
            const caseVal = generator.valueToCode(block, `CASE${i}VAL`, Order.NONE) || '0';
            const caseCode = generator.statementToCode(block, `CASE${i}DO`) || '';

            code += `  case ${caseVal}:\n`;
            // On ajoute un niveau d'indentation pour le contenu du case
            code += `    ${caseCode}\n`;
            code += `    break;\n`;
        }

        // 3) Génération du default
        code += `  default:\n`;
        if (defaultCode.trim()) {
            code += `    ${defaultCode}\n`;
        }
        code += `}\n`;

        return code;
    };



}
