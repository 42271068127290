import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, message } from 'antd';
import { ChallengeConfigProvider, defaultConfig, useChallengeConfig } from '../../contexts/BlocklyConfigContext';
import Blockly, { BlocklyRef } from '../Blockly';
import { TLesson } from '../../../../models/courses/lesson';
import { useUserApi } from '../../../../api/user/userApp';
import { TLessonBlocklyUserAnswer } from '../../../../models/user/UserProgression';


// On reçoit eventManager en props
interface LessonBlocklyPhaserProps {
  lesson: TLesson | undefined;
  checkResult: (isValid: boolean) => void;
}

const LessonBlocklyPhaser: React.FC<LessonBlocklyPhaserProps> = ({ lesson, checkResult }) => {
  return (
    <ChallengeConfigProvider >
      <LessonBlocklyPhaserContent lesson={lesson} checkResult={checkResult} />
    </ChallengeConfigProvider>
  );
};

/**
 * Page d'administration permettant de configurer un challenge en temps réel.
 */
const LessonBlocklyPhaserContent: React.FC<LessonBlocklyPhaserProps> = ({ lesson, checkResult }) => {

  const { setConfig } = useChallengeConfig();
  const blocklyRef = useRef<BlocklyRef>(null);
  // État pour le Drawer 
  const [isWin, setIsWin] = useState(false);

  const { getBlocklyAnswersByLesson, saveBlocklyAnswer } = useUserApi();
  const [answer, setAnswer] = useState<TLessonBlocklyUserAnswer | null>(null);
  useEffect(() => {
    if (!lesson) return;

    getBlocklyAnswersByLesson(lesson.id)
      .then((resp) => {

        setAnswer(resp.data);
        setConfig({
          ...(lesson?.config_phaser?.config ?? defaultConfig()),
          adminMode: false,
          workspace: resp.data?.workspace ?? lesson.config_phaser?.config?.workspace ?? ''
        });
      })
      .catch((err) => {
        message.error(err.response?.data ?? err.message ?? err ?? err)
      })


    // eslint-disable-next-line
  }, []);

  const onWin = () => {
    setIsWin(true);
  }

  useEffect(() => {
    if (isWin) {
      checkResult(true)
    }
    // eslint-disable-next-line
  }, [isWin])

  const onSave = (workspace: string) => {
    if (!lesson) return;
    const lessonAnswer: TLessonBlocklyUserAnswer = {
      id: answer?.id ?? 0,
      userapp_id: 0,
      lesson_id: lesson.id,
      workspace: workspace
    }
    saveBlocklyAnswer(lesson.id, lessonAnswer)
      .then((resp) => {

        setAnswer(resp.data);
      })
      .catch((err) => {
        message.error(err.response?.data ?? err.message ?? err ?? err)
      })
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Row gutter={16} style={{ padding: '0 16px 16px' }}>
        <Col span={24}>
          <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Blockly ref={blocklyRef} onWin={onWin} onSave={onSave} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LessonBlocklyPhaser;
