import React, { useRef, useEffect } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import BlocklyGame from '../../blocks/core/component/BlocklyGame/BlocklyGame';
import BlocklyDraw from '../../blocks/core/component/BlocklyDraw/BlocklyDraw';
import BlocklyArcade from '../../blocks/core/component/BlocklyArcade/BlocklyArcade';
import ContentViewer from '../../components/editor/ContentViewer';
import QuestionViewer from '../../components/forms/QuestionViewer';

import { TLesson } from '../../models/courses/lesson';
import { TCodification } from '../../models/referential/referential';

import { useReferential } from '../../context/ReferentialContext';

import {
    CODIFICATION_LESSON_METADATA,
    CODIFICATION_LESSON_TYPE,
    CODIFICATION_QUESTION_TYPE,
    CODIFICATION_TYPE,
} from '../../constant/codification';

import {
    TLessonCharactersTOTCharacters,
    TLessonCharacterTOTCharacter,
} from '../../utils/blockly/character';
import { TLessonObstaclesTOObstacles } from '../../utils/blockly/obstacle';
import {
    TLessonTargetsTOTTargets,
    TLessonTargetTOTTarget,
} from '../../utils/blockly/target';
import { TLessonSceneTOScene } from '../../utils/blockly/scene';
import { TLessonLinesTOTLines } from '../../utils/blockly/line';
import { TLessonBlocklyContentTOBlocklyBlocks } from '../../utils/blockly/blocklyfields';
import { stringToBool } from '../../utils/format/valueFormat';
import FillInTheBlank from '../../components/forms/FillInTheBlank';
import DragAndDrop from '../../components/forms/DragAndDrop';
import LessonBlocklyPhaser from '../../components/Blockly/root/student/LessonBlocklyPhaser';

interface LessonContentProps {
    lesson: TLesson | null;
    checkResult: (isValid: boolean) => void;
}

const LessonContent: React.FC<LessonContentProps> = ({ lesson, checkResult }) => {
    const { t } = useTranslation();
    const {
        getCodificationIdByCodes,
        getCodificationCodeById,
        getCodificationsByCodeType,
    } = useReferential();

    // Initialiser les hooks au niveau supérieur
    const xmlRef = useRef<string>('');
    const [codificationType, setCodificationType] = React.useState<TCodification | undefined>(undefined);

    // Définir la fonction isValid
    const isValid = (valid: boolean) => {
        checkResult(valid);
        if (valid) {
            message.success(t('message.bravo'));
        }
    };



    // Définir les fonctions utilitaires
    const getMetadataValue = React.useCallback(
        (keyCode: string): string | undefined => {
            if (!lesson) return undefined;
            const keyId = getCodificationIdByCodes(
                CODIFICATION_TYPE.LESSON_METADATA,
                keyCode
            );
            return lesson.metadata?.find((e) => e.key_id === keyId)?.value;
        },
        [lesson, getCodificationIdByCodes]
    );

    const getMetadataBoolean = (keyCode: string): boolean => {
        const value = getMetadataValue(keyCode);
        return stringToBool(value) || false;
    };

    // Utiliser useEffect pour mettre à jour codificationType
    useEffect(() => {
        if (lesson) {
            const lessonTypes: TCodification[] = getCodificationsByCodeType(
                CODIFICATION_TYPE.LESSON_TYPE
            );
            const codification = lessonTypes.find(
                (e) => e.id === lesson.lesson_type_id
            );
            setCodificationType(codification);

            xmlRef.current =
                getMetadataValue(CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX) || '';
        } else {
            setCodificationType(undefined);
        }
        // eslint-disable-next-line
    }, [lesson]);

    // Utiliser useEffect pour valider automatiquement l'exercice
    useEffect(() => {
        if (codificationType?.code === CODIFICATION_LESSON_TYPE.LESSON_SLIDE) {
            checkResult(true);
        }
        // eslint-disable-next-line
    }, [codificationType?.code]);



    // Retours anticipés après les hooks
    if (!lesson) {
        return <p>{t('text.exercideUnavailable')}</p>;
    }

    if (!codificationType) {
        return <p>{t('text.exercideUnavailable')}</p>;
    }

    // Données communes pour les différents types de leçons
    const toolboxContents = TLessonBlocklyContentTOBlocklyBlocks(
        lesson.blocklycontent,
        getCodificationCodeById
    );
    const initialBlocksJsonOrXML = getMetadataValue(
        CODIFICATION_LESSON_METADATA.BLOCKLY_XML_TOOLBOX
    );
    const scene = TLessonSceneTOScene(lesson.scene);

    // Rendu en fonction du type de leçon
    switch (codificationType.code) {
        case CODIFICATION_LESSON_TYPE.BLOCKLY_PHASER:
            return (
                <LessonBlocklyPhaser
                    lesson={lesson}
                    checkResult={isValid}
                />
            )
        case CODIFICATION_LESSON_TYPE.BLOCKLY_GAME:
            return (
                <BlocklyGame
                    toolbox={{ contents: toolboxContents }}
                    initialBlocksJsonOrXML={initialBlocksJsonOrXML}
                    character={TLessonCharacterTOTCharacter(
                        lesson.characters?.[0],
                        getCodificationIdByCodes
                    )}
                    obstacles={TLessonObstaclesTOObstacles(lesson.obstacles)}
                    targets={TLessonTargetsTOTTargets(
                        lesson.targets,
                        getCodificationIdByCodes
                    )}
                    scene={scene}
                    xmlRef={xmlRef}
                    showGrid={getMetadataBoolean(CODIFICATION_LESSON_METADATA.SHOW_GRID)}
                    drawResult={getMetadataBoolean(
                        CODIFICATION_LESSON_METADATA.DRAW_RESULT
                    )}
                    autoReset={getMetadataBoolean(
                        CODIFICATION_LESSON_METADATA.AUTO_RESET
                    )}
                    autoReach={getMetadataBoolean(
                        CODIFICATION_LESSON_METADATA.AUTO_REACH
                    )}
                    respectOrder={getMetadataBoolean(
                        CODIFICATION_LESSON_METADATA.RESPECT_ORDER
                    )}
                    mustReachAllTargets={getMetadataBoolean(
                        CODIFICATION_LESSON_METADATA.REACH_ALL_TARGET
                    )}
                    checkResult={isValid}
                />
            );

        case CODIFICATION_LESSON_TYPE.BLOCKLY_DRAW:
            return (
                <BlocklyDraw
                    toolbox={{ contents: toolboxContents }}
                    initialBlocksJsonOrXML={initialBlocksJsonOrXML}
                    character={TLessonCharacterTOTCharacter(
                        lesson.characters?.[0],
                        getCodificationIdByCodes
                    )}
                    targets={TLessonLinesTOTLines(lesson.lines)}
                    scene={scene}
                    xmlRef={xmlRef}
                    autoReset={getMetadataBoolean(
                        CODIFICATION_LESSON_METADATA.AUTO_RESET
                    )}
                    canChangeXY={getMetadataBoolean(
                        CODIFICATION_LESSON_METADATA.CAN_CHANGE_XY
                    )}
                    checkResult={isValid}
                />
            );

        case CODIFICATION_LESSON_TYPE.BLOCKLY_ARCADE:
            return (
                <BlocklyArcade
                    toolbox={{ contents: toolboxContents }}
                    initialBlocksJsonOrXML={initialBlocksJsonOrXML}
                    characters={TLessonCharactersTOTCharacters(
                        lesson.characters,
                        getCodificationIdByCodes
                    )}
                    target={TLessonTargetTOTTarget(
                        lesson.targets?.[0],
                        getCodificationIdByCodes
                    )}
                    scene={scene}
                    xmlRef={xmlRef}
                    checkResult={isValid}
                    editionMode={false}
                />
            );

        case CODIFICATION_LESSON_TYPE.LESSON_SLIDE:
            return (
                <ContentViewer
                    html={lesson.slide?.html ?? ''}
                    css={lesson.slide?.css ?? ''}
                />
            );

        case CODIFICATION_LESSON_TYPE.LESSON_FORMS:
            if (!lesson.question) {
                return <p>Pas de questions disponibles.</p>;
            }

            const questionType = getCodificationsByCodeType(CODIFICATION_TYPE.QUESTION_TYPE)?.find(
                (e) => e.id === lesson.question?.question_type_id
            );

            if (!questionType) {
                return <p>Type de question non supporté.</p>;
            }

            if (questionType.code === CODIFICATION_QUESTION_TYPE.FILL_IN_THE_BLANKS) {
                return (
                    <FillInTheBlank
                        question={lesson.question}
                        onNextLesson={() => {
                            isValid(true);
                        }}
                    />
                );
            }
            else if (questionType.code === CODIFICATION_QUESTION_TYPE.DRAG_AND_DROP) {
                return (
                    <DragAndDrop
                        question={lesson.question}
                        onNextLesson={() => {
                            isValid(true);
                        }}
                    />
                );
            }

            return (
                <QuestionViewer
                    question={lesson.question}
                    onNextLesson={() => {
                        isValid(true);
                    }}
                />
            );

        default:
            return <p>{t('text.exercideUnavailable')}</p>;
    }
};

export default React.memo(LessonContent);
