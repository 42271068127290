import React from 'react';
import { Card, Row, Col, Divider, Alert } from 'antd';
import LineChart from '../../../components/chart/LineChart';
import { TDashboardChildrenData } from '../../../models/user/Dashboard';


interface ChildrenContentProps {
    dashboardChildrenData: TDashboardChildrenData | null;
}
const ChildrenContent: React.FC<ChildrenContentProps> = (props) => {
    return (
        <Row gutter={16} style={{ height: '100%' }}>
            {/* Colonne pour le graphique */}
            <Col span={16}>
                <Card title="Progression" style={{ height: '100%' }}>
                    {props.dashboardChildrenData?.percentage_subject_done &&
                        (<LineChart
                            data={props.dashboardChildrenData.percentage_subject_done}
                            valueType="%"
                        />)}
                </Card>
            </Col>

            {/* Colonne pour la leçon */}
            <Col span={8}>
                <Card
                    title="Leçon en cours"
                    style={{ height: '100%' }}
                >
                    <div>
                        <h2>Epic : {props.dashboardChildrenData?.ongoing_lesson.epic?.name}</h2>
                        <p>
                            En train de progresser sur la leçon : <strong>{props.dashboardChildrenData?.ongoing_lesson?.name}</strong>.
                        </p>
                    </div>
                    <Divider />

                    <Alert
                        type="info"
                        message="D'autres fonctionnalités seront bientôt disponibles !"
                        showIcon
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default ChildrenContent;
