import Phaser from "phaser";

export interface TextObjectConfig {
  // Indique si on est en mode admin
  adminMode: boolean;

  // Identifiant unique
  id: string;

  // Position de base
  x: number;
  y: number;

  // Contenu du texte initial
  textString: string;

  // Style basique (fontSize, color, background, etc.)
  fontSize?: number; // ex: 16
  color?: string; // ex: "#ffffff"
  backgroundColor?: string; // ex: "#00000088"
  // On peut ajouter bold, italic, align, etc.

  /**
   * Nouvel attribut pour la famille de police (facultatif).
   * Ex : "Arial", "Comic Sans MS", "Courier New", etc.
   */
  fontFamily?: string;

  // Largeur/hauteur max si vous voulez le gérer
  // width?: number;
  // height?: number;
  // ...
}

/**
 * Représente un objet "texte" dans Phaser.
 * Sans physique Arcade (typique pour du texte "UI" ou un label).
 */
export class TextObject {
  private adminMode: boolean;
  public id: string;

  // Coordonnées logiques
  public x: number;
  public y: number;

  // Données texte
  private textString: string;
  private fontSize: number;
  private color: string;
  private backgroundColor: string;

  // Ajout de la police
  private fontFamily: string;

  // Référence Phaser
  private scene?: Phaser.Scene;
  public textObj?: Phaser.GameObjects.Text;

  constructor(config: TextObjectConfig) {
    this.adminMode = config.adminMode;
    this.id = config.id;
    this.x = config.x;
    this.y = config.y;

    this.textString = config.textString || "";
    this.fontSize = config.fontSize ?? 16;
    this.color = config.color ?? "#ffffff";
    this.backgroundColor = config.backgroundColor ?? "";

    // Par défaut, on peut choisir "Arial" ou laisser vide.
    this.fontFamily = config.fontFamily ?? "Arial";

    // Vous pourriez stocker align, style, etc. si besoin
  }

  /**
   * Crée l’élément Phaser (Text).
   */
  public createPhaserText(scene: Phaser.Scene) {
    this.scene = scene;

    // Créer le texte
    this.textObj = scene.add.text(this.x, this.y, this.textString, {
      fontSize: `${this.fontSize}px`,
      fontFamily: this.fontFamily, // On utilise la propriété
      color: this.color,
      backgroundColor: this.backgroundColor,
      // align: 'center',  // si besoin
      // fontStyle: 'bold', // etc.
    });

    // Ancrage (origin) si on veut le centrer
    this.textObj.setOrigin(0.5, 0.5);

    // Mode admin : petite info ou un décor ?
    // Ex.: on pourrait ajouter un bounding box
    if (this.adminMode) {
      // Exemple à faire : tracer un rectangle autour du texte
    }
  }

  /**
   * MàJ position logique si besoin
   * (si vous voulez recaler x,y en continu).
   */
  public updatePhaserText() {
    if (!this.textObj) return;
    // on recopie x,y => textObj
    this.textObj.x = this.x;
    this.textObj.y = this.y;
  }

  /**
   * Méthodes de manipulation
   */
  public setText(newText: string) {
    this.textString = newText;
    if (this.textObj) {
      this.textObj.setText(newText);
    }
  }

  public addText(extra: string) {
    // On concatène
    const newT = this.textString + extra;
    this.setText(newT);
  }

  public replaceText(oldSubstring: string, newSubstring: string) {
    const replaced = this.textString.replace(oldSubstring, newSubstring);
    this.setText(replaced);
  }

  public setPosition(newX: number, newY: number) {
    this.x = newX;
    this.y = newY;
    // Si déjà créé, on met à jour l'objet
    if (this.textObj) {
      this.textObj.setPosition(newX, newY);
    }
  }

  public setFontSize(size: number) {
    this.fontSize = size;
    if (this.textObj) {
      this.textObj.setFontSize(size);
    }
  }

  public setColor(newColor: string) {
    this.color = newColor;
    if (this.textObj) {
      this.textObj.setColor(newColor);
    }
  }

  public setBackgroundColor(bg: string) {
    this.backgroundColor = bg;
    if (this.textObj) {
      this.textObj.setBackgroundColor(bg);
    }
  }

  /**
   * La méthode pour changer la police.
   */
  public setFontFamily(fontName: string) {
    // On stocke le nom de la police
    this.fontFamily = fontName;

    if (this.textObj) {
      // Phaser 3 : on peut utiliser setStyle
      this.textObj.setStyle({ fontFamily: fontName });
    }
  }

  /**
   * Supprimer le texte
   */
  public destroy() {
    if (this.textObj) {
      this.textObj.destroy();
      this.textObj = undefined;
    }
  }
}
