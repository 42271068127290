import React, { useEffect, useRef, useState } from 'react';

interface SpriteAnimationPreviewProps {
    imageUrl: string;        // l’URL de la sprite sheet
    frameWidth: number;
    frameHeight: number;
    frameCount: number;
    frameRate: number;
    offsetX: number;
    offsetY: number;
}

/**
 * Affiche l’animation dans un <canvas> en jouant les frames
 */
export const SpriteAnimationPreview: React.FC<SpriteAnimationPreviewProps> = ({
    imageUrl,
    frameWidth,
    frameHeight,
    frameCount,
    frameRate,
    offsetX,
    offsetY
}) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [img, setImg] = useState<HTMLImageElement | null>(null);
    const [currentFrame, setCurrentFrame] = useState(0);

    // Charger l’image (sprite sheet)
    useEffect(() => {
        if (!imageUrl) return;
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            setImg(image);
            setCurrentFrame(0);
        };
    }, [imageUrl]);

    // Boucle d’animation (on change de frame toutes les 1/frameRate secondes)
    useEffect(() => {
        if (!img || frameCount <= 0) return;

        const interval = setInterval(() => {
            setCurrentFrame((prev) => (prev + 1) % frameCount);
        }, 1000 / (frameRate || 1)); // éviter division par 0

        return () => clearInterval(interval);
    }, [img, frameCount, frameRate]);

    // Dessiner dans le canvas
    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas || !img) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Nettoyer
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calcul de la zone source
        const sx = offsetX + currentFrame * frameWidth;
        const sy = offsetY;
        // Dessin
        ctx.drawImage(img, sx, sy, frameWidth, frameHeight, 0, 0, frameWidth, frameHeight);
    }, [currentFrame, img, frameWidth, frameHeight, offsetX, offsetY]);

    return (
        <canvas
            ref={canvasRef}
            width={frameWidth}
            height={frameHeight}
            style={{
                border: '1px solid #ccc',
                background: '#eee'
            }}
        />
    );
};
