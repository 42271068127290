import * as acorn from 'acorn';
import * as walk from 'acorn-walk';

/**
 * Interface décrivant un interpréteur step-by-step
 * qui gère aussi les "UserFunction" (fonctions AST) façon Scratch.
 */
export interface CodeInterpreter {
    init: (code: string) => void;
    reorderAddEventListenersInString: (originalCode: string) => string;

    /**
     * Exécute *une* instruction (ou statement).
     * Rendu asynchrone pour gérer du code qui contient `await`.
     */
    step: () => Promise<void>;

    /**
     * Exécute tout le code, en boucle, jusqu'à la fin ou qu'on arrête (isRunning=false, etc.).
     * On applique un petit délai (delay=300) entre chaque step, comme dans le code d'origine,
     * pour reproduire le pas "visuel".
     */
    runAll: () => Promise<void>;

    /**
     * Indique s'il reste des instructions à exécuter.
     */
    hasMore: () => boolean;

    /**
     * Permet d'injecter un appel de fonction (callback) au moment d'un événement,
     * ex: collision => injectUserFunctionCall(callbackAst, { eventData: ... });
     */
    injectUserFunctionCall: (fnObject: UserFunction, additionalContext?: Record<string, any>) => void;

    // setters
    setPaused: (paused: boolean) => void;
    setRunning: (running: boolean) => void;
}

/**
 * Représente une "fonction" définie par l'utilisateur (FunctionExpression / ArrowFunctionExpression)
 * qu'on veut exécuter en pas-à-pas.
 */
interface UserFunction {
    type: 'UserFunction';
    params: string[];       // noms des paramètres
    body: any[];            // tableau d'instructions (AST)
    isArrow?: boolean;      // info si besoin, non indispensable
}

/**
 * Contexte d'exécution (provenant de l'extérieur),
 * avec isRunning, isPaused, etc.
 */
interface ExecutionContext {
    isRunning: boolean;
    isPaused: boolean;
    currentStep: number;
    nextStep: () => void;
    addLog: (msg: string) => void;
    stop: () => void;
}

/**
 * Crée un interpréteur "façon Scratch" :
 * - On NE compile PAS les FunctionExpression en "new Function(...)"
 * - On stocke l'AST dans un UserFunction
 * - Au moment d'un appel, on injecte le corps dans executionStack
 */
export function createCodeInterpreter(executionContext: ExecutionContext): CodeInterpreter {
    let ast: any = null;                     // AST global du code
    let executionStack: any[] = [];          // pile de statements à exécuter step-by-step
    let contextVariables: Record<string, any> = {};  // variables "globales" simplifiées

    // On conserve le delay de 300ms entre les steps, comme dans le code d'origine.
    let delay = 300;

    /**
     * init(code) : parse l'AST, reset la pile, etc.
     */
    function init(code: string) {
        // reset
        executionStack = [];
        contextVariables = {};

        // 1) reorder => place en haut tout addCollisionListener etc.
        const reordered = reorderAddEventListenersInString(code);

        // 2) sépare eventSetup vs main
        const { eventSetupCode, mainCode } = separateEventSetupFromMain(reordered);


        try {
            // 3) exécute immédiatement le eventSetupCode ( hors step-by-step )
            if (eventSetupCode.trim()) {
                // eslint-disable-next-line
                eval(eventSetupCode);
            }

            // 4) parse mainCode => stocke dans ast pour le step-by-step
            ast = acorn.parse(mainCode, {
                ecmaVersion: 'latest',
                sourceType: 'module',
                allowAwaitOutsideFunction: true,
            });
            executionContext.addLog('Code analysé avec succès (mainCode).');
        } catch (err: any) {
            executionContext.addLog(`Erreur lors de l'analyse (mainCode) : ${err.message}`);
            executionContext.stop();
        }
    }

    function separateEventSetupFromMain(fullCode: string): {
        eventSetupCode: string;
        mainCode: string;
    } {
        const lines = fullCode.split('\n');
        const eventLines: string[] = [];
        const otherLines: string[] = [];

        let isInEventBlock = false;
        let tempBlock: string[] = [];

        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];

            // On juge qu'une "event line" commence si on voit un "add...Listener(" ou "addTimerEvent("
            if (!isInEventBlock) {
                if ((line.includes('add') && line.includes('Listener(')) || line.includes('addTimerEvent(')) {
                    isInEventBlock = true;
                    tempBlock = [line];
                } else {
                    otherLines.push(line);
                }
            } else {
                // on est dans un bloc d'event
                tempBlock.push(line);
                if (line.includes('});')) {
                    eventLines.push(...tempBlock);
                    tempBlock = [];
                    isInEventBlock = false;
                }
            }
        }
        // Si un bloc event n'a pas été refermé
        if (tempBlock.length > 0) {
            eventLines.push(...tempBlock);
        }

        return {
            eventSetupCode: eventLines.join('\n'),
            mainCode: otherLines.join('\n'),
        };
    }
    /**
 * reorderAddEventListenersInString
 *
 * Prend le code généré par Blockly (sous forme de string),
 * détecte toutes les lignes (et blocs) qui contiennent un des appels
 * addCollisionListener( / addCharacterObjectCollisionListener( / etc.
 * et les met en haut du code, afin que les events soient enregistrés
 * avant le reste des instructions.
 */
    function reorderAddEventListenersInString(originalCode: string): string {
        // Définition des mots-clés qu’on cherche
        const eventListenerKeywords = [
            'addCollisionListener(',
            'addCharacterObjectCollisionListener(',
            'addReachPositionListener(',
            'addKeyPressListener(',
            'addClickObjectListener(',
            'addTimerEvent('
        ];

        const lines = originalCode.split('\n');

        const eventBlocks: string[] = [];
        const otherLines: string[] = [];

        let isInEventBlock = false;
        let tempBlock: string[] = []; // pour accumuler les lignes d'un bloc d'event

        for (const line of lines) {
            // Si on n’est PAS encore dans un bloc d’event
            if (!isInEventBlock) {
                // On détecte si la ligne contient l’un des keywords
                if (eventListenerKeywords.some(keyword => line.includes(keyword))) {
                    isInEventBlock = true;
                    tempBlock = [line]; // la ligne courante est le début du bloc
                } else {
                    // Sinon, c’est juste une ligne "normale"
                    otherLines.push(line);
                }
            } else {
                // On est déjà dans un bloc event
                tempBlock.push(line);

                // On détecte la fin du bloc => si la ligne contient "});"
                if (line.includes('});')) {
                    // Le bloc se termine : on l’enregistre comme un "event block"
                    eventBlocks.push(...tempBlock);
                    // reset
                    tempBlock = [];
                    isInEventBlock = false;
                }
            }
        }

        // Si jamais la fin du code arrive sans `});`,
        // on push quand même ce qu’on a dans eventBlocks
        if (tempBlock.length > 0) {
            eventBlocks.push(...tempBlock);
        }

        // On recompose : d’abord tous les blocs event, puis le reste
        const newLines = [...eventBlocks, ...otherLines];
        return newLines.join('\n');
    }

    /**
     * setPaused, setRunning : on met à jour le contexte isPaused, isRunning
     */
    function setPaused(paused: boolean) {
        executionContext.isPaused = paused;
    }
    function setRunning(running: boolean) {
        executionContext.isRunning = running;
    }

    /**
     * hasMore() : vrai si la pile n'est pas vide ou qu'on n'a pas encore exploré le Program
     */
    function hasMore(): boolean {
        return executionStack.length > 0 || ast !== null;
    }

    /**
     * step() : exécute UNE instruction/statement, en mode async
     */
    async function step(): Promise<void> {
        // Conditions d'arrêt
        if (!hasMore() || executionContext.isPaused || !executionContext.isRunning) {
            executionContext.stop();
            return;
        }

        // Si on n'a pas encore "extract" le Program => on push tous les statements
        if (executionStack.length === 0 && ast) {
            walk.simple(ast, {
                Program(node: any) {
                    // push tous les statements en ordre inverse
                    for (let i = node.body.length - 1; i >= 0; i--) {
                        executionStack.push(node.body[i]);
                    }
                },
            });
            ast = null;
        }

        // On dépile un statement, on l'exécute
        if (executionStack.length > 0) {
            const nextNode = executionStack.pop();
            try {
                await executeStatement(nextNode);
                executionContext.nextStep(); // On signale qu'un step a été fait
            } catch (err: any) {
                executionContext.addLog(`Erreur lors de l'exécution du nœud ${nextNode?.type}: ${err.message}`);
                executionContext.stop();
            }
        }
    }

    /**
     * runAll() : boucle asynchrone, on exécute step() jusqu'à la fin ou l'arrêt,
     * en respectant un "delay" de 300 ms entre deux steps (comme dans le code original).
     */
    async function runAll(): Promise<void> {
        while (hasMore() && executionContext.isRunning && !executionContext.isPaused) {
            await step();
            // on attend 300ms avant le prochain step, pour l'effet "lent"
            await new Promise((r) => setTimeout(r, delay));
        }
    }

    //=================================
    // GESTION DES STATEMENTS (async)
    //=================================
    /**
     * executeStatement : exécute un statement.
     * Rendu "async" pour qu'il puisse faire `await evalExpression(...)` si besoin.
     */
    async function executeStatement(node: any): Promise<void> {
        switch (node.type) {
            case 'VariableDeclaration':
                await handleVariableDeclaration(node);
                break;

            case 'ExpressionStatement':
                // ex: highlightBlock('xx');
                await evalExpression(node.expression);
                break;

            case 'ForStatement':
                await handleForStatement(node);
                break;

            case 'ForLoop':
                await executeForLoop(node);
                break;

            case 'IfStatement':
                await handleIfStatement(node);
                break;

            case 'SwitchStatement':
                await handleSwitchStatement(node);
                break;

            case 'WhileStatement':
                await handleWhileStatement(node);
                break;

            case 'WhileLoop': // l'objet "maison" qu'on va créer, comme ForLoop
                await executeWhileLoop(node);
                break;

            default:
                executionContext.addLog(`Type de statement non pris en charge : ${node.type}`);
        }
    }

    /**
     * Déclaration de variables : ex: let x = 10;
     */
    async function handleVariableDeclaration(node: any) {
        for (const decl of node.declarations) {
            const varName = decl.id.name;
            // On évalue la partie init s'il y en a une
            const varValue = decl.init ? await evalExpression(decl.init) : undefined;
            contextVariables[varName] = varValue;
            executionContext.addLog(`Variable déclarée : ${varName} = ${varValue}`);
        }
    }

    /**
     * await statemant : ex: await function()
     */
    async function handleAwaitExpression(expr: any): Promise<any> {
        // 1) On évalue l’argument => ex: "someAsyncFunction(...)"
        // const awaitedValue = await evalExpression(expr.argument);

        // Bon : on récupère la Promesse brute
        const maybePromise = evalExpression(expr.argument); // SANS await

        if (!maybePromise || typeof maybePromise.then !== 'function') {
            throw new Error(`"await" est utilisé sur une valeur qui n'est pas une Promise.`);
        }
        // Ici, on attend réellement la promesse
        return await maybePromise;
    }

    /**
     * ForStatement : ex: for (let i=0; i<10; i++) { ... }
     */
    async function handleForStatement(node: any) {
        // on exécute node.init (qui peut être un 'VariableDeclaration' ou 'ExpressionStatement')
        if (node.init) {
            await executeStatement(node.init);
        }
        // on empile un "ForLoop"
        executionStack.push({
            type: 'ForLoop',
            test: node.test,
            update: node.update,
            body: node.body.body,
        });
    }

    /**
     * handleWhileStatement : ex: tant que (x<5) { ... }
     */

    async function handleWhileStatement(node: any) {
        // On empile un objet "WhileLoop" (faux node) qui stocke la condition et le corps
        executionStack.push({
            type: 'WhileLoop',
            test: node.test,
            body: node.body.body,  // si node.body est un BlockStatement, on récupère body[]
        });
    }

    /**
     * executeWhileLoop
     */

    async function executeWhileLoop(whileNode: any) {
        const conditionResult = await evalExpression(whileNode.test);
        if (conditionResult) {
            // Condition vraie => on se ré-empile nous-mêmes pour la prochaine itération
            executionStack.push(whileNode);

            // On empile le corps de la boucle (en ordre inverse)
            for (let i = whileNode.body.length - 1; i >= 0; i--) {
                executionStack.push(whileNode.body[i]);
            }
        } else {
            executionContext.addLog(`Fin de la boucle while (condition == false)`);
        }
    }


    /**
     * ForLoop : objet "maison" qu'on a empilé, pour exécuter la condition, l'update, le body, etc.
     */
    async function executeForLoop(forNode: any) {
        const testResult = await evalExpression(forNode.test);
        if (testResult) {
            // On se réempile pour l'itération suivante
            executionStack.push(forNode);

            // On empile l'update (ex: i++)
            if (forNode.update) {
                executionStack.push({
                    type: 'ExpressionStatement',
                    expression: forNode.update,
                });
            }

            // On empile le corps
            for (let i = forNode.body.length - 1; i >= 0; i--) {
                executionStack.push(forNode.body[i]);
            }
        } else {
            executionContext.addLog(`Fin de la boucle for. (testResult = false)`);
        }
    }

    /**
     * IfStatement
     */
    async function handleIfStatement(node: any) {
        const conditionResult = await evalExpression(node.test);
        if (conditionResult) {
            if (node.consequent && node.consequent.body) {
                for (let i = node.consequent.body.length - 1; i >= 0; i--) {
                    executionStack.push(node.consequent.body[i]);
                }
            }
        } else {
            // else / else if
            if (node.alternate) {
                if (node.alternate.type === 'BlockStatement') {
                    for (let i = node.alternate.body.length - 1; i >= 0; i--) {
                        executionStack.push(node.alternate.body[i]);
                    }
                } else if (node.alternate.type === 'IfStatement') {
                    executionStack.push(node.alternate);
                }
            }
        }
    }

    /**
 * Gère un SwitchStatement.
 * On évalue la valeur du switch (node.discriminant).
 * Puis on parcourt les 'cases' dans l'ordre :
 *   - si test == null => c'est le default
 *   - sinon on compare switchValue === caseValue
 * On empile les statements du premier case qui matche,
 * ou du default si rien n'a matché.
 */
    async function handleSwitchStatement(node: any) {
        const switchVal = await evalExpression(node.discriminant);
        let matchedCase = false;

        for (let i = 0; i < node.cases.length; i++) {
            const switchCase = node.cases[i];

            // switchCase.test == null => 'default:'
            if (switchCase.test === null) {
                // default
                if (!matchedCase) {
                    // empiler les statements du default
                    for (let j = switchCase.consequent.length - 1; j >= 0; j--) {
                        executionStack.push(switchCase.consequent[j]);
                    }
                }
                // Qu'on ait matché ou pas, on sort de la boucle (comme un break implicite).
                break;
            } else {
                // Evaluate case test
                const caseVal = await evalExpression(switchCase.test);
                if (caseVal === switchVal) {
                    matchedCase = true;
                    // empiler les statements de ce case
                    for (let j = switchCase.consequent.length - 1; j >= 0; j--) {
                        executionStack.push(switchCase.consequent[j]);
                    }
                    // On sort => comme un break;
                    break;
                }
            }
        }
    }


    //=================================
    // EVALUATION DES EXPRESSIONS (async)
    //=================================
    /**
     * evalExpression : renvoie la valeur d'une expression.
     * On met async car on doit supporter "AwaitExpression" si vous le souhaitez un jour,
     * et pour être cohérent avec l'ensemble du pipeline.
     */
    async function evalExpression(expr: any): Promise<any> {
        switch (expr.type) {
            case 'Literal':
                return expr.value;

            case 'Identifier':
                return evalIdentifier(expr);

            case 'AssignmentExpression':
                return evalAssignmentExpression(expr);

            case 'BinaryExpression':
                return evalBinaryExpression(expr);

            case 'UpdateExpression':
                return evalUpdateExpression(expr);

            case 'CallExpression':
                return evalCallExpression(expr);

            case 'MemberExpression':
                return evalMemberExpression(expr);

            case 'LogicalExpression':
                return evalLogicalExpression(expr);

            case 'AwaitExpression':
                return await handleAwaitExpression(expr);

            // Les FunctionExpression/ArrowFunctionExpression => on stocke l'AST dans un UserFunction
            case 'FunctionExpression':
            case 'ArrowFunctionExpression':
                return compileUserFunction(expr);

            default:
                throw new Error(`Expression non supportée : ${expr.type}`);
        }
    }

    function evalIdentifier(expr: any): any {
        if (expr.name === 'window') return window;
        if (expr.name === 'console') return console;

        if (!(expr.name in contextVariables)) {
            const maybeGlobal = (window as any)[expr.name];
            if (maybeGlobal !== undefined) {
                return maybeGlobal;
            }
            throw new Error(`Variable non déclarée : ${expr.name}`);
        }
        return contextVariables[expr.name];
    }

    async function evalAssignmentExpression(expr: any): Promise<any> {
        if (expr.left.type !== 'Identifier') {
            throw new Error(`Affectation non gérée si la gauche n'est pas un Identifiant`);
        }

        const varName = expr.left.name;
        const rightValue = await evalExpression(expr.right);

        switch (expr.operator) {
            case '=':
                contextVariables[varName] = rightValue;
                break;
            case '+=':
                contextVariables[varName] = (contextVariables[varName] || 0) + rightValue;
                break;
            case '-=':
                contextVariables[varName] = (contextVariables[varName] || 0) - rightValue;
                break;
            default:
                throw new Error(`Opérateur d'affectation non géré : ${expr.operator}`);
        }
        return contextVariables[varName];
    }

    async function evalBinaryExpression(expr: any): Promise<any> {
        const left = await evalExpression(expr.left);
        const right = await evalExpression(expr.right);

        switch (expr.operator) {
            case '+': return left + right;
            case '-': return left - right;
            case '*': return left * right;
            case '/': return left / right;
            case '<': return left < right;
            case '<=': return left <= right;
            case '>': return left > right;
            case '>=': return left >= right;
            case '==': return left === right;
            case '!=': return left !== right;
            default:
                throw new Error(`Opérateur binaire non supporté : ${expr.operator}`);
        }
    }

    async function evalUpdateExpression(expr: any): Promise<any> {
        const varName = expr.argument.name;
        if (!(varName in contextVariables)) {
            throw new Error(`Variable non déclarée : ${varName}`);
        }
        if (expr.operator === '++') {
            contextVariables[varName]++;
            return contextVariables[varName];
        } else if (expr.operator === '--') {
            contextVariables[varName]--;
            return contextVariables[varName];
        }
        throw new Error(`Opérateur update non supporté : ${expr.operator}`);
    }

    async function evalMemberExpression(expr: any): Promise<any> {
        const objVal = await evalExpression(expr.object);
        const propName = expr.computed
            ? await evalExpression(expr.property)
            : expr.property.name;

        if (!objVal) {
            throw new Error('Objet de MemberExpression est falsy');
        }
        return objVal[propName];
    }

    async function evalLogicalExpression(expr: any): Promise<any> {
        const leftVal = await evalExpression(expr.left);

        switch (expr.operator) {
            case '&&':
                // En JS, si le leftVal est falsy, on ne calcule pas la droite
                return leftVal ? await evalExpression(expr.right) : leftVal;

            case '||':
                // En JS, si le leftVal est truthy, on ne calcule pas la droite
                return leftVal ? leftVal : await evalExpression(expr.right);

            default:
                throw new Error(`Opérateur logique non supporté : ${expr.operator}`);
        }
    }


    async function evalCallExpression(expr: any): Promise<any> {
        // On attend JUSTE la valeur du callee (ex. "walkCharacter")
        // car "walkCharacter" lui-même est une fonction => n'est pas forcément la promesse,
        // c'est l'IDENTIFIER ou un MEMBEREXPRESSION.
        const calleeVal = await evalExpression(expr.callee);

        // On évalue (en sync ou async) les arguments
        const args = [];
        for (const arg of expr.arguments) {
            args.push(await evalExpression(arg));
        }

        // Si c'est un "UserFunction"
        if (calleeVal && calleeVal.type === 'UserFunction') {
            // => on injecte le corps => pas de promise
            return callUserFunction(calleeVal, args);
        }
        else if (typeof calleeVal === 'function') {
            // On appelle la fonction "normalement"
            // MAIS on ne fait pas `await` ici => on veut la promesse brute
            const promiseOrValue = calleeVal(...args);
            // On renvoie la promesse brute (ou la valeur).
            return promiseOrValue;
        }
        else {
            throw new Error(`Cible d'appel invalide : ${expr.callee.type}`);
        }
    }

    //=================================
    // GESTION DES "USERFUNCTION"
    //=================================
    function compileUserFunction(fnAst: any): UserFunction {
        const params = fnAst.params.map((p: any) => p.name || 'arg');
        let body: any[] = [];

        if (fnAst.body.type === 'BlockStatement') {
            body = fnAst.body.body; // tableau de statements
        } else {
            // arrow function => expr, on l'enrobe dans un ReturnStatement
            body = [
                {
                    type: 'ReturnStatement',
                    argument: fnAst.body,
                },
            ];
        }
        const userFn: UserFunction = {
            type: 'UserFunction',
            params,
            body,
            isArrow: (fnAst.type === 'ArrowFunctionExpression'),
        };
        return userFn;
    }

    /**
     * callUserFunction => injecte le corps du userFn dans la pile.
     */
    function callUserFunction(fn: UserFunction, args: any[]) {
        // On assigne les args dans contextVariables
        fn.params.forEach((paramName, idx) => {
            contextVariables[paramName] = args[idx];
        });

        // On empile son body (en ordre inverse)
        for (let i = fn.body.length - 1; i >= 0; i--) {
            executionStack.push(fn.body[i]);
        }
        return undefined;
    }

    /**
     * Permet à l'extérieur d'injecter un "UserFunction"
     * en cours d'exécution. (ex.: event collision => injectUserFunctionCall(cb, { eventData: ... }))
     */
    function injectUserFunctionCall(fn: UserFunction, additionalContext?: Record<string, any>) {
        if (additionalContext) {
            Object.entries(additionalContext).forEach(([k, v]) => {
                contextVariables[k] = v;
            });
        }
        // On empile le corps 
        for (let i = fn.body.length - 1; i >= 0; i--) {
            executionStack.push(fn.body[i]);
        }
    }

    //=================================
    // RETOUR DE L'INTERPRETEUR
    //=================================
    return {
        init,
        reorderAddEventListenersInString,
        step,         // async
        runAll,       // async
        hasMore,
        injectUserFunctionCall,
        setPaused,
        setRunning,
    };
}
