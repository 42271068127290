// src/components/BlocklyWorkspace/blocklyConfig.ts

import { Blocks } from 'blockly/core';
import { javascriptGenerator } from 'blockly/javascript';
import * as Blockly from 'blockly';
import { CharacterConfig, ObjectConfig } from '../../types/BlocklyTypes';

/**
 * Définit tous les blocs "Events" (collisions, key press, timer, etc.)
 */
export function defineEventBlocks(characters: CharacterConfig[], objects: ObjectConfig[]) {

    // ==========================
    // 1) on_collision_character_character
    // ==========================
    Blocks['on_collision_character_character'] = {
        init: function () {
            const entityADropdown = new Blockly.FieldDropdown(() => {
                if (!characters.length) {
                    return [["No Characters Available", "NO_CHARACTER"]];
                }
                return characters.map((c) => [c.name, c.id]);
            });

            const entityBDropdown = new Blockly.FieldDropdown(() => {
                const entityASelected = entityADropdown.getValue();
                const filtered = characters.filter((c) => c.id !== entityASelected);

                if (!filtered.length) {
                    return [["No Characters Available", "NO_CHARACTER"]];
                }
                return filtered.map((c) => [c.name, c.id]);
            });

            this.appendDummyInput()
                .appendField('Collision (perso) entre')
                .appendField(entityADropdown, 'entityA')
                .appendField('et')
                .appendField(entityBDropdown, 'entityB');

            this.appendStatementInput('DO')
                .setCheck(null)
                .appendField('do');
            this.setColour(210);
            this.setTooltip('Déclenche ce bloc lorsque 2 personnages (entityA / entityB) entrent en collision.');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['on_collision_character_character'] = function (block) {
        const entityA = block.getFieldValue('entityA');
        const entityB = block.getFieldValue('entityB');
        const branch = javascriptGenerator.statementToCode(block, 'DO');
        // On rend la fonction async
        const code = `
          (window.eventManager).addCollisionListener('${entityA}', '${entityB}', async function(eventData) {  
              ${branch}
          });
          \n`;
        return code;
    };


    // ==========================
    // 2) on_collision_character_object
    // ==========================
    Blocks['on_collision_character_object'] = {
        init: function () {
            // Dropdown pour le personnage
            const charDropdown = new Blockly.FieldDropdown(() => {
                if (!characters.length) {
                    return [["No Characters", "NO_CHAR"]];
                }
                return characters.map(c => [c.name, c.id]);
            });

            // Dropdown pour l'objet
            const objDropdown = new Blockly.FieldDropdown(() => {
                if (!objects.length) {
                    return [["No Objects", "NO_OBJ"]];
                }
                return objects.map(o => [o.name, o.id]);
            });

            this.appendDummyInput()
                .appendField("Collision perso")
                .appendField(charDropdown, "CHAR")
                .appendField("avec objet")
                .appendField(objDropdown, "OBJ_ID");
            this.appendStatementInput("DO")
                .setCheck(null)
                .appendField("do");
            this.setColour(210);
            this.setTooltip("Collision entre un perso et un objet (choisir dans la liste).");
            this.setHelpUrl("");
        }
    };
    javascriptGenerator.forBlock['on_collision_character_object'] = function (block) {
        const charId = block.getFieldValue("CHAR");
        const objId = block.getFieldValue("OBJ_ID");
        const branch = javascriptGenerator.statementToCode(block, "DO");
        return `
        (window.eventManager).addCollisionListener('${charId}', '${objId}', async function(eventData){
           ${branch}
        });\n`;
    };


    // ==========================
    // 3) on_character_reach_position
    // ==========================
    Blocks['on_character_reach_position'] = {
        init: function () {
            // Dropdown pour le personnage
            const charDropdown = new Blockly.FieldDropdown(() => {
                if (!characters.length) {
                    return [["No Characters", "NO_CHAR"]];
                }
                return characters.map(c => [c.name, c.id]);
            });

            this.appendDummyInput()
                .appendField("quand perso")
                .appendField(charDropdown, "CHAR")
                .appendField("arrive à (");
            this.appendDummyInput()
                .appendField(new Blockly.FieldNumber(0), "X")
                .appendField(",")
                .appendField(new Blockly.FieldNumber(0), "Y")
                .appendField(")");

            this.appendStatementInput("DO")
                .setCheck(null)
                .appendField("do");

            this.setColour(210);
            this.setTooltip("Déclenche quand le personnage atteint la position (x,y).");
            this.setHelpUrl("");
        }
    };
    javascriptGenerator.forBlock['on_character_reach_position'] = function (block) {
        const charId = block.getFieldValue("CHAR");
        const xPos = block.getFieldValue("X");
        const yPos = block.getFieldValue("Y");
        const branch = javascriptGenerator.statementToCode(block, "DO");

        return `
        (window.eventManager).addReachPositionListener('${charId}', ${xPos}, ${yPos}, async function(eventData){
            ${branch}
        });\n`;
    };


    // ==========================
    // 4) on_key_press
    // ==========================
    Blocks['on_key_press'] = {
        init: function () {
            // Liste prédéfinie : Espace, Entrée, A, B, C..., 0..9, etc. 
            const keyDropdown = new Blockly.FieldDropdown([
                ["Enter", "Enter"],
                ["Espace", " "],
                ["A", "a"],
                ["B", "b"],
                ["C", "c"],
                ["0", "0"],
                ["1", "1"],
                ["2", "2"],
            ]);

            this.appendDummyInput()
                .appendField("quand la touche")
                .appendField(keyDropdown, "KEY")
                .appendField("est pressée");
            this.appendStatementInput("DO")
                .setCheck(null)
                .appendField("do");
            this.setColour(210);
            this.setTooltip("Déclenche quand la touche spécifiée est appuyée.");
            this.setHelpUrl("");
        }
    };
    javascriptGenerator.forBlock['on_key_press'] = function (block) {
        const key = block.getFieldValue("KEY") || "Enter";
        const branch = javascriptGenerator.statementToCode(block, "DO");
        return `
        (window.eventManager).addKeyPressListener('${key}', async function(eventData){
           ${branch}
        });
        \n`;
    };


    // ==========================
    // 5) on_mouse_click_object
    // ==========================
    Blocks['on_mouse_click_object'] = {
        init: function () {
            // On propose aussi un dropdown pour la liste des objets
            const objDropdown = new Blockly.FieldDropdown(() => {
                if (!objects.length) {
                    return [["No Objects", "NO_OBJ"]];
                }
                return objects.map(o => [o.name, o.id]);
            });

            this.appendDummyInput()
                .appendField("quand on clique sur l'objet")
                .appendField(objDropdown, "OBJ_ID");
            this.appendStatementInput("DO")
                .setCheck(null)
                .appendField("do");
            this.setColour(210);
            this.setTooltip("Déclenche quand on clique sur un objet spécifique.");
            this.setHelpUrl("");
        }
    };
    javascriptGenerator.forBlock['on_mouse_click_object'] = function (block) {
        const objId = block.getFieldValue("OBJ_ID");
        const branch = javascriptGenerator.statementToCode(block, "DO");
        return `
        (window.eventManager).addClickObjectListener('${objId}', async function(eventData){
           ${branch}
        });
        \n`;
    };


    // ==========================
    // 6) on_timer_event
    // ==========================
    Blocks['on_timer_event'] = {
        init: function () {
            this.appendDummyInput()
                .appendField("à chaque (secondes)")
                .appendField(new Blockly.FieldNumber(1, 0, Infinity, 1), "SECONDS")
                .appendField("faire");

            this.appendStatementInput("DO")
                .setCheck(null)
                .appendField("instructions");

            this.setColour(210);
            this.setTooltip("Déclenche toutes les X secondes. A adapter selon votre besoin.");
            this.setHelpUrl("");
        }
    };
    javascriptGenerator.forBlock['on_timer_event'] = function (block) {
        const seconds = block.getFieldValue("SECONDS") || 1;
        const branch = javascriptGenerator.statementToCode(block, "DO");
        return `
        (window.eventManager).addTimerEvent(${seconds}, async function(eventData){
           ${branch}
        }, true );
        \n`;
    };


}
