import { CodeInterpreter } from "../../../services/codeInterpreter";

/**
 * Représente un événement collision
 * (perso vs perso) ou (perso vs objet) si vous réutilisez la structure
 */
export interface CollisionEventData {
    entityA: string;
    entityB: string;
    // positions etc. si besoin
}

export interface UserFunction {
    type: "UserFunction";
    params: string[]; // ex.: ["eventData"]
    body: any[];
    isArrow?: boolean;
}

export type CollisionCallbackAst = UserFunction;

type EventCallback = (...args: any[]) => void;

/**
 * Type de callback pour un événement (perso-perso collision, etc.)
 * Désormais, c’est une FONCTION JS, pas un AST.
 */
export type CollisionCallbackFn = (eventData: CollisionEventData) => void;

/**
 * Idem pour perso-objet, timer, etc.
 */
type CharacterObjectCollisionCallbackFn = (eventData: { charId: string; objId: string; }) => void;
type ReachPositionCallbackFn = (eventData: { charId: string; x: number; y: number; }) => void;
type KeyPressCallbackFn = (eventData: { key: string }) => void;
type ClickObjectCallbackFn = (eventData: { objId: string }) => void;
type TimerCallbackFn = (eventData: { seconds: number }) => void;

/**
 * EventManager "Scratch-like"
 */
export class EventManager {
    private interpreter: CodeInterpreter;

    // map générique on/off
    private genericListeners = new Map<string, EventCallback[]>();

    // Collisions perso-perso : Map< key=“charA|charB”, array de fonctions >
    private characterCollisionListeners = new Map<string, CollisionCallbackFn[]>();

    // Collisions perso-objet
    private characterObjectCollisionListeners = new Map<string, CharacterObjectCollisionCallbackFn[]>();

    // reach position
    private reachPositionListeners = new Map<string, ReachPositionCallbackFn[]>();

    // key press
    private keyPressListeners = new Map<string, KeyPressCallbackFn[]>();

    // click object
    private clickObjectListeners = new Map<string, ClickObjectCallbackFn[]>();

    // timer
    private intervalIds: NodeJS.Timer[] = [];


    constructor(interpreter: CodeInterpreter) {
        this.interpreter = interpreter;
    }

    //////////////////////////////
    // PARTIE GENERIQUE on/emit
    //////////////////////////////

    public on(eventName: string, callback: EventCallback) {
        if (!this.genericListeners.has(eventName)) {
            this.genericListeners.set(eventName, []);
        }
        this.genericListeners.get(eventName)!.push(callback);
    }

    public emit(eventName: string, ...args: any[]) {
        const arr = this.genericListeners.get(eventName);
        if (!arr) return;
        arr.forEach((fn) => fn(...args));
    }

    //////////////////////////////
    // 1) collisions perso-perso
    //////////////////////////////

    public addCollisionListener = (
        entityA: string,
        entityB: string,
        callbackFn: CollisionCallbackFn
    ) => {
        const key = this.normalizeCollisionKey(entityA, entityB);
        if (!this.characterCollisionListeners.has(key)) {
            this.characterCollisionListeners.set(key, []);
        }
        this.characterCollisionListeners.get(key)!.push(callbackFn);
    };

    public dispatchCollisionEvent = (entityA: string, entityB: string) => {
        const key = this.normalizeCollisionKey(entityA, entityB);
        const arr = this.characterCollisionListeners.get(key);
        if (arr) {
            const eventData: CollisionEventData = { entityA, entityB };
            arr.forEach((callbackFn) => {
                // On appelle directement la fonction JS
                callbackFn(eventData);
            });
        }
        this.emit("collisionEvent", entityA, entityB);
    };

    private normalizeCollisionKey = (entityA: string, entityB: string): string => {
        const pair = [entityA, entityB].sort();
        return pair.join("|");
    };

    //////////////////////////////
    // 2) collisions perso-objet
    //////////////////////////////

    public addCharacterObjectCollisionListener(
        charId: string,
        objId: string,
        callbackFn: CharacterObjectCollisionCallbackFn
    ) {
        const key = this.normalizeCollisionKey(charId, objId);
        if (!this.characterObjectCollisionListeners.has(key)) {
            this.characterObjectCollisionListeners.set(key, []);
        }
        this.characterObjectCollisionListeners.get(key)!.push(callbackFn);
    }

    public dispatchCharacterObjectCollisionEvent(charId: string, objId: string) {
        const key = this.normalizeCollisionKey(charId, objId);
        const arr = this.characterObjectCollisionListeners.get(key);
        if (arr) {
            const eventData = { charId, objId };
            arr.forEach((fn) => {
                // Appel direct
                fn(eventData);
            });
        }
        this.emit("characterObjectCollisionEvent", charId, objId);
    }

    //////////////////////////////
    // 3) reach position
    //////////////////////////////

    private normalizeReachPosKey(charId: string, x: number, y: number) {
        return `${charId}|${x}|${y}`;
    }

    public addReachPositionListener(
        charId: string,
        x: number,
        y: number,
        callbackFn: ReachPositionCallbackFn
    ) {
        const key = this.normalizeReachPosKey(charId, x, y);
        if (!this.reachPositionListeners.has(key)) {
            this.reachPositionListeners.set(key, []);
        }
        this.reachPositionListeners.get(key)!.push(callbackFn);
    }

    public dispatchReachPositionEvent(charId: string, x: number, y: number) {
        const key = this.normalizeReachPosKey(charId, x, y);
        const arr = this.reachPositionListeners.get(key);
        if (arr) {
            const eventData = { charId, x, y };
            arr.forEach((fn) => {
                // Appel direct
                fn(eventData);
            });
        }
        this.emit("reachPositionEvent", charId, x, y);
    }

    //////////////////////////////
    // 4) key press
    //////////////////////////////

    public addKeyPressListener(keyName: string, callbackFn: KeyPressCallbackFn) {
        if (!this.keyPressListeners.has(keyName)) {
            this.keyPressListeners.set(keyName, []);
        }
        this.keyPressListeners.get(keyName)!.push(callbackFn);
    }

    public dispatchKeyPressEvent(keyName: string) {
        const arr = this.keyPressListeners.get(keyName);
        if (arr) {
            const eventData = { key: keyName };
            arr.forEach((fn) => {
                fn(eventData);  // Appel direct
            });
        }
        this.emit("keyPressEvent", keyName);
    }

    //////////////////////////////
    // 5) click object
    //////////////////////////////

    public addClickObjectListener(objId: string, callbackFn: ClickObjectCallbackFn) {
        if (!this.clickObjectListeners.has(objId)) {
            this.clickObjectListeners.set(objId, []);
        }
        this.clickObjectListeners.get(objId)!.push(callbackFn);
    }

    public dispatchClickObjectEvent(objId: string) {
        const arr = this.clickObjectListeners.get(objId);
        if (arr) {
            const eventData = { objId };
            arr.forEach((fn) => {
                fn(eventData);
            });
        }
        this.emit("clickObjectEvent", objId);
    }

    //////////////////////////////
    // 6) timer event
    //////////////////////////////

    public addTimerEvent(seconds: number, callbackFn: TimerCallbackFn, repeat: boolean = false) {
        if (!repeat) {
            // one-shot
            setTimeout(() => {
                const eventData = { seconds };
                callbackFn(eventData);
                this.emit("timerEvent", seconds);
            }, seconds * 1000);
        } else {
            // répété toutes les X secondes 
            const intervalId = setInterval(() => {
                const eventData = { seconds };
                callbackFn(eventData);
                this.emit("timerEvent", seconds);
            }, seconds * 1000);
            // On stocke l'ID pour pouvoir stopper plus tard
            this.intervalIds.push(intervalId);
        }
    }


    //////////////////////////////
    //  clearAllCollisionListeners
    //////////////////////////////
    public clearAllCollisionListeners() {
        this.characterCollisionListeners.clear();
        this.characterObjectCollisionListeners.clear();
        this.reachPositionListeners.clear();
        this.keyPressListeners.clear();
        this.clickObjectListeners.clear();

        // Arrête tous les intervals
        for (const id of this.intervalIds) {
            clearInterval(id);
        }
        // On vide le tableau
        this.intervalIds = [];
    }
}
