// src/AnimationView/index.tsx
import React, { useEffect, useRef } from 'react';
import { GameEngine } from './engine/GameEngine';
import { useExecutionContext } from '../contexts/ExecutionContext';
import { Character } from './engine/entities/Character';
import { GameObject } from './engine/entities/Object';
import * as functions from './functions';
import { EventManager } from './engine/events/EventManager';
import { BlocklyPhaserConfig } from '../types/BlocklyTypes';
import { TextObject } from './engine/entities/Text';

// On reçoit eventManager en props
interface AnimationViewProps {
    eventManager?: EventManager | null;
    config: BlocklyPhaserConfig;
    onWin: () => void;
}

const AnimationView: React.FC<AnimationViewProps> = ({ eventManager, config, onWin }) => {

    const engineRef = useRef<GameEngine | null>(null);
    const phaserContainerRef = useRef<HTMLDivElement | null>(null);

    const { isRunning } = useExecutionContext();


    // Exposer toutes les fonctions au scope global
    useEffect(() => {

        Object.entries(functions).forEach(([key, func]) => {
            // Vérifiez que la propriété est une fonction avant de l'assigner
            if (typeof func === 'function') {
                (window as any)[key] = func;
            }
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isRunning) {

            // 1) Créer le moteur
            const engine = new GameEngine(
                {
                    adminMode: config?.adminMode || false,
                    sceneWidth: config?.scene?.width || 400,
                    sceneHeight: config?.scene?.height || 400,
                    backgroundImage: config?.scene?.background?.image || '',
                    eventManager,
                    BlocklyPhaserConfig: config,
                },
                phaserContainerRef,
                onWin
            );

            engineRef.current = engine;
            functions.setEngine(engine);

            if (!engine.phaserScene) return

            // 2) start() => Initialise Phaser, charge la scene
            engine.start();

            // AJOUTER TOUS LES PERSONNAGES
            if (config?.characters) {
                config.characters.forEach((charCfg) => {
                    const characterEntity = new Character({
                        adminMode: config?.adminMode || false,
                        id: charCfg.id,
                        name: charCfg.name,
                        x: charCfg.x,
                        y: charCfg.y,
                        width: charCfg.width,
                        height: charCfg.height,
                        speedPxPerStep: charCfg.speedPxPerStep || 25,
                        image_url: charCfg.image_url,
                        animations: charCfg.animations || [],
                    });
                    engine.addCharacter(characterEntity);
                });
            }

            // AJOUTER TOUS LES OBJETS
            if (config?.objects) {
                config.objects.forEach((objCfg) => {
                    const objectEntity = new GameObject({
                        adminMode: config?.adminMode || false,
                        id: objCfg.id,
                        name: objCfg.name,
                        x: objCfg.x,
                        y: objCfg.y,
                        width: objCfg.width,
                        height: objCfg.height,
                        immovable: objCfg.immovable,
                        pickable: objCfg.pickable,
                        pushable: objCfg.pushable,
                        assets: objCfg.assets || {},
                    });
                    engine.addObject(objectEntity);
                });
            }

            // ajout des textes
            if (config?.texts) {

                config.texts.forEach((textCfg) => {
                    const objectEntity = new TextObject({
                        adminMode: config?.adminMode || false,
                        id: textCfg.id,
                        textString: textCfg.textString,
                        x: textCfg.x,
                        y: textCfg.y,
                        fontSize: textCfg.fontSize,
                        color: textCfg.color,
                        backgroundColor: textCfg.backgroundColor,
                    });
                    engine.addText(objectEntity);
                });
            }

            // Cleanup
            return () => {
                engine.stop();
                engineRef.current = null
            };
        } else {
            engineRef.current?.stop();
            engineRef.current = null
        }
        // eslint-disable-next-line
    }, [isRunning]);


    return (
        <div style={{ width: config?.scene?.width || 400, height: config?.scene?.height || 400 }}>
            <div ref={phaserContainerRef} style={{ width: config?.scene?.width || 400, height: config?.scene?.height || 400 }} />
        </div>
    );
};

export default AnimationView;
