import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, message, Space, Spin, Table } from 'antd';
import {
    SaveOutlined,
    EditOutlined,
    UploadOutlined,
    StopOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import { getImagePath } from '../../../utils/format/valueFormat';
import { CODIFICATION_ROLES } from '../../../constant/codification';
import { useAuth } from '../../../context/AuthContext';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';
import { TSprite } from '../../../models/objects/sprite';
import { useObjectsApi } from '../../../api/objects/objects';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../../components/layout/AppLayout';

/**
 * Page d’administration pour la liste des Sprites
 */
const SpriteList: React.FC = () => {
    const { t } = useTranslation();
    const { isRoleExist } = useAuth();

    const {
        getSprites,
        createSprite,
        updateSprite,
        deleteSprite,
        uploadSpriteImage
    } = useObjectsApi();

    const navigate = useNavigate();
    const [sprites, setSprites] = useState<TSprite[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    // Gérer l'état d'une ligne en cours de chargement (insert, update, upload...)
    const [loadingRow, setLoadingRow] = useState<number | null>(null);

    // Pour la suppression
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);

    // Ajouter un sprite "temporaire" (id négatif)
    const [addingId, setAddingId] = useState<number>(0);

    // Édition
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingSprite, setEditingSprite] = useState<TSprite | null>(null);

    // Charger la liste initiale
    useEffect(() => {
        setLoading(true);
        getSprites()
            .then((resp) => {
                setSprites(resp.data ?? []);
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // 1) Ajouter un sprite
    const handleAddSprite = () => {
        const tmpId = addingId - 1; // ex: -1, -2, ...
        const newSprite: TSprite = {
            id: tmpId,
            name: '',
            image_url: 'https://via.placeholder.com/50',
            animations: []
        };
        setAddingId(tmpId);
        setEditingKey(tmpId);
        setEditingSprite(newSprite);
        setSprites((prev) => [newSprite, ...prev]);
    };

    // 2) Editer
    const edit = (sprite: TSprite) => {
        setEditingKey(sprite.id);
        setEditingSprite({ ...sprite });
    };

    // 3) Sauver (Create / Update)
    const save = (id: number) => {
        if (!editingSprite) return;
        setLoadingRow(id);

        const isNew = editingSprite.id <= 0; // id négatif => nouveau
        const callApi = isNew
            ? createSprite(editingSprite)
            : updateSprite(editingSprite.id, editingSprite);

        callApi
            .then((resp) => {
                const spriteResult: TSprite = resp.data;
                // Mettre à jour la liste
                const updated = sprites.map((spr) => (spr.id === id ? spriteResult : spr));
                setSprites(updated);
                message.success(t('message.savedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
                console.error(err);
            })
            .finally(() => {
                setLoadingRow(null);
            });

        setEditingKey(null);
    };

    // 4) Annuler l'édition
    const cancel = () => {
        if (editingSprite && editingSprite.id <= 0) {
            // Si on annulait un nouveau sprite => on l'enlève de la liste
            setSprites((prev) => prev.filter((e) => e.id !== editingSprite.id));
        }
        setEditingKey(null);
        setEditingSprite(null);
    };

    // 5) Delete (confirmation)
    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId === null) return;
        setDeleteLoading(true);
        deleteSprite(deleteId)
            .then(() => {
                setSprites((prev) => prev.filter((spr) => spr.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };

    const handleNavigateToEdit = (sprite: TSprite) => {
        // On envoie "sprite" dans le state
        navigate('/setup/sprites/edit', { state: sprite });
    };
    // 6) Uploader une image
    const uploadImage = (sprite: TSprite) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                setLoadingRow(sprite.id);
                const formData = new FormData();
                formData.append('image', file);

                uploadSpriteImage(sprite.id, formData)
                    .then((resp) => {
                        const updatedSprite: TSprite = resp.data;
                        // Mettre à jour la liste
                        const updatedList = sprites.map((spr) =>
                            spr.id === sprite.id ? updatedSprite : spr
                        );
                        setSprites(updatedList);
                        message.success(t('message.savedSuccessfully'));
                    })
                    .catch((err) => {
                        message.error(err.response?.data ?? err.message ?? err);
                        console.error(err);
                    })
                    .finally(() => {
                        setLoadingRow(null);
                    });
            }
        };

        input.click();
    };

    // Colonnes du tableau
    const columns: ColumnsType<TSprite> = [
        {
            title: 'Image',
            dataIndex: 'img',
            key: 'img',
            width: 120,
            render: (_, record: TSprite) =>
                record.image_url ? (
                    <img
                        src={getImagePath(record.image_url, true)}
                        alt="sprite-thumbnail"
                        style={{ width: 50, height: 50, objectFit: 'cover' }}
                    />
                ) : (
                    'N/A'
                )
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TSprite) =>
                editingKey === record.id ? (
                    <Input
                        value={editingSprite?.name}
                        onChange={(e) => {
                            if (editingSprite) {
                                setEditingSprite({ ...editingSprite, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
            sorter: (a, b) => a.name.localeCompare(b.name)
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right',
            width: 380,
            render: (_: any, record: TSprite) => (
                <Space size="middle">
                    {loadingRow === record.id ? (
                        <Spin />
                    ) : editingKey === record.id ? (
                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<UploadOutlined />} onClick={() => uploadImage(record)} />
                            <Button onClick={() => handleNavigateToEdit(record)}>
                                Éditer en détail
                            </Button>
                            {isRoleExist(CODIFICATION_ROLES.DELETE_COURSE) && (
                                <Button
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => confirmDelete(record.id)}
                                />
                            )}
                        </>
                    )}
                </Space>
            )
        }
    ];

    return (

        <AppLayout title={"Sprites"} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddSprite}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TSprite>
                columns={columns}
                dataSource={sprites}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />
            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this sprite? This action is irreversible."
            />
        </AppLayout>
    );
};

export default SpriteList;
