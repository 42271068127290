import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, message, Space, Spin, Table } from 'antd';
import { SaveOutlined, EditOutlined, UploadOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { getImagePath } from '../../../utils/format/valueFormat';
import { useObjectsApi } from '../../../api/objects/objects';
import { TObstacle } from '../../../models/objects/objects';
import { CODIFICATION_ROLES } from '../../../constant/codification';
import { useAuth } from '../../../context/AuthContext';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';


const ObstacleList: React.FC = () => {
    const { t } = useTranslation();
    const { isRoleExist } = useAuth();
    const { getObstacles, updateObstacle, createObstacle, deleteObstacle, uploadObstacleImage } = useObjectsApi();
    const [obstacles, setObstacles] = useState<TObstacle[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const [loadingRow, setLoadingRow] = useState<number | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        getObstacles()
            .then((resp) => {
                setObstacles(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingObstacle, setEditingObstacle] = useState<TObstacle | null>(null);

    const edit = (obstacle: TObstacle) => {
        setEditingKey(obstacle.id);
        setEditingObstacle({ ...obstacle });
    };

    const handleAddObstacle = () => {
        const idRow = addingId - 1;
        const newObstacle: TObstacle = {
            id: idRow,
            name: "",
            img: 'https://via.placeholder.com/50',
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingObstacle(newObstacle);
        setObstacles((prev) => [newObstacle, ...prev]);
    };

    const save = (id: number) => {
        if (editingObstacle) {
            setLoadingRow(id);
            (editingObstacle.id > 0 ? updateObstacle(editingObstacle.id, editingObstacle) : createObstacle(editingObstacle))
                .then((resp) => {
                    const obstacleResult: TObstacle = resp.data;
                    const updatedObstacles = obstacles.map((obstacle) =>
                        obstacle.id === id ? obstacleResult : obstacle
                    );
                    setObstacles(updatedObstacles);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                    setLoadingRow(null);
                });
        }

        setEditingKey(null);
    };
    const cancel = () => {
        const idRow = editingObstacle?.id || 0
        if (idRow <= 0) {
            setObstacles((prev) => [...prev ?? []].filter(e => e.id !== idRow));
        }
        setEditingKey(null);
        setEditingObstacle(null);
    }

    // Delete functionality
    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteObstacle(deleteId)
            .then(() => {
                setObstacles(obstacles.filter((obstacle) => obstacle.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };


    const uploadImage = (obstacle: TObstacle) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                setLoadingRow(obstacle.id);
                const formData = new FormData();
                formData.append('image', file);

                uploadObstacleImage(obstacle.id, formData)
                    .then((resp) => {
                        const obstacleResult: TObstacle = resp.data;
                        const updatedObstacles = obstacles.map((crs) =>
                            crs.id === obstacle.id ? obstacleResult : crs
                        );
                        setObstacles(updatedObstacles);
                        message.success(t('message.savedSuccessfully'));
                    })
                    .catch((err) => {
                        message.error(err.response.data ?? err.message);
                        console.error(err);
                    })
                    .finally(() => {
                        setLoadingRow(null);
                    });
            }
        };

        input.click();
    };

    const columns: ColumnsType<TObstacle> = [
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (text: string, record: TObstacle) =>
                record.img ? (
                    <img
                        src={getImagePath(record.img, true)}
                        alt="obstacle-thumbnail"
                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                    />
                ) : (
                    'N/A'
                ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TObstacle) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObstacle?.name}
                        onChange={(e) => {
                            if (editingObstacle) {
                                setEditingObstacle({ ...editingObstacle, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),

            sorter: (a, b) => a.name.localeCompare(b.name),
        },

        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 180,
            render: (_: any, record: TObstacle) => (
                <Space size="middle">
                    {loadingRow === record.id ? ( // Affiche le spinner si la ligne est en chargement
                        <Spin />
                    ) : (
                        editingKey === record.id ? (

                            <>
                                <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                                <Button icon={<StopOutlined />} onClick={cancel} />
                            </>
                        ) : (
                            <>
                                <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                                <Button icon={<UploadOutlined />} onClick={() => uploadImage(record)} />
                                {
                                    isRoleExist(CODIFICATION_ROLES.DELETE_COURSE) && (
                                        <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => confirmDelete(record.id)}
                                            loading={deleteId === record.id && deleteLoading}
                                        />
                                    )
                                }
                            </>
                        )
                    )}
                </Space>
            ),
        },
    ];

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAddObstacle}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TObstacle>
                columns={columns}
                dataSource={obstacles}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />
            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this subject? This action is irreversible."
            />
        </>
    );
};

export default ObstacleList;
