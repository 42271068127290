import React, { useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Alert, Divider, Layout, List } from 'antd';
import DebugTB from '../toolbars/DebugTB';
import BlocklyWorkspace, { BlocklyWorkspaceRef } from '../BlocklyWorkspace';
import { useExecutionContext } from '../contexts/ExecutionContext';
import AnimationView from '../AnimationView';

import { createCodeInterpreter, CodeInterpreter } from '../services/codeInterpreter';
import { EventManager } from '../AnimationView/engine/events/EventManager';
import { useChallengeConfig } from '../contexts/BlocklyConfigContext';

const { Sider, Content } = Layout;

// On peut définir une interface pour le "type" de la ref que ce composant exposera :
export interface BlocklyContentRef {
    getWorkspaceXml: () => string | undefined;
}

interface BlocklyContentProps {
    onWin: () => void;
    onSave?: (workspace: string) => void;
}

/**
 * Composant qui gère :
 * - L'interpréteur asynchrone
 * - L'EventManager (Phaser + collisions, etc.)
 * - Expose `getWorkspaceXml()` via la ref
 */
const BlocklyContent = forwardRef<BlocklyContentRef, BlocklyContentProps>((props, ref) => {
    const workspaceRef = useRef<BlocklyWorkspaceRef>(null);

    // Interpréteur
    const interpreterRef = useRef<CodeInterpreter | null>(null);

    // EventManager
    const eventManagerRef = useRef<EventManager | null>(null);

    // Extraction du contexte
    // Selon vos besoins, vous pouvez garder isRunning/isPaused si vous voulez
    // gérer un état global de "en cours" / "pause".
    const {
        logs,
        isRunning,
        isPaused,
        addLog,
        nextStep,
        stop,
        start,
        restart,
        stepBystepOff,
        stepBystepOn,
    } = useExecutionContext();

    // Config
    const { config } = useChallengeConfig();

    // Au changement de config (ajout perso/objet/etc.), on relance
    useEffect(() => {
        restart();
    }, [config.characters, config.objects, config.texts, config.scene, restart]);

    // 1) Créer l'interpréteur + eventManager (une seule fois)
    useEffect(() => {
        const interpreter = createCodeInterpreter({
            isRunning,
            isPaused,
            currentStep: 0,
            nextStep,
            addLog,
            stop,
        });
        interpreterRef.current = interpreter;

        const eventManager = new EventManager(interpreter);
        eventManagerRef.current = eventManager;

        // Pour debug global
        (window as any).eventManager = eventManager;
        document.addEventListener('keydown', (e) => {
            eventManager.dispatchKeyPressEvent(e.key);
        });

        // eslint-disable-next-line
    }, []);

    // 2) Surveille les changements de paused / running
    // (Optionnel : si votre CodeInterpreter gère un mode pause ou stop interne)
    useEffect(() => {
        interpreterRef.current?.setPaused(isPaused);
    }, [isPaused]);

    useEffect(() => {
        interpreterRef.current?.setRunning(isRunning);
    }, [isRunning]);

    /**
     * handleRun() : prévient le mode "stepBystep", nettoie l'eventManager,
     * génère le code et exécute.
     */
    const handleRun = async () => {
        // Nettoie collisions
        eventManagerRef.current?.clearAllCollisionListeners();

        // Sort du mode step-by-step si on y était
        stepBystepOff();

        // Sauvegarde du workspace
        if (props.onSave) {
            props.onSave(workspaceRef.current?.getWorkspaceXml() ?? '');
        }

        // Récupère le code
        if (!workspaceRef.current) {
            console.error('BlocklyWorkspace not available.');
            return;
        }
        if (!interpreterRef.current) {
            console.error('CodeInterpreter not initialized.');
            return;
        }

        const code = workspaceRef.current.getCode();

        // Initialise le code dans l'interpréteur
        interpreterRef.current.init(code);

        // Lance l’exécution asynchrone complète (sans le runLoop 300 ms)
        // Supposez que votre interpreter a une méthode .run() retournant une Promise
        try {
            start(); // Optionnel : on marque "isRunning = true" côté contexte
            await interpreterRef.current.runAll();
        } catch (err) {
            console.error('Execution error:', err);
        } finally {
        }
    };

    /**
     * handleNextStep() : 
     * Ici, vous pouvez soit :
     *   - conservez un mode stepping si votre interpreter gère encore .step() 
     *   - ou désactiver le bouton "Step" si vous n'en avez plus besoin
     * 
     * Exemple minimal : on exécute un step unique dans l'interpréteur.
     */
    const handleNextStep = () => {
        stepBystepOn();
        if (!isRunning) {
            start();
        }
        if (!workspaceRef.current || !interpreterRef.current) return;

        if (interpreterRef.current.hasMore()) {
            interpreterRef.current.step();
        } else {
            // On réinitialise et on refait un step
            eventManagerRef.current?.clearAllCollisionListeners();
            const code = workspaceRef.current.getCode();
            interpreterRef.current.init(code);
            interpreterRef.current.step();
        }
    };

    const handleRestart = () => {
        eventManagerRef.current?.clearAllCollisionListeners();
        restart();

    }

    // getWorkspaceXml exposé via ref
    const getWorkspaceXml = () => workspaceRef.current?.getWorkspaceXml();

    useImperativeHandle(ref, () => ({
        getWorkspaceXml,
    }));

    // Calcul de layout
    const animationWidth = config?.scene?.width || 400;
    const workspaceWidth = `calc(100% - ${animationWidth}px)`;

    return (
        <Layout style={{ height: '80vh', width: '100%' }}>
            <Sider width={workspaceWidth} style={{ background: '#fff', padding: '16px' }}>
                <BlocklyWorkspace
                    ref={workspaceRef}
                    adminMode={config.adminMode}
                    initialXml={config.workspace}
                />

                {config.adminMode && logs && (
                    <div style={{ marginTop: '16px' }}>
                        <h3>Logs</h3>
                        <List
                            size="small"
                            bordered
                            dataSource={logs}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                            style={{
                                maxHeight: '300px',
                                overflowY: 'auto',
                                background: '#fafafa',
                                padding: '8px',
                            }}
                        />
                    </div>
                )}

                {!config.adminMode && (
                    <Alert
                        type="info"
                        message="D'autres fonctionnalités seront bientôt disponibles !"
                        showIcon
                        style={{ marginTop: '16px' }}
                    />
                )}
            </Sider>

            <Content style={{ background: '#fff', position: 'relative', marginTop: 15 }}>
                {eventManagerRef.current && (
                    <>
                        <AnimationView
                            eventManager={eventManagerRef.current}
                            config={config}
                            onWin={props.onWin}
                        />
                        <Divider />
                        <div style={{ marginTop: 16 }}>
                            <DebugTB onRun={handleRun} onNextStep={handleNextStep} handleRestart={handleRestart} />
                        </div>
                    </>
                )}
            </Content>
        </Layout>
    );
});

export default BlocklyContent;
