/** 
 * TObjetSprite : Représente un sprite sheet et ses différentes animations possibles.
 */
/** Les états possibles */
export type CharacterState = 'idle' | 'walking' | 'jumping' | 'winning' | 'collision';

/** Les infos d’une animation */
export interface TAnimationConfig {
    id: number;
    state: CharacterState;
    frame_width: number;
    frame_height: number;
    frame_count: number;
    frame_rate: number;   // images / seconde
    offset_x: number;
    offset_y: number;
}

/** Notre sprite “global” (sans id côté front) */
export interface TSprite {
    id: number;
    name: string;               // le nom du sprite
    image_url: string;           // URL ou chemin du sprite sheet
    animations: TAnimationConfig[];
}

/** Liste des states possibles pour la sélection */
export const ALL_STATES: CharacterState[] = [
    'idle',
    'walking',
    'jumping',
    'winning',
    'collision'
];
