// src/components/BlocklyWorkspace/blocklyConfig.ts

// Si vous avez besoin des blocs de base (if, loops...) : import 'blockly/blocks';
import { javascriptGenerator } from "blockly/javascript";
import { Order } from "blockly/javascript";
import * as Blockly from "blockly";
import { CharacterConfig } from "../../types/BlocklyTypes";

export function defineActionBlocks(characters: CharacterConfig[]) {
  // ==========================
  // Actions
  // ==========================


  Blockly.Blocks["variable_say_with_character"] = {
    init: function () {
      this.appendValueInput("message")
        .appendField("say")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(180);
      this.setTooltip(
        "Affiche un message au-dessus d'un personnage spécifique."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["variable_say_with_character"] = function (block: any) {
    const message =
      javascriptGenerator.valueToCode(block, "message", Order.NONE) || '""';
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        sayCharacter('${character}', ${message});
    \n`;
  };

  // Définition du bloc text_say_with_character
  Blockly.Blocks["text_say_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("say")
        .appendField(new Blockly.FieldTextInput("..."), "message")
        .appendField("by")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(180);
      this.setTooltip(
        "Affiche un message fixe au-dessus d'un personnage spécifique."
      );
      this.setHelpUrl("");
    },
  };

  // Générateur JavaScript pour le bloc text_say_with_character
  javascriptGenerator.forBlock["text_say_with_character"] = function (block: any) {
    const message = block.getFieldValue("message") || '""';
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        sayCharacter('${character}', "${message}"); // Affiche le texte saisi pour le personnage sélectionné
    \n`;
  };

  // Définition du bloc activate_autopilot_with_character
  Blockly.Blocks["activate_autopilot_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Activer l'auto-pilote pour")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["Aucun personnage disponible", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(180);
      this.setTooltip("Active l'auto-pilote pour un personnage donné.");
      this.setHelpUrl("");
    },
  };

  // Générateur de code pour activate_autopilot_with_character
  javascriptGenerator.forBlock["activate_autopilot_with_character"] = function (block: any) {
    const characterId = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      activateAutoPilot('${characterId}');
  \n`;
  };


  // Définition du bloc deactivate_autopilot_with_character
  Blockly.Blocks["deactivate_autopilot_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("Désactiver l'auto-pilote pour")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["Aucun personnage disponible", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(180);
      this.setTooltip("Désactive l'auto-pilote pour un personnage donné.");
      this.setHelpUrl("");
    },
  };

  // Générateur de code pour deactivate_autopilot_with_character
  javascriptGenerator.forBlock["deactivate_autopilot_with_character"] = function (block: any) {
    const characterId = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      desactivateAutoPilot('${characterId}');
  \n`;
  };


}
