// src/blocks/actions/setSpeedBlock.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../BlocklyFields';
import { TCharacter } from '../../type/character';
import i18next from 'i18next';

export function initSetSpeedChosenCharacterBlock(characters: TCharacter[]) {
    // Créer des options pour le dropdown des personnages, avec leurs images 'idle'
    const characterOptions = characters.map((character) => [
        {
            src: character.images.idle,
            width: 36,
            height: 36,
            alt: character.name,
        },
        character.id.toString(),
    ]);

    Blockly.Blocks[BlocklyFields.SET_SPEED_CHOSEN_CHARACTER] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.SET_SPEED_CHOSEN_CHARACTER,
                "message0": '%1 définir la vitesse sur %2',
                "args0": [
                    {
                        type: 'field_dropdown',
                        name: 'CHARACTER',
                        options: characterOptions,
                    },
                    {
                        type: 'field_dropdown',
                        name: 'SPEED',
                        options: [
                            ["1", "1"],
                            ["2", "2"],
                            ["3", "3"],
                        ],
                    },
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#FF5733',
                "tooltip": i18next.t('blockly.set_speed.tooltip', 'Définit la vitesse d’un personnage.'),
                "helpUrl": '',
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.SET_SPEED_CHOSEN_CHARACTER] = function (block) {
        const characterId = block.getFieldValue('CHARACTER');
        const speed = block.getFieldValue('SPEED');
        return `await setSpeed(${characterId}, ${speed});\n`;
    };
}
