// src/game/GameLogic.ts

import { useGameContext } from './GameContext';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import { roundToNearest } from '../../../../utils/gen/calcul';
import { TLine } from '../../type/line';
import { pureMoveCharacter, pureTeleportTo } from '../BlocklyCommonFunction/Movement';
interface GameLogicProps {
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;
    autoReset: boolean;
    checkResult: (isValid: boolean) => void;
}

export const useGameLogic = ({
    animationPromiseResolverRef,
    autoReset,
    checkResult,
}: GameLogicProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useGameContext();
    const stateRef = useRef(state);
    const lineTypeRef = useRef<string>('solid');
    const lineColorRef = useRef<string>('#000000');
    const lastMovementRef = useRef<{ angle: number; distance: number; jump: boolean }>({ angle: 0, distance: state.character.displacement_unit ?? 50, jump: false });


    let collisionMessageDisplayed = false;

    useEffect(() => {
        stateRef.current = state;
    }, [state]);


    const walkCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        const distance = character.displacement_unit;
        lastMovementRef.current = { angle, distance, jump: false }; // Sauvegarder le mouvement avec action = false (marche)
        return moveCharacter(angle, character.displacement_unit, false);
    };

    const jumpCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        const distance = character.displacement_unit;
        lastMovementRef.current = { angle, distance, jump: true }; // Sauvegarder le mouvement avec action = true (saut)
        return moveCharacter(angle, character.displacement_unit, true);
    };

    const moveForward = async (): Promise<void> => {
        const { angle, distance, jump } = lastMovementRef.current; // Récupérer les informations du dernier mouvement
        return moveCharacter(angle, distance, jump); // Répéter le mouvement en conservant l'action
    };

    const moveBackward = async (): Promise<void> => {
        // Inverser l'angle du mouvement
        const { angle, distance, jump } = lastMovementRef.current;
        const reversedAngle = (angle + 180) % 360; // Ajouter 180° pour obtenir la direction opposée 
        return moveCharacter(reversedAngle, distance, jump); // Exécuter le mouvement inverse avec la même action
    };

    const moveForwardWithDistance = async (distance: number, jump: boolean): Promise<void> => {
        const { angle } = lastMovementRef.current; // Récupère uniquement l'angle
        return moveCharacter(angle, distance, jump); // Déplace le personnage avec la distance et le saut fournis
    };

    const moveBackwardWithDistance = async (distance: number, jump: boolean): Promise<void> => {
        const { angle } = lastMovementRef.current;
        const reversedAngle = (angle + 180) % 360; // Calculer l'angle inversé

        return moveCharacter(reversedAngle, distance, jump); // Déplace le personnage en arrière avec la distance et le saut fournis
    };

    const turnCharacter = async (angleChange: number): Promise<void> => {

        const { angle } = lastMovementRef.current;
        const newAngle = (angle + angleChange + 360) % 360; // Assurer que l'angle est toujours positif (0-359°)

        // Mise à jour de la référence du dernier mouvement pour conserver le nouvel angle
        lastMovementRef.current = {
            ...lastMovementRef.current,
            angle: newAngle,
        };

    };

    const turnByAngle = async (direction: 'RIGHT' | 'LEFT', angle: number): Promise<void> => {
        const angleChange = direction === 'RIGHT' ? angle : -angle;
        await turnCharacter(angleChange);
    };

    const teleportTo = async (position: string): Promise<void> => {
        const { character, scene } = stateRef.current;

        const {
            x,
            y,
            scale,
        } = pureTeleportTo(character, scene, position)

        // Animation et mise à jour du personnage
        return new Promise<void>((resolve) => {
            // animationPromiseResolverRef est géré dans IGame
            animationPromiseResolverRef.current = resolve;

            dispatch({
                type: 'MOVE_CHARACTER',
                payload: {
                    x: x,
                    y: y,
                    state: 'moving',
                    scale: scale,
                },
            });
        });
    };

    const moveCharacter = async (
        angle: number,
        distance: number,
        isJumping: boolean
    ): Promise<void> => {
        const { character, scene, executionCancelled } = stateRef.current;

        if (executionCancelled) {
            return Promise.resolve();
        }

        const {
            finalX,
            finalY,
            isColliding,
            scale,
        } = pureMoveCharacter(character, angle, distance, scene, [])

        return new Promise<void>((resolve) => {
            if (isColliding) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'colliding' });
                dispatch({ type: 'SET_EXECUTION_CANCELLED', payload: true });

                if (!collisionMessageDisplayed) {
                    message.error(t('message.collisionOrOutOfBounds'));
                    collisionMessageDisplayed = true;
                }

                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                        collisionMessageDisplayed = false; // Réinitialiser le drapeau après le reset
                    }, 1000);
                }
                resolve();
            } else {
                const movementLine: TLine = {
                    x1: character.x,
                    y1: character.y,
                    x2: roundToNearest(finalX),
                    y2: roundToNearest(finalY),
                    type: lineTypeRef.current,
                    color: lineColorRef.current,
                };

                // animationPromiseResolverRef est géré dans IGame
                animationPromiseResolverRef.current = resolve;
                dispatch({
                    type: 'MOVE_CHARACTER',
                    payload: {
                        x: roundToNearest(finalX),
                        y: roundToNearest(finalY),
                        state: 'moving',
                        scale: scale,
                        movementLine: !isJumping ? movementLine : undefined
                    },
                });
                // le resolve est dans l'animation IGame
            }
        });
    };

    const resetGame = () => {
        lineTypeRef.current = 'solid';
        lineColorRef.current = '#000000';
        dispatch({ type: 'RESET_GAME' });
    };

    const setLineType = async (type: string): Promise<void> => {
        lineTypeRef.current = type;
        return Promise.resolve();
    };

    const setLineColor = async (color: string): Promise<void> => {
        lineColorRef.current = color;
        return Promise.resolve();
    };

    const setSceneBackground = async (img: string): Promise<void> => {
        dispatch({ type: 'SET_SCENE_BACKGROUND', payload: { img, repeat: false, size: 'cover' } });
    };

    const setSpeed = async (speed: string): Promise<void> => {
        const { character } = stateRef.current

        const speedNumber = Number(speed);
        dispatch({ type: 'UPDATE_CHARACTER', payload: { ...character, speed: speedNumber } });

    };

    // Normaliser une ligne pour que x1 <= x2 ou, si x1 == x2, y1 <= y2
    const normalizeLine = (line: TLine): TLine => {
        if (
            line.x1 > line.x2 ||
            (line.x1 === line.x2 && line.y1 > line.y2)
        ) {
            return {
                ...line,
                x1: line.x2,
                y1: line.y2,
                x2: line.x1,
                y2: line.y1,
            };
        }
        return line;
    };

    // Vérifier si deux lignes sont colinéaires (même direction et même droite)
    const areLinesColinear = (line1: TLine, line2: TLine): boolean => {
        const dx1 = line1.x2 - line1.x1;
        const dy1 = line1.y2 - line1.y1;
        const dx2 = line2.x2 - line2.x1;
        const dy2 = line2.y2 - line2.y1;

        // Vérifier si les vecteurs directeurs sont proportionnels (parallélisme)
        if (dx1 * dy2 !== dx2 * dy1) {
            return false; // Pas parallèles, donc pas colinéaires
        }

        // Vérifier si les lignes sont sur la même droite
        const dx = line2.x1 - line1.x1;
        const dy = line2.y1 - line1.y1;

        if (dx1 * dy !== dy1 * dx) {
            return false; // Parallèles mais pas sur la même droite
        }

        return true; // Lignes colinéaires sur la même droite
    };

    // Vérifier si deux lignes se chevauchent ou sont connectées
    const doLinesOverlapOrConnect = (line1: TLine, line2: TLine): boolean => {

        const line1Min = Math.min(
            line1.x1 !== line1.x2 ? line1.x1 : line1.y1,
            line1.x1 !== line1.x2 ? line1.x2 : line1.y2
        );
        const line1Max = Math.max(
            line1.x1 !== line1.x2 ? line1.x1 : line1.y1,
            line1.x1 !== line1.x2 ? line1.x2 : line1.y2
        );

        const line2Min = Math.min(
            line2.x1 !== line2.x2 ? line2.x1 : line2.y1,
            line2.x1 !== line2.x2 ? line2.x2 : line2.y2
        );
        const line2Max = Math.max(
            line2.x1 !== line2.x2 ? line2.x1 : line2.y1,
            line2.x1 !== line2.x2 ? line2.x2 : line2.y2
        );

        return Math.max(line1Min, line2Min) <= Math.min(line1Max, line2Max);
    };

    // Fusionner les lignes continues
    const mergeLines = (lines: TLine[]): TLine[] => {
        let normalizedLines = lines.map(normalizeLine);
        let didMerge = true;

        while (didMerge) {
            didMerge = false;
            const newLines: TLine[] = [];

            while (normalizedLines.length > 0) {
                const line = normalizedLines.shift()!;
                let mergedLine = { ...line };

                let i = 0;
                while (i < normalizedLines.length) {
                    const otherLine = normalizedLines[i];
                    if (
                        areLinesColinear(mergedLine, otherLine) &&
                        doLinesOverlapOrConnect(mergedLine, otherLine)
                    ) {
                        // Fusionner les lignes
                        mergedLine.x1 = Math.min(mergedLine.x1, otherLine.x1);
                        mergedLine.y1 = Math.min(mergedLine.y1, otherLine.y1);
                        mergedLine.x2 = Math.max(mergedLine.x2, otherLine.x2);
                        mergedLine.y2 = Math.max(mergedLine.y2, otherLine.y2);
                        // Renormaliser la ligne fusionnée
                        mergedLine = normalizeLine(mergedLine);
                        // Retirer otherLine de normalizedLines
                        normalizedLines.splice(i, 1);
                        didMerge = true;
                    } else {
                        i++;
                    }
                }

                newLines.push(mergedLine);
            }

            normalizedLines = newLines;
        }

        return normalizedLines;
    };

    // Convertir une ligne en chaîne pour l'utiliser dans un ensemble
    const lineToString = (line: TLine): string => {
        const points = [
            { x: line.x1, y: line.y1 },
            { x: line.x2, y: line.y2 },
        ].sort((a, b) => {
            if (a.x !== b.x) {
                return a.x - b.x;
            } else {
                return a.y - b.y;
            }
        });
        return `${points[0].x},${points[0].y},${points[1].x},${points[1].y}`;
    };

    // Comparer deux collections de lignes
    const areCollectionsEquivalent = (collectionA: TLine[], collectionB: TLine[]): boolean => {
        const mergedA = mergeLines(collectionA);
        const mergedB = mergeLines(collectionB);

        // Vérifier si les deux collections fusionnées ont le même nombre de lignes
        if (mergedA.length !== mergedB.length) {
            // 'Nombre de lignes différent après fusion.'
            return false;
        }

        // Créer des ensembles pour stocker les lignes sous forme de chaînes pour une comparaison facile
        const setA = new Set(mergedA.map(lineToString));
        const setB = new Set(mergedB.map(lineToString));

        // Vérifier si les ensembles sont identiques
        if (setA.size !== setB.size) {
            // Les ensembles de lignes ont des tailles différentes.
            return false;
        }

        for (const item of setA) {
            if (!setB.has(item)) {
                // `La ligne ${item} n'est pas présente dans les deux collections.`
                return false;
            }
        }

        return true;
    };


    const isDone = async () => {
        // Attendre que l'état soit mis à jour
        await new Promise<void>((resolve) => setTimeout(resolve, 0));


        const { targetLines } = stateRef.current;
        if (state.initialTargetLines.length > 0) {
            let success = areCollectionsEquivalent(targetLines, state.initialTargetLines);

            checkResult(success);
            if (success) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'wining' });
            } else {
                message.warning(t('message.bug'));
                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                    }, 500);
                }
            }
        } else {
            checkResult(true);
        }
    };

    return {
        moveForward,
        moveBackward,
        moveForwardWithDistance,
        moveBackwardWithDistance,
        turnCharacter,
        turnByAngle,
        walkCharacter,
        jumpCharacter,
        moveCharacter,
        teleportTo,
        resetGame,
        setSceneBackground,
        setSpeed,
        isDone,
        setLineType,
        setLineColor,
    };
};
