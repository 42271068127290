// src/components/BlocklyWorkspace/blocklyConfig.ts

import { Blocks } from 'blockly/core';
// Si vous avez besoin des blocs de base (if, loops...) : import 'blockly/blocks';
import { javascriptGenerator } from 'blockly/javascript';
import * as Blockly from 'blockly';

import { FieldColour, registerFieldColour } from '@blockly/field-colour';

// Enregistrer les champs spéciaux 
registerFieldColour();

export function defineAttributeBlocks() {

    // Blocks['speak'] = {
    //     init: function () {
    //         this.appendValueInput('TEXT')
    //             .setCheck('String')
    //             .appendField('speak');
    //         this.setPreviousStatement(true, null);
    //         this.setNextStatement(true, null);
    //         this.setColour(160);
    //         this.setTooltip('Fait parler le personnage.');
    //         this.setHelpUrl('');
    //     },
    // };
    // javascriptGenerator.forBlock['speak'] = function (block: any) {
    //     const message =
    //         javascriptGenerator.valueToCode(block, 'TEXT', javascriptGenerator) || '""';
    //     const blockId = block.id;
    //     return `
    //   highlightBlock('${blockId}');
    //   speakCharacter('mainChar', ${message});
    // \n`;
    // };

    // ==========================
    // Attributs
    // ==========================
    Blocks['set_speed'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('set speed of')
                .appendField(new Blockly.FieldTextInput('mainChar'), 'target')
                .appendField('to')
                .appendField(new Blockly.FieldNumber(1, 0, 100), 'speed');
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(230);
            this.setTooltip("Change la vitesse d’un personnage ou d’un objet.");
            this.setHelpUrl('');
        },
    };
    javascriptGenerator.forBlock['set_speed'] = function (block: any) {
        const target = block.getFieldValue('target');
        const speed = block.getFieldValue('speed');
        const blockId = block.id;
        return `
      highlightBlock('${blockId}');
      setSpeed('${target}', ${speed});
    \n`;
    };

    Blocks['change_color'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('change color of')
                .appendField(new Blockly.FieldTextInput('mainChar'), 'target')
                .appendField('to')
                .appendField(
                    new FieldColour('#ff0000', undefined, {
                        colourOptions: [
                            '#ff4040',
                            '#ff8080',
                            '#ffc0c0',
                            '#4040ff',
                            '#8080ff',
                            '#c0c0ff',
                        ],
                        colourTitles: [
                            'dark pink',
                            'pink',
                            'light pink',
                            'dark blue',
                            'blue',
                            'light blue',
                        ],
                        columns: 3,
                    }),
                    'color',
                );
            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(230);
            this.setTooltip("Modifie la couleur d’un personnage ou d’un objet.");
            this.setHelpUrl('');
        },
    };
    javascriptGenerator.forBlock['change_color'] = function (block: any) {
        const target = block.getFieldValue('target');
        const color = block.getFieldValue('color');
        const blockId = block.id;
        return `
      highlightBlock('${blockId}');
      changeColor('${target}', '${color}');
    \n`;
    };

}
