// defineInputBlocks.ts
import * as Blockly from 'blockly/core';
import { javascriptGenerator, Order } from 'blockly/javascript';
// Pour l'angle :
import { FieldAngle, registerFieldAngle } from '@blockly/field-angle';
import { FieldColour, registerFieldColour } from '@blockly/field-colour';

// Enregistrer le FieldAngle si on ne l'a pas déjà fait
registerFieldAngle();
registerFieldColour();

/**
 * Déclare / redéfinit quelques blocs "input" (valeurs) :
 *  - math_number
 *  - text
 *  - text_join
 *  - logic_boolean
 *  - angle_field_block
 *  - color_picker_block
 *
 * Appelle defineInputBlocks() avant d'initialiser le workspace Blockly.
 */
export function defineInputBlocks() {

    /***********************************************
     * 1) math_number (nombre)
     ***********************************************/
    // S'il est déjà défini par Blockly, on peut écraser sa définition ou la laisser telle quelle.
    // Exemple d'une version minimaliste personnalisée :
    Blockly.Blocks['math_number'] = {
        init: function () {
            this.appendDummyInput()
                .appendField(new Blockly.FieldNumber(0), 'NUM');
            this.setOutput(true, 'Number');
            this.setColour(310);
            this.setTooltip('Un nombre');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['math_number'] = function (block, generator) {
        const code = block.getFieldValue('NUM') || '0';
        // Bloc expression => on renvoie [code, ordre]
        return [code, 0];
    };


    /***********************************************
     * 2) text (chaîne)
     ***********************************************/
    Blockly.Blocks['text'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('"')
                .appendField(new Blockly.FieldTextInput(''), 'TEXT')
                .appendField('"');
            this.setOutput(true, 'String');
            this.setColour(310);
            this.setTooltip('Un texte');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['text'] = function (block) {
        const textVal = block.getFieldValue('TEXT') || '';
        const code = `"${textVal}"`;
        return [code, 0];
    };


    /***********************************************
     * 3) text_join (concaténation de textes)
     ***********************************************/
    Blockly.Blocks['text_join'] = {
        init: function () {
            this.setColour(310);
            this.itemCount_ = 2; // On commence avec 2 entrées
            this.updateShape_();
            this.setOutput(true, 'String');
            this.setTooltip('Concatène plusieurs éléments en un texte.');
            this.setHelpUrl('');
        },
        updateShape_: function () {
            if (this.itemCount_ && this.itemCount_ > 0) {
                if (!this.getInput('EMPTY')) {
                    // remove if it exists
                    this.removeInput('EMPTY', true);
                }
            } else if (!this.getInput('EMPTY')) {
                this.appendDummyInput('EMPTY')
                    .appendField('texte vide');
            }
            // Ajouter / retirer inputs
            for (let i = 0; i < this.itemCount_; i++) {
                if (!this.getInput('ADD' + i)) {
                    const input = this.appendValueInput('ADD' + i)
                        .setCheck(['String', 'Number']);
                    if (i === 0) {
                        input.appendField('concaténer');
                    }
                }
            }
            // Retirer ceux en trop
            let i = this.itemCount_;
            while (this.getInput('ADD' + i)) {
                this.removeInput('ADD' + i);
                i++;
            }
        },
        mutationToDom: function () {
            const container = document.createElement('mutation');
            container.setAttribute('items', `${this.itemCount_}`);
            return container;
        },
        domToMutation: function (xmlElement: Element) {
            const items = parseInt(xmlElement.getAttribute('items') || '2', 10);
            this.itemCount_ = items;
            this.updateShape_();
        },
    };

    javascriptGenerator.forBlock['text_join'] = function (block: any, generator: any) {
        const codeArray: string[] = [];
        for (let i = 0; i < block.itemCount_; i++) {
            const val = generator.valueToCode(block, 'ADD' + i, 0) || '""';
            codeArray.push(val);
        }
        if (codeArray.length === 0) {
            return ['""', 0];
        } else if (codeArray.length === 1) {
            return [codeArray[0], 0];
        } else {
            const joined = codeArray.join(' + ');
            return [joined, 0];
        }
    };


    /***********************************************
     * 4) logic_boolean (true / false)
     ***********************************************/
    Blockly.Blocks['logic_boolean'] = {
        init: function () {
            this.appendDummyInput()
                .appendField(new Blockly.FieldDropdown([
                    ['vrai', 'TRUE'],
                    ['faux', 'FALSE'],
                ]), 'BOOL');
            this.setOutput(true, 'Boolean');
            this.setColour(310);
            this.setTooltip('Renvoie vrai ou faux.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['logic_boolean'] = function (block) {
        const boolVal = block.getFieldValue('BOOL') === 'TRUE' ? 'true' : 'false';
        return [boolVal, 0];
    };


    /***********************************************
     * 5) angle_field_block
     ***********************************************/
    Blockly.Blocks['angle_field_block'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('angle')
                // On utilise FieldAngle (enregistré via registerFieldAngle)
                .appendField(new FieldAngle(90), 'ANGLE');
            this.setOutput(true, 'Number'); // renvoie un nombre (ex: 45)
            this.setColour(310);
            this.setTooltip('Bloc qui retourne un angle (0..360)');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['angle_field_block'] = function (block) {
        const angle = block.getFieldValue('ANGLE') || 0;
        // c’est un bloc expression => [code, order]
        return [`${angle}`, 0];
    };


    /***********************************************
     * 6) color_picker_block
     ***********************************************/
    Blockly.Blocks['color_picker_block'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('couleur')
                .appendField(new FieldColour('#ff0000'), 'COLOR');
            this.setOutput(true, 'String'); // ça renvoie une chaîne "#ff0000"
            this.setColour(310);
            this.setTooltip('Bloc couleur.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['color_picker_block'] = function (block) {
        const colorVal = block.getFieldValue('COLOR') || '#000000';
        // on renvoie une string => '"#ff0000"'
        return [`"${colorVal}"`, 0];
    };

    // ------------------------------------
    // 4) logic_boolean (true / false)
    // ------------------------------------
    Blockly.Blocks['logic_boolean'] = {
        init: function () {
            this.appendDummyInput()
                .appendField(new Blockly.FieldDropdown([
                    ['vrai', 'TRUE'],
                    ['faux', 'FALSE']
                ]), 'BOOL');
            this.setOutput(true, 'Boolean');
            this.setColour(310);
            this.setTooltip('Renvoie vrai ou faux.');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['logic_boolean'] = function (block) {
        const boolVal = block.getFieldValue('BOOL') === 'TRUE' ? 'true' : 'false';
        return [boolVal, Order.ATOMIC];
    };
}
