import { ToolboxCategory, ToolboxBlock } from "../types/BlocklyTypes";

/**
 * Exemple de génération d'un bloc Toolbox.
 * On le rend "deletable" et "updatable" à true par défaut,
 * mais on peut ajuster selon les besoins.
 */
function makeBlock(type: string): ToolboxBlock {
  return {
    type,
    movable: true,
    deletable: true,
    editable: true,
    updatable: true,
  };
}

export const DEFAULT_TOOLBOX_ITEMS: ToolboxCategory[] = [
  {
    name: "Mouvement",
    color: "120",
    blocks: [
      makeBlock("walk_right"),
      makeBlock("walk_left"),
      makeBlock("walk_up"),
      makeBlock("walk_down"),
      makeBlock("walk"),
      makeBlock("jump"),
      makeBlock("turn"),
      makeBlock("walk_right_up"),
      makeBlock("walk_right_down"),
      makeBlock("walk_left_up"),
      makeBlock("walk_left_down"),
      makeBlock("jump_right"),
      makeBlock("jump_left"),
      makeBlock("jump_up"),
      makeBlock("jump_down"),
      makeBlock("jump_right_up"),
      makeBlock("jump_right_down"),
      makeBlock("jump_left_up"),
      makeBlock("jump_left_down"),
      makeBlock("turn_right_or_left"),
      makeBlock("choose_turn_angle"),
      makeBlock("walk_forward"),
      makeBlock("walk_backward"),
      makeBlock("walk_choice"),
      makeBlock("walk_choice_by_unit"),
      makeBlock("jump_forward"),
      makeBlock("jump_backward"),
      makeBlock("jump_choice"),
      makeBlock("jump_choice_by_unit"),
      makeBlock("variable_walk"),
      makeBlock("variable_turn"),
      makeBlock("variable_jump"),
    ],
  },
  {
    name: "Mouvements",
    color: "160",
    blocks: [
      makeBlock("walk_with_character"),
      makeBlock("walk_right_with_character"),
      makeBlock("walk_left_with_character"),
      makeBlock("walk_up_with_character"),
      makeBlock("walk_down_with_character"),
      makeBlock("walk_right_up_with_character"),
      makeBlock("walk_right_down_with_character"),
      makeBlock("walk_left_up_with_character"),
      makeBlock("walk_left_down_with_character"),
      makeBlock("walk_forward_with_character"),
      makeBlock("walk_backward_with_character"),
      makeBlock("walk_choice_with_character"),
      makeBlock("walk_choice_by_unit_with_character"),

      makeBlock("jump_with_character"),
      makeBlock("jump_right_with_character"),
      makeBlock("jump_left_with_character"),
      makeBlock("jump_up_with_character"),
      makeBlock("jump_down_with_character"),
      makeBlock("jump_right_up_with_character"),
      makeBlock("jump_right_down_with_character"),
      makeBlock("jump_left_up_with_character"),
      makeBlock("jump_left_down_with_character"),
      makeBlock("jump_forward_with_character"),
      makeBlock("jump_backward_with_character"),
      makeBlock("jump_choice_with_character"),
      makeBlock("jump_choice_by_unit_with_character"),

      makeBlock("turn_with_character"),
      makeBlock("turn_right_or_left_with_character"),
      makeBlock("choose_turn_angle_with_character"),

      makeBlock("variable_walk_with_character"),
      makeBlock("variable_turn_with_character"),
      makeBlock("variable_jump_with_character"),
    ],
  },
  {
    name: "Actions",
    color: "180",
    blocks: [
      // makeBlock("pick_up_object"),
      // makeBlock("throw_object"),
      // makeBlock("variable_say"),
      makeBlock("variable_say_with_character"),
      // makeBlock("text_say"),
      makeBlock("text_say_with_character"),
      makeBlock("activate_autopilot_with_character"),
      makeBlock("deactivate_autopilot_with_character"),
    ],
  },
  {
    name: "Events",
    color: "210",
    blocks: [
      makeBlock("on_collision_character_character"),
      makeBlock("on_collision_character_object"),
      makeBlock("on_character_reach_position"),
      makeBlock("on_key_press"),
      // makeBlock("on_mouse_click_object"),
      makeBlock("on_timer_event"),
    ],
  },
  {
    name: "Control",
    color: "240",
    blocks: [
      makeBlock("controls_if"),
      makeBlock("controls_repeat_ext"),
      makeBlock("controls_whileUntil"),
      makeBlock("controls_for"),
      makeBlock("controls_forEach"),
      makeBlock("controls_flow_statements"),
      makeBlock("controls_switch"),
      makeBlock("controls_switch_dynamic"),
    ],
  },
  {
    name: "Logic",
    color: "270",
    blocks: [
      makeBlock("logic_compare"),
      makeBlock("logic_operation"),
      makeBlock("logic_negate"),
      makeBlock("logic_boolean"),
      makeBlock("logic_ternary"),
    ],
  },
  {
    name: "Math",
    color: "290",
    blocks: [
      makeBlock("math_number"),
      makeBlock("math_arithmetic"),
    ],
  },
  {
    name: "Input",
    color: "310",
    blocks: [
      makeBlock("math_number"),
      makeBlock("text"),
      makeBlock("text_join"),
      makeBlock("logic_boolean"),
      makeBlock("angle_field_block"),
      makeBlock("color_picker_block"),
      makeBlock("lists_create_with"),
    ],
  },
  {
    name: "Variables",
    color: "330",
    blocks: [
      makeBlock("variables_declare"),
      makeBlock("variables_set"),
      makeBlock("variables_increment"),
      makeBlock("variables_decrement"),
      makeBlock("variables_get"),
    ],
  },
  {
    name: "Text",
    color: "240",
    blocks: [
      makeBlock("create_text"),
      makeBlock("create_text_full"),
      makeBlock("set_text_content"),
      makeBlock("add_text_content"),
      makeBlock("replace_text_content"),
      makeBlock("move_text_object"),
      makeBlock("set_text_font_size"),
      makeBlock("set_text_font"),
      makeBlock("set_text_color"),
      makeBlock("set_text_bg_color"),
      makeBlock("destroy_text_object"),
    ],
  },
];
