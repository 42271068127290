// src/blocks/move/moveBackward/.ts

import * as Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import { BlocklyFields } from "../../BlocklyFields";
import i18next from "i18next";

export function initMoveBackwardBlock() {
  Blockly.Blocks[BlocklyFields.MOVE_BACKWARD] = {
    init: function () {
      this.jsonInit({
        type: BlocklyFields.MOVE_BACKWARD,
        message0:
          "%1 " + i18next.t("blockly.move.backward.message", "Move backward"),
        args0: [
          {
            type: "field_image",
            src: "/images/blocks/backward.png", 
            width: 16, // Largeur de l'image
            height: 16, // Hauteur de l'image
          },
        ],
        previousStatement: null,
        nextStatement: null,
        colour: "#FFAB19", // Rose, par exemple
        tooltip: i18next.t("blockly.move.backward.tooltip", ""),
        helpUrl: "",
      });
    },
  };

  javascriptGenerator.forBlock[BlocklyFields.MOVE_BACKWARD] = function (
    block,
    generator
  ) {
    return "await moveBackward();\n";
  };
}
