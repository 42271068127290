import React, { useState } from 'react';
import { Select, Input, Space, Button, Table, Checkbox } from 'antd';
import { TCharacter } from '../../../../models/objects/objects';
import { TLesson, TLessonCharacter } from '../../../../models/courses/lesson';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { getImagePath } from '../../../../utils/format/valueFormat';

const { Option } = Select;

interface MultiCharacterProps {
    characters: TCharacter[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const MultiCharacter: React.FC<MultiCharacterProps> = ({ characters, editingLesson, setEditingLesson }) => {

    const [addingId, setAddingId] = useState<number>(0);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingCharacter, setEditingCharacter] = useState<TLessonCharacter | null>(null);

    // Calculer les IDs des personnages déjà sélectionnés
    const selectedCharacterIds = (editingLesson?.characters || []).map(c => c.character_id);

    // Liste des personnages disponibles pour la sélection
    const availableCharacters = characters.filter(char => !selectedCharacterIds.includes(char.id));

    // Fonction pour passer en mode édition
    const edit = (target: TLessonCharacter) => {
        setEditingKey(target.id);
        setEditingCharacter({ ...target });
    };

    // Fonction pour gérer l'ajout d'un nouveau personnage
    const handleAddCharacter = () => {
        if (availableCharacters.length === 0) {
            // Aucun personnage disponible
            return;
        }

        const idRow = addingId - 1;
        const characterDefault = availableCharacters[0];

        const newCharacter: TLessonCharacter = {
            id: idRow,
            character_id: characterDefault.id,
            lesson_id: 0,
            canbeplayedwith: false,
            x: 0,
            y: 0,
            width: 50,
            height: 50,
            scale: 1,
            displacement_unit: 10,
            character: characterDefault
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingCharacter(newCharacter);

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                characters: [
                    ...(prevLesson.characters || []),
                    newCharacter
                ]
            };
        });
    };

    // Sauvegarde les modifications
    const save = (id: number) => {
        if (!editingCharacter) {
            return;
        }

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;
            const newCharacters = prevLesson.characters?.map((char) => {
                if (char.id === id) {
                    return { ...editingCharacter, id };
                }
                return char;
            });

            return {
                ...prevLesson,
                characters: newCharacters,
            };
        });

        setEditingKey(null);
        setEditingCharacter(null);
    };

    // Gestion de la suppression d'un personnage
    const handleDelete = (id: number) => {
        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                characters: prevLesson.characters?.filter((char) => char.id !== id),
            };
        });
    };

    // Gestion des changements des champs d'input
    const handleInputChange = (field: string, value: string | number | boolean) => {
        setEditingCharacter((prev) => {
            if (!prev) return prev;
            let newValue: any = value;
            if (['x', 'y', 'width', 'height', 'scale', 'displacement_unit'].includes(field)) {
                newValue = Number(value);
            }
            if (field === 'canbeplayedwith') {
                newValue = Boolean(value);
            }
            return { ...prev, [field]: newValue };
        });
    };

    // Gestion du changement de personnage dans le sélecteur
    const handleCharacterChange = (value: number) => {
        const selectedCharacter = characters.find((t) => t.id === value);
        setEditingCharacter((prev) => {
            if (!prev) return prev;
            return { ...prev, character_id: value, character: selectedCharacter || ({} as TCharacter) };
        });
    };

    const columns = [
        {
            title: 'Character',
            dataIndex: 'character',
            key: 'character',
            width: 200,
            render: (text: string, record: TLessonCharacter) => {
                // IDs des personnages sélectionnés dans les autres lignes
                const otherSelectedCharacterIds = (editingLesson?.characters || [])
                    .filter(c => c.id !== record.id)
                    .map(c => c.character_id);

                const characterOptions = characters
                    .filter(char => !otherSelectedCharacterIds.includes(char.id) || char.id === editingCharacter?.character_id)
                    .map((char) => (
                        <Option key={char.id} value={char.id}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={getImagePath(char?.images?.[0]?.img ?? "")}
                                    alt={char.name}
                                    style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                />
                                <span>{char.name}</span>
                            </div>
                        </Option>
                    ));

                return editingKey === record.id ? (
                    <Select
                        placeholder={'Character'}
                        style={{ width: '100%' }}
                        onChange={(value) => handleCharacterChange(value)}
                        value={editingCharacter?.character_id}
                    >
                        {characterOptions}
                    </Select>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={getImagePath(record?.character?.images?.[0]?.img ?? "")}
                            alt={record.character.name}
                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                        />
                        <span>{record.character.name}</span>
                    </div>
                );
            },
        },
        {
            title: 'X',
            dataIndex: 'x',
            key: 'x',
            render: (text: number, record: TLessonCharacter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCharacter?.x}
                        onChange={(e) => handleInputChange('x', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonCharacter, b: TLessonCharacter) => a.x - b.x,
        },
        {
            title: 'Y',
            dataIndex: 'y',
            key: 'y',
            render: (text: number, record: TLessonCharacter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCharacter?.y}
                        onChange={(e) => handleInputChange('y', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonCharacter, b: TLessonCharacter) => a.y - b.y,
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
            render: (text: number, record: TLessonCharacter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCharacter?.width}
                        onChange={(e) => handleInputChange('width', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonCharacter, b: TLessonCharacter) => a.width - b.width,
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            render: (text: number, record: TLessonCharacter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCharacter?.height}
                        onChange={(e) => handleInputChange('height', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonCharacter, b: TLessonCharacter) => a.height - b.height,
        },
        {
            title: 'Player',
            dataIndex: 'canbeplayedwith',
            key: 'canbeplayedwith',
            render: (text: number, record: TLessonCharacter) =>
                editingKey === record.id ? (
                    <Checkbox
                        checked={editingCharacter?.canbeplayedwith}
                        onChange={(e) => handleInputChange('canbeplayedwith', e.target.checked)}
                    />
                ) : (
                    <Checkbox
                        checked={record.canbeplayedwith}
                        disabled
                    />
                ),
        },
        {
            title: 'Déplacement',
            dataIndex: 'displacement_unit',
            key: 'displacement_unit',
            width: 180,
            render: (text: number, record: TLessonCharacter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCharacter?.displacement_unit}
                        onChange={(e) => handleInputChange('displacement_unit', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonCharacter, b: TLessonCharacter) => a.displacement_unit - b.displacement_unit,
        },
        {
            title: 'Scale',
            dataIndex: 'scale',
            key: 'scale',
            width: 180,
            render: (text: number, record: TLessonCharacter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingCharacter?.scale}
                        onChange={(e) => handleInputChange('scale', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonCharacter, b: TLessonCharacter) => a.scale - b.scale,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: TLessonCharacter) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <Button
                            icon={<SaveOutlined />}
                            onClick={() => save(record.id)}
                        />
                    ) : (
                        <>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => edit(record)}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(record.id)}
                            />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Button type="primary" onClick={handleAddCharacter} disabled={editingKey !== null || availableCharacters.length === 0}>
                Add Character
            </Button>
            <Table
                dataSource={editingLesson?.characters}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </Space>
    );
};

export default MultiCharacter;
