// src/blocks/move/teleportTo.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../BlocklyFields';
import i18next from 'i18next';

export function initTeleportBlock() {
    Blockly.Blocks[BlocklyFields.TELEPORT_TO] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.TELEPORT_TO,
                "message0": '%1 ' + i18next.t('blockly.teleport.message', 'Téléporter vers') + ' %2',
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/teleport.png", // Lien vers votre icône (modifiez selon votre besoin)
                        "width": 16,   // Largeur de l'image
                        "height": 16,  // Hauteur de l'image
                    },
                    {
                        "type": "field_dropdown",
                        "name": "POSITION",
                        "options": [
                            [i18next.t('blockly.teleport.options.top_left.message'), "TOP_LEFT"],
                            [i18next.t('blockly.teleport.options.top.message'), "TOP"],
                            [i18next.t('blockly.teleport.options.top_right.message'), "TOP_RIGHT"],
                            [i18next.t('blockly.teleport.options.center_left.message'), "CENTER_LEFT"],
                            [i18next.t('blockly.teleport.options.center.message'), "CENTER"],
                            [i18next.t('blockly.teleport.options.center_right.message'), "CENTER_RIGHT"],
                            [i18next.t('blockly.teleport.options.bottom_left.message'), "BOTTOM_LEFT"],
                            [i18next.t('blockly.teleport.options.bottom.message'), "BOTTOM"],
                            [i18next.t('blockly.teleport.options.bottom_right.message'), "BOTTOM_RIGHT"]
                        ]
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#4A90E2',
                "tooltip": i18next.t('blockly.teleport.tooltip', 'Téléporte le personnage à une position spécifiée.'),
                "helpUrl": ""
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.TELEPORT_TO] = function (block, generator) {
        // Récupère la valeur sélectionnée dans le dropdown
        const position = block.getFieldValue('POSITION');
        // Génère le code pour appeler la fonction teleportTo avec la position
        return `await teleportTo('${position}');\n`;
    };
}
