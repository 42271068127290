import React from 'react';
import { Button, Space } from 'antd';
import { useExecutionContext } from '../contexts/ExecutionContext';

interface DebugTBProps {
    onRun?: () => void;
    onNextStep?: () => void;
    handleRestart?: () => void;
}

const DebugTB: React.FC<DebugTBProps> = ({ onRun, onNextStep, handleRestart }) => {
    const {
        isRunning,
        isPaused,
        // start,
        // restart,
        // pause, 
        // resume,
        // stop,
        // logs 
    } = useExecutionContext();

    return (
        <Space>
            {/* <Button onClick={start} disabled={isRunning && !isPaused}>Start</Button> */}
            {/* <Button onClick={pause} disabled={!isRunning || isPaused}>Pause</Button>
            <Button onClick={resume} disabled={!isRunning || !isPaused}>Resume</Button> */}
            {/* <Button onClick={stop} disabled={!isRunning}>Stop</Button> */}
            <Button onClick={handleRestart} >Restart</Button>
            <Button onClick={onNextStep} disabled={!isRunning || isPaused}>Next Step</Button>
            <Button onClick={onRun} disabled={!isRunning || isPaused}>Run</Button>
        </Space>
    );
};

export default DebugTB;
