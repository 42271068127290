import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, Button, Typography, message, Space } from 'antd';
import { ChallengeConfigProvider, defaultConfig, useChallengeConfig } from '../../contexts/BlocklyConfigContext';
import Blockly, { BlocklyRef } from '../Blockly';
import AdminToolboxDrawer from './AdminToolboxDrawer';
import AdminCharactersDrawer from './AdminCharactersDrawer';
import AdminObjectsDrawer from './AdminObjectsDrawer';
import AdminSceneDrawer from './AdminSceneDrawer';
import AdminWinConditionsDrawer from './AdminWinConditionsDrawer';
import FloatButtons from '../../../button/FloatButtons';
import { TLesson } from '../../../../models/courses/lesson';
import { useCourseApi } from '../../../../api/courses/courses';
import { useNavigate } from 'react-router-dom';
import AdminTextsDrawer from './AdminTextsDrawer';

const { Title } = Typography;

// On reçoit eventManager en props
interface AdminConfiguratorProps {
  editingLesson: TLesson | undefined;
}

const AdminConfigurator: React.FC<AdminConfiguratorProps> = ({ editingLesson }) => {
  return (
    <ChallengeConfigProvider initialConfig={defaultConfig(true)}>
      <AdminConfiguratorContent editingLesson={editingLesson} />
    </ChallengeConfigProvider>
  );
};

/**
 * Page d'administration permettant de configurer un challenge en temps réel.
 */
const AdminConfiguratorContent: React.FC<AdminConfiguratorProps> = ({ editingLesson }) => {

  const navigate = useNavigate();
  const blocklyRef = useRef<BlocklyRef>(null);
  const { config, setConfig } = useChallengeConfig();

  const { saveLessonDetails } = useCourseApi();
  // État pour le Drawer
  const [sceneDrawerVisible, setSceneDrawerVisible] = useState(false);
  const [toolboxDrawerVisible, setToolboxDrawerVisible] = useState(false);
  const [charDrawerVisible, setCharDrawerVisible] = useState(false);
  const [objectDrawerVisible, setObjectDrawerVisible] = useState(false);
  const [textDrawerVisible, setTextDrawerVisible] = useState(false);
  const [winCondDrawerVisible, setWinCondDrawerVisible] = useState(false);
  const [isWin, setIsWin] = useState(false);

  useEffect(() => {
    if (!editingLesson) return;
    if (!editingLesson.config_phaser) return;
    if (!editingLesson.config_phaser.config) return;

    // Ici, TS sait que config n'est pas undefined
    setConfig({
      ...editingLesson.config_phaser.config,
      adminMode: true,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isWin) {
      message.success("win")
    }
  }, [isWin]);

  const onWin = () => {
    setIsWin(true);
  }

  const save = async (): Promise<void> => {
    if (editingLesson) {


      // Mise à jour des données de la leçon avec le contenu de l'éditeur
      let data: TLesson = {
        ...editingLesson,
        config_phaser: {
          id: 0,
          lesson_id: editingLesson.id,
          config: { ...config, workspace: blocklyRef?.current?.getWorkspaceXml() ?? '' },
        },
      };

      try {
        await saveLessonDetails(data);
      } catch (err: any) {
        message.error(err.response?.data ?? err.message ?? err);
      }
    }
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Row gutter={16} style={{ padding: 16 }}>
        <Col span={24}>
          <Card style={{ marginBottom: 16 }}>
            <Title level={3}>Administration du Challenge</Title>

            <Space>
              <Button onClick={() => setSceneDrawerVisible(true)}>
                Configurer la Scène
              </Button>
              <Button onClick={() => setToolboxDrawerVisible(true)}>
                Configurer la Toolbox
              </Button>
              <Button onClick={() => setCharDrawerVisible(true)} style={{ marginLeft: 8 }}>
                Configurer les Personnages
              </Button>
              <Button onClick={() => setObjectDrawerVisible(true)} style={{ marginLeft: 8 }}>
                Configurer les Objets
              </Button>
              <Button onClick={() => setTextDrawerVisible(true)} style={{ marginLeft: 8 }}>
                Configurer les Textes
              </Button>
              <Button onClick={() => setWinCondDrawerVisible(true)}>
                Configurer les conditions de victoire
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>


      <Row gutter={16} style={{ padding: '0 16px 16px' }}>
        <Col span={24}>
          <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Title level={3}>Aperçu en temps réel : Blockly + Animation</Title>
            <div style={{ flex: 1, marginTop: 16 }}>
              <Blockly ref={blocklyRef} onWin={onWin} />
            </div>
          </Card>
        </Col>
      </Row>
      <AdminSceneDrawer
        visible={sceneDrawerVisible}
        onClose={() => setSceneDrawerVisible(false)}
      />
      <AdminToolboxDrawer
        visible={toolboxDrawerVisible}
        onClose={() => setToolboxDrawerVisible(false)}
      />
      <AdminCharactersDrawer
        visible={charDrawerVisible}
        onClose={() => setCharDrawerVisible(false)}
      />
      <AdminObjectsDrawer
        visible={objectDrawerVisible}
        onClose={() => setObjectDrawerVisible(false)}
      />
      <AdminTextsDrawer
        visible={textDrawerVisible}
        onClose={() => setTextDrawerVisible(false)}
      />
      <AdminWinConditionsDrawer
        visible={winCondDrawerVisible}
        onClose={() => setWinCondDrawerVisible(false)}
      />


      <FloatButtons save={save} />
    </div>
  );
};

export default AdminConfigurator;
