// AdminCharactersDrawer .tsx

import React, { useEffect, useState } from 'react';
import { Table, Button, InputNumber, Select, message, Drawer } from 'antd';
import { useChallengeConfig } from '../../contexts/BlocklyConfigContext';
import { useObjectsApi } from '../../../../api/objects/objects';
import { BlocklyPhaserConfig, CharacterConfig } from '../../types/BlocklyTypes';
import { getImagePath } from '../../../../utils/format/valueFormat';
import { TSprite } from '../../../../models/objects/sprite';


interface AdminCharactersDrawerProps {
    visible: boolean;
    onClose: () => void;
}

/**
 * Affiche un Drawer pour configurer la liste des personnages (characters).
 * - On peut ajouter un personnage (issu de la BDD),
 * - Modifier x, y, width, height,
 * - Supprimer un perso.
 */
const AdminCharactersDrawer: React.FC<AdminCharactersDrawerProps> = ({
    visible,
    onClose,
}) => {
    const { config, setConfig } = useChallengeConfig();
    const { getSprites, getSpriteById } = useObjectsApi();

    // Liste de tous les persos en BDD
    const [sprites, setSprites] = useState<TSprite[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCharacterId, setSelectedCharacterId] = useState<number | null>(null);
    const { Option } = Select;


    // Charger la liste BDD au montage
    // Charger la liste initiale
    useEffect(() => {
        setLoading(true);
        getSprites()
            .then((resp) => {
                setSprites(resp.data ?? []);
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Ajoute un nouveau perso dans la config “Blockly” (config.characters).
     * On demande par exemple un "dbId" = TCharacter.id, 
     * et on initialise x, y, width, height avec des valeurs par défaut.
     */
    const handleAddCharacter = (dbId: number) => {

        getSpriteById(dbId)
            .then((resp) => {
                const spriteData: TSprite = resp.data;
                setConfig((prev: BlocklyPhaserConfig) => {
                    // Vérifier si déjà présent
                    //   const alreadyExists = prev.characters.some((c) => c.id === dbId);
                    //   if (alreadyExists) {
                    //     message.warning('Ce personnage est déjà dans la liste.');
                    //     return prev;
                    //   }

                    // Retrouver l'info du perso en BDD
                    const dbChar = sprites.find((ch) => ch.id === dbId);
                    if (!dbChar) {
                        message.error("Personnage introuvable dans la liste BDD.");
                        return prev;
                    }

                    // Créer un CharacterConfig
                    const newChar: CharacterConfig = {
                        adminMode: config.adminMode,
                        id: `${dbChar.name.replaceAll(" ", "_")}_${(prev.characters ?? []).length + 1}`,
                        name: dbChar.name,
                        x: 100,
                        y: 100,
                        width: 50,
                        height: 50,
                        speedPxPerStep: 50,
                        image_url: dbChar.image_url,
                        animations: spriteData.animations,
                    }

                    return {
                        ...prev,
                        characters: [...prev.characters, newChar],
                    };
                });
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
            })
            .finally(() => {
                setLoading(false);
            });

    };

    /**
     * Mettre à jour un champ (x, y, width, height) pour un perso déjà dans config.
     */
    const handleUpdateCharField = (
        charId: string,
        field: 'x' | 'y' | 'width' | 'height',
        value: number
    ) => {
        setConfig((prev: BlocklyPhaserConfig) => {
            const updatedChars = prev.characters.map((c) => {
                if (c.id === charId) {
                    return {
                        ...c,
                        [field]: value,
                    };
                }
                return c;
            });
            return { ...prev, characters: updatedChars };
        });
    };

    /**
     * Supprimer un perso de la config
     */
    const handleRemoveCharacter = (charId: string) => {
        setConfig((prev: BlocklyPhaserConfig) => ({
            ...prev,
            characters: prev.characters.filter((c) => c.id !== charId),
        }));
    };

    // Prépare la structure pour un Table (ou un composant custom)
    // On affiche name (depuis config), x, y, width, height, et un bouton remove
    const columns = [
        {
            title: 'Image',
            dataIndex: 'img',
            key: 'image',
            width: 80,
            render: (_value: any, record: CharacterConfig) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={getImagePath(record.image_url)}
                        alt={record.name}
                        style={{ width: '30px', height: '30px', marginRight: '10px' }}
                    />
                </div>
            ),
        },
        {
            title: 'Nom du personnage',
            dataIndex: 'name',
            key: 'name',
            render: (_value: any, record: CharacterConfig) => record.name,
        },
        {
            title: 'X',
            dataIndex: 'x',
            key: 'x',
            render: (_value: any, record: CharacterConfig) => (
                <InputNumber
                    min={0}
                    value={record.x}
                    onChange={(val) => handleUpdateCharField(record.id, 'x', val ?? 0)}
                />
            ),
            sorter: (a: CharacterConfig, b: CharacterConfig) => a.x - b.x,

        },
        {
            title: 'Y',
            dataIndex: 'y',
            key: 'y',
            render: (_value: any, record: CharacterConfig) => (
                <InputNumber
                    min={0}
                    value={record.y}
                    onChange={(val) => handleUpdateCharField(record.id, 'y', val ?? 0)}
                />
            ),
            sorter: (a: CharacterConfig, b: CharacterConfig) => a.y - b.y,
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
            render: (_value: any, record: CharacterConfig) => (
                <InputNumber
                    min={0}
                    value={record.width}
                    onChange={(val) => handleUpdateCharField(record.id, 'width', val ?? 50)}
                />
            ),
            sorter: (a: CharacterConfig, b: CharacterConfig) => a.width - b.width,
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            render: (_value: any, record: CharacterConfig) => (
                <InputNumber
                    min={0}
                    value={record.height}
                    onChange={(val) => handleUpdateCharField(record.id, 'height', val ?? 50)}
                />
            ),
            sorter: (a: CharacterConfig, b: CharacterConfig) => a.height - b.height,
        },
        {
            title: 'Action',
            key: 'action',
            render: (_value: any, record: CharacterConfig) => (
                <Button danger onClick={() => handleRemoveCharacter(record.id)}>
                    Supprimer
                </Button>
            ),
        },
    ];

    return (
        <Drawer
            title="Configuration des personnages"
            placement="left"
            width={900}
            onClose={onClose}
            open={visible}
        >

            {/* Sélection pour ajouter un nouveau perso depuis la BDD */}
<div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
    <Select
        placeholder="Choisir un personnage à ajouter"
        style={{ width: 300, marginRight: 8 }}
        value={selectedCharacterId}
        onChange={(value: number) => setSelectedCharacterId(value)} // Gère la sélection
    >
        {sprites.map((char) => (
            <Option value={char.id} key={char.id}>
                {char.name}
            </Option>
        ))}
    </Select>
    <Button
        type="primary"
        onClick={() => {
            if (selectedCharacterId) {
                handleAddCharacter(selectedCharacterId); // Ajoute le personnage sélectionné
            } else {
                message.warning("Veuillez sélectionner un personnage à ajouter.");
            }
        }}
    >
        Ajouter
    </Button>
</div>

            {/* Table des persos configurés */}
            <Table
                dataSource={config.characters}
                columns={columns}
                rowKey="id"
                pagination={false}
                loading={loading}
            />
        </Drawer>
    );
};

export default AdminCharactersDrawer;
