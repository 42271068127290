// useApiFunctions.js
import { TCharacter, TObstacle, TScene, TTarget } from '../../models/objects/objects';
import { TSprite } from '../../models/objects/sprite';
import { useApiClient } from '../apiClient';
import { ENDPOINTS_CHARACTER, ENDPOINTS_OBSTACLE, ENDPOINTS_SCENE, ENDPOINTS_SPRITE, ENDPOINTS_TARGET } from './endpoints';

export const useObjectsApi = () => {
    const apiClient = useApiClient();

    // character
    const getCharacters = () => apiClient.get<TCharacter[]>(ENDPOINTS_CHARACTER.GET_ALL);
    const createCharacter = (data: TCharacter) => apiClient.post(ENDPOINTS_CHARACTER.POST, data);
    const updateCharacter = (id: number, data: TCharacter) => apiClient.put(ENDPOINTS_CHARACTER.PUT(id), data);
    const deleteCharacter = (id: number) => apiClient.delete(ENDPOINTS_CHARACTER.DELETE(id));
    const uploadCharacterImage = (id: number, idstate: number, formData: FormData) => {
        return apiClient.post<TCharacter>(ENDPOINTS_CHARACTER.UPLOAD_IMAGE(id, idstate), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };

    // obstacle
    const getObstacles = () => apiClient.get<TObstacle[]>(ENDPOINTS_OBSTACLE.GET_ALL);
    const createObstacle = (data: TObstacle) => apiClient.post(ENDPOINTS_OBSTACLE.POST, data);
    const updateObstacle = (id: number, data: TObstacle) => apiClient.put(ENDPOINTS_OBSTACLE.PUT(id), data);
    const deleteObstacle = (id: number) => apiClient.delete(ENDPOINTS_OBSTACLE.DELETE(id));
    const uploadObstacleImage = (id: number, formData: FormData) => {
        return apiClient.post<TObstacle>(ENDPOINTS_OBSTACLE.UPLOAD_IMAGE(id), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };

    // scene
    const getScenes = () => apiClient.get<TScene[]>(ENDPOINTS_SCENE.GET_ALL);
    const createScene = (data: TScene) => apiClient.post(ENDPOINTS_SCENE.POST, data);
    const updateScene = (id: number, data: TScene) => apiClient.put(ENDPOINTS_SCENE.PUT(id), data);
    const deleteScene = (id: number) => apiClient.delete(ENDPOINTS_SCENE.DELETE(id));
    const uploadSceneImage = (id: number, formData: FormData) => {
        return apiClient.post<TScene>(ENDPOINTS_SCENE.UPLOAD_IMAGE(id), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };

    // target
    const getTargets = () => apiClient.get<TTarget[]>(ENDPOINTS_TARGET.GET_ALL);
    const createTarget = (data: TTarget) => apiClient.post(ENDPOINTS_TARGET.POST, data);
    const updateTarget = (id: number, data: TTarget) => apiClient.put(ENDPOINTS_TARGET.PUT(id), data);
    const deleteTarget = (id: number) => apiClient.delete(ENDPOINTS_TARGET.DELETE(id));
    const uploadTargetImage = (id: number, stateId: number, formData: FormData) => {
        return apiClient.post<TTarget>(ENDPOINTS_TARGET.UPLOAD_IMAGE(id, stateId), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };


    // scene
    const getSprites = () => apiClient.get<TSprite[]>(ENDPOINTS_SPRITE.GET_ALL);
    const getSpriteById = (id: number) => apiClient.get<TSprite>(ENDPOINTS_SPRITE.GET_BY_ID(id));
    const createSprite = (data: TSprite) => apiClient.post(ENDPOINTS_SPRITE.POST, data);
    const createSpriteAnimation = (data: TSprite) => apiClient.post(ENDPOINTS_SPRITE.POST_ANIMATIONS, data);
    const updateSprite = (id: number, data: TSprite) => apiClient.put(ENDPOINTS_SPRITE.PUT(id), data);
    const deleteSprite = (id: number) => apiClient.delete(ENDPOINTS_SPRITE.DELETE(id));
    const uploadSpriteImage = (id: number, formData: FormData) => {
        return apiClient.post<TSprite>(ENDPOINTS_SPRITE.UPLOAD_IMAGE(id), formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    };

    return {
        getCharacters,
        createCharacter,
        updateCharacter,
        deleteCharacter,
        uploadCharacterImage,

        getObstacles,
        createObstacle,
        updateObstacle,
        deleteObstacle,
        uploadObstacleImage,

        getScenes,
        createScene,
        updateScene,
        deleteScene,
        uploadSceneImage,

        getTargets,
        createTarget,
        updateTarget,
        deleteTarget,
        uploadTargetImage,

        getSprites,
        getSpriteById,
        createSprite,
        createSpriteAnimation,
        updateSprite,
        deleteSprite,
        uploadSpriteImage,

    };
};
