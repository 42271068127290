import React, { createContext, useContext, useState } from 'react';
import { BlocklyPhaserConfig } from '../types/BlocklyTypes';

interface BlocklyConfigContextValue {
    config: BlocklyPhaserConfig;
    setConfig: React.Dispatch<React.SetStateAction<BlocklyPhaserConfig>>;
}

export const defaultConfig = (admin: boolean = false): BlocklyPhaserConfig => {
    return {
        adminMode: admin,
        workspace: '',
        toolboxCategories: [],
        toolboxGrouped: false,
        scene: {
            width: 400,
            height: 400,
            background: {
                image: '',
                mode: 'cover',
            }
        },
        characters: [],
        objects: [],
        texts: [],
        winConditions: [],
    }
};

const BlocklyConfigContext = createContext<BlocklyConfigContextValue>({
    config: defaultConfig(),
    setConfig: () => { },
});

export const ChallengeConfigProvider: React.FC<{
    initialConfig?: BlocklyPhaserConfig
    children: React.ReactNode;
}> = ({
    initialConfig,
    children
}) => {
        const [config, setConfig] = useState<BlocklyPhaserConfig>(initialConfig || defaultConfig);

        return (
            <BlocklyConfigContext.Provider value={{ config, setConfig }}>
                {children}
            </BlocklyConfigContext.Provider>
        );
    };

export const useChallengeConfig = () => useContext(BlocklyConfigContext);