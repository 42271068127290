// useApiFunctions.js
import { TLessonAnswerUser } from '../../models/courses/lesson';
import { TDashboard } from '../../models/user/Dashboard';
import { TPassword, TRole, TRoleUserType, TUserApp } from '../../models/user/User';
import { TLessonBlocklyUserAnswer, TLessonProgress } from '../../models/user/UserProgression';
import { useApiClient } from '../apiClient';
import {
    ENDPOINTS_BLOCKLY_ANSWERS,
    ENDPOINTS_DASHBOARD,
    ENDPOINTS_PASSWORDS,
    ENDPOINTS_PROGRESS,
    ENDPOINTS_ROLES,
    ENDPOINTS_USER_ANSWERS,
    ENDPOINTS_USERAPP,
} from './endpoints';


export const useUserApi = () => {
    const apiClient = useApiClient();

    // -------------------------------------------------------------------------
    // UserApps
    // -------------------------------------------------------------------------
    const getAllUserApps = () => apiClient.get<TUserApp[]>(ENDPOINTS_USERAPP.GET_ALL);
    const getUserAppById = (id: number) => apiClient.get<TUserApp>(ENDPOINTS_USERAPP.GET_BY_ID(id));
    const createUserApp = (data: TUserApp) => apiClient.post(ENDPOINTS_USERAPP.POST, data);
    const updateUserApp = (id: number, data: TUserApp) => apiClient.put(ENDPOINTS_USERAPP.PUT(id), data);
    const deleteUserApp = (id: number) => apiClient.delete(ENDPOINTS_USERAPP.DELETE(id));
    const generateLoginUserApp = (data: any) => apiClient.post(ENDPOINTS_USERAPP.POST_GENERATE_LOGIN, data);

    const getAllUsersAffected = () => apiClient.get<TUserApp[]>(ENDPOINTS_USERAPP.GET_ALL_AFFECTED_DETAILS);
    const getUserDetails = (id: number) => apiClient.get<TUserApp>(ENDPOINTS_USERAPP.GET_USER_DETAILS(id));
    const getAllUsersForSearch = (typeRelationId: number, typeUserParentId: number) =>
        apiClient.get<TUserApp[]>(ENDPOINTS_USERAPP.GET_ALL_BY_TYPE(typeRelationId, typeUserParentId));
    const saveUserWithDetails = (data: TUserApp) => apiClient.post<TUserApp>(ENDPOINTS_USERAPP.POST_SAVE_DETAILS, data);

    // -------------------------------------------------------------------------
    // Passwords 
    // -------------------------------------------------------------------------
    const getPasswordsByUserAppId = (id: number) =>
        apiClient.get<TPassword[]>(ENDPOINTS_PASSWORDS.GET_BY_IDUSERAPP(id));
    const createPassword = (data: TPassword) => apiClient.post(ENDPOINTS_PASSWORDS.POST, data);
    const updatePassword = (id: number, data: TPassword) => apiClient.put(ENDPOINTS_PASSWORDS.PUT(id), data);
    const deletePassword = (id: number) => apiClient.delete(ENDPOINTS_PASSWORDS.DELETE(id));

    // -------------------------------------------------------------------------
    // Progression
    // -------------------------------------------------------------------------
    const getProgressionUserByCourseId = (course_id: number) =>
        apiClient.get<TLessonProgress[]>(ENDPOINTS_PROGRESS.GET_BY_IDCOURSE(course_id));
    const saveProgressionUser = (lesson_id: number) =>
        apiClient.post(ENDPOINTS_PROGRESS.POST_SAVE(lesson_id));
    const getProgressStateByCourseId = (course_id: number) =>
        apiClient.get<TLessonProgress[]>(ENDPOINTS_PROGRESS.GET_PROGRESS_BY_IDCOURSE(course_id));
    const getProgressStateBySubjectId = (subject_id: number) =>
        apiClient.get<TLessonProgress[]>(ENDPOINTS_PROGRESS.GET_PROGRESS_BY_IDSUBJECT(subject_id));

    // -------------------------------------------------------------------------
    // Question answers
    // -------------------------------------------------------------------------
    const getQuestionAnswersByQuestionId = (question_id: number) =>
        apiClient.get<TLessonAnswerUser[]>(ENDPOINTS_USER_ANSWERS.GET_BY_QUESTION_ID(question_id));
    const saveQuestionAnswers = (data: TLessonAnswerUser[]) =>
        apiClient.post<TLessonAnswerUser[]>(ENDPOINTS_USER_ANSWERS.POST_SAVE, data);

    // -------------------------------------------------------------------------
    // Roles
    // -------------------------------------------------------------------------
    const getAllRoles = () => apiClient.get<TRole[]>(ENDPOINTS_ROLES.GET_ALL);
    const getAllRolesTypeUsersLinks = () => apiClient.get<TRoleUserType[]>(ENDPOINTS_ROLES.GEL_ALL_LINKED_ROLES);
    const createRoleTypeUserLink = (data: TRoleUserType) => apiClient.post(ENDPOINTS_ROLES.POST_ROLE_TO_TYPE_USER, data);
    const deleteRoleTypeUserLink = (id: number) => apiClient.delete(ENDPOINTS_ROLES.DELETE_ROLE_TO_TYPE_USER(id));

    // -------------------------------------------------------------------------
    // Dashboard 
    // -------------------------------------------------------------------------
    const getDashboard = () => apiClient.get<TDashboard>(ENDPOINTS_DASHBOARD.GET_DASHBOARD);

    // -------------------------------------------------------------------------
    // NEW - Blockly Answers
    // -------------------------------------------------------------------------
    // Récupère toutes les réponses blockly pour l'utilisateur connecté
    const getBlocklyAnswersByUser = () =>
        apiClient.get<TLessonBlocklyUserAnswer[]>(ENDPOINTS_BLOCKLY_ANSWERS.GET_BY_USER);

    // Récupère toutes les réponses blockly pour l'utilisateur connecté sur la leçon lesson_id
    const getBlocklyAnswersByLesson = (lesson_id: number) =>
        apiClient.get<TLessonBlocklyUserAnswer>(ENDPOINTS_BLOCKLY_ANSWERS.GET_BY_LESSON(lesson_id));

    // Sauvegarde une nouvelle réponse (workspace) pour l'utilisateur connecté / leçon lesson_id
    // La signature d'API peut être adaptée (single ou array).
    const saveBlocklyAnswer = (lesson_id: number, data: Partial<TLessonBlocklyUserAnswer>) =>
        apiClient.post<TLessonBlocklyUserAnswer>(ENDPOINTS_BLOCKLY_ANSWERS.POST_SAVE(lesson_id), data);

    return {
        // ---------------------------------------------------------------------
        // UserApps
        getAllUserApps,
        getUserAppById,
        createUserApp,
        updateUserApp,
        deleteUserApp,
        generateLoginUserApp,

        getAllUsersAffected,
        getUserDetails,
        getAllUsersForSearch,
        saveUserWithDetails,

        // ---------------------------------------------------------------------
        // Passwords
        createPassword,
        updatePassword,
        deletePassword,
        getPasswordsByUserAppId,

        // ---------------------------------------------------------------------
        // Progress
        getProgressionUserByCourseId,
        saveProgressionUser,
        getProgressStateByCourseId,
        getProgressStateBySubjectId,

        // ---------------------------------------------------------------------
        // Question answers
        getQuestionAnswersByQuestionId,
        saveQuestionAnswers,

        // ---------------------------------------------------------------------
        // Roles
        getAllRoles,
        getAllRolesTypeUsersLinks,
        createRoleTypeUserLink,
        deleteRoleTypeUserLink,

        // ---------------------------------------------------------------------
        // Dashboard
        getDashboard,

        // ---------------------------------------------------------------------
        // NEW - Blockly answers
        getBlocklyAnswersByUser,
        getBlocklyAnswersByLesson,
        saveBlocklyAnswer,
    };
};
