import { getEngine } from "./engineManager";

export const sayCharacter = (id: string, message: any) => {
  const engine = getEngine();
  const char = engine?.getCharacter(id);
  if (!char) {
    console.warn(`Character with id ${id} not found.`);
    return;
  }

  const messageStr = String(message); // Convertir en chaîne de caractères
  char.say(messageStr); // Cette ligne affiche la phrase du personnage
  engine?.phaserScene?.emit("sayPhraseEvent", id, messageStr); // Émission de l'événement
};


/**
 * Active l'auto-pilote pour un personnage.
 */
export function activateAutoPilot(id: string) {
  const engine = getEngine();
  const char = engine?.getCharacter(id);
  if (!char) {
    console.warn(`Character with id ${id} not found.`);
    return;
  }
  char.startAutoPilot(); // Cette ligne affiche la phrase du personnage

}

/**
* Désactive l'auto-pilote pour un personnage.
*/
export function desactivateAutoPilot(id: string) {
  const engine = getEngine();
  const char = engine?.getCharacter(id);
  if (!char) {
    console.warn(`Character with id ${id} not found.`);
    return;
  }

  char.stopAutoPilot(); // Cette ligne affiche la phrase du personnage

}

