// textBlocks.ts

import { Blocks } from "blockly/core";
import { javascriptGenerator } from "blockly/javascript";
import * as Blockly from "blockly";

import { TextObjectConfig } from "../../types/BlocklyTypes";

/**
 * Crée les blocs pour manipuler les objets TextObject.
 * @param texts - Liste des TextObjects définis quelque part (ou vides si aucun)
 */
export function defineTextBlocks(texts: TextObjectConfig[]) {
  /**
   * On va générer un dropdown "TEXT_ID" contenant les IDs/labels
   * des TextObjects existants.
   */
  const textDropdown: [string, string][] = (texts ?? []).length
    ? texts.map((t) => [t.id, t.id])
    : [["No Text Objects Available", "NO_TEXT"]];

  // Liste de polices courantes avec Bonzai, Lemon Milk et Akira
  const fontDropdown: [string, string][] = [
    ["Arial", "Arial"],
    ["Arial Black", "Arial Black"],
    ["Verdana", "Verdana"],
    ["Tahoma", "Tahoma"],
    ["Trebuchet MS", "Trebuchet MS"],
    ["Impact", "Impact"],
    ["Times New Roman", "Times New Roman"],
    ["Georgia", "Georgia"],
    ["Courier New", "Courier New"],
    ["Lucida Console", "Lucida Console"],
    ["Lucida Sans Unicode", "Lucida Sans Unicode"],
    ["Garamond", "Garamond"],
    ["Palatino", "Palatino"],
    ["Bookman", "Bookman"],
    ["Comic Sans MS", "Comic Sans MS"],
    ["Consolas", "Consolas"],
    ["Palatino Linotype", "Palatino Linotype"],
    ["Century Gothic", "Century Gothic"],
    ["Lucida Grande", "Lucida Grande"],
    ["Roboto", "Roboto"],
    ["Open Sans", "Open Sans"],
    ["Noto Sans", "Noto Sans"],
    ["Montserrat", "Montserrat"],
    ["Poppins", "Poppins"],
    ["Raleway", "Raleway"],
  ];

  // ================ CREATE TEXT ================
  Blocks["create_text"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("create text")
        .appendField(new Blockly.FieldTextInput("txt1"), "TEXT_ID");
      // On ajoute un champ de type String pour saisir le contenu
      this.appendValueInput("TEXT_CONTENT")
        .setCheck("String")
        .appendField("content");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Crée un objet texte avec un contenu donné.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["create_text"] = function (block: any) {
    // Récupération de l'ID (tel que saisi dans le champ texte)
    const textId = block.getFieldValue("TEXT_ID") || "txt1";
    // Récupération du contenu (entrée de type String)
    const content =
      javascriptGenerator.valueToCode(block, "TEXT_CONTENT", 0) || '""';

    // On peut décider du blockId pour mettre un highlight si nécessaire
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      createTextObject(
        '${textId}',
        ${content},
        200,        // X par défaut
        200,        // Y par défaut
        16,         // fontSize par défaut
        "#000000",  // color
        "#ffffff"   // backgroundColor
      );
    \n`;
  };

  // ================ CREATE TEXT (FULL PARAMS) ================
  Blocks["create_text_full"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("create text")
        .appendField(new Blockly.FieldTextInput("txt1"), "TEXT_ID");

      // Le contenu du texte
      this.appendValueInput("TEXT_CONTENT")
        .setCheck("String")
        .appendField("content");

      // Coordonnée X
      this.appendValueInput("X_POS").setCheck("Number").appendField("x");

      // Coordonnée Y
      this.appendValueInput("Y_POS").setCheck("Number").appendField("y");

      // Taille de police
      this.appendValueInput("FONT_SIZE")
        .setCheck("Number")
        .appendField("font size");

      // Couleur du texte
      this.appendValueInput("COLOR")
        .setCheck("String")
        .appendField("text color");

      // Couleur de fond
      this.appendValueInput("BG_COLOR")
        .setCheck("String")
        .appendField("background color");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Crée un objet texte en spécifiant tous les paramètres.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["create_text_full"] = function (block: any) {
    const blockId = block.id;

    // Récupération des champs et inputs
    const textId = block.getFieldValue("TEXT_ID") || "txt1";
    const content =
      javascriptGenerator.valueToCode(block, "TEXT_CONTENT", 0) || '""';
    const xPos = javascriptGenerator.valueToCode(block, "X_POS", 0) || "200";
    const yPos = javascriptGenerator.valueToCode(block, "Y_POS", 0) || "200";
    const fontSize =
      javascriptGenerator.valueToCode(block, "FONT_SIZE", 0) || "16";
    const color =
      javascriptGenerator.valueToCode(block, "COLOR", 0) || "'#000000'";
    const backgroundColor =
      javascriptGenerator.valueToCode(block, "BG_COLOR", 0) || "'#ffffff'";

    // Génération du code JavaScript : on appelle la fonction createTextObject
    return `
    highlightBlock('${blockId}');
    await createTextObject(
      '${textId}',
      ${content},
      ${xPos},
      ${yPos},
      ${fontSize},
      ${color},
      ${backgroundColor}
    );
  \n`;
  };

  // ================ SET TEXT ================
  Blocks["set_text_content"] = {
    init: function () {
      this.appendValueInput("NEW_TEXT")
        .setCheck("String")
        .appendField("set text of")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID")
        .appendField("to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Remplace entièrement le contenu du texte.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["set_text_content"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const newText =
      javascriptGenerator.valueToCode(block, "NEW_TEXT", 0) || '""';
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      setTextContent('${textId}', ${newText});
    \n`;
  };

  // ================ ADD TEXT ================
  Blocks["add_text_content"] = {
    init: function () {
      this.appendValueInput("ADDITIONAL_TEXT")
        .setCheck("String")
        .appendField("add text to")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Ajoute (concatène) du texte à la suite du texte existant."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["add_text_content"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const additionalText =
      javascriptGenerator.valueToCode(block, "ADDITIONAL_TEXT", 0) || '""';
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      addTextContent('${textId}', ${additionalText});
    \n`;
  };

  // ================ REPLACE TEXT ================
  Blocks["replace_text_content"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("replace in")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID");
      this.appendValueInput("OLD_SUBSTRING")
        .setCheck("String")
        .appendField("old substring");
      this.appendValueInput("NEW_SUBSTRING")
        .setCheck("String")
        .appendField("new substring");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Remplace une sous-chaîne par une autre dans le texte.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["replace_text_content"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const oldSub =
      javascriptGenerator.valueToCode(block, "OLD_SUBSTRING", 0) || '""';
    const newSub =
      javascriptGenerator.valueToCode(block, "NEW_SUBSTRING", 0) || '""';
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      await replaceTextContent('${textId}', ${oldSub}, ${newSub});
    \n`;
  };

  // ================ MOVE TEXT ================
  Blocks["move_text_object"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("move text")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID")
        .appendField("to x:");
      this.appendValueInput("X_POS").setCheck("Number");
      this.appendDummyInput().appendField("y:");
      this.appendValueInput("Y_POS").setCheck("Number");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Déplace l'objet texte à la position (x,y) spécifiée.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["move_text_object"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const xPos = javascriptGenerator.valueToCode(block, "X_POS", 0) || "0";
    const yPos = javascriptGenerator.valueToCode(block, "Y_POS", 0) || "0";
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      await moveTextObject('${textId}', ${xPos}, ${yPos});
    \n`;
  };

  // ================ SET FONT SIZE ================
  Blocks["set_text_font_size"] = {
    init: function () {
      this.appendValueInput("FONT_SIZE")
        .setCheck("Number")
        .appendField("set font size of")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID")
        .appendField("to");
      this.appendDummyInput().appendField("px");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Change la taille de la police du texte.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["set_text_font_size"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const size = javascriptGenerator.valueToCode(block, "FONT_SIZE", 0) || "16";
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      await setFontSize('${textId}', ${size});
    \n`;
  };

  // ================ SET TEXT COLOR ================
  Blocks["set_text_color"] = {
    init: function () {
      this.appendValueInput("COLOR")
        .setCheck("String")
        .appendField("set color of")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID")
        .appendField("to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Change la couleur du texte, ex: '#ff0000'.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["set_text_color"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const color =
      javascriptGenerator.valueToCode(block, "COLOR", 0) || "'#ffffff'";
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      await setTextColor('${textId}', ${color});
    \n`;
  };

  // ================ SET TEXT BACKGROUND COLOR ================
  Blocks["set_text_bg_color"] = {
    init: function () {
      this.appendValueInput("BG_COLOR")
        .setCheck("String")
        .appendField("set background of")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID")
        .appendField("to");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Change la couleur de fond du texte, ex: '#00000088'.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["set_text_bg_color"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const bgColor =
      javascriptGenerator.valueToCode(block, "BG_COLOR", 0) || "'#00000088'";
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      await setTextBackgroundColor('${textId}', ${bgColor});
    \n`;
  };

  // ================ DESTROY TEXT ================
  Blocks["destroy_text_object"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("destroy text")
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip("Détruit l'objet texte.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["destroy_text_object"] = function (block: any) {
    const textId = block.getFieldValue("TEXT_ID") || "";
    const blockId = block.id;

    return `
      highlightBlock('${blockId}');
      await destroyTextObject('${textId}');
    \n`;
  };
  // ================ SET TEXT FONT ================
  Blocks["set_text_font"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("set font of")
        // On réutilise le dropdown existant pour l’ID du texte
        .appendField(new Blockly.FieldDropdown(textDropdown), "TEXT_ID")
        .appendField("to")
        // Ici, le dropdown pour la police
        .appendField(new Blockly.FieldDropdown(fontDropdown), "FONT_NAME");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(230);
      this.setTooltip(
        "Change la police (font-family) du texte via un dropdown."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["set_text_font"] = function (block: any) {
    const blockId = block.id;
    // Récupérer l'ID du TextObject
    const textId = block.getFieldValue("TEXT_ID") || "";
    // Récupérer la police choisie
    const chosenFont = block.getFieldValue("FONT_NAME") || "Arial";

    return `
    highlightBlock('${blockId}');
    await setTextFont('${textId}', '${chosenFont}');
  \n`;
  };
}
