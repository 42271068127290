// src/components/IGame.tsx

import React, { useEffect, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Obstacle from './Obstacle';
import Target from './Target';
import { useGameContext } from './GameContext';
import { TObstacle } from '../../type/obstacle';
import { TScene } from '../../type/scene';

interface IGameProps {
    obstacles?: TObstacle[];
    showGrid?: boolean;
    editionMode?: boolean;
    drawResult?: boolean;
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;

}

const IGame: React.FC<IGameProps> = ({
    obstacles = [],
    showGrid = true,
    editionMode = false,
    drawResult = false,
    animationPromiseResolverRef
}) => {
    const { state } = useGameContext();
    const { character, scene, remainingTargets, movementHistory } = state;

    const controls = useAnimation();

    useEffect(() => {
        // Calcul de la durée de transition en fonction de character.speed
        const speedDurationMap = {
            1: 1, // Lent
            2: 0.5, // Moyen
            3: 0.2, // Rapide
        };

        const durationTime = speedDurationMap[character.speed as 1 | 2 | 3] || 0.5;

        const durationStruct = {
            ...character.transition,
            duration: durationTime
        }
        controls
            .start({
                scaleX: character.scale,
                x: character.x,
                y: character.y,
                transition: durationStruct,
            })
            .then(() => {
                if (animationPromiseResolverRef.current) {
                    animationPromiseResolverRef.current();
                    animationPromiseResolverRef.current = null;
                }
            });

        // eslint-disable-next-line
    }, [character.x, character.y, character.scale]);

    // Précharger les images lors du montage du composant
    useEffect(() => {
        const imagesToPreload = [
            character.images.idle,
            character.images.moving,
            character.images.paused,
            character.images.colliding
        ];

        imagesToPreload.forEach((src) => {
            const img = new Image();
            img.src = src;
        });
    }, [character.images]);

    const [styleScene, setStyleScene] = useState<React.CSSProperties>({});

    useEffect(() => {
        setStyleScene(getBackgroundStyle(scene, showGrid));
    }, [scene, showGrid]);

    const getBackgroundStyle = (scene: TScene, showGrid: boolean): React.CSSProperties => {
        const backgroundImages: string[] = [];
        const backgroundSizes: string[] = [];
        const backgroundRepeats: string[] = [];

        // Ajouter l'image de fond si elle est définie
        if (scene.background?.img) {
            backgroundImages.push(`url(${scene.background.img})`);
            backgroundSizes.push(scene.background.size || 'cover');
            backgroundRepeats.push(scene.background.repeat ? 'repeat' : 'no-repeat');
        }

        // Ajouter la grille si showGrid est true
        if (showGrid) {
            // Lignes horizontales de la grille
            backgroundImages.push('linear-gradient(to right, lightgray 1px, transparent 1px)');
            backgroundSizes.push(`${scene.gridColumnWidth}px 100%`); // Largeur de la colonne, hauteur 100%
            backgroundRepeats.push('repeat-x'); // Répéter horizontalement

            // Lignes verticales de la grille
            backgroundImages.push('linear-gradient(to bottom, lightgray 1px, transparent 1px)');
            backgroundSizes.push(`100% ${scene.gridRowWidth}px`); // Largeur 100%, hauteur de la ligne
            backgroundRepeats.push('repeat-y'); // Répéter verticalement
        }

        // Vérifier s'il y a des images d'arrière-plan à appliquer
        if (backgroundImages.length === 0) {
            return {
                backgroundImage: 'none',
            };
        } else {
            return {
                backgroundImage: backgroundImages.join(', '),
                backgroundSize: backgroundSizes.join(', '),
                backgroundRepeat: backgroundRepeats.join(', '),
            };
        }
    };

    const getCharacterImage = () => {
        switch (character.state) {
            case 'idle':
                return character.images.idle;
            case 'moving':
                return character.images.moving;
            case 'paused':
                return character.images.paused;
            case 'colliding':
                return character.images.colliding;
            case 'wining':
                return character.images.win;
            default:
                return character.images.idle;
        }
    };

    return (
        <div
            id="scene"
            style={{
                position: 'relative',
                width: `${scene.width}px`,
                height: `${scene.height}px`,
                border: '1px solid black',
                ...styleScene,
            }}
        >
            {/* Render obstacles */}
            {obstacles.map((obstacle, index) => (
                <Obstacle key={index} obstacle={obstacle} editionMode={editionMode} />
            ))}

            {/* Render targets */}
            {remainingTargets.map((target, index) => (
                <Target key={index} target={target} editionMode={editionMode} />
            ))}

            {/* Render movement history if drawResult is true */}
            {drawResult && movementHistory.length > 1 && (
                <svg
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none',
                    }}
                >
                    {movementHistory.map((movement, index) => {
                        if (index === 0) return null;
                        const previousMovement = movementHistory[index - 1];
                        return (
                            <line
                                key={index}
                                x1={previousMovement.x + character.width / 2}
                                y1={previousMovement.y + character.height / 2}
                                x2={movement.x + character.width / 2}
                                y2={movement.y + character.height / 2}
                                stroke="black"
                                strokeWidth="5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        );
                    })}
                </svg>
            )}

            {/* Render character */}
            <motion.div
                id="character"
                style={{
                    width: `${character.width}px`,
                    height: `${character.height}px`,
                    backgroundImage: `url(${getCharacterImage()})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    position: 'absolute',
                    backgroundPosition: 'center',
                    border: editionMode ? '2px dashed blue' : 'none',
                }}
                animate={controls}
            />
        </div>
    );
};

export default IGame;
