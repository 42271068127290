// src/AnimationView/functions/engineManager.ts
import { GameEngine } from '../engine/GameEngine';

let engine: GameEngine | null = null;

/**
 * Initialise la référence au GameEngine.
 * @param newEngine - Instance du GameEngine.
 */
export const setEngine = (newEngine: GameEngine) => {
    engine = newEngine;
};

/**
 * Nettoie la référence au GameEngine.
 */
export const clearEngine = () => {
    engine = null;
};

/**
 * Obtient la référence actuelle du GameEngine.
 * @returns Instance du GameEngine ou null.
 */
export const getEngine = (): GameEngine | null => {
    return engine;
};
