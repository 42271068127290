import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { Input, Button, Row, Space, Table, message, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PlusCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { useUserApi } from '../../../api/user/userApp';
import { TUserApp } from '../../../models/user/User';
import FloatButtons from '../../../components/button/FloatButtons';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';
import dayjs from 'dayjs';
import { useReferential } from '../../../context/ReferentialContext';
import { CODIFICATION_ROLES, CODIFICATION_TYPE, CODIFICATION_TYPE_USERS } from '../../../constant/codification';
import { TCodification } from '../../../models/referential/referential';
import { useAuth } from '../../../context/AuthContext';
import type { GetProps } from 'antd';
import { downloadCSV } from '../../../utils/file/csv';

type SearchProps = GetProps<typeof Input.Search>;

const { Search } = Input;

const AffectedUsersList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isRoleExist } = useAuth();

    const { getAllUsersAffected, deleteUserApp } = useUserApi();
    const { getCodificationByCodes, getCodificationById, isReferentialLoaded } = useReferential();

    const [userApps, setUserApps] = useState<TUserApp[]>([]);
    const [userAppsFiltered, setUserAppsFiltered] = useState<TUserApp[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

    const [typeUsers, setTypeUsers] = useState<TCodification[]>([]);
    const canDeleteUser = isRoleExist(CODIFICATION_ROLES.DELETE_USER);
    useEffect(() => {
        setIsLoading(true);
        getAllUsersAffected()
            .then((resp) => {
                setUserApps(resp.data);
                setUserAppsFiltered(resp.data)
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        //Initialise typeUsers depends of connected user rights
        let usersTypes: TCodification[] = [];

        if (isRoleExist(CODIFICATION_ROLES.ADD_ADMIN)) {
            const type = getCodificationByCodes(CODIFICATION_TYPE.USER_TYPE, CODIFICATION_TYPE_USERS.ADMIN)
            if (type) {
                usersTypes.push(type)
            }
        }
        if (isRoleExist(CODIFICATION_ROLES.ADD_BUSINESS)) {
            const type = getCodificationByCodes(CODIFICATION_TYPE.USER_TYPE, CODIFICATION_TYPE_USERS.BUSINESS)
            if (type) {
                usersTypes.push(type)
            }
        }
        if (isRoleExist(CODIFICATION_ROLES.ADD_PARENT)) {
            const type = getCodificationByCodes(CODIFICATION_TYPE.USER_TYPE, CODIFICATION_TYPE_USERS.ADULT)
            if (type) {
                usersTypes.push(type)
            }
        }
        if (isRoleExist(CODIFICATION_ROLES.ADD_TEACHER)) {
            const type = getCodificationByCodes(CODIFICATION_TYPE.USER_TYPE, CODIFICATION_TYPE_USERS.TEACHER)
            if (type) {
                usersTypes.push(type)
            }
        }
        if (isRoleExist(CODIFICATION_ROLES.ADD_STUDENT)) {
            const type = getCodificationByCodes(CODIFICATION_TYPE.USER_TYPE, CODIFICATION_TYPE_USERS.MINOR)
            if (type) {
                usersTypes.push(type)
            }
        }
        if (isRoleExist(CODIFICATION_ROLES.ADD_OP_COURSE)) {
            const type = getCodificationByCodes(CODIFICATION_TYPE.USER_TYPE, CODIFICATION_TYPE_USERS.COURSE_OP)
            if (type) {
                usersTypes.push(type)
            }
        }
        setTypeUsers(usersTypes);
        // eslint-disable-next-line
    }, [isReferentialLoaded]);


    const edit = (userAppId: number) => {
        navigate('/conf/users/form', { state: { userAppId } })
    };
    const add = (typeUser: TCodification) => {
        navigate('/conf/users/form', { state: { typeUser } })
    };


    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteUserApp(deleteId)
            .then(() => {
                setUserApps(userApps.filter((userapp) => userapp.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };

    const columns: ColumnsType<TUserApp> = [
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
            render: (text: string, record: TUserApp) => record.login || 'N/A',
        },
        {
            title: t("text.name"),
            key: 'name',
            render: (_: any, record: TUserApp) => {
                if (record.personal_info) {
                    const firstname = record.personal_info.firstname || '';
                    const lastname = record.personal_info.lastname || '';
                    const birthdate = record.personal_info.birthdate
                        ? ` (${dayjs(record.personal_info.birthdate).format('YYYY-MM-DD')})`
                        : '';
                    return `${firstname} ${lastname}${birthdate}`;
                } else if (record.business_info) {
                    const name = record.business_info.name || '';
                    const taxRegistry = record.business_info.taxeregistry || '';
                    return `${name} / ${taxRegistry}`;
                } else {
                    return 'N/A';
                }
            },
        },
        {
            title: t("text.profil"),
            key: 'profile',
            render: (_: any, record: TUserApp) => {
                const profil = getCodificationById(record.usertype_id);
                if (profil) {
                    return profil.label;
                } else {
                    return 'N/A';
                }
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 220,
            render: (_: any, record: TUserApp) => (
                <Space size="middle">
                    <>
                        <Button icon={<EditOutlined />} onClick={() => edit(record.id)} />
                        {canDeleteUser && <Button
                            icon={<DeleteOutlined />}
                            danger
                            onClick={() => confirmDelete(record.id)}
                            loading={deleteId === record.id && deleteLoading}
                        />}
                    </>
                </Space>
            ),
        },
    ];
    const onSearch: SearchProps['onSearch'] = (value, _e, info) => setUserAppsFiltered(userApps.filter(user => value.trim() === "" ||
        user.login.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        (user.business_info && user.business_info.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())) ||
        (user.personal_info && user.personal_info.firstname.toLocaleLowerCase().includes(value.toLocaleLowerCase())) ||
        (user.personal_info && user.personal_info.lastname.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
    ));

    function convertUserAppsToCSV(userApps: TUserApp[]): string {
        if (!userApps || !userApps.length) {
            return '';
        }

        const separator = ';';
        const headers = ['Login', 'Name', 'Profile'];

        const csvRows = userApps.map((user) => {
            const login = user.login || 'N/A';

            let name = 'N/A';
            if (user.personal_info) {
                const firstname = user.personal_info.firstname || '';
                const lastname = user.personal_info.lastname || '';
                const birthdate = user.personal_info.birthdate
                    ? ` (${dayjs(user.personal_info.birthdate).format('YYYY-MM-DD')})`
                    : '';
                name = `${firstname} ${lastname}${birthdate}`;
            } else if (user.business_info) {
                const businessName = user.business_info.name || '';
                const taxRegistry = user.business_info.taxeregistry || '';
                name = `${businessName} / ${taxRegistry}`;
            }

            const profileCodification = getCodificationById(user.usertype_id);
            const profile = profileCodification ? profileCodification.label : 'N/A';

            // Échapper les valeurs qui contiennent des séparateurs, des guillemets ou des sauts de ligne
            const escapedValues = [login, name, profile].map((value) => {
                let stringValue = value.toString().replace(/"/g, '""');
                if (stringValue.search(/("|,|\n)/g) >= 0) {
                    stringValue = `"${stringValue}"`;
                }
                return stringValue;
            });

            return escapedValues.join(separator);
        });

        // Combiner les en-têtes et les lignes de données
        const csvContent = [headers.join(separator), ...csvRows].join('\n');

        return csvContent;
    }

    const handleExportCsv = () => {
        const data = convertUserAppsToCSV(userAppsFiltered);
        downloadCSV(data, "Users.csv")
    };

    return (
        <AppLayout title={t('text.users')} loading={loading}>
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Space>
                    <Search
                        placeholder={t('text.search')}
                        onSearch={onSearch}
                        style={{ width: 200 }}
                    />
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: 400 }}
                        placeholder={t('text.profil')}
                        onChange={(value: number[]) => {
                            setUserAppsFiltered(userApps.filter(user => value.length === 0 || value.includes(user.usertype_id)));
                        }}
                    >
                        {typeUsers.map((type: TCodification) => (
                            <Select.Option key={type.id} value={type.id}>
                                {type.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Button
                        icon={<DownloadOutlined />}
                        onClick={handleExportCsv}
                    >
                        {t('text.download')}
                    </Button>
                </Space>
                <Space>
                    {typeUsers.map((type) => (
                        <Button
                            key={type.id}
                            type="primary"
                            onClick={() => {
                                add(type);
                            }}
                            icon={<PlusCircleOutlined />}
                        >
                            {t('text.' + type.code)}
                        </Button>
                    ))}
                </Space>
            </Row>
            <Table<TUserApp>
                columns={columns}
                dataSource={userAppsFiltered}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />
            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this user? This action is irreversible."
            />
            <FloatButtons />
        </AppLayout>
    );
};

export default AffectedUsersList;
