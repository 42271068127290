// src/components/BlocklyWorkspace/blocklyConfig.ts

import { Blocks } from "blockly/core";
// Si vous avez besoin des blocs de base (if, loops...) : import 'blockly/blocks';
import { javascriptGenerator } from "blockly/javascript";
import * as Blockly from "blockly";

import { FieldAngle, registerFieldAngle } from "@blockly/field-angle";

// Enregistrer les champs spéciaux
registerFieldAngle();

export function defineMovementBlocks() {
  // ==========================
  // Mouvements
  // ==========================
  Blocks["walk"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk")
        .appendField(new Blockly.FieldNumber(10, 1, 100), "distance")
        .appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage d'une certaine distance.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk"] = function (block: any) {
    const distance = block.getFieldValue("distance") || 0;
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      await walkCharacter('', ${distance});
    \n`;
  };

  // ==========================
  Blocks["walk_right"] = {
    init: function () {
      this.appendDummyInput().appendField("right");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage vers la droite.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_right"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 0);
      await walkCharacter('', 1);
    \n`;
  };

  // Walk Left
  Blocks["walk_left"] = {
    init: function () {
      this.appendDummyInput().appendField("left");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage vers la gauche.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_left"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 180);
      await walkCharacter('', 1);
    \n`;
  };

  // Walk Up
  Blocks["walk_up"] = {
    init: function () {
      this.appendDummyInput().appendField("up");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage vers le haut.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_up"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 90);
      await walkCharacter('', 1);
    \n`;
  };

  // Walk Down
  Blocks["walk_down"] = {
    init: function () {
      this.appendDummyInput().appendField("down");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage vers le bas.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_down"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 270);
      await walkCharacter('', 1);
    \n`;
  };

  // ==========================
  // Walk Right Up
  Blocks["walk_right_up"] = {
    init: function () {
      this.appendDummyInput().appendField("walk right up");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Avance le personnage en diagonale vers le haut à droite."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_right_up"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 45);
      await walkCharacter('', 1);
    \n`;
  };

  // ==========================
  // Walk Right Down
  Blocks["walk_right_down"] = {
    init: function () {
      this.appendDummyInput().appendField("walk right down");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Avance le personnage en diagonale vers le bas à droite."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_right_down"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 315);
      await walkCharacter('', 1);
    \n`;
  };

  // ==========================
  // Walk Left Up
  Blocks["walk_left_up"] = {
    init: function () {
      this.appendDummyInput().appendField("walk left up");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Avance le personnage en diagonale vers le haut à gauche."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_left_up"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 135);
      await walkCharacter('', 1);
    \n`;
  };

  // ==========================
  // Walk Left Down
  Blocks["walk_left_down"] = {
    init: function () {
      this.appendDummyInput().appendField("walk left down");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Avance le personnage en diagonale vers le bas à gauche."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_left_down"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 225);
      await walkCharacter('', 1);
    \n`;
  };

  // Ajout des blocs walk_forward et walk_backward

  // Walk Forward
  Blocks["walk_forward"] = {
    init: function () {
      this.appendDummyInput().appendField("walk forward");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage d'une unité vers l'avant.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_forward"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      await walkCharacter('', 1);
    \n`;
  };

  // Walk Backward
  Blocks["walk_backward"] = {
    init: function () {
      this.appendDummyInput().appendField("walk backward");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Recule le personnage d'une unité.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_backward"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', 'BACKWARD', 0);
      await walkCharacter('', 1);
    \n`;
  };

  Blocks["walk_choice"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk")
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Permet de choisir si le personnage marche en avant ou en arrière."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_choice"] = function (block: any) {
    const direction = block.getFieldValue("direction");
    const blockId = block.id;

    if (direction === "FORWARD") {
      return `
          highlightBlock('${blockId}');
          await walkCharacter('', 1);
        \n`;
    } else if (direction === "BACKWARD") {
      return `
        highlightBlock('${blockId}');
        turnCharacter('', 'BACKWARD', 0);
        await walkCharacter('', 1);
        \n`;
    }
    return "";
  };
  Blocks["walk_choice_by_unit"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk")
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        )
        .appendField("by")
        .appendField(new Blockly.FieldNumber(0, 1, 1000), "distance")
        .appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Permet de choisir une direction et la distance à parcourir."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_choice_by_unit"] = function (block: any) {
    const direction = block.getFieldValue("direction");
    const distance = block.getFieldValue("distance");
    const blockId = block.id;

    if (direction === "FORWARD") {
      return `
          highlightBlock('${blockId}');
          await walkCharacter('', ${distance});
        \n`;
    } else if (direction === "BACKWARD") {
      return `
            highlightBlock('${blockId}');
            turnCharacter('', 'BACKWARD', 0);
            await walkCharacter('', ${distance});
        \n`;
    }
    return "";
  };

  // ==========================
  Blocks["jump"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump")
        .appendField(new Blockly.FieldNumber(1, 1, 10), "height")
        .appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait sauter le personnage d’une certaine hauteur.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump"] = function (block: any) {
    const height = block.getFieldValue("height") || 1;
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      await jumpCharacter('', ${height});
    \n`;
  };

  // Ajouter les blocs Jump
  Blocks["jump_right"] = {
    init: function () {
      this.appendDummyInput().appendField("jump right");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait sauter le personnage vers la droite.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_right"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 0);
      await jumpCharacter('', 1);
    \n`;
  };

  Blocks["jump_left"] = {
    init: function () {
      this.appendDummyInput().appendField("jump left");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait sauter le personnage vers la gauche.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_left"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 180);
      await jumpCharacter('', 1);
    \n`;
  };

  Blocks["jump_up"] = {
    init: function () {
      this.appendDummyInput().appendField("jump up");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait sauter le personnage vers le haut.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_up"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 90);
      await jumpCharacter('', 1);
    \n`;
  };

  Blocks["jump_down"] = {
    init: function () {
      this.appendDummyInput().appendField("jump down");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait sauter le personnage vers le bas.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_down"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 270);
      await jumpCharacter('', 1);
    \n`;
  };
  // Bloc jump_right_up
  Blocks["jump_right_up"] = {
    init: function () {
      this.appendDummyInput().appendField("jump right up");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Fait sauter le personnage en diagonale vers le haut à droite."
      );
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_right_up"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 45);
      await jumpCharacter('', 1);
    \n`;
  };

  // Bloc jump_right_down
  Blocks["jump_right_down"] = {
    init: function () {
      this.appendDummyInput().appendField("jump right down");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Fait sauter le personnage en diagonale vers le bas à droite."
      );
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_right_down"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 315);
      await jumpCharacter('', 1);
    \n`;
  };

  // Bloc jump_left_up
  Blocks["jump_left_up"] = {
    init: function () {
      this.appendDummyInput().appendField("jump left up");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Fait sauter le personnage en diagonale vers le haut à gauche."
      );
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_left_up"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 135);
      await jumpCharacter('', 1);
    \n`;
  };

  // Bloc jump_left_down
  Blocks["jump_left_down"] = {
    init: function () {
      this.appendDummyInput().appendField("jump left down");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Fait sauter le personnage en diagonale vers le bas à gauche."
      );
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["jump_left_down"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      turnCharacter('', '', 225);
      await jumpCharacter('', 1);
    \n`;
  };

  // jump Forward
  Blocks["jump_forward"] = {
    init: function () {
      this.appendDummyInput().appendField("jump forward");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage d'une unité vers l'avant.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_forward"] = function (block: any) {
    const blockId = block.id;
    return `
      highlightBlock('${blockId}');
      await jumpCharacter('', 1);
    \n`;
  };

  // jump Backward
  Blocks["jump_backward"] = {
    init: function () {
      this.appendDummyInput().appendField("jump backward");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Recule le personnage d'une unité.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_backward"] = function (block: any) {
    const blockId = block.id;
    return `
            highlightBlock('${blockId}');
            turnCharacter('', 'BACKWARD', 0);
            await jumpCharacter('', 1);
    \n`;
  };

  Blocks["jump_choice"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump")
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Permet de choisir si le personnage suate en avant ou en arrière."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_choice"] = function (block: any) {
    const direction = block.getFieldValue("direction");
    const blockId = block.id;

    if (direction === "FORWARD") {
      return `
          highlightBlock('${blockId}');
          await jumpCharacter('', 1);
        \n`;
    } else if (direction === "BACKWARD") {
      return `
        highlightBlock('${blockId}');
        turnCharacter('', 'BACKWARD', 0);
        await jumpCharacter('', 1);
        \n`;
    }
    return "";
  };
  Blocks["jump_choice_by_unit"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump")
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        )
        .appendField("by")
        .appendField(new Blockly.FieldNumber(0, 1, 1000), "distance")
        .appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Permet de choisir une direction et la distance à sauter."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_choice_by_unit"] = function (block: any) {
    const direction = block.getFieldValue("direction");
    const distance = block.getFieldValue("distance");
    const blockId = block.id;

    if (direction === "FORWARD") {
      return `
          highlightBlock('${blockId}');
          await jumpCharacter('', ${distance});
        \n`;
    } else if (direction === "BACKWARD") {
      return `
                highlightBlock('${blockId}');
                turnCharacter('', 'BACKWARD', 0);
                await jumpCharacter('', ${distance});
        \n`;
    }
    return "";
  };

  // ==========================
  Blocks["turn"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("turn")
        .appendField(new FieldAngle(90), "angle")
        .appendField("°");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait tourner le personnage d’un certain angle.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["turn"] = function (block: any) {
    const angle = block.getFieldValue("angle") || 0;
    const blockId = block.id;
    return `
        highlightBlock('${blockId}');
        turnCharacter('', '', ${angle});
        \n`;
  };

  // Ajouter les blocs turn_right or turn_left
  Blocks["turn_right_or_left"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("turn")
        .appendField(
          new Blockly.FieldDropdown([
            ["right", "RIGHT"],
            ["left", "LEFT"],
          ]),
          "direction"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Tourne le personnage de 90 degrés dans la direction choisie."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["turn_right_or_left"] = function (block) {
    const direction = block.getFieldValue("direction");
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('', '${direction}', 90);
        \n`;
  };

  Blocks["choose_turn_angle"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("turn")
        .appendField(
          new Blockly.FieldDropdown([
            ["right", "RIGHT"],
            ["left", "LEFT"],
          ]),
          "direction"
        )
        .appendField("by")
        .appendField(new FieldAngle(90), "angle")
        .appendField("°");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip(
        "Tourne le personnage à gauche ou à droite par un certain angle."
      );
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["choose_turn_angle"] = function (block: any) {
    const direction = block.getFieldValue("direction");
    const angle = parseInt(block.getFieldValue("angle"), 10);
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('', '${direction}', ${angle});
    \n`;
  };

  Blockly.Blocks["variable_walk"] = {
    init: function () {
      this.appendValueInput("distance").setCheck("Number").appendField("walk");
      this.appendDummyInput().appendField("unit(s)");

      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Avance le personnage d'une certaine distance.");
      this.setHelpUrl("");
    },
  };
  javascriptGenerator.forBlock["variable_walk"] = function (block: any) {
    const distance =
      javascriptGenerator.valueToCode(block, "distance", 0) || "0";

    const blockId = block.id;
    return `
            highlightBlock('${blockId}');
            await walkCharacter('', ${distance});
        \n`;
  };

  Blockly.Blocks["variable_turn"] = {
    init: function () {
      this.appendValueInput("angle").setCheck("Number").appendField("turn");
      this.appendDummyInput().appendField("degree(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait tourner le personnage d’un certain angle.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["variable_turn"] = function (block) {
    const angle = javascriptGenerator.valueToCode(block, "angle", 0) || "0";

    const blockId = block.id;
    return `
            highlightBlock('${blockId}');
            turnCharacter('', '', ${angle});
        \n`;
  };

  Blockly.Blocks["variable_jump"] = {
    init: function () {
      this.appendValueInput("height").setCheck("Number").appendField("jump");
      this.appendDummyInput().appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(120);
      this.setTooltip("Fait sauter le personnage d’une certaine hauteur.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["variable_jump"] = function (block) {
    const height = javascriptGenerator.valueToCode(block, "height", 0) || "0";

    const blockId = block.id;
    return `
            highlightBlock('${blockId}');
            await jumpCharacter('', ${height});
        \n`;
  };
}
