import React, { useEffect, useState } from 'react';
import { useObjectsApi } from '../../../api/objects/objects';
import { TCharacter } from '../../../models/objects/objects';
import { Button, Input, message, Space, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import Table, { ColumnsType } from 'antd/es/table';
import { EditOutlined, SaveOutlined, StopOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useReferential } from '../../../context/ReferentialContext';
import { TCodification } from '../../../models/referential/referential';
import { CODIFICATION_TYPE } from '../../../constant/codification';
import { getImagePath } from '../../../utils/format/valueFormat';
import { CODIFICATION_ROLES } from '../../../constant/codification';
import { useAuth } from '../../../context/AuthContext';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';

const CharacterList: React.FC = () => {
    const { t } = useTranslation();
    const { isRoleExist } = useAuth();
    const { getCharacters, createCharacter, deleteCharacter, updateCharacter, uploadCharacterImage } = useObjectsApi();
    const [characters, setCharacters] = useState<TCharacter[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const { getCodificationsByCodeType } = useReferential();
    const [loadingRow, setLoadingRow] = useState<number | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

    const states: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.CHARACTER_STATE)

    useEffect(() => {
        setIsLoading(true);
        getCharacters()
            .then((resp) => {
                setCharacters(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingObject, setEditingObject] = useState<TCharacter | null>(null);

    const edit = (target: TCharacter) => {
        setEditingKey(target.id);
        setEditingObject({ ...target });
    };

    const handleAdd = () => {
        const idRow = addingId - 1;
        const newObject: TCharacter = {
            id: idRow,
            name: '',
            transition: {
                "ease": "easeInOut",
                "type": "tween",
                "duration": 0.5
            },
            images: [],
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingObject(newObject);
        setCharacters((prev) => [newObject, ...prev ?? []]);
    };

    const cancel = () => {
        const idRow = editingObject?.id || 0
        if (idRow <= 0) {
            setCharacters((prev) => [...prev ?? []].filter(e => e.id !== idRow));
        }
        setEditingKey(null);
        setEditingObject(null);
    }

    const save = (id: number) => {
        if (editingObject) {
            setLoadingRow(id);
            (editingObject.id > 0 ? updateCharacter(editingObject.id, editingObject) : createCharacter(editingObject))
                .then((resp) => {
                    const result: TCharacter = resp.data;
                    const updatedObjects = characters.map((e) =>
                        e.id === id ? result : e
                    );
                    setCharacters(updatedObjects);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                    console.error(err);
                })
                .finally(() => {
                    setIsLoading(false);
                    setLoadingRow(null);
                });
        }

        setEditingKey(null);
    };

    // Delete functionality
    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteCharacter(deleteId)
            .then(() => {
                setCharacters(characters.filter((character) => character.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };


    const uploadImage = (item: TCharacter, state: TCodification) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event: any) => {
            const file = event.target.files[0];
            if (file) {
                setLoadingRow(item.id);
                const formData = new FormData();
                formData.append('image', file);

                uploadCharacterImage(item.id, state.id, formData)
                    .then((resp) => {
                        const result: TCharacter = resp.data;
                        const updatedObjects = characters.map((e) =>
                            item.id === e.id ? result : e
                        );
                        setCharacters(updatedObjects);
                        message.success(t('message.savedSuccessfully'));
                    })
                    .catch((err) => {
                        message.error(err.response.data ?? err.message);
                        console.error(err);
                    })
                    .finally(() => {
                        setLoadingRow(null);
                    });
            }
        };

        input.click();
    };

    const columns: ColumnsType<TCharacter> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: TCharacter) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObject?.name}
                        onChange={(e) => {
                            if (editingObject) {
                                setEditingObject({ ...editingObject, name: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        // Colonnes dynamiques pour chaque état
        ...states.map((state) => ({
            title: state.label,
            dataIndex: `state_${state.id}`,
            key: `state_${state.id}`,
            render: (_: any, record: TCharacter) => {
                const image = record.images?.find((img) => img.state_id === state.id);
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={image && image.img ? getImagePath(image.img, true) : 'https://via.placeholder.com/50'}
                            alt={`${state.label}`}
                            style={{ width: "50px", height: "50px", marginRight: 8 }}
                        />
                        {editingKey !== record.id && (
                            <Button icon={<UploadOutlined />} onClick={() => uploadImage(record, state)} />
                        )}
                    </div>
                );
            },
        })),
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 180,
            render: (_: any, record: TCharacter) => (
                <Space size="middle">
                    {loadingRow === record.id ? ( // Affiche le spinner si la ligne est en chargement
                        <Spin />
                    ) : (
                        editingKey === record.id ? (
                            <>
                                <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                                <Button icon={<StopOutlined />} onClick={cancel} />
                            </>
                        ) : (
                            <>
                                <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                                {
                                    isRoleExist(CODIFICATION_ROLES.DELETE_COURSE) && (
                                        <Button
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={() => confirmDelete(record.id)}
                                            loading={deleteId === record.id && deleteLoading}
                                        />
                                    )
                                }
                            </>

                        )
                    )}
                </Space>
            ),
        },
    ];

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleAdd}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TCharacter>
                columns={columns}
                dataSource={characters ?? []}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />
            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this subject? This action is irreversible."
            />
        </>
    );
};

export default CharacterList;
