import React, { useState } from 'react';
import { Select, Input, Space, Button, Table } from 'antd';
import { TObstacle } from '../../../../models/objects/objects';
import { TLesson, TLessonObstacle } from '../../../../models/courses/lesson';
import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { getImagePath } from '../../../../utils/format/valueFormat';

const { Option } = Select;

interface ObstacleProps {
    obstacles: TObstacle[];
    editingLesson: TLesson | undefined;
    setEditingLesson: React.Dispatch<React.SetStateAction<TLesson | undefined>>;
}

const Obstacle: React.FC<ObstacleProps> = ({ obstacles, editingLesson, setEditingLesson }) => {

    const [addingId, setAddingId] = useState<number>(0);

    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingObstacle, setEditingObstacle] = useState<TLessonObstacle | null>(null);

    // Fonction pour passer en mode édition
    const edit = (obstacle: TLessonObstacle) => {
        setEditingKey(obstacle.id);
        setEditingObstacle({ ...obstacle });
    };

    // Fonction pour gérer l'ajout d'un nouvel obstacle
    const handleAddObstacle = () => {
        const idRow = addingId - 1;
        const newObstacle: TLessonObstacle = {
            id: idRow,
            obstacle_id: 0,
            lesson_id: editingLesson?.id || 0,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            rotate: 0,
            obstacle: {} as TObstacle,
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingObstacle(newObstacle);

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                obstacles: [...(prevLesson.obstacles || []), newObstacle],
            };
        });
    };

    // Sauvegarde les modifications
    const save = (id: number) => {
        if (!editingObstacle) {
            return;
        }

        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;
            const newObstacles = prevLesson.obstacles?.map((obstacle) => {
                if (obstacle.id === id) {
                    return { ...editingObstacle, id };
                }
                return obstacle;
            });

            return {
                ...prevLesson,
                obstacles: newObstacles,
            };
        });

        setEditingKey(null);
        setEditingObstacle(null);
    };

    // const cancel = () => {

    //     setEditingKey(null);
    //     setEditingObstacle(null);
    // };

    const handleDelete = (id: number) => {
        setEditingLesson((prevLesson) => {
            if (!prevLesson) return prevLesson;

            return {
                ...prevLesson,
                obstacles: prevLesson.obstacles?.filter((obstacle) => obstacle.id !== id),
            };
        });
    };

    const handleInputChange = (field: string, value: string) => {
        setEditingObstacle((prev) => {
            if (!prev) return prev;
            return { ...prev, [field]: Number(value) };
        });
    };

    const handleObstacleChange = (value: number, id: number) => {
        const selectedObstacle = obstacles.find((o) => o.id === value);
        setEditingObstacle((prev) => {
            if (!prev) return prev;
            return { ...prev, obstacleId: value, obstacle: selectedObstacle || ({} as TObstacle) };
        });
    };

    const columns = [
        {
            title: 'Obstacle',
            dataIndex: 'obstacle',
            key: 'obstacle',
            width: 200,
            render: (text: string, record: TLessonObstacle) =>
                editingKey === record.id ? (
                    <Select
                        placeholder={'Obstacle'}
                        style={{ width: '100%' }}
                        onChange={(value) => handleObstacleChange(value, record.id)}
                        value={editingObstacle?.obstacle_id}
                    >
                        {obstacles.map((obs) => (
                            <Option key={obs.id} value={obs.id}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={getImagePath(obs.img)}
                                        alt={obs.name}
                                        style={{ width: '30px', height: '30px', marginRight: '10px' }}
                                    />
                                    <span>{obs.name}</span>
                                </div>
                            </Option>
                        ))}
                    </Select>
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src={getImagePath(record.obstacle.img)}
                            alt={record.obstacle.name}
                            style={{ width: '30px', height: '30px', marginRight: '10px' }}
                        />
                        <span>{record.obstacle.name}</span>
                    </div>
                ),
        },
        {
            title: 'X',
            dataIndex: 'x',
            key: 'x',
            render: (text: number, record: TLessonObstacle) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObstacle?.x}
                        onChange={(e) => handleInputChange('x', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonObstacle, b: TLessonObstacle) => a.x - b.x,
        },
        {
            title: 'Y',
            dataIndex: 'y',
            key: 'y',
            render: (text: number, record: TLessonObstacle) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObstacle?.y}
                        onChange={(e) => handleInputChange('y', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonObstacle, b: TLessonObstacle) => a.y - b.y,
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
            render: (text: number, record: TLessonObstacle) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObstacle?.width}
                        onChange={(e) => handleInputChange('width', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonObstacle, b: TLessonObstacle) => a.width - b.width,
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
            render: (text: number, record: TLessonObstacle) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObstacle?.height}
                        onChange={(e) => handleInputChange('height', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonObstacle, b: TLessonObstacle) => a.height - b.height,
        },
        {
            title: 'Rotate',
            dataIndex: 'rotate',
            key: 'rotate',
            render: (text: number, record: TLessonObstacle) =>
                editingKey === record.id ? (
                    <Input
                        value={editingObstacle?.rotate}
                        onChange={(e) => handleInputChange('rotate', e.target.value)}
                    />
                ) : (
                    text
                ),
            sorter: (a: TLessonObstacle, b: TLessonObstacle) => a.rotate - b.rotate,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text: string, record: TLessonObstacle) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <>
                            <Button
                                icon={<SaveOutlined />}
                                onClick={() => save(record.id)}
                            />
                            {/* <Button icon={<StopOutlined />} onClick={cancel} /> */}
                        </>

                    ) : (
                        <>
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => edit(record)}
                            />
                            <Button
                                icon={<DeleteOutlined />}
                                onClick={() => handleDelete(record.id)}
                            />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <Space direction="vertical" style={{ width: '100%' }}>
            <Button type="primary" onClick={handleAddObstacle} disabled={editingKey !== null}>
                Add Obstacle
            </Button>
            <Table
                dataSource={editingLesson?.obstacles}
                columns={columns}
                rowKey="id"
                pagination={false}
            />
        </Space>
    );
};

export default Obstacle;