// src/components/IGame.tsx

import React, { useEffect, useRef, useState } from 'react';
import { animate, motion } from 'framer-motion';
import Obstacle from './Obstacle';
import { useGameContext } from './GameContext';
import { TObstacle } from '../../type/obstacle';
import { TScene } from '../../type/scene';
import { TCharacter } from '../../type/character';
import Target from './Target';

interface IGameProps {
    obstacles?: TObstacle[];
    showGrid?: boolean;
    editionMode: boolean;
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;

}

const IGame: React.FC<IGameProps> = ({
    obstacles = [],
    showGrid = true,
    editionMode,
    animationPromiseResolverRef,
}) => {
    const { state } = useGameContext();
    const { characters, scene, target } = state;

    // Tableau de références pour chaque élément à animer
    const animationRefs = useRef<(HTMLDivElement | null)[]>([]);


    useEffect(() => {
        characters.forEach((character, index) => {
            // Calcul de la durée de transition en fonction de character.speed
            const speedDurationMap = {
                1: 1, // Lent
                2: 0.5, // Moyen
                3: 0.2, // Rapide
            };

            const durationTime = speedDurationMap[character.speed as 1 | 2 | 3] || 0.5;

            const element = animationRefs.current[index];
            if (element) {
                animate(
                    element,
                    {
                        x: character.x,
                        y: character.y,
                        scaleX: character.scale,
                    },
                    {
                        duration: durationTime,
                    }
                ).then(() => {
                    if (animationPromiseResolverRef.current) {
                        animationPromiseResolverRef.current();
                        animationPromiseResolverRef.current = null;
                    }
                });
            }
        });
        // eslint-disable-next-line
    }, [characters.map((character) => [character.x, character.y, character.scale])]);


    // Précharger les images lors du montage du composant
    useEffect(() => {
        characters.forEach((character) => {
            const imagesToPreload = [
                character.images.idle,
                character.images.moving,
                character.images.paused,
                character.images.colliding
            ];

            imagesToPreload.forEach((src) => {
                const img = new Image();
                img.src = src;
            });
        })

        // eslint-disable-next-line
    }, [characters.map(character => character.images)]);

    const [styleScene, setStyleScene] = useState<React.CSSProperties>({});

    useEffect(() => {
        setStyleScene(getBackgroundStyle(scene, showGrid));
    }, [scene, showGrid]);

    const getBackgroundStyle = (scene: TScene, showGrid: boolean): React.CSSProperties => {
        const backgroundImages: string[] = [];
        const backgroundSizes: string[] = [];
        const backgroundRepeats: string[] = [];

        // Ajouter l'image de fond si elle est définie
        if (scene.background?.img) {
            backgroundImages.push(`url(${scene.background.img})`);
            backgroundSizes.push(scene.background.size || 'cover');
            backgroundRepeats.push(scene.background.repeat ? 'repeat' : 'no-repeat');
        }

        // Ajouter la grille si showGrid est true
        if (showGrid) {
            // Lignes horizontales de la grille
            backgroundImages.push('linear-gradient(to right, lightgray 1px, transparent 1px)');
            backgroundSizes.push(`${scene.gridColumnWidth}px 100%`); // Largeur de la colonne, hauteur 100%
            backgroundRepeats.push('repeat-x'); // Répéter horizontalement

            // Lignes verticales de la grille
            backgroundImages.push('linear-gradient(to bottom, lightgray 1px, transparent 1px)');
            backgroundSizes.push(`100% ${scene.gridRowWidth}px`); // Largeur 100%, hauteur de la ligne
            backgroundRepeats.push('repeat-y'); // Répéter verticalement
        }

        // Vérifier s'il y a des images d'arrière-plan à appliquer
        if (backgroundImages.length === 0) {
            return {
                backgroundImage: 'none',
            };
        } else {
            return {
                backgroundImage: backgroundImages.join(', '),
                backgroundSize: backgroundSizes.join(', '),
                backgroundRepeat: backgroundRepeats.join(', '),
            };
        }
    };

    const getCharacterImage = (character: TCharacter) => {
        switch (character.state) {
            case 'idle':
                return character.images.idle;
            case 'moving':
                return character.images.moving;
            case 'paused':
                return character.images.paused;
            case 'colliding':
                return character.images.colliding;
            case 'wining':
                return character.images.win;
            default:
                return character.images.idle;
        }
    };

    return (
        <div
            id="scene"
            style={{
                position: 'relative',
                width: `${scene.width}px`,
                height: `${scene.height}px`,
                border: '1px solid black',
                ...styleScene,
            }}
        >
            {/* Render obstacles */}
            {obstacles.map((obstacle, index) => (
                <Obstacle key={index} obstacle={obstacle} editionMode={editionMode} />
            ))}

            <Target target={target} editionMode={editionMode} />

            {/* Render characters */}
            {characters.map((character, index) => (
                <motion.div
                    key={index}
                    id={`character-${index}`}
                    ref={(el) => (animationRefs.current[index] = el)}
                    style={{
                        width: `${character.width}px`,
                        height: `${character.height}px`,
                        backgroundImage: `url(${getCharacterImage(character)})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        position: 'absolute',
                        backgroundPosition: 'center',
                        border: editionMode ? '2px dashed blue' : 'none',

                    }}

                    initial={{
                        x: character.x,
                        y: character.y,
                    }}
                // animate={controlsArray.current[index]} // Utiliser un contrôleur spécifique à chaque personnage
                >
                    {character.speech && (
                        <div style={{
                            position: 'absolute',
                            bottom: `${character.height}px`,
                            left: '50%',
                            transform: character.scale === -1 ? 'translateX(-50%) scaleX(-1)' : 'translateX(-50%)',
                            backgroundColor: 'white',
                            padding: '5px',
                            borderRadius: '5px',
                            border: '1px solid black',
                            whiteSpace: 'nowrap',
                        }}>
                            {character.speech}
                        </div>
                    )}
                </motion.div>
            ))}

        </div>
    );
};

export default IGame;
