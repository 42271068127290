import React from 'react';
import { Drawer, Table, Button, InputNumber, Input } from 'antd';
import { useChallengeConfig } from '../../contexts/BlocklyConfigContext';
import { BlocklyPhaserConfig, TextObjectConfig } from '../../types/BlocklyTypes';


interface AdminTextsDrawerProps {
  visible: boolean;
  onClose: () => void;
}

/**
 * Gère l'ajout/édition/suppression de "TextObjectConfig" dans config.texts
 */
const AdminTextsDrawer: React.FC<AdminTextsDrawerProps> = ({
  visible,
  onClose,
}) => {
  const { config, setConfig } = useChallengeConfig();

  /**
   * Ajoute un nouveau texte à la config
   */
  const handleAddText = () => {

    setConfig((prev: BlocklyPhaserConfig) => {
      const newId = `text_${(prev.texts ?? []).length + 1}`;
      const newText: TextObjectConfig = {
        adminMode: prev.adminMode,
        id: newId,
        x: 100,
        y: 100,
        textString: 'Hello World',
        fontSize: 16,
        color: '#000000',
        backgroundColor: '#ffffff',
      };
      return {
        ...prev,
        texts: [...(prev.texts || []), newText],
      };
    });
  };

  /**
   * Mettre à jour un champ du texte (x, y, textString, fontSize, etc.)
   */
  const handleUpdateTextField = (
    textId: string,
    field: keyof TextObjectConfig,
    value: any
  ) => {
    setConfig((prev: BlocklyPhaserConfig) => {
      const newTexts = prev.texts.map((t) => {
        if (t.id === textId) {
          return { ...t, [field]: value };
        }
        return t;
      });
      return { ...prev, texts: newTexts };
    });
  };

  /**
   * Supprimer un texte de la config
   */
  const handleRemoveText = (textId: string) => {
    setConfig((prev: BlocklyPhaserConfig) => ({
      ...prev,
      texts: prev.texts.filter((t) => t.id !== textId),
    }));
  };

  // Définition des colonnes du tableau
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 90,
    },
    {
      title: 'Text',
      key: 'textString',
      render: (_value: any, record: TextObjectConfig) => (
        <Input
          value={record.textString}
          onChange={(e) =>
            handleUpdateTextField(record.id, 'textString', e.target.value)
          }
          style={{ width: 120 }}
        />
      ),
    },
    {
      title: 'X',
      key: 'x',
      render: (_value: any, record: TextObjectConfig) => (
        <InputNumber
          min={0}
          value={record.x}
          onChange={(val) =>
            handleUpdateTextField(record.id, 'x', val ?? 0)
          }
          style={{ width: 70 }}
        />
      ),
      sorter: (a: TextObjectConfig, b: TextObjectConfig) => a.x - b.x,
    },
    {
      title: 'Y',
      key: 'y',
      render: (_value: any, record: TextObjectConfig) => (
        <InputNumber
          min={0}
          value={record.y}
          onChange={(val) =>
            handleUpdateTextField(record.id, 'y', val ?? 0)
          }
          style={{ width: 70 }}
        />
      ),
      sorter: (a: TextObjectConfig, b: TextObjectConfig) => a.y - b.y,
    },
    {
      title: 'FontSize',
      key: 'fontSize',
      render: (_value: any, record: TextObjectConfig) => (
        <InputNumber
          min={1}
          max={200}
          value={record.fontSize ?? 16}
          onChange={(val) =>
            handleUpdateTextField(record.id, 'fontSize', val ?? 16)
          }
          style={{ width: 70 }}
        />
      ),
      sorter: (a: TextObjectConfig, b: TextObjectConfig) =>
        (a.fontSize ?? 16) - (b.fontSize ?? 16),
    },
    {
      title: 'Color',
      key: 'color',
      render: (_value: any, record: TextObjectConfig) => (
        <Input
          type="text"
          value={record.color ?? '#000000'}
          onChange={(e) =>
            handleUpdateTextField(record.id, 'color', e.target.value)
          }
          style={{ width: 80 }}
        />
      ),
    },
    {
      title: 'BgColor',
      key: 'backgroundColor',
      render: (_value: any, record: TextObjectConfig) => (
        <Input
          type="text"
          value={record.backgroundColor ?? ''}
          onChange={(e) =>
            handleUpdateTextField(record.id, 'backgroundColor', e.target.value)
          }
          style={{ width: 80 }}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_value: any, record: TextObjectConfig) => (
        <Button danger onClick={() => handleRemoveText(record.id)}>
          Supprimer
        </Button>
      ),
    },
  ];

  return (
    <Drawer
      title="Configuration des Textes"
      placement="left"
      width={1000}
      onClose={onClose}
      open={visible}
    >
      {/* Bouton pour ajouter un nouveau texte */}
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={handleAddText}>
          Ajouter un texte
        </Button>
      </div>

      {/* Tableau des textes configurés */}
      <Table
        dataSource={config.texts}
        columns={columns}
        rowKey="id"
        pagination={false}
        style={{ maxHeight: '60vh', overflowY: 'auto' }}
      />

      <div style={{ marginTop: 16, textAlign: 'right' }}>
        <Button onClick={onClose}>Fermer</Button>
      </div>
    </Drawer>
  );
};

export default AdminTextsDrawer;
