// textFunctions.ts

import { TextObject } from "../engine/entities/Text";
import { getEngine } from "./engineManager";

/**
 * Met à jour le texte complet du TextObject.
 * @param id - L'ID du TextObject
 * @param newText - Le nouveau texte à afficher
 */
export async function setTextContent(
  id: string,
  newText: string
): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.setText(newText);
}

/**
 * Ajoute (concatène) du texte au TextObject existant.
 * @param id - L'ID du TextObject
 * @param additionalText - Le texte à ajouter
 */
export async function addTextContent(
  id: string,
  additionalText: string
): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.addText(additionalText);
}

/**
 * Remplace une sous-chaîne dans le texte du TextObject.
 * @param id - L'ID du TextObject
 * @param oldSubstring - Sous-chaîne à remplacer
 * @param newSubstring - Nouvelle valeur de remplacement
 */
export async function replaceTextContent(
  id: string,
  oldSubstring: string,
  newSubstring: string
): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.replaceText(oldSubstring, newSubstring);
}

/**
 * Change la position (x,y) du TextObject.
 * @param id - L'ID du TextObject
 * @param x - Nouvelle coordonnée X
 * @param y - Nouvelle coordonnée Y
 */
export async function moveTextObject(
  id: string,
  x: number,
  y: number
): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.setPosition(x, y);
}

/**
 * Change la taille de police du TextObject.
 * @param id - L'ID du TextObject
 * @param fontSize - Nouvelle taille de police
 */
export async function setFontSize(id: string, fontSize: number): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.setFontSize(fontSize);
}

/**
 * Change la couleur du texte.
 * @param id - L'ID du TextObject
 * @param color - Nouvelle couleur (ex: "#ff0000")
 */
export async function setTextColor(id: string, color: string): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.setColor(color);
}

/**
 * Change la couleur de fond du texte.
 * @param id - L'ID du TextObject
 * @param color - Nouvelle couleur de fond (ex: "#00000088")
 */
export async function setTextBackgroundColor(
  id: string,
  color: string
): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.setBackgroundColor(color);
}

/**
 * Détruit l'objet texte.
 * @param id - L'ID du TextObject
 */
export async function destroyTextObject(id: string): Promise<void> {
  const engine = getEngine();
  const textObj = engine?.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.destroy();
}

/**
 * Crée un nouvel objet texte dans la scène.
 * @param id - L'ID unique pour ce TextObject
 * @param content - Le texte à afficher
 * @param x - Position X
 * @param y - Position Y
 * @param fontSize - Taille de la police
 * @param color - Couleur du texte (ex: "#000000")
 * @param backgroundColor - Couleur de fond (ex: "#ffffff")
 */
export async function createTextObject(
  id: string,
  content: string,
  x: number,
  y: number,
  fontSize: number,
  color: string,
  backgroundColor: string
): Promise<void> {
  const engine = getEngine();
  if (!engine) {
    console.warn("Engine introuvable");
    return;
  }

  // Vérifie si un objet texte portant déjà cet ID existe
  const existing = engine.getTextObject(id);
  if (existing) {
    console.warn(`Un TextObject avec l'ID "${id}" existe déjà.`);
    return;
  }

  // Créer l'instance
  const txtObj = new TextObject({
    adminMode: false, // ou true si vous voulez le mode admin
    id,
    x,
    y,
    textString: content,
    fontSize,
    color,
    backgroundColor,
  });

  // L’ajouter dans Phaser via le GameEngine
  engine.addText(txtObj);
}

/**
 * Change la police (font-family) du TextObject.
 * @param id - L'ID du TextObject
 * @param fontName - Le nom de la police (ex: "Arial", "Courier", etc.)
 */
export async function setTextFont(id: string, fontName: string): Promise<void> {
  const engine = getEngine();
  if (!engine) {
    console.warn("Engine introuvable");
    return;
  }
  const textObj = engine.getTextObject(id);
  if (!textObj) {
    console.warn(`TextObject with id ${id} not found.`);
    return;
  }
  textObj.setFontFamily(fontName);
}
