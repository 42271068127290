import React, {
    useRef,
    useEffect,
    useState,
    useCallback,
    useImperativeHandle,
    forwardRef,
    ForwardRefRenderFunction,
} from "react";

// 1) Import global de Blockly
import * as Blockly from "blockly";
// 3) Import du générateur JavaScript
import { javascriptGenerator } from "blockly/javascript";

// 2) Import des blocs de base
import "blockly/blocks";
import "blockly/javascript";
import "blockly/msg/fr"; // ou 'blockly/msg/en' selon la langue

// 3) Import de la config custom
import { initAllCustomBlocks } from "./blocks";

// 4) Import du contexte & types
import { useChallengeConfig } from "../contexts/BlocklyConfigContext";
import { generateToolboxXML } from "./funcToolbox";
import { Button, Space } from "antd";
import './css/toolbox.css';

export interface BlocklyWorkspaceRef {
    getCode: () => string;
    getWorkspaceXml: () => string;
    getWorkspace: () => Blockly.WorkspaceSvg | null;
}

interface BlocklyWorkspaceProps {
    adminMode?: boolean;
    initialXml?: string;
    onCodeChange?: (code: string) => void;
}

/**
 * Fonction "fictive" pour mettre en surbrillance un bloc durant l'exécution.
 * On l'attache volontairement à l'objet global pour être utilisable dans le code généré.
 */
declare global {
    interface Window {
        highlightBlock?: (blockId: string) => void;
    }
}

const BlocklyWorkspace: ForwardRefRenderFunction<
    BlocklyWorkspaceRef,
    BlocklyWorkspaceProps
> = ({ adminMode = false, initialXml, onCodeChange }, ref) => {
    const { config } = useChallengeConfig();
    const blocklyDiv = useRef<HTMLDivElement | null>(null);

    // On stocke le workspace dans un state pour pouvoir le détruire proprement
    // avant de le recréer en cas de modifications de la toolbox, etc.
    const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg | null>(null);

    /**
     * 1) (Ré)Injection de Blockly :
     *    À chaque fois que la toolbox change (config.toolboxCategories, config.toolboxGrouped), 
     *    on détruit l'ancien workspace et on en crée un nouveau.
     */
    useEffect(() => {
        if (!blocklyDiv.current) return;

        // Initialise vos blocs customs (ex.: en fonction de config.characters)
        initAllCustomBlocks(config.characters, config.objects, config.texts);

        // On attache Blockly à window pour debug, si besoin
        (window as any).Blockly = Blockly;

        // Génère la toolbox
        const toolboxXml = generateToolboxXML(config);

        // Injecter le nouveau workspace
        const newWorkspace = Blockly.inject(blocklyDiv.current, {
            toolbox: toolboxXml,
            readOnly: false,
            trashcan: true,

            zoom: {
                controls: true,
                wheel: true,
                startScale: 1,
                maxScale: 3,
                minScale: 0.3,
                scaleSpeed: 1.2,
            },
            grid: {
                spacing: 20,      // espace entre les lignes
                length: 3,        // longueur de chaque ligne
                colour: "#ccc",   // couleur du trait
                snap: true,       // si true, les blocs s’alignent
            },
            renderer: "geras",
        });

        // Charger le XML initial si présent
        if (initialXml) {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(initialXml, "text/xml");
            Blockly.Xml.domToWorkspace(xmlDoc.documentElement, newWorkspace);
        }

        // On écoute l'événement "change" pour générer le code
        const onChangeHandler = () => {
            if (!newWorkspace) return;
            const code = javascriptGenerator.workspaceToCode(newWorkspace);
            onCodeChange?.(code);
        };
        newWorkspace.addChangeListener(onChangeHandler);

        // Ajout de la fonction highlightBlock globale
        window.highlightBlock = (blockId: string) => {
            if (!newWorkspace) return;
            newWorkspace.highlightBlock(blockId);
        };

        setWorkspace(newWorkspace);

        // =========== CLEANUP ===========
        return () => {
            newWorkspace.removeChangeListener(onChangeHandler);
            newWorkspace.dispose();
        };
        // eslint-disable-next-line
    }, [initialXml, config.toolboxCategories, config.toolboxGrouped,
        config.characters, config.texts, config.objects]);

    /**
     * 2) Méthodes pour générer le code JS et l'XML depuis le workspace
     */
    const getGeneratedCode = useCallback(() => {
        if (!workspace) return "";
        return javascriptGenerator.workspaceToCode(workspace);
    }, [workspace]);

    const workspaceToXml = useCallback(() => {
        if (!workspace) return "";
        const dom = Blockly.Xml.workspaceToDom(workspace);

        // Utiliser XMLSerializer pour convertir le DOM en texte XML
        const serializer = new XMLSerializer();
        const xml = serializer.serializeToString(dom);
        return xml;
    }, [workspace]);

    /**
     * 3) Exposer getCode / getWorkspace via ref
     */
    useImperativeHandle(ref, () => ({
        getCode: () => getGeneratedCode(),
        getWorkspaceXml: () => workspaceToXml(),
        getWorkspace: () => workspace,
    }));

    /**
     * 4) Bouton "Afficher le code"
     */
    const handleShowCode = () => {
        const code = getGeneratedCode();
        console.log(code);
    };

    /**
     * Bouton "Vider le workspace"
     */
    const handleClearWorkspace = () => {
        if (!workspace) return;
        workspace.clear();
    };

    /**
     * Rendu JSX
     */
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div ref={blocklyDiv} style={{ flex: 1, border: "1px solid #ccc" }} />

            {adminMode && (
                <Space style={{ marginTop: 8 }}>
                    <Button onClick={handleShowCode}>Afficher le code</Button>
                    <Button danger onClick={handleClearWorkspace}>
                        Vider le workspace
                    </Button>
                </Space>
            )}
        </div>
    );
};

export default forwardRef(BlocklyWorkspace);
