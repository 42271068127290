// src/blocks/move/turnByAngle.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../BlocklyFields';
import i18next from 'i18next';

export function initTurnByAngleBlock() {
    Blockly.Blocks[BlocklyFields.TURN_BY_ANGLE] = {
        init: function () {
            this.jsonInit({
                type: BlocklyFields.TURN_BY_ANGLE,
                message0: '%1' + i18next.t('text.Turn', 'Turn') + '%2' + i18next.t('text.by', 'by') + '%3°',
                args0: [
                    {
                        type: "field_image",
                        src: "/images/blocks/turn.png", // Icône pour représenter l'action
                        width: 16, // Largeur de l'image
                        height: 16, // Hauteur de l'image
                    },
                    {
                        type: "field_dropdown",
                        name: "TURN_DIRECTION",
                        options: [
                            [
                                i18next.t('blockly.move.right.message', 'right'),
                                "RIGHT",
                            ],
                            [
                                i18next.t('blockly.move.left.message', 'left'),
                                "LEFT",
                            ],
                        ],
                    },
                    {
                        type: "field_dropdown",
                        name: "TURN_ANGLE",
                        options: [
                            ["45", "45"],
                            ["60", "60"],
                            ["90", "90"],
                            ["120", "120"],
                            ["180", "180"],
                        ],
                    },
                ],
                previousStatement: null,
                nextStatement: null,
                colour: "#FFAB19", // Couleur unifiée
                tooltip: i18next.t("blockly.turnByAngle.choice.tooltip", ""),
                helpUrl: "",
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.TURN_BY_ANGLE] = function (
        block,
        generator
    ) {
        const direction = block.getFieldValue("TURN_DIRECTION");
        const angle = block.getFieldValue("TURN_ANGLE");
        return `await turnByAngle('${direction}', ${angle});\n`;
    };
}
