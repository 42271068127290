import React, { useState } from "react";
import { Card, Input, Button, Typography, Space, Col, Row, message } from "antd";
import { useUserApi } from "../../api/user/userApp";
import { TLessonAnswerUser, TLessonQuestion } from "../../models/courses/lesson";

const { Title, Text } = Typography;

interface FillInTheBlankProps {
  question: TLessonQuestion;
  onNextLesson: () => void;
}

const FillInTheBlank: React.FC<FillInTheBlankProps> = ({ question, onNextLesson }) => {
  const { saveQuestionAnswers } = useUserApi();
  const [answers, setAnswers] = useState<TLessonAnswerUser[]>([]);
  const [onSave, setOnSave] = useState<boolean>(false);

  const handleInputChange = (value: string, index: number) => {
    // Mise à jour de la réponse utilisateur pour un index donné
    const updatedAnswers = [...answers];
    const existingAnswer = updatedAnswers[index];
    updatedAnswers[index] = {
      id: existingAnswer?.id || 0,
      answer_id: question.answers[index]?.id || 0,
      userapp_id: 0,
      question_id: question.id,
      user_input: value,
    };
    setAnswers(updatedAnswers);
  };


  const handleValidation = () => {
    // Validation des réponses utilisateur par rapport aux réponses correctes
    if (!question.answers || question.answers.length === 0) {
      message.error("Pas de réponses définies pour cette question.");
      return;
    }

    const isIncorrect = question.answers.some((answer, index) =>
      answer.content !== "*" &&
      answer.content.trim().toLowerCase() !== (answers[index]?.user_input || "").trim().toLowerCase()
    );

    if (isIncorrect) {
      message.error("Mauvaise réponse, réessayez.");
    }

    setOnSave(true);

    // Sauvegarder les réponses, même si elles sont incorrectes
    saveQuestionAnswers(answers)
      .then((response) => {
        setAnswers(response.data); // Mettre à jour les réponses
        // Ne passe à la leçon suivante que si la réponse est correcte
        if (!isIncorrect) {
          onNextLesson();
        }
      })
      .catch((error) => {
        message.error(error.response?.data ?? error.message); // Gérer les erreurs
      })
      .finally(() => {
        setOnSave(false); // Réinitialiser l'état
      });
  };



  const renderQuestions = () => {
    const lines = question.content.split("\n");
    return lines.map((line, lineIndex) => {
      const parts = line.split(/(%\d)/);
      return (
        <div key={lineIndex} style={{ marginBottom: "10px", fontSize: 16 }}>
          {parts.map((part, index) => {
            if (/^%\d$/.test(part)) {
              const placeholderIndex = parseInt(part.replace("%", "")) - 1;

              return (
                <Input
                  key={`${lineIndex}-${index}`}
                  style={{
                    minWidth: question.answers[placeholderIndex].content === "*" ? "100px" : `${question.answers[placeholderIndex].content.length * 1.5}ch`,
                    width: question.answers[placeholderIndex].content === "*" ? "100px" : `${question.answers[placeholderIndex].content.length * 1.5}ch`,
                    maxWidth: "100%",
                    margin: 0,
                    padding: 0,
                    color: '#4c68e6',
                    borderRadius: 0,
                    fontWeight: 700,
                  }}
                  value={answers[placeholderIndex]?.user_input || ""}
                  onChange={(e) => handleInputChange(e.target.value, placeholderIndex)}
                  variant="borderless"
                  placeholder="..."
                />

              );
            }
            return <span key={`${lineIndex}-${index}`}>{part}</span>;
          })}
        </div>
      );
    });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100%",
        padding: "5%",
      }}
    >
      <Card
        title={<Title level={4} >Question</Title>}
        style={{
          width: "65%",
          maxWidth: "90%",
          borderRadius: "10px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          padding: "20px",
        }}
      >

        <Row justify="center" style={{ marginBottom: "30px" }}>
          <Col>
            <Text type="secondary" style={{ fontSize: 16 }}>
              Remplissez les champs pour compléter les phrases ci-dessous.
            </Text>
          </Col>
        </Row>
        <Row justify="center">
          <Space direction="vertical" size="large" style={{ width: "100%", textAlign: "left" }}>
            {renderQuestions()}
          </Space>
        </Row>
        <Row justify="center" style={{ marginTop: "30px" }}>
          <Col>
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={handleValidation}
              style={{
                backgroundColor: "#1890ff",
                borderColor: "#1890ff",
              }}
              loading={onSave}
            >
              Soumettre
            </Button>
          </Col>
        </Row>
      </Card>
    </div>

  );
};

export default FillInTheBlank;
