import React, { useState, useEffect, useRef } from 'react';
import { FloatButton } from 'antd';
import { SaveOutlined, RollbackOutlined, LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

interface Props {
    save?: () => Promise<void>;
    navigationBack?: () => void;
    autosave?: boolean; // <-- Ajout du paramètre autosave
}

const FloatButtons: React.FC<Props> = ({ save, navigationBack, autosave = true }) => {
    const [isSaving, setIsSaving] = useState(false);
    const navigate = useNavigate();

    // Ref pour stocker l'ID de l'intervalle si autosave est activé
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    /**
     * Sauvegarde "classique" (appelée automatiquement et manuellement si autosave === false)
     */
    const handleSave = async () => {
        if (!save) return;
        setIsSaving(true);
        try {
            await save();
        } catch (error) {
            console.error('An error occurred while saving:', error);
        } finally {
            setIsSaving(false);
        }
    };

    /**
     * Sauvegarde déclenchée lorsque l'utilisateur clique sur le bouton,
     * qui, en mode autosave, enchaîne avec le retour en arrière.
     */
    const handleManualSave = async () => {
        if (!save) return;
        setIsSaving(true);
        try {
            await save();
            // Si on est en autosave, après la sauvegarde manuelle on exécute la même action que le bouton "Back"
            if (autosave) {
                if (navigationBack) {
                    navigationBack();
                } else {
                    navigate(-1);
                }
            }
        } catch (error) {
            console.error('An error occurred while saving:', error);
        } finally {
            setIsSaving(false);
        }
    };

    /**
     * Gère le bouton "Back"
     */
    const handleBack = () => {
        if (navigationBack) {
            navigationBack();
        } else {
            navigate(-1);
        }
    };

    /**
     * Gestion de l'autosave: lancement d'un intervalle qui appelle handleSave toutes les 2 minutes
     */
    useEffect(() => {
        if (autosave) {
            intervalRef.current = setInterval(() => {
                // On peut réutiliser handleSave, ou appeler directement save()
                handleSave();
            }, 30 * 1000); // 30 sec interval

            // Nettoyage à la fin du cycle de vie ou si autosave devient false
            return () => {
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        } else {
            // Si autosave est faux, on clear l'interval s'il existe encore
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [autosave]);

    return (
        <FloatButton.Group shape="circle" style={{ insetInlineEnd: 24 }}>
            {/* Bouton Save */}
            {save && (
                <FloatButton
                    type="default"
                    icon={isSaving ? <LoadingOutlined spin /> : <SaveOutlined />}
                    // Lorsque autosave === true, cliquer manuellement déclenche handleManualSave
                    // Sinon, on appelle handleSave “classique”
                    onClick={!isSaving ? handleManualSave : undefined}
                    style={{
                        opacity: isSaving ? 0.5 : 1,
                        pointerEvents: isSaving ? 'none' : 'auto'
                    }}
                />
            )}

            {/* Bouton Back */}
            <FloatButton
                type="default"
                icon={<RollbackOutlined />}
                onClick={handleBack}
            />

            <FloatButton.BackTop visibilityHeight={0} />
        </FloatButton.Group>
    );
};

export default FloatButtons;
