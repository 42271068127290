import { apiPath } from "../../config/config";




export const stringToBool = (value: string | undefined | null): boolean => {

    return ((value && value === "true") || false)
}

export const isJson = (str: any): boolean => {
    try {
        JSON.parse(str); // Tente de parser la chaîne
        return true;
    } catch (e) {
        return false; // Si une erreur survient, ce n'est pas un JSON valide
    }
}

export const getImagePath = (img: string, reloadCache?: boolean): string => {

    // Si l’image est vide : renvoie un fond blanc (base64) par exemple
    if (img === "") {
        return "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjEwMCIgaGVpZ2h0PSIxMDAiIGZpbGw9IndoaXRlIiAvPjwvc3ZnPg==";
    }

    // Gérer le cache-busting en ajoutant un timestamp
    let finalPath = img;
    if (reloadCache === true) {
        const timestamp = new Date().getTime();
        finalPath = `${img}?v=${timestamp}`;
    }

    return buildURL(apiPath, finalPath);
};

function buildURL(baseURL: string, path: string): string {
    // Si le path est déjà absolu, on le renvoie immédiatement
    if (/^(https?:\/\/|data:|blob:)/i.test(path)) {
        return path;
    }

    // Sinon on combine baseURL + path
    const cleanBase = baseURL.replace(/\/+$/, "");
    const cleanPath = path.replace(/^\/+/, "");

    return `${cleanBase}/${cleanPath}`;
}

