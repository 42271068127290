// defineVariablesBlocks.ts
import * as Blockly from 'blockly/core';
import { javascriptGenerator } from 'blockly/javascript';

// On sauvegarde l'ancienne méthode .finish
const oldFinish = javascriptGenerator.finish;
javascriptGenerator.finish = function (code) {
    // Appel de l’original
    code = oldFinish.call(this, code);
    // On retire toute déclaration "var x, y;"
    code = code.replace(/^var\s+[^\n]*\n/, '');
    return code;
};

/**
 * Déclare des blocs pour manipuler les variables :
 *   - variables_declare
 *   - variables_set
 *   - variables_increment
 *   - variables_get
 */
export function defineVariablesBlocks() {

    /***********************************************
     * 1) variables_declare (déclarer var = value)
     ***********************************************/
    Blockly.Blocks['variables_declare'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('déclarer');
            // Champ variable
            this.appendDummyInput('VAR')
                .appendField(
                    new Blockly.FieldVariable(`myVar_${this.id.substr(-3)}`), // ex: “myVar_123”
                    "VAR_NAME"
                );
            // Champ "type" facultatif : si tu veux un FieldDropdown ou un FieldTextInput
            // this.appendDummyInput('TYPE')
            //     .appendField('de type')
            //     .appendField(new Blockly.FieldTextInput('any'), 'TYPE_NAME');

            // Valeur
            this.appendValueInput('VALUE')
                .setCheck(null)
                .appendField('=');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(330);
            this.setTooltip('Déclare une nouvelle variable et lui assigne une valeur.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['variables_declare'] = function (block, generator) {
        const varName = block.getField('VAR_NAME')?.getText() || 'myVar';
        // const typeName = block.getFieldValue('TYPE_NAME') || 'any';  // si on gère un type
        const valueCode = generator.valueToCode(block, 'VALUE', 0) || 'undefined';

        // ex: "let myVar = 10;" 
        // ou "let myVar: typeName = val;" si on gère un pseudo-typage
        const code = `let ${varName} = ${valueCode};\n`;
        return code;
    };


    /***********************************************
     * 2) variables_set (mettre var = value)
     ***********************************************/
    Blockly.Blocks['variables_set'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('mettre');
            this.appendDummyInput()
                .appendField(new Blockly.FieldVariable('myVar'), 'VAR_NAME');
            this.appendValueInput('VALUE')
                .setCheck(null)
                .appendField('=');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(330);
            this.setTooltip('Assigne une nouvelle valeur à une variable existante.');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['variables_set'] = function (block, generator) {
        const varName = block.getField('VAR_NAME')?.getText() || 'myVar';
        const valueCode = generator.valueToCode(block, 'VALUE', 0) || 'undefined';
        // ex: "myVar = 10;"
        return `${varName} = ${valueCode};\n`;
    };


    /***********************************************
     * 3) variables_increment (changer var de value)
     * => var = var + value
     ***********************************************/
    Blockly.Blocks['variables_increment'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('Incrémente');
            this.appendDummyInput()
                .appendField(new Blockly.FieldVariable('myVar'), 'VAR_NAME');
            this.appendValueInput('DELTA')
                .setCheck('Number')
                .appendField('de');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(330);
            this.setTooltip('Ajoute la valeur spécifiée à la variable (ex: var += delta).');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['variables_increment'] = function (block, generator) {
        const varName = block.getField('VAR_NAME')?.getText() || 'myVar';
        const deltaCode = generator.valueToCode(block, 'DELTA', 0) || '0';
        // ex: "myVar += 5;"
        return `${varName} += ${deltaCode};\n`;
    };

    /***********************************************
    * 3) variables_decrement (changer var de value)
    * => var = var + value
    ***********************************************/
    Blockly.Blocks['variables_decrement'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('Décrémente');
            this.appendDummyInput()
                .appendField(new Blockly.FieldVariable('myVar'), 'VAR_NAME');
            this.appendValueInput('DELTA')
                .setCheck('Number')
                .appendField('de');

            this.setPreviousStatement(true, null);
            this.setNextStatement(true, null);
            this.setColour(330);
            this.setTooltip('Soustraire la valeur spécifiée à la variable (ex: var -= delta).');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['variables_decrement'] = function (block, generator) {
        const varName = block.getField('VAR_NAME')?.getText() || 'myVar';
        const deltaCode = generator.valueToCode(block, 'DELTA', 0) || '0';
        // ex: "myVar += 5;"
        return `${varName} -= ${deltaCode};\n`;
    };

    /***********************************************
     * 5) variables_get (obtenir var)
     * => un bloc output
     ***********************************************/
    Blockly.Blocks['variables_get'] = {
        init: function () {
            this.appendDummyInput()
                .appendField(new Blockly.FieldVariable('myVar'), 'VAR_NAME');
            this.setOutput(true, null);
            this.setColour(330);
            this.setTooltip('Renvoie la valeur de la variable.');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['variables_get'] = function (block) {
        const varName = block.getField('VAR_NAME')?.getText() || 'myVar';
        return [varName, 0];
    };
}
