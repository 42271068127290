/**
 * Point d’entrée / moteur principal pour le “jeu” en 2D top-down avec Phaser.
 * On instancie un Phaser.Game, on y intègre la PhaserScene,
 * et on expose des méthodes pour ajouter des entités (Characters, Objects).
 */

import Phaser from "phaser";
import { PhaserScene } from "./PhaserScene";
import { Character } from "./entities/Character";
import { GameObject } from "./entities/Object";
import { EventManager } from "./events/EventManager";
import { BlocklyPhaserConfig } from "../../types/BlocklyTypes";
import { TextObject } from "./entities/Text";

export interface EngineConfig {
  adminMode: boolean;
  sceneWidth: number; // Largeur en px
  sceneHeight: number; // Hauteur en px
  backgroundImage?: string; // Chemin / URL de l'image de fond
  backgroundMode?: "cover" | "repeat"; // (Non utilisé directement par PhaserScene, ajustez si besoin)
  eventManager?: EventManager | null;
  BlocklyPhaserConfig: BlocklyPhaserConfig;
  // Autres options possibles : ex. fps, physics, etc.
}

/**
 * Classe principale gérant un Phaser.Game.
 */
export class GameEngine {
  private game?: Phaser.Game; // Instance Phaser
  public phaserScene?: PhaserScene; // Notre scène personnalisée

  constructor(
    config: EngineConfig,
    containerRef: React.MutableRefObject<HTMLElement | null>,
    onWin: () => void
  ) {
    // 1) Créer la PhaserScene avec la config
    this.phaserScene = new PhaserScene(
      {
        width: config.sceneWidth,
        height: config.sceneHeight,
        backgroundImage: config.backgroundImage,
        BlocklyPhaserConfig: config.BlocklyPhaserConfig,
        eventManager: config.eventManager,
      },
      onWin
    );

    // 2) Créer la config Phaser
    const phaserConfig: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      width: config.sceneWidth,
      height: config.sceneHeight,
      parent: containerRef?.current || null, // On attache le canvas dans le <div> ref, si fourni
      scene: [this.phaserScene],
      physics: {
        default: "arcade", // Utiliser le système physique "arcade"
        arcade: {
          debug: config.adminMode, // Facultatif : afficher les collisions et déboguer
          gravity: { x: 0, y: 0 }, // Pas de gravité dans un jeu top-down
        },
      },
    };

    // 3) Instancier le Phaser.Game
    this.game = new Phaser.Game(phaserConfig);
  }

  /**
   * Démarre le jeu (Phaser démarre déjà en créant le Game,
   * donc on peut laisser cette méthode vide ou l’utiliser pour autre chose).
   */
  public start() {
    // Phaser est déjà “en route” dès qu’on a fait new Phaser.Game(...).
    // On peut éventuellement faire un "resume" si on l’avait "pause".
  }

  /**
   * Stoppe le jeu (détruit l’instance Phaser).
   */
  public stop() {
    this.phaserScene?.cleanup();

    this.game?.destroy(true);
    this.game = undefined;
    this.phaserScene = undefined;
  }

  /**
   * Ajoute un personnage à la scène (logique + sprite).
   */
  public addCharacter(char: Character) {
    this.phaserScene?.addCharacter(char);
  }

  /**
   * Ajoute un objet à la scène.
   */
  public addObject(obj: GameObject) {
    this.phaserScene?.addObject(obj);
  }

  /**
   * Ajoute un objet à la scène.
   */
  public addText(obj: TextObject) {
    this.phaserScene?.addText(obj);
  }
  /**
   * Récupère un objet texte par son ID.
   */
  public getTextObject(id: string): TextObject | undefined {
    // Hypothèse : on délègue à la scene
    return this.phaserScene?.getTextObjectById(id);
  }

  /**
   * (Optionnel) Récupérer le personnage par ID si vous voulez un système d'accès direct.
   */
  public getCharacter(id: string): Character | undefined {
    if (id === "") {
      return this.phaserScene?.getCharacterFirst();
    }
    return this.phaserScene?.getCharacterById(id);
  }

  /**
   * (Optionnel) Récupérer l’objet par ID.
   */
  //   public getObject(id: string): GameObject | undefined {
  //     return this.phaserScene?.getObjectById(id);
  //   }
}
