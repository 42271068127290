
import AppLayout from '../../components/layout/AppLayout'

const ResearchandDevelopment = () => {
    return (
        <AppLayout title='r&d'>
            <></>
            {/* <AdminConfigurator /> */}
        </AppLayout>
    )
}

export default ResearchandDevelopment