import { CharacterConfig, ObjectConfig } from "../../types/BlocklyTypes";
import { TextObjectConfig } from "../../types/BlocklyTypes";
import { defineActionBlocks } from "./action";
import { defineAttributeBlocks } from "./attribute";
import { defineControlBlocks } from "./control";
import { defineEventBlocks } from "./event";
import { defineInputBlocks } from "./Input";
import { defineLogicBlocks } from "./logic";
import { defineMathBlocks } from "./math";
import { defineMovementBlocks } from "./mouvement";
import { defineMovementsBlocks } from "./mouvement_multicharacter";
import { defineTextBlocks } from "./text";
import { defineVariablesBlocks } from "./variable";

export function initAllCustomBlocks(characters: CharacterConfig[], objetcs: ObjectConfig[], texts: TextObjectConfig[]) {
  defineMovementBlocks();
  defineMovementsBlocks(characters);
  defineActionBlocks(characters);
  defineAttributeBlocks();
  defineEventBlocks(characters, objetcs);
  defineInputBlocks();
  defineMathBlocks();
  defineLogicBlocks();
  defineControlBlocks();
  defineVariablesBlocks();
  defineTextBlocks(texts);
}
