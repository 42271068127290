import { TCharacter } from "../../blocks/core/type/character"
import { CODIFICATION_CHARACTER_STATE, CODIFICATION_TYPE } from "../../constant/codification"
import { TLessonCharacter } from "../../models/courses/lesson"
import { getImagePath } from "../format/valueFormat";


export const TLessonCharacterTOTCharacter = (
    lessonCharacter: TLessonCharacter | undefined,
    getCodificationIdByCodes: (codeType: string, code: string) => number
): TCharacter => {
    if (!lessonCharacter) {
        return {
            id: 0,
            name: '',
            x: 0,
            y: 0,
            state: 'idle',
            width: 0,
            height: 0,
            scale: 1,
            displacement_unit: 1,
            images: {
                idle: '',
                moving: '',
                paused: '',
                colliding: '',
                win: '',
            },
            canbeplayedwith: false,
            transition: {},
            speed: 1,
            speech: null,
        };
    }

    return {
        id: lessonCharacter.id,
        name: lessonCharacter.character.name,
        x: lessonCharacter.x,
        y: lessonCharacter.y,
        state: 'idle',
        width: lessonCharacter.width,
        height: lessonCharacter.height,
        scale: lessonCharacter.scale,
        displacement_unit: lessonCharacter.displacement_unit > 0 ? lessonCharacter.displacement_unit : 50,
        images: {
            idle: getImagePath(lessonCharacter.character.images.find(
                (e) => e.state_id === getCodificationIdByCodes(CODIFICATION_TYPE.CHARACTER_STATE, CODIFICATION_CHARACTER_STATE.idle)
            )?.img || ""),
            moving: getImagePath(lessonCharacter.character.images.find(
                (e) => e.state_id === getCodificationIdByCodes(CODIFICATION_TYPE.CHARACTER_STATE, CODIFICATION_CHARACTER_STATE.moving)
            )?.img || ""),
            paused: getImagePath(lessonCharacter.character.images.find(
                (e) => e.state_id === getCodificationIdByCodes(CODIFICATION_TYPE.CHARACTER_STATE, CODIFICATION_CHARACTER_STATE.idle)
            )?.img || ""),
            colliding: getImagePath(lessonCharacter.character.images.find(
                (e) => e.state_id === getCodificationIdByCodes(CODIFICATION_TYPE.CHARACTER_STATE, CODIFICATION_CHARACTER_STATE.colliding)
            )?.img || ""),
            win: getImagePath(lessonCharacter.character.images.find(
                (e) => e.state_id === getCodificationIdByCodes(CODIFICATION_TYPE.CHARACTER_STATE, CODIFICATION_CHARACTER_STATE.win)
            )?.img || ""),
        },
        transition: {
            ...lessonCharacter.character.transition,
        },
        canbeplayedwith: lessonCharacter.canbeplayedwith ?? true,
        speed: 1,
        speech: null,
    };
};


export const TLessonCharactersTOTCharacters = (
    lessonCharacters: TLessonCharacter[] | undefined,
    getCodificationIdByCodes: (codeType: string, code: string) => number
): TCharacter[] => {
    if (!lessonCharacters) {
        return [];
    }

    return lessonCharacters.map(lessonCharacter =>
        TLessonCharacterTOTCharacter(lessonCharacter, getCodificationIdByCodes)
    );
};