import { CODIFICATION_ROLES } from "../constant/codification";

export interface RouteConfig {
    path: string;
    isProtected: boolean;
    requiredRoles?: string[];
}

const routes: RouteConfig[] = [
    {
        path: '/',
        isProtected: false,
    },
    {
        path: '/dashboard',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.DASHBOARD],
    },
    {
        path: '/program',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.ACCESS_PROGRAM],
    },
    {
        path: '/subject-courses/:subjectId',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.ACCESS_PROGRAM],
    },
    {
        path: '/course-details/:courseId',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.ACCESS_PROGRAM],
    },
    {
        path: '/course-progression/:lessonId',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.ACCESS_PROGRAM],
    },
    {
        path: '/conf/users',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.CONFIGURATION_OWN_USERS],
    },
    {
        path: '/conf/users/form',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.CONFIGURATION_OWN_USERS],
    },
    {
        path: '/setup/subject',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/course',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/chapter',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/epic',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/lesson',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/lesson-blockly-phaser',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/lesson-blockly-game',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/lesson-blockly-draw',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/lesson-blockly-arcade',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/lesson-slide',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/lesson-forms',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.COURSE_CONFIGURATOR],
    },
    {
        path: '/setup/users',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.ADMIN_USERS],
    },
    {
        path: '/setup/passwords',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.ADMIN_USERS],
    },
    {
        path: '/setup/blockly-objects',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.BLOCKLY_CONFIGURATOR],
    },
    {
        path: '/setup/sprites',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.BLOCKLY_CONFIGURATOR],
    },
    {
        path: '/setup/sprites/edit',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.BLOCKLY_CONFIGURATOR],
    },
    {
        path: '/setup/roles',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.ROLE_CONFIGURATOR],
    },
    {
        path: '/td',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.DEVELOPER],
    },
    {
        path: '/rd',
        isProtected: true,
        requiredRoles: [CODIFICATION_ROLES.DEVELOPER],
    },
    {
        path: '/access-denied',
        isProtected: false,
    },
];

export default routes;