import React from 'react'
import { Tabs } from 'antd';
import { TDashboard } from '../../../models/user/Dashboard';
import ChildrenContent from './ChildrenContent';


interface DashboardParentProps {
    dashboard: TDashboard | null;
}

const DashboardParent: React.FC<DashboardParentProps> = (props) => {
    return (

        <>
            <Tabs
                defaultActiveKey="1"
                type="card"
                style={{ marginBottom: 32 }}
                items={(props.dashboard?.dashboard_children || []).map((dsbChild) => {

                    return {
                        label: `${dsbChild.userapp.personal_info?.firstname ?? "-"}`,
                        key: String(dsbChild.userapp.id),
                        children: <ChildrenContent dashboardChildrenData={dsbChild} />,
                    };
                })}
            />
        </>
    )
}

export default DashboardParent