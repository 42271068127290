// logicBlocks.ts 
import { Blocks } from 'blockly/core';
// Si vous avez besoin des blocs de base (if, loops...) : import 'blockly/blocks';
import { javascriptGenerator, Order } from 'blockly/javascript';
import * as Blockly from 'blockly';

/**
 * Déclaration complète de la catégorie "Logique"
 * avec plusieurs blocs : logique_compare, logique_operation,
 * logique_negate, logique_boolean, logique_ternary...
 */
export function defineLogicBlocks() {
    // ------------------------------------
    // 1) logic_compare
    // ------------------------------------
    Blocks['logic_compare'] = {
        init: function () {
            this.appendValueInput('A');
            this.appendValueInput('B')
                .appendField(new Blockly.FieldDropdown([
                    ['=', 'EQ'],
                    ['≠', 'NEQ'],
                    ['<', 'LT'],
                    ['≤', 'LTE'],
                    ['>', 'GT'],
                    ['≥', 'GTE'],
                ]), 'OP');
            this.setInputsInline(true);
            this.setOutput(true, 'Boolean');
            this.setColour(270);
            this.setTooltip('Compare deux valeurs.');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['logic_compare'] = function (block, generator) {
        const operator = block.getFieldValue('OP');
        const argument0 = generator.valueToCode(block, 'A', 0) || '0';
        const argument1 = generator.valueToCode(block, 'B', 0) || '0';

        let code = '';
        switch (operator) {
            case 'EQ': code = `${argument0} == ${argument1}`; break;
            case 'NEQ': code = `${argument0} != ${argument1}`; break;
            case 'LT': code = `${argument0} < ${argument1}`; break;
            case 'LTE': code = `${argument0} <= ${argument1}`; break;
            case 'GT': code = `${argument0} > ${argument1}`; break;
            case 'GTE': code = `${argument0} >= ${argument1}`; break;
            // éventuellement un "default" si needed
        }

        return [code, 10]; // 10 = un ORDER (ex. RELATIONAL).
    };

    // ------------------------------------
    // 2) logic_operation (AND / OR)
    // ------------------------------------
    Blocks['logic_operation'] = {
        init: function () {
            this.appendValueInput('A');
            this.appendValueInput('B')
                .appendField(new Blockly.FieldDropdown([
                    ['et', 'AND'],
                    ['ou', 'OR']
                ]), 'OP');
            this.setInputsInline(true);
            this.setOutput(true, 'Boolean');
            this.setColour(270);
            this.setTooltip('Opération ET / OU');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['logic_operation'] = function (block) {
        const operator = block.getFieldValue('OP');
        const argument0 = javascriptGenerator.valueToCode(block, 'A', Order.ATOMIC) || 'false';
        const argument1 = javascriptGenerator.valueToCode(block, 'B', Order.ATOMIC) || 'false';
        let code;
        if (operator === 'AND') {
            code = `${argument0} && ${argument1}`;
        } else {
            code = `${argument0} || ${argument1}`;
        }

        return [code, Order.LOGICAL_AND]; // ou ORDER_LOGICAL_OR
    };

    // ------------------------------------
    // 3) logic_negate (NOT)
    // ------------------------------------
    Blocks['logic_negate'] = {
        init: function () {
            this.appendValueInput('BOOL')
                .appendField('non');
            this.setOutput(true, 'Boolean');
            this.setColour(270);
            this.setTooltip('Inverse la valeur booléenne (non).');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['logic_negate'] = function (block) {
        const argument = javascriptGenerator.valueToCode(block, 'BOOL', Order.LOGICAL_NOT) || 'false';
        const code = `!${argument}`;
        return [code, Order.LOGICAL_NOT];
    };

    // ------------------------------------
    // 5) logic_ternary ( condition ? x : y )
    // ------------------------------------
    Blocks['logic_ternary'] = {
        init: function () {
            this.appendValueInput('IF')
                .setCheck('Boolean')
                .appendField('test');
            this.appendValueInput('THEN')
                .appendField('si vrai');
            this.appendValueInput('ELSE')
                .appendField('si faux');
            this.setOutput(true, null);
            this.setColour(270);
            this.setTooltip('Renvoie le premier résultat si la condition est vraie, sinon le second résultat.');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['logic_ternary'] = function (block) {
        const condition = javascriptGenerator.valueToCode(block, 'IF', Order.CONDITIONAL) || 'false';
        const thenValue = javascriptGenerator.valueToCode(block, 'THEN', Order.CONDITIONAL) || 'null';
        const elseValue = javascriptGenerator.valueToCode(block, 'ELSE', Order.CONDITIONAL) || 'null';
        const code = `(${condition}) ? (${thenValue}) : (${elseValue})`;
        return [code, Order.CONDITIONAL];
    };

    // ------------------------------------
    // 6) logic_null (optionnel)
    // ------------------------------------
    Blocks['logic_null'] = {
        init: function () {
            this.appendDummyInput()
                .appendField('null');
            this.setOutput(true, null);
            this.setColour(270);
            this.setTooltip('Renvoie la valeur null.');
            this.setHelpUrl('');
        }
    };

    javascriptGenerator.forBlock['logic_null'] = function (block) {
        return ['null', Order.ATOMIC];
    };


}