// src/blocks/move/moveTurn.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyFields } from '../../BlocklyFields';
import i18next from 'i18next';

export function initTurnBlock() {
    Blockly.Blocks[BlocklyFields.TURN] = {
        init: function () {
            this.jsonInit({
                type: BlocklyFields.TURN,
                message0: '%1' + i18next.t('text.Turn', 'Turn') + '%2',
                args0: [
                    {
                        type: "field_image",
                        src: "/images/blocks/turn.png", // Icône pour représenter l'action
                        width: 16, // Largeur de l'image
                        height: 16, // Hauteur de l'image
                    },
                    {
                        type: "field_dropdown",
                        name: "TURN_ACTION",
                        options: [
                            [
                                i18next.t('blockly.move.right.message', 'right'),
                                "RIGHT",
                            ],
                            [
                                i18next.t('blockly.move.left.message', 'left'),
                                "LEFT",
                            ],
                        ],
                    },
                ],
                previousStatement: null,
                nextStatement: null,
                colour: "#FFAB19", // Couleur unifiée
                tooltip: i18next.t("blockly.turn.choice.tooltip", ""),
                helpUrl: "",
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.TURN] = function (
        block,
        generator
    ) {
        const action = block.getFieldValue("TURN_ACTION");
        if (action === "RIGHT") {
            return "await turnCharacter(90);\n";
        } else if (action === "LEFT") {
            return "await turnCharacter(-90);\n";
        }
        return "";
    };
}
