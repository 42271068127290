export const BlocklyFields = {
  STARTBOX: "starting_box",

  //statement
  REPEAT: "repeat_custom",

  // action
  GET_TARGET: "get_target",
  SET_SPEED: "SET_SPEED",

  //move
  MOVE_UP: "move_up",
  MOVE_DOWN: "move_down",
  MOVE_LEFT: "move_left",
  MOVE_RIGHT: "move_right",
  MOVE_FORWARD: "move_forward",
  MOVE_BACKWARD: "move_backward",
  MOVE_WITH_DISTANCE: "MOVE_WITH_DISTANCE", // Nouveau bloc

  JUMP_UP: "jump_up",
  JUMP_DOWN: "jump_down",
  JUMP_LEFT: "jump_left",
  JUMP_RIGHT: "jump_right",
  JUMP_WITH_DISTANCE: "JUMP_WITH_DISTANCE", // Nouveau bloc

  MOVE_UPRIGHT: "move_upRight",
  MOVE_UPLEFT: "move_upLeft",
  MOVE_DOWNRIGHT: "move_downRight",
  MOVE_DOWNLEFT: "move_downLeft",
  TELEPORT_TO: "TELEPORT_TO",

  TURN: "TURN", // Nouveau bloc
  TURN_BY_ANGLE: "TURN_BY_ANGLE", // Nouveau bloc

  CHANGE_LINE_TYPE: "change_line_type",
  CHANGE_LINE_COLOR: "change_line_color",

  SELECT_IMAGE: "change_bg_image",

  // arcade
  MOVE_CHOSEN_DIRECTION: "MOVE_CHOSEN_DIRECTION",
  MOVE_CHOSEN_CARACTER: "MOVE_CHOSEN_CARACTER",
  WHEN_RIGHT_ARROW_PRESSED: "WHEN_RIGHT_ARROW_PRESSED",
  WHEN_UP_ARROW_PRESSED: "WHEN_UP_ARROW_PRESSED",
  WHEN_LEFT_ARROW_PRESSED: "WHEN_LEFT_ARROW_PRESSED",
  WHEN_DOWN_ARROW_PRESSED: "WHEN_DOWN_ARROW_PRESSED",
  WHEN_CHARACTERS_COLLIDE: "WHEN_CHARACTERS_COLLIDE",
  SAY_PHRASE: "SAY_PHRASE",
  SAY_SECRET: "SAY_SECRET",
  SET_SPEED_CHOSEN_CHARACTER: "SET_SPEED_CHOSEN_CHARACTER",
};
