import { BlocklyPhaserConfig, ToolboxCategory, ToolboxBlock } from "../types/BlocklyTypes";

/**
 * Génère le XML de la toolbox Blockly en fonction de la config.
 * - Si toolboxGrouped = true => on crée une <category> par catégorie.
 * - Sinon => on met tous les blocs en racine.
 */
export function generateToolboxXML(config: BlocklyPhaserConfig): string {
    const grouped = config.toolboxGrouped ?? true;

    if (grouped) {
        return categoriesToXML(config.toolboxCategories, config.adminMode);
    } else {
        // On “éclate” tous les blocs en racine
        const allBlocks = config.toolboxCategories.flatMap((cat) => cat.blocks);
        return rootBlocksXML(allBlocks, config.adminMode);
    }
}

/**
 * Construit le XML des catégories :
 *   <xml>
 *     <category name="..." colour="...">
 *       <block type="..." deletable="true" movable="true"></block>
 *       ...
 *     </category>
 *     ...
 *   </xml>
 */
function categoriesToXML(categories: ToolboxCategory[], modeAdmin: boolean): string {
    let xml = '<xml xmlns="https://developers.google.com/blockly/xml">';
    for (const cat of categories) {
        if (cat.blocks.length === 0) continue;
        // La couleur de la catégorie
        const colorAttr = cat.color ? ` colour="${cat.color}"` : '';
        xml += `<category name="${cat.name}"${colorAttr}>`;

        for (const block of cat.blocks) {
            xml += blockXML(block, modeAdmin);
        }
        xml += '</category>';
    }
    xml += '</xml>';
    return xml;
}

/**
 * Place directement les blocs en racine (pas de <category>).
 */
function rootBlocksXML(blocks: ToolboxBlock[], modeAdmin: boolean): string {
    let xml = '<xml xmlns="https://developers.google.com/blockly/xml">';
    for (const block of blocks) {
        xml += blockXML(block, modeAdmin);
    }
    xml += '</xml>';
    return xml;
}

/**
 * Construit le tag <block ...> pour un bloc donné,
 * en incluant `movable`, `deletable`, `editable`, `updatable`.
 * S'il manque l'une de ces propriétés (false), on force une couleur ;
 * sinon, on ne spécifie pas `colour=...` pour laisser la catégorie
 * gérer la couleur par défaut.
 */
function blockXML({
    type,
    movable,
    deletable,
    editable,
    updatable,
}: ToolboxBlock, modeAdmin: boolean): string {
    return `<block
    type="${type}"
    movable="${modeAdmin || movable}"
    deletable="${modeAdmin || deletable}"
    editable="${modeAdmin || editable}"
    updatable="${modeAdmin || updatable}" 
  ></block>`;
}
