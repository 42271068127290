import React, { useEffect, useState } from 'react';
import AppLayout from '../../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import { Button, Input, InputNumber, message, Select, Space, Table } from 'antd';
import { SaveOutlined, EditOutlined, KeyOutlined, StopOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { useUserApi } from '../../../api/user/userApp';
import { TUserApp } from '../../../models/user/User';
import { TCodification } from '../../../models/referential/referential';
import { useReferential } from '../../../context/ReferentialContext';
import { CODIFICATION_TYPE } from '../../../constant/codification';
import FloatButtons from '../../../components/button/FloatButtons';
import ConfirmationDialog from '../../../components/layout/ConfirmationDialog';
import type { GetProps } from 'antd';

type SearchProps = GetProps<typeof Input.Search>;

const { Search } = Input;

const UserAppList: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isReferentialLoaded } = useReferential();
    const { getAllUserApps, createUserApp, updateUserApp, deleteUserApp } = useUserApi();
    const [userApps, setUserApps] = useState<TUserApp[]>([]);
    const [loading, setIsLoading] = useState<boolean>(false);
    const [addingId, setAddingId] = useState<number>(0);
    const { getCodificationsByCodeType } = useReferential();
    const [userAppsFiltered, setUserAppsFiltered] = useState<TUserApp[]>([]);
    const userTypes: TCodification[] = getCodificationsByCodeType(CODIFICATION_TYPE.USER_TYPE);
    const [editingKey, setEditingKey] = useState<number | null>(null);
    const [editingUserApp, setEditingUserApp] = useState<TUserApp | null>(null);
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);
    const [typeUsers, setTypeUsers] = useState<TCodification[]>([]);

    useEffect(() => {
        setIsLoading(true);
        getAllUserApps()
            .then((resp) => {
                setUserApps(resp.data);
                setUserAppsFiltered(resp.data)

            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Fonction pour initialiser les types d'utilisateurs en fonction des droits de l'utilisateur connecté
        const initializeUserTypes = async () => {
            try {
                // Récupérer les codifications des types d'utilisateur
                const types: TCodification[] = await getCodificationsByCodeType(CODIFICATION_TYPE.USER_TYPE);

                // Si les types existent, les ajouter à la liste
                if (types && Array.isArray(types)) {
                    setTypeUsers(types); // On met à jour directement avec le tableau
                }
            } catch (err: any) {
                message.error(err.response.data ?? err.message);
            }
        };
        initializeUserTypes();
        // eslint-disable-next-line
    }, [isReferentialLoaded]);


    const edit = (userapp: TUserApp) => {
        setEditingKey(userapp.id);
        setEditingUserApp({ ...userapp });
    };

    const handleAddUserApp = () => {
        const idRow = addingId - 1;
        const newUserApp: TUserApp = {
            id: idRow,
            login: '',
            max_session: -1,
            usertype_id: 0,
        };

        setAddingId(idRow);
        setEditingKey(idRow);
        setEditingUserApp(newUserApp);
        setUserApps((prev) => [newUserApp, ...prev]);
    };

    const save = (id: number) => {
        if (editingUserApp) {
            (editingUserApp.id > 0 ? updateUserApp(editingUserApp.id, editingUserApp) : createUserApp(editingUserApp))
                .then((resp) => {
                    const userappResult: TUserApp = resp.data;
                    const updatedUserApps = userApps.map((userapp) =>
                        userapp.id === id ? userappResult : userapp
                    );
                    setUserApps(updatedUserApps);
                    setEditingKey(null);
                    setEditingUserApp(null);
                    message.success(t('message.savedSuccessfully'));
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const showDetails = (userapp: TUserApp) => {
        navigate('/setup/passwords', { state: { userapp } });
    };

    const cancel = () => {
        const idRow = editingUserApp?.id || 0;
        if (idRow <= 0) {
            setUserApps((prev) => [...prev ?? []].filter(e => e.id !== idRow));
        }
        setEditingKey(null);
        setEditingUserApp(null);
    };

    const confirmDelete = (id: number) => {
        setDeleteId(id);
        setIsDeleteDialogVisible(true);
    };

    const handleDelete = () => {
        if (deleteId == null) return;
        setDeleteLoading(true);
        deleteUserApp(deleteId)
            .then(() => {
                setUserApps(userApps.filter((userapp) => userapp.id !== deleteId));
                message.success(t('message.deletedSuccessfully'));
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setDeleteLoading(false);
                setIsDeleteDialogVisible(false);
                setDeleteId(null);
            });
    };

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => setUserAppsFiltered(userApps.filter(user => value.trim() === "" ||
        user.login.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
        (user.business_info && user.business_info.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())) ||
        (user.personal_info && user.personal_info.firstname.toLocaleLowerCase().includes(value.toLocaleLowerCase())) ||
        (user.personal_info && user.personal_info.lastname.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
    ));

    const columns: ColumnsType<TUserApp> = [
        {
            title: 'Login',
            dataIndex: 'login',
            key: 'login',
            render: (text: string, record: TUserApp) =>
                editingKey === record.id ? (
                    <Input
                        value={editingUserApp?.login}
                        onChange={(e) => {
                            if (editingUserApp) {
                                setEditingUserApp({ ...editingUserApp, login: e.target.value });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'Max Session',
            dataIndex: 'max_session',
            key: 'max_session',
            render: (text: string, record: TUserApp) =>
                editingKey === record.id ? (
                    <InputNumber
                        value={editingUserApp?.max_session}
                        onChange={(value) => {
                            if (editingUserApp) {
                                setEditingUserApp({ ...editingUserApp, max_session: value ?? -1 });
                            }
                        }}
                    />
                ) : (
                    text
                ),
        },
        {
            title: 'User Type',
            dataIndex: 'usertype_id',
            key: 'usertype_id',
            render: (usertype_id: number, record: TUserApp) =>
                editingKey === record.id ? (
                    <Select
                        value={editingUserApp?.usertype_id}
                        onChange={(value: number) => {
                            if (editingUserApp) {
                                setEditingUserApp({ ...editingUserApp, usertype_id: value });
                            }
                        }}
                    >
                        {userTypes.map((type: TCodification) => (
                            <Select.Option key={type.id} value={type.id}>
                                {type.label}
                            </Select.Option>
                        ))}
                    </Select>
                ) : (
                    userTypes.find((type) => type.id === usertype_id)?.label || 'N/A'
                ),
        },
        {
            title: 'Actions',
            key: 'actions',
            fixed: 'right' as 'right',
            width: 220,
            render: (_: any, record: TUserApp) => (
                <Space size="middle">
                    {editingKey === record.id ? (
                        <>
                            <Button icon={<SaveOutlined />} onClick={() => save(record.id)} />
                            <Button icon={<StopOutlined />} onClick={cancel} />
                        </>
                    ) : (
                        <>
                            <Button icon={<EditOutlined />} onClick={() => edit(record)} />
                            <Button icon={<KeyOutlined />} onClick={() => showDetails(record)} />
                            <Button
                                icon={<DeleteOutlined />}
                                danger
                                onClick={() => confirmDelete(record.id)}
                                loading={deleteId === record.id && deleteLoading}
                            />
                        </>
                    )}
                </Space>
            ),
        },
    ];

    return (
        <AppLayout title={t('text.users')} loading={loading}>
            <Space style={{ marginBottom: 16 }}>
                <Search
                    placeholder={t('text.search')}
                    onSearch={onSearch}
                    style={{ width: 200 }}
                />
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: 400 }}
                    placeholder={t('text.profil')}
                    onChange={(value: number[]) => {
                        setUserAppsFiltered(userApps.filter(user => value.length === 0 || value.includes(user.usertype_id)));
                    }}
                >
                    {typeUsers.map((type: TCodification) => (
                        <Select.Option key={type.id} value={type.id}>
                            {type.label}
                        </Select.Option>
                    ))}
                </Select>
                <Button type="primary" onClick={handleAddUserApp}>
                    {t('button.add')}
                </Button>
            </Space>
            <Table<TUserApp>
                columns={columns}
                dataSource={userAppsFiltered}
                rowKey="id"
                loading={loading}
                pagination={{ pageSize: 20 }}
                scroll={{ x: 1000 }}
            />

            <ConfirmationDialog
                visible={isDeleteDialogVisible}
                onConfirm={handleDelete}
                onCancel={() => setIsDeleteDialogVisible(false)}
                loading={deleteLoading}
                title="Confirm Deletion"
                description="Are you sure you want to delete this user? This action is irreversible."
            />

            <FloatButtons />
        </AppLayout>
    );
};

export default UserAppList;
