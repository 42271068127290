

export const CODIFICATION_TYPE = {
    // blockly game
    TARGET_STATE: "TARGET_STATE",
    CHARACTER_STATE: "CHARACTER_STATE",
    BLOCKLY_CONTENT: "BLOCKLY_FIELD",
    LESSON_METADATA: "LESSON_METADATA",

    LESSON_TYPE: "LESSON_TYPE",
    QUESTION_TYPE: "QUESTION_TYPE",
    USER_TYPE: "USER_TYPE",
    CONTACT_TYPE: "CONTACT_TYPE",
    TYPE_RELATION: "TYPE_RELATION",
}

export const CODIFICATION_TARGET_STATE = {
    idle: "idle",
    reached: "reached"
}

export const CODIFICATION_CHARACTER_STATE = {
    idle: "idle",
    moving: "moving",
    win: "win",
    colliding: "colliding",
}

export const CODIFICATION_LESSON_METADATA = {
    BLOCKLY_XML_TOOLBOX: "BLOCKLY_XML_TOOLBOX",
    SHOW_GRID: "SHOW_GRID",
    DRAW_RESULT: "DRAW_RESULT",
    REACH_ALL_TARGET: "REACH_ALL_TARGET",
    RESPECT_ORDER: "RESPECT_ORDER",
    AUTO_REACH: "AUTO_REACH",
    AUTO_RESET: "AUTO_RESET",
    CAN_CHANGE_XY: "CAN_CHANGE_XY",
}

export const CODIFICATION_LESSON_TYPE = {
    BLOCKLY_PHASER: "BLOCKLY_PHASER",
    BLOCKLY_GAME: "BLOCKLY_GAME",
    BLOCKLY_DRAW: "BLOCKLY_DRAW",
    BLOCKLY_ARCADE: "BLOCKLY_ARCADE",
    LESSON_SLIDE: "LESSON_SLIDE",
    LESSON_FORMS: "LESSON_FORMS",

}

export const CODIFICATION_QUESTION_TYPE = {
    // type question
    SINGLE_CHOICE: "SINGLE_CHOICE",
    MULTI_CHOICE: "MULTI_CHOICE",
    TEXT_INPUT: "TEXT_INPUT",
    NUMERIC_INPUT: "NUMERIC_INPUT",
    FILL_IN_THE_BLANKS: "FILL_IN_THE_BLANKS",
    DRAG_AND_DROP: "DRAG_AND_DROP",
    MATCHING: "MATCHING",

}

export const CODIFICATION_ROLES = {
    DASHBOARD: "DASHBOARD",

    DEVELOPER: "DEVELOPER",
    COURSE_CONFIGURATOR: "COURSE_CONFIGURATOR",
    ACCESS_PROGRAM: "ACCESS_PROGRAM",
    ADMIN_USERS: "ADMIN_USERS",
    BLOCKLY_CONFIGURATOR: "BLOCKLY_CONFIGURATOR",
    ROLE_CONFIGURATOR: "ROLE_CONFIGURATOR",
    CONFIGURATION_OWN_USERS: "CONFIGURATION_OWN_USERS",

    ADD_ADMIN: "ADD_ADMIN",
    ADD_TEACHER: "ADD_TEACHER",
    ADD_BUSINESS: "ADD_BUSINESS",
    ADD_PARENT: "ADD_PARENT",
    ADD_STUDENT: "ADD_STUDENT",
    ADD_OP_COURSE: "ADD_OP_COURSE",
    DELETE_USER: "DELETE_USER",
    DELETE_COURSE: "DELETE_COURSE",

}

export const CODIFICATION_TYPE_USERS = {
    MINOR: "MINOR",
    COURSE_OP: "COURSE_OP",
    ADULT: "ADULT",
    ADMIN: "admin",
    TEACHER: "TEACHER",
    BUSINESS: "BUSINESS",
}

export const CODIFICATION_TYPE_RELATION = {
    SCHOOL_STUDENT: "school_student",
    SCHOOL_TEACHER: "school_teacher",
    TEACHER_STUDENT: "teacher_student",
    PARENT_STUDENT: "parent_student",
    CREATOR: "creator",
}
