// src/blocks/move/moveChoice.ts

import * as Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import i18next from "i18next";
import { BlocklyFields } from "../../BlocklyFields";

export function initMoveChosenDirectionBlock() {
  Blockly.Blocks[BlocklyFields.MOVE_CHOSEN_DIRECTION] = {
    init: function () {
      this.jsonInit({
        type: BlocklyFields.MOVE_CHOSEN_DIRECTION,
        message0: '%1' + i18next.t('text.Move', 'Move') + '%2',
        args0: [
          {
            type: "field_image",
            src: "/images/blocks/move.png", // Icône pour représenter l'action
            width: 16, // Largeur de l'image
            height: 16, // Hauteur de l'image
          },
          {
            type: "field_dropdown",
            name: "MOVE_ACTION",
            options: [
              [
                i18next.t(
                  "blockly.move.forward.dropdown",
                  "Move forward"
                ),
                "FORWARD",
              ],
              [
                i18next.t(
                  "blockly.move.backward.dropdown",
                  "Move backward"
                ),
                "BACKWARD",
              ],
            ],
          },
        ],
        previousStatement: null,
        nextStatement: null,
        colour: "#FFAB19", // Couleur unifiée
        tooltip: i18next.t("blockly.move.choice.tooltip", ""),
        helpUrl: "",
      });
    },
  };

  javascriptGenerator.forBlock[BlocklyFields.MOVE_CHOSEN_DIRECTION] = function (
    block,
    generator
  ) {
    const action = block.getFieldValue("MOVE_ACTION");
    if (action === "FORWARD") {
      return "await moveForward();\n";
    } else if (action === "BACKWARD") {
      return "await moveBackward();\n";
    }
    return "";
  };
}
