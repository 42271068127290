// Blockly.tsx

import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
} from 'react';
import BlocklyContent, { BlocklyContentRef } from './BlocklyContent';
import { ExecutionProvider } from '../contexts/ExecutionContext';

export interface BlocklyRef {
    getWorkspaceXml: () => string | undefined;
}

interface BlocklyProps {
    onWin: () => void;
    onSave?: (workspace: string) => void;
}

const Blockly = forwardRef<BlocklyRef, BlocklyProps>(({ onWin, onSave }, ref) => {
    // On crée une ref vers le composant `BlocklyContent`
    const blocklyContentRef = useRef<BlocklyContentRef>(null);

    // useImperativeHandle nous permet d'exposer des méthodes publiques
    useImperativeHandle(ref, () => ({
        getWorkspaceXml: () => {
            // On délègue simplement au composant enfant `BlocklyContent`
            return blocklyContentRef.current?.getWorkspaceXml();
        },
    }));

    return (
        <ExecutionProvider>
            {/* On passe la ref au composant enfant */}
            <BlocklyContent ref={blocklyContentRef} onWin={onWin} onSave={onSave} />
        </ExecutionProvider>
    );
});

export default Blockly;
