import { WinCondition } from "../../../types/BlocklyTypes";
import { PhaserScene } from "../PhaserScene";
import { EventManager } from "./EventManager";

/**
 * Manager dédié à l’évaluation des conditions de victoire.
 */
export class WinConditionManager {
  private conditions: WinCondition[];
  private scene: PhaserScene;
  private eventManager: EventManager;
  private allConditionsMet: boolean = false;

  constructor(
    conditions: WinCondition[],
    scene: PhaserScene,
    eventManager: EventManager
  ) {
    // On stocke les conditions dans un tableau interne, et on ajoute un flag _done
    this.conditions = conditions.map((condition) => ({
      ...condition,
      _done: false,
    }));
    this.scene = scene;
    this.eventManager = eventManager;

    // Écouter l’événement “pickupEvent”
    this.eventManager.on("pickupEvent", (charId: string, objId: string) => {
      this.handlePickup(charId, objId);
    });

    // Écouter l’événement “sayPhraseEvent” (on le laisse en exemple)
    this.eventManager.on("sayPhraseEvent", (charId: string, phrase: string) => {
      this.handleSayPhrase(charId, phrase);
    });
  }

  /**
   * Appelée régulièrement (par ex. dans scene.update)
   * pour vérifier les conditions qui dépendent du temps ou de la position
   * (ex: 'characterAtPosition').
   */
  public update() {
    // Exemple simplifié :
    // 1) Pour chaque condition, si c’est 'characterAtPosition', on vérifie la position
    // 2) Si validé, on marque _done = true
    // 3) Ensuite on appelle this.checkAllConditions()

    let updated = false;
    this.conditions.forEach((cond) => {
      if ((cond as any)._done) return;

      if (cond.type === "characterAtPosition" && cond.targetPosition) {
        // Par exemple, on vérifie le 1er perso (ou le perso défini par cond.targetCharacterId)
        const char = cond.targetCharacterId
          ? this.scene.getCharacterById(cond.targetCharacterId)
          : this.scene.getCharacterFirst();
        if (!char) return;

        // Compare les positions arrondies
        if (
          Math.round(char.x) === cond.targetPosition.x &&
          Math.round(char.y) === cond.targetPosition.y
        ) {
          (cond as any)._done = true;
          updated = true;
        }
      }

      // 1) Condition textContentEquals
      if (
        cond.type === "textContentEquals" &&
        cond.targetTextObjectId &&
        cond.expectedTextContent
      ) {
        const txtObj = this.scene.getTextObjectById(cond.targetTextObjectId);
        if (txtObj) {
          // Comparaison stricte
          if (txtObj["textString"] === cond.expectedTextContent) {
            (cond as any)._done = true;
            updated = true;
          }
        }
      }
    });
    if (updated) {
      this.checkAllConditions();
    }
  }

  /**
   * Quand on reçoit “sayPhraseEvent(charId, phrase)”
   */
  private handleSayPhrase(charId: string, phrase: string) {
    let updated = false;
    this.conditions.forEach((cond) => {
      if ((cond as any)._done) return;

      if (cond.type === "saySpecificPhrase") {
        if (
          cond.targetCharacterId === charId &&
          cond.specificPhrase === phrase
        ) {
          (cond as any)._done = true;
          updated = true;
        }
      }
    });

    if (updated) {
      this.checkAllConditions();
    }
  }

  /**
   * Quand un personnage ramasse un objet => “pickupEvent”
   */
  private handlePickup(charId: string, objId: string) {
    let updated = false;
    this.conditions.forEach((cond) => {
      if ((cond as any)._done) return;

      // On part du principe que cond.type === "characterPickup"
      if (cond.type === "characterPickup") {
        // Cas 1 : la condition mentionne un perso ET un objet
        if (cond.targetCharacterId && cond.targetObjectId) {
          if (
            cond.targetCharacterId === charId &&
            cond.targetObjectId === objId
          ) {
            (cond as any)._done = true;
            updated = true;
          }
        }
        // Cas 2 : la condition mentionne seulement un objet
        else if (cond.targetObjectId && !cond.targetCharacterId) {
          if (cond.targetObjectId === objId) {
            (cond as any)._done = true;
            updated = true;
          }
        }
      }
    });

    if (updated) {
      this.checkAllConditions();
    }
  }

  /**
   * Vérifie si toutes les conditions sont remplies.
   * Si oui, on émet un event “win” une seule fois.
   */
  private checkAllConditions() {
    const allMet = this.conditions.every((c) => (c as any)._done);
    if (allMet && !this.allConditionsMet) {
      this.allConditionsMet = true;
      // On émet l’événement global “win”
      this.eventManager.emit("win");
    }
  }
}
