// src/contexts/ExecutionContext.tsx
import React, { createContext, useContext, useState, useCallback } from 'react';

/**
 * Définition du type décrivant l’état d’exécution.
 * On peut en ajouter/retirer selon nos besoins :
 * - isRunning : indique si le “jeu” ou la séquence de blocs est en cours d’exécution.
 * - isPaused : si on a un mode pause.
 * - currentStep : index de l’instruction en cours (optionnel).
 * - logs : un tableau de logs ou messages (optionnel).
 * - code : le code généré par Blockly (optionnel).
 */
interface ExecutionState {
    isRunning: boolean;
    isPaused: boolean;
    stepBystep: boolean;
    currentStep: number;
    logs: string[];
    code: string; // code JS/TS généré par le workspace
}

/**
 * Les actions ou fonctions que le contexte expose :
 * - start : lancer l’exécution (et éventuellement reinitialiser currentStep, logs…)
 * - pause : mettre en pause
 * - resume : reprendre
 * - stop : arrêter totalement
 * - addLog : rajouter un message au tableau logs
 * - setCode : stocker le code généré
 * - nextStep : exécuter l’instruction suivante (et incrémenter currentStep)
 */
interface ExecutionActions {
    start: () => void;
    pause: () => void;
    resume: () => void;
    stop: () => void;
    restart: () => void;
    addLog: (message: string) => void;
    setCode: (newCode: string) => void;
    nextStep: () => void;
    stepBystepOn: () => void;
    stepBystepOff: () => void;
}

/**
 * Le contexte combiné (état + actions).
 */
interface ExecutionContextValue extends ExecutionState, ExecutionActions { }

const ExecutionContext = createContext<ExecutionContextValue | undefined>(undefined);

/**
 * Hook pour consommer le contexte plus facilement.
 */
export function useExecutionContext(): ExecutionContextValue {
    const context = useContext(ExecutionContext);
    if (!context) {
        throw new Error('useExecutionContext must be used within an ExecutionProvider');
    }
    return context;
}

/**
 * Fournisseur du contexte. 
 * On y stocke tout l’état d’exécution et les fonctions associées.
 */
export const ExecutionProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    // On initialise l’état
    const [isRunning, setIsRunning] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [stepBystep, setStepBystep] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [logs, setLogs] = useState<string[]>([]);
    const [code, setCodeState] = useState<string>('');

    /**
     * start : lance l’exécution
     * - On remet currentStep à 0
     * - On vide les logs si besoin
     * - On marque isRunning=true, isPaused=false
     */
    const start = useCallback(() => {
        setCurrentStep(0);
        setLogs([]);
        setIsRunning(true);
        setIsPaused(false);
        // On pourrait aussi déclencher d'autres actions si nécessaire
    }, []);

    /**
     * pause : met en pause l’exécution (isPaused=true)
     */
    const pause = useCallback(() => {
        setIsPaused(true);
    }, []);

    const stepBystepOn = useCallback(() => {
        setStepBystep(true);
    }, [])
    const stepBystepOff = useCallback(() => {
        setStepBystep(false);
    }, [])
    /**
     * resume : reprend l’exécution (isPaused=false) si isRunning=true
     */
    const resume = useCallback(() => {
        if (isRunning) {
            setIsPaused(false);
        }
    }, [isRunning]);

    /**
     * stop : arrête complètement (isRunning=false, isPaused=false)
     */
    const stop = useCallback(() => {
        setIsRunning(false);
        setIsPaused(false);
    }, []);

    const restart = useCallback(() => {
        stop();   // arrête l'exécution (isRunning=false, isPaused=false)
        setTimeout(() => {
            start();
        }, 1000);  // réinitialise les logs, currentStep, etc. et relance
    }, [stop, start]);

    /**
     * addLog : ajoute un message dans le tableau logs
     */
    const addLog = useCallback((message: string) => {
        setLogs(prev => [...prev, message]);
    }, []);

    /**
     * setCode : remplace le code généré par Blockly
     */
    const setCode = useCallback((newCode: string) => {
        setCodeState(newCode);
    }, []);

    /**
     * nextStep : simule la lecture de l’instruction suivante (currentStep++)
     * - On peut s’en servir dans l’interpréteur (ex. codeInterpreter.ts) 
     *   pour avancer d’une instruction, 
     *   mettre en surbrillance le bloc correspondant, etc.
     */
    const nextStep = useCallback(() => {
        setCurrentStep(prev => prev + 1);
    }, []);

    // On rassemble tout dans un objet “value” à fournir au provider
    const value: ExecutionContextValue = {
        isRunning,
        isPaused,
        stepBystep,
        currentStep,
        logs,
        code,
        start,
        pause,
        resume,
        restart,
        stop,
        addLog,
        setCode,
        nextStep,
        stepBystepOn,
        stepBystepOff,
    };

    return (
        <ExecutionContext.Provider value={value}>
            {children}
        </ExecutionContext.Provider>
    );
};
