// routes.tsx
import React from 'react';
import CourseDetails from '../pages/program/CourseDetails';
import CourseProgress from '../pages/program/CourseProgress';
import Program from '../pages/program/Course';
import Subjects from '../pages/program/Subjects';
import SubjectList from '../pages/admin/course/SubjectList';
import CourseList from '../pages/admin/course/CourseList';
import ChapterList from '../pages/admin/course/ChapterList';
import EpicList from '../pages/admin/course/EpicList';
import LessonList from '../pages/admin/course/LessonList';
import LessonBlocklyGame from '../pages/admin/course/lessonBlocklyGame/LessonBlocklyGame';
import LessonBlocklyDraw from '../pages/admin/course/lessonBlocklyDraw/LessonBlocklyDraw';
import UserAppList from '../pages/admin/user/UserAppList';
import PasswordList from '../pages/admin/user/PasswordList';
import BlocklyObjects from '../pages/admin/blocklyObjects/BlocklyObjects';
import AffectedUsersList from '../pages/configuration/users/AffectedUsersList';
import UserForm from '../pages/configuration/users/userForm/UserForm';
import RoleTypeUser from '../pages/admin/roles/RoleTypeUser';
import AccessDenied from '../pages/auth/accessDenied/AccessDenied';
import AuthRedirect from './AuthRedirect';
import routeConfigs from './routeConfig';
import LessonBlocklyArcade from '../pages/admin/course/lessonBlocklyArcade/LessonBlocklyArcade';
// import ResearchandDevelopment from '../pages/r&d/ResearchandDevelopment';
import LessonSlide from '../pages/admin/course/lessonSlide/LessonSlide';
import LessonForms from '../pages/admin/course/lessonForms/LessonForms';
import Dashboard from '../pages/dashboard/Dashboard';
import ResearchandDevelopment from '../pages/r&d/ResearchandDevelopment';
import { SpriteEditor } from '../pages/admin/sprites/SpriteEditor';
import SpriteList from '../pages/admin/sprites/SpriteList';
import LessonBlocklyPhaserAdmin from '../pages/admin/course/BlocklyPhaser/LessonBlocklyPhaser';

const routeComponents: { [key: string]: React.ReactElement } = {
  '/': <AuthRedirect />,
  '/dashboard': <Dashboard />,
  '/program': <Subjects />,
  '/subject-courses/:subjectId': <Program />,
  '/course-details/:courseId': <CourseDetails />,
  '/course-progression/:lessonId': <CourseProgress />,

  '/conf/users': <AffectedUsersList />,
  '/conf/users/form': <UserForm />,

  '/setup/subject': <SubjectList />,
  '/setup/course': <CourseList />,
  '/setup/chapter': <ChapterList />,
  '/setup/epic': <EpicList />,
  '/setup/lesson': <LessonList />,
  '/setup/lesson-blockly-phaser': <LessonBlocklyPhaserAdmin />,
  '/setup/lesson-blockly-game': <LessonBlocklyGame />,
  '/setup/lesson-blockly-draw': <LessonBlocklyDraw />,
  '/setup/lesson-blockly-arcade': <LessonBlocklyArcade />,
  '/setup/lesson-slide': <LessonSlide />,
  '/setup/lesson-forms': <LessonForms />,
  '/setup/users': <UserAppList />,
  '/setup/passwords': <PasswordList />,
  '/setup/blockly-objects': <BlocklyObjects />,
  '/setup/sprites': <SpriteList />,
  '/setup/sprites/edit': <SpriteEditor />,
  '/setup/roles': <RoleTypeUser />,

  '/rd': <ResearchandDevelopment />,

  '/access-denied': <AccessDenied />,
};

const routes = routeConfigs.map(route => {
  const element = routeComponents[route.path];
  return {
    ...route,
    element,
  };
});

export default routes;