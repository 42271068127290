// AdminSceneDrawer.tsx
import React, { useState, useEffect } from 'react';
import { Drawer, InputNumber, Select, message, Button } from 'antd';
import { useChallengeConfig } from '../../contexts/BlocklyConfigContext';
import { BlocklyPhaserConfig } from '../../types/BlocklyTypes';
import { useObjectsApi } from '../../../../api/objects/objects';
import { TScene } from '../../../../models/objects/objects';

const { Option } = Select;

interface AdminSceneDrawerProps {
    visible: boolean;
    onClose: () => void;
}

/**
 * Affiche un Drawer pour configurer la scène :
 * - Largeur / hauteur
 * - Choix de la scène (background) depuis la BDD
 */
const AdminSceneDrawer: React.FC<AdminSceneDrawerProps> = ({
    visible,
    onClose,
}) => {
    const { config, setConfig } = useChallengeConfig();
    const { getScenes } = useObjectsApi(); // suppose que c’est là que se trouve l’api
    const [dbScenes, setDbScenes] = useState<TScene[]>([]);
    const [loading, setLoading] = useState(false);

    // On stocke localement width, height pour permettre une édition
    const [localWidth, setLocalWidth] = useState(config.scene.width);
    const [localHeight, setLocalHeight] = useState(config.scene.height);
    // Pour la scène sélectionnée, on peut soit stocker l’id, soit stocker l’img
    // Mais on va stocker l'id si on veut re-sélectionner plus tard.
    const [selectedSceneId, setSelectedSceneId] = useState<number | null>(null);

    // Charger la liste des scènes quand on ouvre le Drawer
    useEffect(() => {
        setLoading(true);
        getScenes()
            .then((resp) => {
                setDbScenes(resp.data);
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    // Synchroniser le state local à l’ouverture / changement de config
    useEffect(() => {
        setLocalWidth(config.scene.width);
        setLocalHeight(config.scene.height);
        // Tenter de retrouver dans dbScenes la scène dont l’img == background.image
        // (ou bien, si tu stockes un "sceneId" dans config, l’utiliser directement)
        const found = dbScenes.find((sc) => sc.img === config.scene.background?.image);
        setSelectedSceneId(found ? found.id : null);
    }, [config, dbScenes]);

    /**
     * Enregistre (width, height) et la scène sélectionnée dans le contexte
     */
    const handleSaveScene = () => {
        // Récupérer l’image associée à selectedSceneId
        let backgroundImg = config.scene.background.image;
        if (selectedSceneId) {
            const foundScene = dbScenes.find((sc) => sc.id === selectedSceneId);
            if (foundScene) {
                backgroundImg = foundScene.img;
            }
        }

        setConfig((prev: BlocklyPhaserConfig) => ({
            ...prev,
            scene: {
                ...prev.scene,
                width: localWidth,
                height: localHeight,
                background: {
                    ...prev.scene.background,
                    image: backgroundImg,
                },
            },
        }));
        onClose(); // Fermer le Drawer après sauvegarde
    };

    return (
        <Drawer
            title="Configuration de la Scène"
            placement="right"
            width={400}
            onClose={onClose}
            open={visible}
        >
            <div style={{ marginBottom: 16 }}>
                <p>Largeur :</p>
                <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    value={localWidth}
                    onChange={(val) => setLocalWidth(val ?? 0)}
                />
            </div>

            <div style={{ marginBottom: 16 }}>
                <p>Hauteur :</p>
                <InputNumber
                    min={0}
                    style={{ width: '100%' }}
                    value={localHeight}
                    onChange={(val) => setLocalHeight(val ?? 0)}
                />
            </div>

            <div style={{ marginBottom: 16 }}>
                <p>Choisir la scène (Background) :</p>
                <Select
                    style={{ width: '100%' }}
                    placeholder="Choisir une scène"
                    loading={loading}
                    value={selectedSceneId ?? undefined}
                    onChange={(val: number) => setSelectedSceneId(val)}
                    allowClear
                >
                    {dbScenes.map((sc) => (
                        <Option key={sc.id} value={sc.id}>
                            {sc.name}
                        </Option>
                    ))}
                </Select>
            </div>

            <div style={{ textAlign: 'right', marginTop: 24 }}>
                <Button onClick={onClose} style={{ marginRight: 8 }}>
                    Annuler
                </Button>
                <Button type="primary" onClick={handleSaveScene}>
                    Sauvegarder
                </Button>
            </div>
        </Drawer>
    );
};

export default AdminSceneDrawer;
