// src/game/GameLogic.ts

import { useGameContext } from './GameContext';
import { TObstacle } from '../../type/obstacle';
import { TTarget } from '../../type/target';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { pureMoveCharacter, pureTeleportTo } from '../BlocklyCommonFunction/Movement';

interface GameLogicProps {
    animationPromiseResolverRef: React.MutableRefObject<(() => void) | null>;
    obstacles: TObstacle[];
    autoReset: boolean;
    checkResult: (isValid: boolean) => void;
    autoReach: boolean;
    mustReachAllTargets: boolean;
    respectOrder: boolean;
}

export const useGameLogic = ({
    animationPromiseResolverRef,
    obstacles,
    autoReset,
    checkResult,
    autoReach,
    mustReachAllTargets,
    respectOrder,
}: GameLogicProps) => {
    const { t } = useTranslation();
    const { state, dispatch } = useGameContext();
    const stateRef = useRef(state);
    const lastMovementRef = useRef<{ angle: number; distance: number }>({ angle: 0, distance: state.character.displacement_unit ?? 50 });

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    let collisionMessageDisplayed = false;



    const walkCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character` 
        const distance = character.displacement_unit;
        lastMovementRef.current = { angle, distance }; // Sauvegarder le mouvement
        return moveCharacter(angle, character.displacement_unit);
    };

    const jumpCharacter = async (angle: number): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        const distance = character.displacement_unit * 2;
        lastMovementRef.current = { angle, distance }; // Sauvegarder le mouvement
        return moveCharacter(angle, character.displacement_unit * 2);
    };

    const moveForward = async (): Promise<void> => {
        const { angle, distance } = lastMovementRef.current;
        return moveCharacter(angle, distance); // Répéter le mouvement
    };

    const moveBackward = async (): Promise<void> => {
        // Inverser l'angle du mouvement
        const { angle, distance } = lastMovementRef.current;
        const reversedAngle = (angle + 180) % 360; // Ajouter 180° pour obtenir la direction opposée 
        return moveCharacter(reversedAngle, distance); // Exécuter le mouvement inverse
    };

    const teleportTo = async (position: string): Promise<void> => {
        const { character, scene } = stateRef.current;

        const {
            x,
            y,
            scale,
        } = pureTeleportTo(character, scene, position)

        // Animation et mise à jour du personnage
        return new Promise<void>((resolve) => {
            // animationPromiseResolverRef est géré dans IGame
            animationPromiseResolverRef.current = resolve;

            dispatch({
                type: 'MOVE_CHARACTER',
                payload: {
                    x: x,
                    y: y,
                    state: 'moving',
                    scale: scale,
                },
            });
        });
    };

    const moveCharacter = async (angle: number, distance: number): Promise<void> => {
        // Récupérer le dernier état à chaque appel
        const { character, scene, executionCancelled } = stateRef.current;

        if (executionCancelled) {
            return Promise.resolve();
        }

        const {
            finalX,
            finalY,
            isColliding,
            scale,
        } = pureMoveCharacter(character, angle, distance, scene, obstacles)

        return new Promise<void>((resolve) => {

            if (isColliding) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'colliding' });
                dispatch({ type: 'SET_EXECUTION_CANCELLED', payload: true });

                if (!collisionMessageDisplayed) {
                    message.error(t('message.collisionOrOutOfBounds'));
                    collisionMessageDisplayed = true;
                }

                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                        collisionMessageDisplayed = false; // Réinitialiser le drapeau après le reset
                    }, 1000);
                }
                resolve();
            } else {
                // animationPromiseResolverRef est géré dans IGame
                animationPromiseResolverRef.current = resolve;
                dispatch({
                    type: 'MOVE_CHARACTER',
                    payload: {
                        x: finalX,
                        y: finalY,
                        state: 'moving',
                        scale: scale,
                    },
                });
                if (autoReach) {
                    reachTargetByXY(finalX, finalY);
                }
                // le resolve est dans l'animation IGame
            }
        });
    };

    const resetGame = () => {
        dispatch({ type: 'RESET_GAME' });
    };

    const areTargetsEqual = (t1: TTarget, t2: TTarget): boolean => {
        return (
            t1.x === t2.x &&
            t1.y === t2.y &&
            t1.width === t2.width &&
            t1.height === t2.height
        );
    };
    // Dans `updateOrRemoveTarget`
    const updateOrRemoveTarget = (target: TTarget) => {
        dispatch({ type: 'UPDATE_OR_REMOVE_TARGET', payload: target });
    };

    const reachTargetByXY = async (x: number, y: number): Promise<void> => {
        const { remainingTargets } = stateRef.current;
        const { character } = stateRef.current; // Récupérer le dernier `character`

        // Trouver la cible atteinte
        const actualTarget = remainingTargets.find((target) => {
            return (
                x < target.x + target.width &&
                x + character.width > target.x &&
                y < target.y + target.height &&
                y + character.height > target.y
            );
        });

        if (actualTarget) {
            if (respectOrder && !areTargetsEqual(actualTarget, remainingTargets[0])) {
                dispatch({ type: 'SET_EXECUTION_CANCELLED', payload: true });
                message.error(t('message.wrongOrder'));
                if (autoReset) {
                    setTimeout(() => {
                        resetGame();
                    }, 1000);
                }
            } else {
                // Mettre à jour ou retirer la cible atteinte
                updateOrRemoveTarget(actualTarget);

                // Vérifier si toutes les cibles ont été atteintes
                const { remainingTargets: updatedRemainingTargets } = stateRef.current;

                if (updatedRemainingTargets.length === 0 && mustReachAllTargets) {
                    checkResult(true);
                    dispatch({ type: 'SET_CHARACTER_STATE', payload: 'wining' });
                }
            }
        }
    };

    const setSpeed = async (speed: string): Promise<void> => {
        const { character } = stateRef.current

        const speedNumber = Number(speed);
        dispatch({ type: 'UPDATE_CHARACTER', payload: { ...character, speed: speedNumber } });

    };

    const setSceneBackground = async (img: string): Promise<void> => {
        const { scene } = stateRef.current;
        dispatch({
            type: 'SET_SCENE_BACKGROUND',
            payload: {
                img,
                repeat: scene.background?.repeat || false,
                size: scene.background?.size || 'cover',
            },
        });
    };

    const pauseCharacter = () => {
        dispatch({ type: 'SET_CHARACTER_STATE', payload: 'paused' });
    };

    const reachTarget = async (): Promise<void> => {
        const { character } = stateRef.current; // Récupérer le dernier `character`
        await reachTargetByXY(character.x, character.y);
    };

    const isDone = async () => {

        // Attendre que l'état soit mis à jour
        await new Promise<void>((resolve) => setTimeout(resolve, 0));
        const { executionCancelled } = stateRef.current;

        if (executionCancelled) {
            return Promise.resolve();
        }
        const { initialTargets, remainingTargets } = stateRef.current;
        if (initialTargets.length > 0) {
            let success =
                (mustReachAllTargets && remainingTargets.length === 0) ||
                (!mustReachAllTargets && remainingTargets.length < initialTargets.length);

            checkResult(success);
            if (success) {
                dispatch({ type: 'SET_CHARACTER_STATE', payload: 'wining' });
            } else {

                message.warning(t('message.bug'));
                if (autoReset) {
                    setTimeout(async () => {
                        resetGame();
                    }, 500);
                }
            }
        } else {
            // Si aucune cible n'est définie, considérez que l'exercice est terminé avec succès
            checkResult(true);
        }
    };

    return {
        walkCharacter,
        jumpCharacter,
        moveForward,
        moveBackward,
        moveCharacter,
        teleportTo,
        resetGame,
        reachTargetByXY,
        setSceneBackground,
        setSpeed,
        pauseCharacter,
        reachTarget,
        isDone,
    };
};
