import React, { useCallback, useState } from 'react';
import { Drawer, Button, Select, Table, InputNumber, Form, Space, Input, ColorPicker } from 'antd';
import { useChallengeConfig } from '../../contexts/BlocklyConfigContext';
import { BlocklyPhaserConfig, WinCondition } from '../../types/BlocklyTypes';

const WIN_CONDITION_TYPES = [
    { label: 'Pickup Perso - Objet', value: 'characterPickup' },
    { label: 'Perso à Position', value: 'characterAtPosition' },
    { label: 'Objet détruit', value: 'objectDestroyed' },
    { label: 'Dire une phrase', value: 'saySpecificPhrase' },
    { label: 'Texte = Contenu spécifique', value: 'textContentEquals' },
    { label: 'Texte = Couleur spécifique', value: 'textHasColor' },
    { label: 'Texte = BackgroundColor spécifique', value: 'textHasBackgroundColor' },
];

interface AdminWinConditionsDrawerProps {
    visible: boolean;
    onClose: () => void;
}

const AdminWinConditionsDrawer: React.FC<AdminWinConditionsDrawerProps> = ({
    visible,
    onClose,
}) => {
    const { config, setConfig } = useChallengeConfig();

    // États internes pour la "form"
    const [formType, setFormType] = useState<string>('characterPickup');
    const [formCharacterId, setFormCharacterId] = useState<string | undefined>(undefined);
    const [formObjectId, setFormObjectId] = useState<string | undefined>(undefined);
    const [formTextObjectId, setFormTextObjectId] = useState<string | undefined>(undefined);
    const [formTextExpected, setFormTextExpected] = useState<string>("");
    const [formTextColor, setFormTextColor] = useState<string>("");
    const [formTextBgColor, setFormTextBgColor] = useState<string>("");

    // Pour "characterAtPosition"
    const [formPosX, setFormPosX] = useState<number>(0);
    const [formPosY, setFormPosY] = useState<number>(0);

    // Pour "saySpecificPhrase"
    const [formPhrase, setFormPhrase] = useState<string>("");

    const characterOptions = (config.characters || []).map((ch) => ({
        label: ch.id,
        value: ch.id,
    }));

    const objectOptions = (config.objects || []).map((obj) => ({
        label: obj.id,
        value: obj.id,
    }));

    const textOptions = (config.texts || []).map((txt) => ({
        label: txt.id,
        value: txt.id,
    }));

    /**
     * Ajouter une condition
     */
    const handleAddWinCondition = useCallback(() => {
        setConfig((prev: BlocklyPhaserConfig): BlocklyPhaserConfig => {
            const newCondition: WinCondition = {
                id: Date.now().toString(),
                type: formType,
            };

            if (formType === 'characterPickup') {
                // → Au lieu de “collision”, on gère le "pickup"
                if (formCharacterId) {
                    newCondition.targetCharacterId = formCharacterId;
                }
                if (formObjectId) {
                    newCondition.targetObjectId = formObjectId;
                }
            } else if (formType === 'characterAtPosition') {
                if (formCharacterId) {
                    newCondition.targetCharacterId = formCharacterId;
                }
                newCondition.targetPosition = { x: formPosX, y: formPosY };
            } else if (formType === 'objectDestroyed') {
                if (formObjectId) {
                    newCondition.targetObjectId = formObjectId;
                }
            } else if (formType === 'saySpecificPhrase') {
                if (formCharacterId) {
                    newCondition.targetCharacterId = formCharacterId;
                }
                if (formPhrase) {
                    newCondition.specificPhrase = formPhrase;
                }
            } else if (formType === 'textContentEquals') {
                if (formTextObjectId) {
                    newCondition.targetTextObjectId = formTextObjectId;
                    newCondition.expectedTextContent = formTextExpected;
                }
            }

            return {
                ...prev,
                winConditions: [...prev.winConditions, newCondition],
            };
        });
    }, [
        formType,
        formCharacterId,
        formObjectId,
        formPosX,
        formPosY,
        formPhrase,
        formTextObjectId,
        formTextExpected,
        formTextColor,
        formTextBgColor,
        setConfig,
    ]);

    /**
     * Supprimer une condition
     */
    const handleRemoveCondition = useCallback(
        (index: number) => {
            setConfig((prev: BlocklyPhaserConfig) => {
                const newConds = [...prev.winConditions];
                newConds.splice(index, 1);
                return { ...prev, winConditions: newConds };
            });
        },
        [setConfig]
    );

    /**
     * Colonnes du tableau
     */
    const columns = [
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Personnage ciblé',
            key: 'character',
            render: (_: any, record: WinCondition) => {
                if (record.targetCharacterId) return record.targetCharacterId;
                if (record.targetTextObjectId) return record.targetTextObjectId;
                return '-';
            },
        },
        {
            title: 'Description',
            key: 'description',
            render: (_: any, record: WinCondition) => {
                switch (record.type) {
                    case 'characterPickup':
                        // On affiche l’ID de l’objet à ramasser
                        return record.targetObjectId || '-';

                    case 'characterAtPosition':
                        return record.targetPosition
                            ? `(${record.targetPosition.x}, ${record.targetPosition.y})`
                            : '-';

                    case 'saySpecificPhrase':
                        return record.specificPhrase || '-';

                    case 'objectDestroyed':
                        return record.targetObjectId || '-';

                    case 'textContentEquals':
                        return record.expectedTextContent || '-';

                    default:
                        return '-';
                }
            },
        },
        {
            title: 'Action',
            key: 'action',
            render: (_: any, _record: WinCondition, index: number) => (
                <Button danger onClick={() => handleRemoveCondition(index)}>
                    Supprimer
                </Button>
            ),
        },
    ];

    return (
        <Drawer
            title="Configuration des conditions de victoire"
            placement="right"
            width={1000}
            onClose={onClose}
            open={visible}
        >
            <p>Liste des conditions existantes</p>
            <Table
                dataSource={config.winConditions}
                columns={columns}
                rowKey="id"
                pagination={false}
                style={{ marginBottom: 24 }}
            />

            <p>Ajouter une nouvelle condition</p>
            <Form layout="vertical">
                <Form.Item label="Type de condition">
                    <Select
                        style={{ width: '100%' }}
                        options={WIN_CONDITION_TYPES}
                        value={formType}
                        onChange={(val) => {
                            setFormType(val);
                            setFormCharacterId(undefined);
                            setFormObjectId(undefined);
                        }}
                    />
                </Form.Item>

                {formType === 'characterPickup' && (
                    <>
                        <Form.Item label="Personnage">
                            <Select
                                placeholder="Choisir un personnage"
                                style={{ width: '100%' }}
                                value={formCharacterId}
                                onChange={setFormCharacterId}
                                options={characterOptions}
                            />
                        </Form.Item>
                        <Form.Item label="Objet">
                            <Select
                                placeholder="Choisir un objet"
                                style={{ width: '100%' }}
                                value={formObjectId}
                                onChange={setFormObjectId}
                                options={objectOptions}
                            />
                        </Form.Item>
                    </>
                )}

                {formType === 'characterAtPosition' && (
                    <>
                        <Form.Item label="Personnage">
                            <Select
                                placeholder="Choisir un personnage"
                                style={{ width: '100%' }}
                                value={formCharacterId}
                                onChange={setFormCharacterId}
                                options={characterOptions}
                            />
                        </Form.Item>
                        <Space>
                            <Form.Item label="Pos X">
                                <InputNumber
                                    value={formPosX}
                                    onChange={(val) => setFormPosX(val || 0)}
                                />
                            </Form.Item>
                            <Form.Item label="Pos Y">
                                <InputNumber
                                    value={formPosY}
                                    onChange={(val) => setFormPosY(val || 0)}
                                />
                            </Form.Item>
                        </Space>
                    </>
                )}

                {formType === 'objectDestroyed' && (
                    <Form.Item label="Objet à détruire">
                        <Select
                            placeholder="Choisir un objet"
                            style={{ width: '100%' }}
                            value={formObjectId}
                            onChange={setFormObjectId}
                            options={objectOptions}
                        />
                    </Form.Item>
                )}

                {formType === 'saySpecificPhrase' && (
                    <>
                        <Form.Item label="Personnage">
                            <Select
                                options={characterOptions}
                                value={formCharacterId}
                                onChange={setFormCharacterId}
                            />
                        </Form.Item>
                        <Form.Item label="Phrase">
                            <Input
                                value={formPhrase}
                                onChange={(e) => setFormPhrase(e.target.value)}
                            />
                        </Form.Item>
                    </>
                )}

                {formType === 'textContentEquals' && (
                    <>
                        <Form.Item label="TextObject ID">
                            <Select
                                placeholder="Choisir un ID de texte"
                                options={textOptions}
                                value={formTextObjectId}
                                onChange={setFormTextObjectId}
                            />
                        </Form.Item>
                        <Form.Item label="Contenu attendu">
                            <Input
                                value={formTextExpected}
                                onChange={(e) => setFormTextExpected(e.target.value)}
                            />
                        </Form.Item>
                    </>
                )}


                <Button type="primary" onClick={handleAddWinCondition}>
                    Ajouter
                </Button>
            </Form>

            <div style={{ textAlign: 'right', marginTop: 24 }}>
                <Button onClick={onClose}>Fermer</Button>
            </div>
        </Drawer>
    );
};

export default AdminWinConditionsDrawer;
