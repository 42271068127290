import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/layout/AppLayout';
import { useTranslation } from 'react-i18next';
import DashboardParent from './parent/DashboardParent';
import { useUserApi } from '../../api/user/userApp';
import { TDashboard } from '../../models/user/Dashboard';
import { message } from 'antd';
import DashboardAnimateur from './animateur/DashboardAnimator';

const Dashboard = () => {
    const { t } = useTranslation();
    const { getDashboard } = useUserApi();
    const [dashboard, setDashboard] = useState<TDashboard | null>(null);
    const [loading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        getDashboard()
            .then((resp) => {
                setDashboard(resp.data);
            })
            .catch((err) => {
                message.error(err.response.data ?? err.message);
            })
            .finally(() => {
                setIsLoading(false);
            });

        // eslint-disable-next-line 
    }, []);

    return (
        <AppLayout title={t('sidebar.dashboard', 'Dashboard')} loading={loading}>
            {dashboard?.dashboard_children?.length ? (
                <DashboardParent dashboard={dashboard} />
            ) : (
                <DashboardAnimateur dashboard={dashboard} />
            )}
        </AppLayout>
    );
};

export default Dashboard;
