import React, { useEffect, useState } from 'react';
import { Drawer, Table, Button, InputNumber, Checkbox, Select, message } from 'antd';
import { useChallengeConfig } from '../../contexts/BlocklyConfigContext';
import { useObjectsApi } from '../../../../api/objects/objects';
import { TObstacle } from '../../../../models/objects/objects';
import { BlocklyPhaserConfig, ObjectConfig } from '../../types/BlocklyTypes';
import { getImagePath } from '../../../../utils/format/valueFormat';

const { Option } = Select;

interface AdminObjectsDrawerProps {
    visible: boolean;
    onClose: () => void;
}

/**
 * Affiche un Drawer pour configurer les objets (obstacles).
 * - Charger la liste BDD via getObstacles()
 * - Ajouter un obstacle dans config.objects
 * - Configurer x, y, width, height
 * - Configurer immovable/pickable/pushable
 * - Supprimer l’obstacle
 */
const AdminObjectsDrawer: React.FC<AdminObjectsDrawerProps> = ({
    visible,
    onClose,
}) => {
    const { config, setConfig } = useChallengeConfig();
    const { getObstacles } = useObjectsApi();

    // Liste des obstacles de la BDD
    const [dbObstacles, setDbObstacles] = useState<TObstacle[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedObstacleId, setSelectedObstacleId] = useState<number | null>(null);


    // Charger la liste BDD au montage
    useEffect(() => {
        setLoading(true);
        getObstacles()
            .then((resp) => {
                setDbObstacles(resp.data);
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, []);

    /**
     * Ajoute un nouvel obstacle dans la config
     */
    const handleAddObstacle = (dbId: number) => {
        setConfig((prev: BlocklyPhaserConfig) => {
            // Vérifier si déjà présent
            //   if (prev.objects.some((obj) => obj.dbId === dbId)) {
            //     message.warning('Cet obstacle est déjà dans la liste.');
            //     return prev;
            //   }

            // Trouver l'obstacle dans dbObstacles
            const dbObs = dbObstacles.find((o) => o.id === dbId);
            if (!dbObs) {
                message.error("Obstacle introuvable en BDD.");
                return prev;
            }
            const newObj: ObjectConfig = {
                adminMode: config.adminMode,
                id: `${dbObs.name}_${(prev.objects ?? []).length + 1}`,
                name: dbObs.name,
                x: 200,
                y: 200,
                width: 50,
                height: 50,
                immovable: true,
                pickable: false,
                pushable: false,
                assets: {
                    texture: dbObs.img || ''
                }
            };

            return {
                ...prev,
                objects: [...prev.objects, newObj],
            };
        });
    };

    /**
     * Mettre à jour un champ (x, y, width, height, immovable, pickable, pushable)
     */
    const handleUpdateObjectField = (
        objId: string,
        field: keyof ObjectConfig,
        value: any
    ) => {
        setConfig((prev: BlocklyPhaserConfig) => {
            const newObjects = prev.objects.map((o) => {
                if (o.id === objId) {
                    return { ...o, [field]: value };
                }
                return o;
            });
            return { ...prev, objects: newObjects };
        });
    };

    /**
     * Supprimer un obstacle de la config
     */
    const handleRemoveObject = (objId: string) => {
        setConfig((prev: BlocklyPhaserConfig) => ({
            ...prev,
            objects: prev.objects.filter((o) => o.id !== objId),
        }));
    };

    // Colonnes du tableau
    const columns = [
        {
            title: 'Image',
            dataIndex: 'img',
            key: 'image',
            width: 80,
            render: (_value: any, record: ObjectConfig) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={getImagePath(record.assets.texture)}
                        alt={record.name}
                        style={{ width: '30px', height: '30px', marginRight: '10px' }}
                    />
                </div>
            ),
        },
        {
            title: 'Nom',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'X',
            key: 'x',
            render: (_value: any, record: ObjectConfig) => (
                <InputNumber
                    min={0}
                    value={record.x}
                    onChange={(val) =>
                        handleUpdateObjectField(record.id, 'x', val ?? 0)
                    }
                />
            ),
            sorter: (a: ObjectConfig, b: ObjectConfig) => a.x - b.x,
        },
        {
            title: 'Y',
            key: 'y',
            render: (_value: any, record: ObjectConfig) => (
                <InputNumber
                    min={0}
                    value={record.y}
                    onChange={(val) =>
                        handleUpdateObjectField(record.id, 'y', val ?? 0)
                    }
                />
            ),
            sorter: (a: ObjectConfig, b: ObjectConfig) => a.y - b.y,
        },
        {
            title: 'Width',
            key: 'width',
            render: (_value: any, record: ObjectConfig) => (
                <InputNumber
                    min={0}
                    value={record.width}
                    onChange={(val) =>
                        handleUpdateObjectField(record.id, 'width', val ?? 50)
                    }
                />
            ),
            sorter: (a: ObjectConfig, b: ObjectConfig) => a.width - b.width,
        },
        {
            title: 'Height',
            key: 'height',
            render: (_value: any, record: ObjectConfig) => (
                <InputNumber
                    min={0}
                    value={record.height}
                    onChange={(val) =>
                        handleUpdateObjectField(record.id, 'height', val ?? 50)
                    }
                />
            ),
            sorter: (a: ObjectConfig, b: ObjectConfig) => a.height - b.height,
        },
        {
            title: 'Immovable',
            key: 'immovable',
            render: (_value: any, record: ObjectConfig) => (
                <Checkbox
                    checked={record.immovable}
                    onChange={(e) =>
                        handleUpdateObjectField(record.id, 'immovable', e.target.checked)
                    }
                />
            ),
        },
        {
            title: 'Pickable',
            key: 'pickable',
            render: (_value: any, record: ObjectConfig) => (
                <Checkbox
                    checked={record.pickable}
                    onChange={(e) =>
                        handleUpdateObjectField(record.id, 'pickable', e.target.checked)
                    }
                />
            ),
        },
        {
            title: 'Pushable',
            key: 'pushable',
            render: (_value: any, record: ObjectConfig) => (
                <Checkbox
                    checked={record.pushable}
                    onChange={(e) =>
                        handleUpdateObjectField(record.id, 'pushable', e.target.checked)
                    }
                />
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_value: any, record: ObjectConfig) => (
                <Button danger onClick={() => handleRemoveObject(record.id)}>
                    Supprimer
                </Button>
            ),
        },
    ];

    return (
        <Drawer
            title="Configuration des Objets"
            placement="left"
            width={1200}
            onClose={onClose}
            open={visible}
        >
            {/* Sélecteur pour ajouter un obstacle depuis la BDD */}
            <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
                <Select
                    placeholder="Choisir un obstacle à ajouter"
                    style={{ width: 300, marginRight: 8 }}
                    value={selectedObstacleId} // Maintenez l'ID sélectionné dans un état local
                    onChange={(value: number) => setSelectedObstacleId(value)} // Gère la sélection
                    loading={loading}
                >
                    {dbObstacles.map((obs) => (
                        <Option value={obs.id} key={obs.id}>
                            {obs.name}
                        </Option>
                    ))}
                </Select>
                <Button
                    type="primary"
                    onClick={() => {
                        if (selectedObstacleId) {
                            handleAddObstacle(selectedObstacleId); // Ajoute l'obstacle sélectionné
                        } else {
                            message.warning("Veuillez sélectionner un obstacle à ajouter.");
                        }
                    }}
                >
                    Ajouter
                </Button>
            </div>

            {/* Tableau des obstacles configurés */}
            <Table
                dataSource={config.objects}
                columns={columns}
                rowKey="id"
                pagination={false}
                style={{ maxHeight: '60vh', overflowY: 'auto' }}
            />

            <div style={{ marginTop: 16, textAlign: 'right' }}>
                <Button onClick={onClose}>Fermer</Button>
            </div>
        </Drawer>
    );
};

export default AdminObjectsDrawer;
