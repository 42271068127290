// src/AnimationView/engine/PhaserScene.ts

import Phaser from "phaser";
import { Character } from "./entities/Character";
import { GameObject } from "./entities/Object";
import { EventManager } from "./events/EventManager";
import { BlocklyPhaserConfig, WinCondition } from "../../types/BlocklyTypes";
import { WinConditionManager } from "./events/WinConditionManager";
import { getImagePath } from "../../../../utils/format/valueFormat";
import { TextObject } from "./entities/Text";

/**
 * Configuration spécifique à la PhaserScene.
 */
export interface PhaserSceneConfig {
  width: number;
  height: number;
  backgroundImage?: string; // Chemin d’une image de fond
  BlocklyPhaserConfig: BlocklyPhaserConfig;
  eventManager?: EventManager | null;
}

/**
 * Représente notre scène Phaser principale.
 * On y charge le background, on y ajoute les personnages / objets, etc.
 */
export class PhaserScene extends Phaser.Scene {
  private configData: PhaserSceneConfig;
  private eventManager?: EventManager | null;
  private onWin: () => void;

  // Entités logiques (personnages, objets)
  private characters: Character[] = [];
  private objects: GameObject[] = [];
  private texts: TextObject[] = [];

  // Conditions de victoire (configurées côté admin)
  private winConditions: WinCondition[] = [];

  // Manager qui va écouter les events et checker les winConditions
  private winConditionManager?: WinConditionManager;

  constructor(configData: PhaserSceneConfig, onWin: () => void) {
    super({ key: "MainScene" });
    this.configData = configData;
    this.eventManager = configData.eventManager;

    if (configData.BlocklyPhaserConfig) {
      this.winConditions = configData.BlocklyPhaserConfig.winConditions;
    }
    this.onWin = onWin;
  }

  /**
   * Pré-chargement des assets (images, etc.).
   */
  preload() {
    this.load.setCORS("anonymous");

    // Charger le background s’il existe
    if (this.configData.backgroundImage) {
      // 'bg' = key, this.configData.backgroundImage = URL ou chemin du fichier
      this.load.image("bg", getImagePath(this.configData.backgroundImage));
    }

    // Charger les images des personnages
    if (this.characters) {
      this.characters.forEach((char) => {
        this.load.image({
          key: char.id,
          url: getImagePath(char.image_url, true),
        });
      }); // Charger les images des personnages
    }
    // Charger les images des objets
    if (this.objects) {
      this.objects.forEach((obj) => {
        this.load.image({
          key: `${obj.id}`,
          url: getImagePath(obj.texture, true),
        });
      });
    }
  }

  /**
   * Création initiale de la scène (appelée une fois juste après preload).
   */
  create() {
    // ---------------------------------------------------------------------
    // 0) AFFICHAGE DU BACKGROUND
    // ---------------------------------------------------------------------
    if (this.configData.backgroundImage) {
      const bg = this.add.image(0, 0, "bg").setOrigin(0, 0);
      bg.displayWidth = this.configData.width;
      bg.displayHeight = this.configData.height;
    }

    // ---------------------------------------------------------------------
    // 1) CRÉATION DES SPRITES POUR LES PERSONNAGES (CHARACTERS)
    // ---------------------------------------------------------------------
    this.characters.forEach((char) => {
      const tex = this.textures.get(char.id);

      char.animations.forEach((anim) => {
        const framesArray = [];
        for (let i = 0; i < anim.frame_count; i++) {
          const frameKey = `${char.id}_${anim.state}_${i}`;
          tex.add(
            frameKey,
            0,
            anim.offset_x + i * anim.frame_width,
            anim.offset_y,
            anim.frame_width,
            anim.frame_height
          );
          framesArray.push({ key: char.id, frame: frameKey });
        }

        this.anims.create({
          key: `${anim.state}_${char.id}`,
          frames: framesArray,
          frameRate: anim.frame_rate,
          repeat: -1,
        });
      });

      // Méthode custom de ton Character : crée le sprite Phaser associé
      char.createPhaserSprite(this);
    });

    // ---------------------------------------------------------------------
    // 2) CRÉATION DES SPRITES POUR LES OBJETS
    // ---------------------------------------------------------------------
    this.objects.forEach((obj) => {
      obj.createPhaserSprite(this);
    });

    // ---------------------------------------------------------------------
    // 3) CRÉATION DES SPRITES POUR LES OBJETS
    // ---------------------------------------------------------------------
    this.texts.forEach((obj) => {
      obj.createPhaserText(this);
    });

    // ---------------------------------------------------------------------
    // 3) COLLISIONS : on prépare quelques variables et callbacks "usine"
    // ---------------------------------------------------------------------
    // a) Tableau de sprites pour les personnages
    const characterSprites = this.characters
      .map((char) => char.sprite)
      .filter(Boolean);

    // b) Paramètres globaux de "char vs char"
    const lastCollisionTimeRef = { value: 0 }; // pour char vs char
    const collisionInterval = 1000; // 1 seconde

    /**
     * Fabrique le "collideCallback" (char vs char),
     * appelé si processCallback a renvoyé true.
     */
    const createCollideCallbackCharChar = (
      charA: Character,
      charB: Character
    ) => {
      return (spriteA: any, spriteB: any) => {
        // → On vérifie si c'est la première frame de contact
        const bodyA = (spriteA as Phaser.Physics.Arcade.Sprite).body;
        const bodyB = (spriteB as Phaser.Physics.Arcade.Sprite).body;
        if (bodyA && bodyB) {
          const justCollidedA = bodyA.wasTouching.none && !bodyA.touching.none;
          const justCollidedB = bodyB.wasTouching.none && !bodyB.touching.none;

          if (justCollidedA || justCollidedB) {
            // => c’est un début de collision
            this.eventManager?.dispatchCollisionEvent(charA.id, charB.id);
          }
        }
      };
    };

    /**
     * Fabrique le "processCallback" (char vs char),
     * qui décide si la collision doit être prise en compte ou non (cooldown).
     */
    const createProcessCallbackCharChar = (
      lastCollisionTimeRef: any,
      interval: any
    ) => {
      return (spriteA: any, spriteB: any) => {
        const now = this.time.now;
        if (now - lastCollisionTimeRef.value > interval) {
          lastCollisionTimeRef.value = now;
          return true; // => exécute collideCallback
        }
        return false; // => ignore la collision
      };
    };

    // ---------------------------------------------------------------------
    // 4) COLLISION : CHAR vs CHAR
    // ---------------------------------------------------------------------
    for (let i = 0; i < characterSprites.length; i++) {
      for (let j = i + 1; j < characterSprites.length; j++) {
        const charA = this.characters[i];
        const charB = this.characters[j];
        if (charA.sprite && charB.sprite) {
          // On fabrique nos deux callbacks
          const collideCb = createCollideCallbackCharChar(charA, charB);
          const processCb = createProcessCallbackCharChar(
            lastCollisionTimeRef,
            collisionInterval
          );

          this.physics.add.collider(
            charA.sprite,
            charB.sprite,
            collideCb,
            processCb,
            this
          );
        }
      }
    }

    // ---------------------------------------------------------
    // 5) COLLISION / OVERLAP : CHAR vs OBJ
    // ---------------------------------------------------------

    /**
     * Crée le collideCallback pour un objet "immovable"
     * (on suppose qu'on veut juste animation "collision" sur le perso)
     */
    const createCollideCallbackImmovable = (
      charInstance: Character,
      objInstance: GameObject
    ) => {
      return (spriteA: any, spriteB: any) => {
        // Optionnel : on peut dispatcher un event custom de collision
        // si c'est un "début" de collision
        const bodyA = spriteA.body;
        const bodyB = spriteB.body;
        if (bodyA && bodyB) {
          const justCollidedA = bodyA.wasTouching.none && !bodyA.touching.none;
          const justCollidedB = bodyB.wasTouching.none && !bodyB.touching.none;
          if (justCollidedA || justCollidedB) {
            // => début collision
            this.eventManager?.dispatchCollisionEvent(
              charInstance.id,
              objInstance.id
            );
          }
        }

        // On joue l’animation "collision" sur le perso
        charInstance.playCollisionAnimation();
      };
    };

    /**
     * Crée le processCallback (cooldown) => on déclenche "collision" max 1x / interval
     */
    const createProcessCallbackCooldown = (
      lastCollisionRefObj: { value: number },
      intervalObj: number
    ) => {
      return (spriteA: any, spriteB: any) => {
        const now = this.time.now;
        if (now - lastCollisionRefObj.value > intervalObj) {
          lastCollisionRefObj.value = now;
          return true; // => exécute collideCallback
        }
        return false; // => ignore
      };
    };

    /**
     * Crée un "overlap" callback pour un objet pickable
     * => on teste la distance centre-à-centre
     */
    const createOverlapCallbackPickable = (
      charInstance: Character,
      objInstance: GameObject
    ) => {
      return (charSprite: any, objSprite: any) => {
        // Test de distance
        const dx = charSprite.x - objSprite.x;
        const dy = charSprite.y - objSprite.y;
        const dist = Math.sqrt(dx * dx + dy * dy);

        // Choix d'un seuil, ex. 5 px
        if (dist < 5) {
          // On ramasse l'objet
          const success = objInstance.pickUp();
          if (success) {
            charInstance.pickUpObject(objInstance);
            this.eventManager?.emit(
              "pickupEvent",
              charInstance.id,
              objInstance.id
            );
          }
        }
      };
    };

    // ---------------------------------------------------------
    // Parcourir tous les persos et tous les objets
    // ---------------------------------------------------------
    this.characters.forEach((charInstance) => {
      this.objects.forEach((objInstance) => {
        if (!charInstance.sprite || !objInstance.sprite) return;

        // 1) S'il est immovable => on met un "collider" (pour bloquer physiquement le perso)
        if (objInstance.immovable) {
          const lastCollisionTimeRefObj = { value: 0 };
          const collisionIntervalObj = 500; // ms cooldown

          const collideCb = createCollideCallbackImmovable(
            charInstance,
            objInstance
          );
          const processCb = createProcessCallbackCooldown(
            lastCollisionTimeRefObj,
            collisionIntervalObj
          );

          this.physics.add.collider(
            charInstance.sprite,
            objInstance.sprite,
            collideCb,
            processCb,
            this
          );
        }

        // 2) S'il est pickable => on utilise un "overlap" + test distance
        if (objInstance.pickable) {
          const overlapCb = createOverlapCallbackPickable(
            charInstance,
            objInstance
          );

          // On peut mettre un "processCallback" si on veut limiter la fréquence
          // mais souvent on laisse undefined
          this.physics.add.overlap(
            charInstance.sprite,
            objInstance.sprite,
            overlapCb,
            undefined,
            this
          );
        }
      });
    });

    // ---------------------------------------------------------------------
    // 6) COLLISION : OBJ vs OBJ (simple, pas de processCallback)
    // ---------------------------------------------------------------------
    /**
     * Fabrique le collideCallback pour obj vs obj
     */
    const createCollideCallbackObjObj = (
      objA: GameObject,
      objB: GameObject
    ) => {
      return (spriteA: any, spriteB: any) => {
        const bodyA = (spriteA as Phaser.Physics.Arcade.Sprite).body;
        const bodyB = (spriteB as Phaser.Physics.Arcade.Sprite).body;
        if (bodyA && bodyB) {
          const justCollidedA = bodyA.wasTouching.none && !bodyA.touching.none;
          const justCollidedB = bodyB.wasTouching.none && !bodyB.touching.none;

          if (justCollidedA || justCollidedB) {
            // => c’est un “début” de collision
            this.eventManager?.dispatchCollisionEvent(objA.id, objB.id);
          }
        }
      };
    };

    // Parcours des objets deux à deux
    for (let i = 0; i < this.objects.length; i++) {
      for (let j = i + 1; j < this.objects.length; j++) {
        const objA = this.objects[i];
        const objB = this.objects[j];
        if (objA.sprite && objB.sprite) {
          const collideCb = createCollideCallbackObjObj(objA, objB);
          this.physics.add.collider(objA.sprite, objB.sprite, collideCb);
        }
      }
    }

    // ---------------------------------------------------------------------
    // 7) WIN CONDITION MANAGER (facultatif si pas de conditions)
    // ---------------------------------------------------------------------
    if (this.winConditions.length > 0 && this.eventManager) {
      this.winConditionManager = new WinConditionManager(
        this.winConditions,
        this,
        this.eventManager
      );

      // Écouter l’event "win"
      this.eventManager.on("win", () => {
        // a) Mettre le(s) personnage(s) en animation “winning”
        this.characters.forEach((char) => {
          char.playWinAnimation();
        });
        // b) Action perso
        this.onWin();
      });
    }
  }

  /**
   * Boucle de mise à jour (appelée ~60 fps).
   * On met à jour la position des sprites en fonction de x, y, etc.
   */
  update(time: number, delta: number) {
    // Mettre à jour nos persos
    this.characters.forEach((char) => {
      char.updatePhaserSprite();
    });
    // Mettre à jour nos objets
    this.objects.forEach((obj) => {
      obj.updatePhaserSprite();
    });

    // 2) Vérifier les conditions de victoire (ex: "characterAtPosition") en continu
    this.winConditionManager?.update();
  }

  /**
   * Ajoute un personnage dans la scène (avant ou après le create).
   */
  public addCharacter(character: Character) {
    this.characters.push(character);
  }

  /**
   * Ajoute un objet dans la scène (avant ou après le create).
   */
  public addObject(obj: GameObject) {
    this.objects.push(obj);
  }

  /**
   * Ajoute un texte dans la scène (avant ou après le create).
   */
  public addText(obj: TextObject) {
    this.texts.push(obj);

    // Si la scène est déjà "active", on crée immédiatement le GameObject text
    if (this.scene?.isActive()) {
      obj.createPhaserText(this);
    }
  }

  public getTextObjectById(id: string): TextObject | undefined {
    return this.texts.find((txt) => txt.id === id);
  }

  public getCharacterById(id: string): Character | undefined {
    return this.characters.find((char) => char.id === id);
  }

  public getCharacterFirst(): Character | undefined {
    return this.characters[0];
  }

  public cleanup() {
    // 1) Vider le WinConditionManager s’il existe
    if (this.winConditionManager) {
      // Pas grand-chose à faire à part potentiellement
      // un “this.winConditionManager.destroy()”
      // si tu veux mettre un .destroy() dedans (par ex. pour supprimer des timers).
      // Ex.:
      // this.winConditionManager.destroy();
    }

    // 2) Retirer tous les “on('xxx')” ajoutés sur this.eventManager
    if (this.eventManager) {
      // Si tu as ajouté un “this.eventManager.on('win', callback)”
      // tu peux faire un “this.eventManager.off('win', callback)”
      // ou “clearAllCollisionListeners()” pour la collision map.
      // this.eventManager.clearAllCollisionListeners();
      // this.eventManager.clearAllGenericListeners();
      // S’il existe une méthode “off(eventName, callback)”, utilise-la
      // pour enlever “win” ou “collisionEvent”.
      // On ne la voit pas dans EventManager, mais tu pourrais l’implémenter comme “off()”
    }

    // 3) Optionnel : retirer les collisions, etc.
    // (Phaser s’en occupe en grande partie quand on détruit le game)
  }

  public emit(eventName: string, ...args: any[]) {
    if (!this.eventManager) return;
    this.eventManager.emit(eventName, ...args);
  }
}
