// src/reducers/gameReducer.ts

import { GameState } from './GameState';
import { GameAction } from './GameAction';



export function gameReducer(state: GameState, action: GameAction): GameState {
    switch (action.type) {
        case 'INIT_ALL_CHARACTERS':
            return {
                ...state,
                initialCharacters: action.payload,
                characters: action.payload,
            };
        case 'UPDATE_CHARACTER':
            const { id, newX, newY, newState, newScale, newSpeed } = action.payload;
            return {
                ...state,
                characters: state.characters.map((character) =>
                    character.id === id
                        ? {
                            ...character,
                            x: newX ?? character.x,
                            y: newY ?? character.y,
                            state: newState ?? character.state,
                            scale: newScale ?? character.scale,
                            speed: newSpeed ?? character.speed,

                        }
                        : character
                ),
            };
        case 'SPEAK_CHARACTER':
            return {
                ...state,
                characters: state.characters.map((character) =>
                    character.id === action.payload.id ?
                        {
                            ...character,
                            speech: action.payload.speech
                        }
                        : character
                )
            }
        case 'RESET_GAME':
            return {
                ...state,
                characters: state.initialCharacters,
                scene: state.initialScene,
                target: state.initialTarget,
            };

        case 'SET_SCENE_BACKGROUND':
            return {
                ...state,
                scene: {
                    ...state.scene,
                    background: action.payload,
                },
            };

        case 'UPDATE_SCENE':
            return {
                ...state,
                scene: action.payload,
            };
        case 'UPDATE_TARGET':
            return {
                ...state,
                target: action.payload,
            };

    }
}