// src/blocks/move/setSpeed.ts

import * as Blockly from 'blockly';
import { javascriptGenerator } from 'blockly/javascript';
import i18next from 'i18next';
import { BlocklyFields } from '../BlocklyFields';

export function initSetSpeedBlock() {
    Blockly.Blocks[BlocklyFields.SET_SPEED] = {
        init: function () {
            this.jsonInit({
                "type": BlocklyFields.SET_SPEED,
                "message0": '%1 ' + i18next.t('blockly.speed.message', 'Définir la vitesse sur') + ' %2',
                "args0": [
                    {
                        "type": "field_image",
                        "src": "/images/blocks/speed.png", // Lien vers votre icône (modifiez selon votre besoin)
                        "width": 16,   // Largeur de l'image
                        "height": 16,  // Hauteur de l'image
                    },
                    {
                        "type": "field_dropdown",
                        "name": "SPEED_LEVEL",
                        "options": [
                            ["1", "1"],
                            ["2", "2"],
                            ["3", "3"]
                        ]
                    }
                ],
                "previousStatement": null,
                "nextStatement": null,
                "colour": '#FF5733',
                "tooltip": i18next.t('blockly.speed.tooltip', 'Définit la vitesse du personnage (1, 2, ou 3).'),
                "helpUrl": ""
            });
        },
    };

    javascriptGenerator.forBlock[BlocklyFields.SET_SPEED] = function (block, generator) {
        // Récupère le niveau de vitesse sélectionné
        const speedLevel = block.getFieldValue('SPEED_LEVEL');
        // Génère le code pour appeler la fonction setSpeed avec le niveau de vitesse
        return `setSpeed(${speedLevel});\n`
    }
}

