// defineInputBlocks.ts
import * as Blockly from 'blockly/core';
import { javascriptGenerator } from 'blockly/javascript';


/**
 * Déclare / redéfinit quelques blocs "input" (valeurs) :
 *  - math_number
 *  - text
 *  - text_join
 *  - logic_boolean
 *  - angle_field_block
 *  - color_picker_block
 *
 * Appelle defineInputBlocks() avant d'initialiser le workspace Blockly.
 */
export function defineMathBlocks() {

    /***********************************************
     * 1) math_number (nombre)
     ***********************************************/
    // S'il est déjà défini par Blockly, on peut écraser sa définition ou la laisser telle quelle.
    // Exemple d'une version minimaliste personnalisée :
    Blockly.Blocks['math_number'] = {
        init: function () {
            this.appendDummyInput()
                .appendField(new Blockly.FieldNumber(0), 'NUM');
            this.setOutput(true, 'Number');
            this.setColour(270);
            this.setTooltip('Un nombre');
            this.setHelpUrl('');
        },
    };

    javascriptGenerator.forBlock['math_number'] = function (block, generator) {
        const code = block.getFieldValue('NUM') || '0';
        // Bloc expression => on renvoie [code, ordre]
        return [code, 0];
    };


}
