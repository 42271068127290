import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';

interface ContentViewerProps {
    html: string;
    css: string;
}

const ContentViewer: React.FC<ContentViewerProps> = ({ html, css }) => {
    const [htmlContent, setHtmlContent] = useState('');
    const [cssContent, setCssContent] = useState('');

    useEffect(() => {
        // Configuration DOMPurify pour autoriser les iframes et les attributs nécessaires
        const config: DOMPurify.Config = {
            // On ajoute la balise iframe si elle n'est pas déjà autorisée
            ADD_TAGS: ['iframe'],

            // On ajoute les attributs à autoriser sur cette balise
            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],

            // Facultatif : restreindre l'URL pour éviter d'autres iframes malveillantes
            ALLOWED_URI_REGEXP: /^(?:https?:\/\/)?(www\.)?(youtube\.com\/embed\/|youtu\.be\/).*/i,
        };

        // Nettoyer le HTML avec la configuration
        const sanitizedHtml = DOMPurify.sanitize(html, config);

        setHtmlContent(sanitizedHtml);
        setCssContent(css);
    }, [html, css]);

    return (
        <div style={{ backgroundColor: '#fff' }}>
            {/* Injecter le CSS dans une balise <style> */}
            <style>{cssContent}</style>

            {/* Afficher le contenu HTML purifié */}
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
    );
};

export default ContentViewer;
