// src/components/BlocklyWorkspace/blocklyConfig.ts

import { Blocks } from "blockly/core";
// Si vous avez besoin des blocs de base (if, loops...) : import 'blockly/blocks';
import { javascriptGenerator } from "blockly/javascript";
import * as Blockly from "blockly";

import { FieldAngle, registerFieldAngle } from "@blockly/field-angle";
import { CharacterConfig } from "../../types/BlocklyTypes";

// Enregistrer les champs spéciaux
registerFieldAngle();

export function defineMovementsBlocks(characters: CharacterConfig[]) {
  // ==========================
  // Mouvements
  // ==========================
  Blocks["walk_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(new Blockly.FieldNumber(10, 1, 100), "distance")
        .appendField("by unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique d'une certaine distance."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_with_character"] = function (block: any) {
    const distance = block.getFieldValue("distance") || 0;
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            await walkCharacter('${character}', ${distance});
        \n`;
  };

  // Walk Right with Character
  Blocks["walk_right_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("right")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers la droite.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_right_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 0);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Left with Character
  Blocks["walk_left_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("left")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers la gauche.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_left_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 180);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Up with Character
  Blocks["walk_up_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("up")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers le haut.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_up_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 90);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Down with Character
  Blocks["walk_down_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("down")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers le bas.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_down_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 270);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Right Up With Character
  Blocks["walk_right_up_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("right up")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le haut à droite."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_right_up_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 45);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Right Down With Character
  Blocks["walk_right_down_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("right down")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le bas à droite."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_right_down_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 315);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Left Up With Character
  Blocks["walk_left_up_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("left up")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le haut à gauche."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_left_up_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 135);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Left Down With Character
  Blocks["walk_left_down_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("left down")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le bas à gauche."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_left_down_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', 225);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Forward With Character
  Blocks["walk_forward_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk forward")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique d'une unité vers l'avant."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_forward_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;
    return `
        highlightBlock('${blockId}');
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Backward With Character
  Blocks["walk_backward_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk backward")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Recule un personnage spécifique d'une unité.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_backward_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;
    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', 'BACKWARD', 0);
        await walkCharacter('${character}', 1);
    \n`;
  };

  // Walk Choice With Character
  Blocks["walk_choice_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk ")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Permet de choisir si un personnage marche en avant ou en arrière."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_choice_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const direction = block.getFieldValue("direction");
    const blockId = block.id;

    if (direction === "FORWARD") {
      return `
            highlightBlock('${blockId}');
            await walkCharacter('${character}', 1);
        \n`;
    } else if (direction === "BACKWARD") {
      return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', 'BACKWARD', 0);
            await walkCharacter('${character}', 1);
        \n`;
    }
    return "";
  };

  // Walk Choice By Unit With Character
  Blocks["walk_choice_by_unit_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("walk")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        )
        .appendField("by")
        .appendField(new Blockly.FieldNumber(0, 1, 1000), "distance")
        .appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Permet de choisir une direction et la distance à parcourir pour un personnage."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["walk_choice_by_unit_with_character"] =
    function (block: any) {
      const character = block.getFieldValue("CHARACTER_ID") || "";
      const direction = block.getFieldValue("direction");
      const distance = block.getFieldValue("distance");
      const blockId = block.id;

      if (direction === "FORWARD") {
        return `
            highlightBlock('${blockId}');
            await walkCharacter('${character}', ${distance});
        \n`;
      } else if (direction === "BACKWARD") {
        return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', 'BACKWARD', 0);
            await walkCharacter('${character}', ${distance});
        \n`;
      }
      return "";
    };

  // ==========================
  // ==========================

  Blocks["jump_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(new Blockly.FieldNumber(1, 1, 10), "distance")
        .appendField("by unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Fait sauter un personnage spécifique d'une certaine distance."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_with_character"] = function (block: any) {
    const distance = block.getFieldValue("distance") || 0;
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            await jumpCharacter('${character}', ${distance});
        \n`;
  };

  Blocks["jump_right_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump right")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers la droite.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_right_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 0);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Left with Character
  Blocks["jump_left_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump left")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers la gauche.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_left_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 180);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Up with Character
  Blocks["jump_up_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump up")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers le haut.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_up_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 90);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Down with Character
  Blocks["jump_down_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump down")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Avance un personnage spécifique vers le bas.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_down_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 270);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Right Up With Character
  Blocks["jump_right_up_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump right up")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le haut à droite."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_right_up_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 45);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Right Down With Character
  Blocks["jump_right_down_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump right down")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le bas à droite."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_right_down_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 315);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Left Up With Character
  Blocks["jump_left_up_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump left up")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le haut à gauche."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_left_up_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 135);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Left Down With Character
  Blocks["jump_left_down_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump left down")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique en diagonale vers le bas à gauche."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_left_down_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', 225);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Forward With Character
  Blocks["jump_forward_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump forward")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique d'une unité vers l'avant."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_forward_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;
    return `
            highlightBlock('${blockId}');
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Backward With Character
  Blocks["jump_backward_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump backward")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip("Recule un personnage spécifique d'une unité.");
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_backward_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;
    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', 'BACKWARD', 0);
            await jumpCharacter('${character}', 1);
        \n`;
  };

  // jump Choice With Character
  Blocks["jump_choice_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump ")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Permet de choisir si un personnage marche en avant ou en arrière."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_choice_with_character"] = function (
    block: any
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const direction = block.getFieldValue("direction");
    const blockId = block.id;

    if (direction === "FORWARD") {
      return `
                highlightBlock('${blockId}');
                await jumpCharacter('${character}', 1);
            \n`;
    } else if (direction === "BACKWARD") {
      return `
                highlightBlock('${blockId}');
                turnCharacter('${character}', 'BACKWARD', 0);
                await jumpCharacter('${character}', 1);
            \n`;
    }
    return "";
  };

  // jump Choice By Unit With Character
  Blocks["jump_choice_by_unit_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("jump")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(
          new Blockly.FieldDropdown([
            ["forward", "FORWARD"],
            ["backward", "BACKWARD"],
          ]),
          "direction"
        )
        .appendField("by")
        .appendField(new Blockly.FieldNumber(0, 1, 1000), "distance")
        .appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Permet de choisir une direction et la distance à parcourir pour un personnage."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["jump_choice_by_unit_with_character"] =
    function (block: any) {
      const character = block.getFieldValue("CHARACTER_ID") || "";
      const direction = block.getFieldValue("direction");
      const distance = block.getFieldValue("distance");
      const blockId = block.id;

      if (direction === "FORWARD") {
        return `
                highlightBlock('${blockId}');
                await jumpCharacter('${character}', ${distance});
            \n`;
      } else if (direction === "BACKWARD") {
        return `
                highlightBlock('${blockId}');
                turnCharacter('${character}', 'BACKWARD', 0);
                await jumpCharacter('${character}', ${distance});
            \n`;
      }
      return "";
    };

  // ==========================
  // ==========================

  Blocks["turn_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("turn")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField("by")
        .appendField(new FieldAngle(90), "angle")
        .appendField("°");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Fait tourner un personnage spécifique d’un certain angle."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["turn_with_character"] = function (block: any) {
    const angle = block.getFieldValue("angle") || 0;
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
            highlightBlock('${blockId}');
            turnCharacter('${character}', '', ${angle});
        \n`;
  };

  // turn_right_or_left_with_character
  Blocks["turn_right_or_left_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("turn")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(
          new Blockly.FieldDropdown([
            ["right", "RIGHT"],
            ["left", "LEFT"],
          ]),
          "direction"
        );
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Tourne un personnage spécifique de 90 degrés dans la direction choisie."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["turn_right_or_left_with_character"] = function (
    block
  ) {
    const direction = block.getFieldValue("direction");
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '${direction}', 90);
    \n`;
  };

  // choose_turn_angle_with_character
  Blocks["choose_turn_angle_with_character"] = {
    init: function () {
      this.appendDummyInput()
        .appendField("turn")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField(
          new Blockly.FieldDropdown([
            ["right", "RIGHT"],
            ["left", "LEFT"],
          ]),
          "direction"
        )
        .appendField("by")
        .appendField(new FieldAngle(90), "angle")
        .appendField("°");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Tourne un personnage spécifique à gauche ou à droite par un certain angle."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["choose_turn_angle_with_character"] = function (
    block
  ) {
    const direction = block.getFieldValue("direction");
    const angle = block.getFieldValue("angle");
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '${direction}', ${angle});
    \n`;
  };

  // variable_walk_with_character
  Blocks["variable_walk_with_character"] = {
    init: function () {
      this.appendValueInput("distance")
        .setCheck("Number")
        .appendField("walk")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField("by");
      this.appendDummyInput().appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Avance un personnage spécifique d'une certaine distance."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["variable_walk_with_character"] = function (
    block
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const distance =
      javascriptGenerator.valueToCode(block, "distance", 0) || "0";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        await walkCharacter('${character}', ${distance});
    \n`;
  };

  // variable_turn_with_character
  Blocks["variable_turn_with_character"] = {
    init: function () {
      this.appendValueInput("angle")
        .setCheck("Number")
        .appendField("turn")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField("by");
      this.appendDummyInput().appendField("degree(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Fait tourner un personnage spécifique d’un certain angle."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["variable_turn_with_character"] = function (
    block
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const angle = javascriptGenerator.valueToCode(block, "angle", 0) || "0";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        turnCharacter('${character}', '', ${angle});
    \n`;
  };

  // variable_jump_with_character
  Blocks["variable_jump_with_character"] = {
    init: function () {
      this.appendValueInput("height")
        .setCheck("Number")
        .appendField("jump")
        .appendField(
          new Blockly.FieldDropdown(
            characters.length
              ? characters.map((character) => [character.name, character.id])
              : [["No Characters Available", "NO_CHARACTER"]]
          ),
          "CHARACTER_ID"
        )
        .appendField("by");
      this.appendDummyInput().appendField("unit(s)");
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(160);
      this.setTooltip(
        "Fait sauter un personnage spécifique d’une certaine hauteur."
      );
      this.setHelpUrl("");
    },
  };

  javascriptGenerator.forBlock["variable_jump_with_character"] = function (
    block
  ) {
    const character = block.getFieldValue("CHARACTER_ID") || "";
    const height = javascriptGenerator.valueToCode(block, "height", 0) || "0";
    const blockId = block.id;

    return `
        highlightBlock('${blockId}');
        await jumpCharacter('${character}', ${height});
    \n`;
  };
}
