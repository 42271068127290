import React, { useEffect, useState } from 'react';
import AppLayout from '../../../../components/layout/AppLayout';
import { message } from 'antd';
import { useLocation } from 'react-router-dom';
import { TLesson } from '../../../../models/courses/lesson';
import { useCourseApi } from '../../../../api/courses/courses';
import AdminConfigurator from '../../../../components/Blockly/root/admin/AdminConfigurator';

const LessonBlocklyPhaserAdmin: React.FC = () => {
    const location = useLocation();
    const { getLessonDetailsById } = useCourseApi();

    const lesson: TLesson = location.state?.lesson;
    const [editingLesson, setEditingLesson] = useState<TLesson | undefined>(lesson);
    const [loading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);

        if (lesson) {
            getLessonDetailsById(lesson.id)
                .then((results) => {
                    const lessonData = results;
                    setEditingLesson(lessonData.data);
                })
                .catch((err) => {
                    message.error(err.response.data ?? err.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
        // eslint-disable-next-line
    }, [lesson]);



    return (
        <AppLayout title={lesson.name} loading={loading}>
            <AdminConfigurator editingLesson={editingLesson} />
        </AppLayout>
    );
};

export default LessonBlocklyPhaserAdmin;
