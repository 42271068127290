// src/blocks/move/move_with_distance.ts

import * as Blockly from "blockly";
import { javascriptGenerator } from "blockly/javascript";
import { BlocklyFields } from "../../BlocklyFields";
import i18next from "i18next";

export function initMoveWithDistanceBlock() {
  Blockly.Blocks[BlocklyFields.MOVE_WITH_DISTANCE] = {
    init: function () {
      this.jsonInit({
        type: BlocklyFields.MOVE_WITH_DISTANCE,
        message0: '%1' + i18next.t('text.Move', 'Move') + '%2' + i18next.t('text.by', 'by') + '%3 pixels',
        args0: [
          {
            type: "field_image",
            src: "/images/blocks/move.png", // Icône de mouvement
            width: 16,
            height: 16,
          },
          {
            type: "field_dropdown",
            name: "MOVE_ACTION",
            options: [
                [
                  i18next.t(
                    "blockly.move.forward.dropdown",
                    "Move forward"
                  ),
                  "FORWARD",
                ],
                [
                  i18next.t(
                    "blockly.move.backward.dropdown",
                    "Move backward"
                  ),
                  "BACKWARD",
                ],
              ],
          },
          {
            type: "field_number",
            name: "DISTANCE",
            value: 50, // Valeur par défaut
            min: 1, // Minimum
            precision: 1, // Pas
          },
        ],
        previousStatement: null,
        nextStatement: null,
        colour: "#FFAB19",
        tooltip: i18next.t(
          "blockly.move.withDistance.tooltip", "Move forward or backward by a specific distance"),
        helpUrl: "",
      });
    },
  };

  javascriptGenerator.forBlock[BlocklyFields.MOVE_WITH_DISTANCE] = function (
    block,
    generator
  ) {
    const action = block.getFieldValue("MOVE_ACTION");
    const distance = block.getFieldValue("DISTANCE");

    if (action === "FORWARD") {
      return `await moveForwardWithDistance(${distance}, false);\n`;
    } else if (action === "BACKWARD") {
      return `await moveBackwardWithDistance(${distance}, false);\n`;
    }
    return "";
  };
}
