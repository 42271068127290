// src/AnimationView/engine/entities/Object.ts

import Phaser from 'phaser';
import { ObjectConfig } from '../../../types/BlocklyTypes';



export type ObjectState = 'idle' | 'carried' | 'thrown' | 'pushed' | 'obstacle';

export class GameObject {
    private adminMode: boolean;
    public id: string;
    private scene?: Phaser.Scene;
    public x: number;
    public y: number;
    public width: number;
    public height: number;
    public immovable: boolean;
    public pickable: boolean;
    public pushable: boolean;

    public currentState: ObjectState = 'idle';

    // Sprite Phaser
    public sprite?: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;

    public texture: string;

    constructor(config: ObjectConfig) {
        this.adminMode = config.adminMode;
        this.id = config.id;
        this.x = config.x;
        this.y = config.y;
        this.width = config.width;
        this.height = config.height;

        this.immovable = config.immovable ?? false;
        this.pickable = config.pickable ?? false;
        this.pushable = config.pushable ?? false;

        this.texture = config.assets.texture;

        if (this.immovable) {
            this.currentState = 'obstacle';
        }
    }

    /**
     * Crée l’image Phaser dans la scène (ex: un rocher).
     */
    public createPhaserSprite(scene: Phaser.Scene) {
        this.scene = scene;

        // const idleKey = `${this.id}_idle`;
        // this.sprite = scene.physics.add.sprite(this.x, this.y, idleKey);
        this.sprite = scene.physics.add.sprite(this.x, this.y, this.id);
        // this.sprite.setFrame(`${this.id}_idle_0`);
        this.sprite.setOrigin(0.5, 0.5);
        this.sprite.setDisplaySize(this.width, this.height);

        // Activer la collision avec les bords du monde
        this.sprite.setCollideWorldBounds(true);
        this.sprite.setImmovable(this.immovable);

        this.setAnimation("idle");

        this.sprite.setDamping(true);
        this.sprite.setDrag(0.5);

        if (this.adminMode) {
            const idText = scene.add.text(
                this.sprite.x,
                this.sprite.y - 15,
                this.id,
                {
                    fontSize: "12px",
                    color: "#ffffff",
                    backgroundColor: "#00000088",
                }
            );
            idText.setOrigin(0.5, 1); // ancrer le texte juste au-dessus du sprite

            // Pour que le texte suive le sprite en temps réel
            scene.events.on("update", () => {
                if (this.sprite) {
                    idText.x = this.sprite.x;
                    idText.y = this.sprite.y - this.sprite.displayHeight / 2 - 2;
                }
            });
        }
    }

    /**
     * Met à jour la position du sprite dans scene.update().
     */
    public updatePhaserSprite() {
        if (!this.sprite) return;
        // Synchroniser les coordonnées logiques avec la position physique
        this.x = this.sprite.x;
        this.y = this.sprite.y;
    }

    /**
     * Pousser l’objet (s’il est pushable).
     */
    public push(dx: number, dy: number) {
        if (!this.pushable || this.immovable) {
            return;
        }
        this.currentState = 'pushed';

        this.x += dx;
        this.y += dy;
        // collisions ? => si collision, revert ?

        this.currentState = 'idle';
    }

    /**
     * Ramasser l’objet (si pickable).
     */
    public pickUp(): boolean {
        if (!this.pickable) return false;
        this.currentState = "carried";

        if (this.sprite && this.scene) {
            const startY = this.sprite.y;

            // Petit mouvement vers le haut + disparition (alpha = 0)
            this.scene.tweens.add({
                targets: this.sprite,
                duration: 300,        // durée de 500ms (modifie si besoin)
                y: startY - 30,       // monte de 20 px
                alpha: 0,             // disparaît (de 1 à 0 automatiquement)
                ease: "Quad.easeIn",  // ajuster l’effet (accélération) si besoin
                onComplete: () => {
                    // Masquer l’objet totalement
                    this.sprite!.visible = false;
                    // Désactiver le body physique s’il y en a un
                    if (this.sprite!.body) {
                        this.sprite!.body.enable = false;
                    }
                },
            });
        }

        return true;
    }




    /**
     * Déposer l’objet (aux pieds du perso, par ex).
     */
    public drop(newX: number, newY: number): boolean {
        if (this.currentState !== 'carried') {
            return false;
        }
        this.x = newX;
        this.y = newY;
        if (this.sprite) {
            this.sprite.visible = true;
        }
        this.currentState = 'idle';
        return true;
    }

    /**
     * Jeter l’objet (throw) : le projeter sur un angle / distance.
     */
    public throw(angleDegree: number, distancePx: number): boolean {
        if (this.currentState !== 'carried') {
            return false;
        }
        this.currentState = 'thrown';

        if (this.sprite) {
            this.sprite.visible = true;
        }

        const angleRad = (Math.PI / 180) * angleDegree;
        const dx = Math.cos(angleRad) * distancePx;
        const dy = Math.sin(angleRad) * distancePx;

        this.x += dx;
        this.y += dy;

        // collisions ?
        this.currentState = 'idle';
        return true;
    }

    private setAnimation(state: ObjectState) {
        this.currentState = state;
        if (!this.sprite) return;

        switch (state) {
            case 'idle':
                this.sprite.setTexture(`${this.id}`);
                break;
            default:
                this.sprite.setTexture(`${this.id}_idle`);

        }
    }

    /**
     * Par ex, détruire l’objet si on ne l’utilise plus.
     */
    public destroy() {
        if (this.sprite) {
            this.sprite.destroy();
        }
    }
}
