import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input, message, Radio, Card, Typography, InputNumber, Space, Tag } from 'antd';
import { TLessonAnswerUser, TLessonQuestion } from '../../models/courses/lesson';
import { useReferential } from '../../context/ReferentialContext';
import { CODIFICATION_QUESTION_TYPE } from '../../constant/codification';
import { useUserApi } from '../../api/user/userApp';

const { Title, Text } = Typography;

interface QuestionViewerProps {
  question: TLessonQuestion;
  onNextLesson: () => void;
}

const QuestionViewer: React.FC<QuestionViewerProps> = ({ question, onNextLesson }) => {
  const { getCodificationById } = useReferential();
  const { getQuestionAnswersByQuestionId, saveQuestionAnswers } = useUserApi();
  const [onSave, setOnSave] = useState<boolean>(false);
  const [answers, setAnswers] = useState<TLessonAnswerUser[]>([]);
  const [blockTime, setBlockTime] = useState<number | null>(null);
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null);
  const [remainingAttempts, setRemainingAttempts] = useState<number>(0);
  const [incorrectAttempts, setIncorrectAttempts] = useState<number>(0);


  const questionType = getCodificationById(question.question_type_id);

  // Calcul du nombre maximum de tentatives
  const calculateMaxAttempts = (totalAnswers: number, correctAnswers: number): number =>
    correctAnswers !== 1 ? Math.max(1, Math.ceil(totalAnswers / correctAnswers)) : 1;


  useEffect(() => {
    // Récupérer les réponses de la question à partir de l'API
    getQuestionAnswersByQuestionId(question.id)
      .then((resp) => {
        setAnswers(resp.data ?? []);
      })
      .catch((err) => {
        message.error(err.response.data ?? err.message);
      })
      .finally(() => {
        // Récupérer les tentatives restantes depuis le localStorage
        const storedAttempts = localStorage.getItem(`remainingAttempts_${question.id}`);
        if (storedAttempts && parseInt(storedAttempts, 10) !== 0) {
          // Si les tentatives sont stockées et différentes de 0, les définir
          setRemainingAttempts(parseInt(storedAttempts, 10));
        } else {
          // Sinon, calculer et définir les tentatives maximales
          const maxAttempts = calculateMaxAttempts(
            question.answers.length,
            question.answers.filter((a) => a.is_correct).length
          );
          setRemainingAttempts(maxAttempts);
          // Sauvegarder les tentatives maximales dans le localStorage
          localStorage.setItem(`remainingAttempts_${question.id}`, maxAttempts.toString());
        }
      });
    // eslint-disable-next-line
  }, [question]);


  // Initialisation du blocage
  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const storedBlockTime = localStorage.getItem(`blockTime_${question.id}`);
    const storedAttempts = localStorage.getItem(`remainingAttempts_${question.id}`);
    const maxAttempts = calculateMaxAttempts(
      question.answers.length,
      question.answers.filter((a) => a.is_correct).length
    );

    // Gestion du blocage
    if (storedBlockTime) {
      const remainingTime = parseInt(storedBlockTime, 10) - currentTime;
      if (remainingTime > 0) {
        setBlockTime(parseInt(storedBlockTime, 10));
        setTimeRemaining(remainingTime);
      } else {
        // Si le blocage a expiré
        localStorage.removeItem(`blockTime_${question.id}`);
      }
    }

    // Gestion des tentatives restantes
    if (storedAttempts !== null) {
      setRemainingAttempts(parseInt(storedAttempts, 10));
    } else {
      setRemainingAttempts(maxAttempts);
      localStorage.setItem(`remainingAttempts_${question.id}`, maxAttempts.toString());
    }
    const storedIncorrectAttempts = localStorage.getItem(`incorrectAttempts_${question.id}`);
    if (storedIncorrectAttempts) {
      setIncorrectAttempts(parseInt(storedIncorrectAttempts, 10));
    }
    if (incorrectAttempts > 0) {
      localStorage.setItem(`incorrectAttempts_${question.id}`, incorrectAttempts.toString());
    }

    // Gestion du timer pour décrémenter `timeRemaining`
    const timer = setInterval(() => {
      if (timeRemaining) {
        setTimeRemaining((prev) => (prev && prev > 0 ? prev - 1 : null));
        if (timeRemaining === 1) {
          setBlockTime(null);
          localStorage.removeItem(`blockTime_${question.id}`);
          setRemainingAttempts(maxAttempts);
          localStorage.setItem(`remainingAttempts_${question.id}`, maxAttempts.toString());
        }
      }
    }, 1000);

    // Nettoyage de l'intervalle
    return () => clearInterval(timer);

    // eslint-disable-next-line
  }, [timeRemaining, question]);


  const handleAnswersRadioButton = (idAnswer: number) => {
    const answerSelected = question.answers.find((e) => e.id === idAnswer);
    if (answerSelected) {
      const newAnswer: TLessonAnswerUser = {
        id: 0,
        answer_id: answerSelected.id,
        userapp_id: 0,
        question_id: question.id,
        user_input: '',
      };
      setAnswers([newAnswer])
    }
  }

  const handleAnswersCheckBox = (idAnswer: number, remove: boolean = false) => {
    if (remove) {
      // Supprimer une réponse de la liste
      setAnswers((prevAnswers) => prevAnswers.filter((e) => e.answer_id !== idAnswer));
    } else {
      // Trouver la réponse à ajouter
      const answerToAdd = question.answers.find((e) => e.id === idAnswer);
      if (answerToAdd) {
        setAnswers((prevAnswers) => {
          // Ajouter la réponse seulement si elle n'est pas déjà présente
          const isAlreadyAdded = prevAnswers.some((e) => e.answer_id === idAnswer);
          if (!isAlreadyAdded) {
            const newAnswer: TLessonAnswerUser = {
              id: 0,
              answer_id: answerToAdd.id,
              userapp_id: 0,
              question_id: question.id,
              user_input: '',
            };

            return [...prevAnswers, newAnswer];
          }
          return prevAnswers;
        });
      }
    }
  };

  const handleAnswersText = (value: string) => {
    if (question.answers.length > 0) {
      const answerSelected = question.answers[0];
      const newAnswer: TLessonAnswerUser = {
        id: 0,
        answer_id: answerSelected.id,
        userapp_id: 0,
        question_id: question.id,
        user_input: value,
      };
      setAnswers([newAnswer])
    }

  }

  const validateAnswer = () => {
    if (!questionType) {
      return
    }

    if (blockTime) {
      message.warning(
        `Veuillez réviser votre cours et réessayer dans ${Math.floor(timeRemaining! / 60)
          .toString()
          .padStart(2, '0')}:${(timeRemaining! % 60)
            .toString()
            .padStart(2, '0')}.`
      );
      return;
    }

    // if error, stop
    if (questionType.code === CODIFICATION_QUESTION_TYPE.MULTI_CHOICE || questionType.code === CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE) {
      if (remainingAttempts <= 0) {
        setIncorrectAttempts((prev) => prev + 1); // 🆕 Incrémenter les blocages
        message.warning('Vous avez atteint le nombre maximal de tentatives pour cette question.');
        return;
      }
      const incorrectSelected = answers.some((answer) =>
        question.answers.some(
          (answerQuestion) => answerQuestion.id === answer.answer_id && !answerQuestion.is_correct
        )
      );
      if (incorrectSelected) {
        const updatedAttempts = remainingAttempts - 1;
        setRemainingAttempts(updatedAttempts);
        localStorage.setItem(`remainingAttempts_${question.id}`, updatedAttempts.toString()); // Sauvegarde dans localStorage

        if (updatedAttempts <= 0) {
          const newIncorrectAttempts = incorrectAttempts + 1; // Incrémenter les blocages
          setIncorrectAttempts(newIncorrectAttempts);
          const nextBlockTime = Math.floor(Date.now() / 1000) + newIncorrectAttempts * 300;
          setBlockTime(nextBlockTime);
          setTimeRemaining(newIncorrectAttempts * 300);
          localStorage.setItem(`blockTime_${question.id}`, nextBlockTime.toString());
          message.error('Question bloquée après trop de tentatives incorrectes.');
        } else {
          message.error(`Mauvaise réponse. Tentatives restantes : ${updatedAttempts}.`);
        }
        return;
      }

    }

    switch (questionType.code) {
      case CODIFICATION_QUESTION_TYPE.MULTI_CHOICE:
        // Vérifier le minimum de réponses correctes
        if (answers.length < question.min_valid_answers) {
          message.warning(
            `Vous devez sélectionner au moins ${question.min_valid_answers} réponses correctes.`
          );
          return;
        }
        break;

      case CODIFICATION_QUESTION_TYPE.TEXT_INPUT:
      case CODIFICATION_QUESTION_TYPE.NUMERIC_INPUT:
        if (question.answers.length > 0 && answers.length > 0) {
          const questionAnswer = question.answers[0];
          const answerUser = answers[0];
          if (
            questionAnswer.content !== "*" &&
            questionAnswer.content.toLowerCase() !== answerUser.user_input.toLowerCase()
          ) {
            message.error('Mauvaise réponse, réessayez.');
            return;
          }
        } else {
          return;
        }
        break;

      default:
        break;
    }


    setOnSave(true);
    saveQuestionAnswers(answers)
      .then(e => {
        setAnswers(e.data)
        onNextLesson();
      })
      .catch((err) => {
        message.error(err.response.data ?? err.message);
      })
      .finally(() => {
        setOnSave(false);
      })
  };

  return (
    <Card
      title={<Title level={4}>Question</Title>}
      style={{ maxWidth: 600, margin: '20px auto', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)', padding: 20 }}
    >
      <Space style={{ fontSize: '16px', fontWeight: 500, marginBottom: '20px' }}>
        <Text>{question.content}</Text>
        {(questionType?.code === CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE || questionType?.code === CODIFICATION_QUESTION_TYPE.MULTI_CHOICE) && (
          <Tag color="blue">Tentatives restantes : {remainingAttempts}</Tag>
        )}
      </Space>

      {blockTime !== null ? (
        <div style={{ color: 'red', textAlign: 'center', margin: '20px 0' }}>
          <Text strong>
            Prenez le temps de revoir le cours et revenez pour essayer dans
            {' '}
            {Math.floor(timeRemaining! / 60)
              .toString()
              .padStart(2, '0')}
            :
            {(timeRemaining! % 60).toString().padStart(2, '0')}.
          </Text>
        </div>
      ) : (
        <>
          {questionType?.code === CODIFICATION_QUESTION_TYPE.SINGLE_CHOICE && (
            <Radio.Group
              onChange={(e) => handleAnswersRadioButton(Number(e.target.value))} // Appelle avec l'ID de la réponse
              style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
              value={answers.find(e => question.answers.some(a => a.id === e.answer_id))?.answer_id || null} // Définit la valeur sélectionnée
            >
              {question.answers.map((answer) => (
                <Radio
                  key={answer.id}
                  value={answer.id}
                  style={{ padding: '5px 10px' }}
                >
                  {answer.content} {/* Affiche le contenu de la réponse */}
                </Radio>
              ))}
            </Radio.Group>

          )}

          {questionType?.code === CODIFICATION_QUESTION_TYPE.MULTI_CHOICE && (
            <Checkbox.Group
              style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
              onChange={(checkedValues) => {
                // Mettre à jour les réponses sélectionnées en fonction des `checkedValues`
                const selectedIds = new Set(checkedValues.map((value: number) => value));
                question.answers.forEach((answer) => {
                  if (selectedIds.has(answer.id)) {
                    handleAnswersCheckBox(answer.id, false); // Ajouter si sélectionné
                  } else {
                    handleAnswersCheckBox(answer.id, true); // Supprimer si désélectionné
                  }
                });
              }}
              value={answers.filter(e => question.answers.some(a => a.id === e.answer_id)).map(e => e.answer_id)} // Définit les valeurs sélectionnées

            >
              {question.answers.map((answer) => (
                <Checkbox
                  key={answer.id}
                  value={answer.id} // Utilise l'ID comme valeur pour la Checkbox
                  style={{ padding: '5px 10px' }}
                >
                  {answer.content}
                </Checkbox>
              ))}
            </Checkbox.Group>

          )}

          {questionType?.code === CODIFICATION_QUESTION_TYPE.TEXT_INPUT && (
            <div>
              <Input
                placeholder="Entrez votre réponse ici"
                onChange={(e) => handleAnswersText(e.target.value)}
                style={{ borderRadius: '8px', padding: '10px', width: '100%' }}
                value={answers?.[0]?.user_input}
              />
            </div>
          )}
          {questionType?.code === CODIFICATION_QUESTION_TYPE.NUMERIC_INPUT && (
            <div>
              <InputNumber
                placeholder="Entrez votre réponse ici"
                onChange={(e) => handleAnswersText(String(e))}
                style={{ borderRadius: '8px', padding: '10px', width: '100%' }}
                value={Number(answers?.[0]?.user_input)}
              />
            </div>
          )}

          <Button
            type="primary"
            onClick={validateAnswer}
            style={{
              width: '100%',
              marginTop: 20,
              borderRadius: '8px',
              backgroundColor: '#1890ff',
              border: 'none',
            }}
            loading={onSave}
          >
            Soumettre
          </Button>
        </>
      )}
    </Card>
  );
};

export default QuestionViewer;
