import React, { useEffect, useState } from 'react';
import { Button, Input, Row, Col, Card, List, Select, Divider, message } from 'antd';
import { SpriteAnimationPreview } from './SpriteAnimationPreview';
import { ALL_STATES, TAnimationConfig, TSprite } from '../../../models/objects/sprite';
import AppLayout from '../../../components/layout/AppLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useObjectsApi } from '../../../api/objects/objects';
import { getImagePath } from '../../../utils/format/valueFormat';
import FloatButtons from '../../../components/button/FloatButtons';

export const SpriteEditor: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        getSpriteById,
        createSpriteAnimation
    } = useObjectsApi();

    // Option A : Récupérer l’objet complet passé en state
    const initialSprite = location.state as TSprite | undefined;

    const [spriteData, setSpriteData] = useState<TSprite>({
        id: initialSprite?.id ?? 0,
        name: initialSprite?.name ?? '',
        image_url: initialSprite?.image_url ?? '',
        // si initialSprite?.animations est null, on passe un tableau vide :
        animations: initialSprite?.animations || [],
    });

    const [selectedAnimIndex, setSelectedAnimIndex] = useState<number | null>(null);


    // -----------------------------------------------------------------------------------
    // 1) Loading
    // -----------------------------------------------------------------------------------
    useEffect(() => {
        setLoading(true);
        getSpriteById(initialSprite?.id ?? 0)
            .then((resp) => {
                setSpriteData({
                    id: resp.data?.id ?? 0,
                    name: resp.data?.name ?? '',
                    image_url: resp.data?.image_url ?? '',
                    // si initialSprite?.animations est null, on passe un tableau vide :
                    animations: resp.data?.animations || [],
                });
            })
            .catch((err) => {
                message.error(err.response?.data ?? err.message ?? err);
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialSprite]);



    // -----------------------------------------------------------------------------------
    // 2) Ajout d’une nouvelle animation
    // -----------------------------------------------------------------------------------
    const handleAddAnimation = (newState: string) => {
        const anim: TAnimationConfig = {
            id: 0,
            state: newState as TAnimationConfig['state'],
            frame_width: 100,
            frame_height: 100,
            frame_count: 1,
            frame_rate: 10,
            offset_x: 0,
            offset_y: 0,
        };

        setSpriteData((prev) => {
            const updatedAnims = [...prev.animations, anim];
            return { ...prev, animations: updatedAnims };
        });

        // Sélection de la nouvelle animation (à l'index suivant)
        setSelectedAnimIndex((spriteData.animations || []).length);
    };

    // -----------------------------------------------------------------------------------
    // 3) Mise à jour d’un champ d’animation
    // -----------------------------------------------------------------------------------
    const handleChangeField = <K extends keyof TAnimationConfig>(
        index: number,
        field: K,
        value: TAnimationConfig[K]
    ) => {
        setSpriteData((prev) => {
            const newAnims = [...prev.animations];
            newAnims[index] = { ...newAnims[index], [field]: value };
            return { ...prev, animations: newAnims };
        });
    };

    const handleDeleteAnimation = (index: number) => {
        setSpriteData((prev) => {
            const newAnims = [...prev.animations];
            newAnims.splice(index, 1);
            return { ...prev, animations: newAnims };
        });
        // Optionnel : si tu veux désélectionner après suppression
        if (selectedAnimIndex === index) {
            setSelectedAnimIndex(null);
        }
    };

    // -----------------------------------------------------------------------------------
    // 4) Sauvegarde en base  
    // -----------------------------------------------------------------------------------
    const handleSave = async (): Promise<void> => {
        try {
            await createSpriteAnimation(spriteData);  // Attendre la fin de la promesse 
        } catch (err: any) {
            message.error(err.response?.data ?? err.message ?? err);  // Gestion des erreurs
        }
    };

    // -----------------------------------------------------------------------------------
    // Sélection et états disponibles
    // -----------------------------------------------------------------------------------
    const currentAnim = selectedAnimIndex !== null ? spriteData.animations[selectedAnimIndex] : null;
    const usedStates = (spriteData.animations || []).map((a) => a.state);
    const availableStates = ALL_STATES.filter((st) => !usedStates.includes(st));

    return (
        <AppLayout title={"Sprites"} loading={loading}>
            <div style={{ padding: 16 }}>
                <Row gutter={16}>
                    <Col span={24}>
                        <h2>Sprite Editor - {spriteData.name}</h2>
                    </Col>
                </Row>


                <Divider />

                {/* Liste des animations & ajout d'animations */}
                <Row gutter={16}>
                    <Col span={8}>
                        <Card
                            title="Animations"
                            size="small"
                            extra={
                                <Select
                                    placeholder="Ajouter une anim"
                                    style={{ width: 150 }}
                                    onSelect={(val) => handleAddAnimation(val.toString())}
                                    disabled={(availableStates || []).length === 0}
                                >
                                    {availableStates.map((st) => (
                                        <Select.Option key={st} value={st}>
                                            {st}
                                        </Select.Option>
                                    ))}
                                </Select>
                            }
                        >
                            {(spriteData.animations || []).length === 0 && (
                                <p style={{ color: '#999' }}>Aucune animation configurée</p>
                            )}
                            <List
                                bordered
                                dataSource={spriteData.animations}
                                renderItem={(anim, idx) => {
                                    const isSelected = idx === selectedAnimIndex;
                                    return (
                                        <List.Item
                                            style={{
                                                background: isSelected ? '#e6f7ff' : 'transparent',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <div onClick={() => setSelectedAnimIndex(idx)}>
                                                <strong>{anim.state}</strong> ({anim.frame_count} frames)
                                            </div>
                                            <Button
                                                danger
                                                onClick={() => handleDeleteAnimation(idx)}
                                                style={{ marginLeft: 8 }}
                                            >
                                                Supprimer
                                            </Button>
                                        </List.Item>
                                    );
                                }}
                            />
                        </Card>
                    </Col>

                    {/* Édition de l’animation sélectionnée */}
                    <Col span={8}>
                        <Card title="Paramètres de l'animation" size="small">
                            {currentAnim ? (
                                <div>
                                    <div style={{ marginBottom: 8 }}>
                                        <label>frameWidth :</label>
                                        <Input
                                            type="number"
                                            value={currentAnim.frame_width}
                                            onChange={(e) =>
                                                handleChangeField(
                                                    selectedAnimIndex!,
                                                    'frame_width',
                                                    parseInt(e.target.value, 10)
                                                )
                                            }
                                        />
                                    </div>

                                    <div style={{ marginBottom: 8 }}>
                                        <label>frameHeight :</label>
                                        <Input
                                            type="number"
                                            value={currentAnim.frame_height}
                                            onChange={(e) =>
                                                handleChangeField(
                                                    selectedAnimIndex!,
                                                    'frame_height',
                                                    parseInt(e.target.value, 10)
                                                )
                                            }
                                        />
                                    </div>

                                    <div style={{ marginBottom: 8 }}>
                                        <label>frameCount :</label>
                                        <Input
                                            type="number"
                                            value={currentAnim.frame_count}
                                            onChange={(e) =>
                                                handleChangeField(
                                                    selectedAnimIndex!,
                                                    'frame_count',
                                                    parseInt(e.target.value, 10)
                                                )
                                            }
                                        />
                                    </div>

                                    <div style={{ marginBottom: 8 }}>
                                        <label>frameRate :</label>
                                        <Input
                                            type="number"
                                            value={currentAnim.frame_rate}
                                            onChange={(e) =>
                                                handleChangeField(
                                                    selectedAnimIndex!,
                                                    'frame_rate',
                                                    parseInt(e.target.value, 10)
                                                )
                                            }
                                        />
                                    </div>

                                    <div style={{ marginBottom: 8 }}>
                                        <label>offsetX :</label>
                                        <Input
                                            type="number"
                                            value={currentAnim.offset_x}
                                            onChange={(e) =>
                                                handleChangeField(
                                                    selectedAnimIndex!,
                                                    'offset_x',
                                                    parseInt(e.target.value, 10)
                                                )
                                            }
                                        />
                                    </div>

                                    <div style={{ marginBottom: 8 }}>
                                        <label>offsetY :</label>
                                        <Input
                                            type="number"
                                            value={currentAnim.offset_y}
                                            onChange={(e) =>
                                                handleChangeField(
                                                    selectedAnimIndex!,
                                                    'offset_y',
                                                    parseInt(e.target.value, 10)
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            ) : (
                                <p style={{ color: '#999' }}>Sélectionnez une animation</p>
                            )}
                        </Card>
                    </Col>

                    {/* Preview temps réel */}
                    <Col span={8}>
                        <Card title="Aperçu de l'animation" size="small">
                            {currentAnim && spriteData.image_url ? (
                                <SpriteAnimationPreview
                                    imageUrl={getImagePath(spriteData.image_url)}
                                    frameWidth={currentAnim.frame_width}
                                    frameHeight={currentAnim.frame_height}
                                    frameCount={currentAnim.frame_count}
                                    frameRate={currentAnim.frame_rate}
                                    offsetX={currentAnim.offset_x}
                                    offsetY={currentAnim.offset_y}
                                />
                            ) : (
                                <p style={{ color: '#999' }}>
                                    Sélectionnez une animation pour la visualiser
                                </p>
                            )}
                        </Card>
                    </Col>
                </Row>


            </div>

            <FloatButtons save={handleSave} />
        </AppLayout>
    );
};
