import React from 'react';
import { Column } from '@ant-design/charts';
import { TKeyValueDataFloat64 } from '../../models/objects/gen';

interface LineChartProps {
    data: TKeyValueDataFloat64[];
    valueType: string;  // Par exemple : '%' ou 'USD'
}

const LineChart: React.FC<LineChartProps> = ({ data, valueType }) => {

    const config = {
        data,
        xField: 'key',
        yField: 'value',
        colorField: 'key',

        // Label avec formatter qui utilise le paramètre text
        label: {
            position: 'top',
            style: {
                fill: '#fff',
                fontSize: 12,
            },
            formatter: (text: string) => {
                // text devrait déjà être la valeur numérique sous forme de string
                return `${text}${valueType}`;
            },
        },

    };

    return <Column {...config} />;
};

export default LineChart;
