import React, { useEffect, useState } from 'react';
import { Select, Row, Space, Card, Input, message } from 'antd';
import { TAnimateurInfo, TDashboard } from '../../../models/user/Dashboard';
import { TSubject, TCourse, TChapter, TEpic } from '../../../models/courses/course';
import { useCourseApi } from '../../../api/courses/courses';
import AnimateurContent from './AnimatorContent';
import { TLesson } from '../../../models/courses/lesson';

const { Option } = Select;

interface DashboardAnimateurProps {
    dashboard: TDashboard | null;
}

// Fonction utilitaire pour regrouper les données par étudiant
const groupByUser = (data: TAnimateurInfo[]) => {
    const grouped = new Map<number, { user: TAnimateurInfo; subjects: Set<number>; courses: Set<number> }>();

    data.forEach((entry) => {
        if (!grouped.has(entry.user_id)) {
            grouped.set(entry.user_id, {
                user: entry,
                subjects: new Set(),
                courses: new Set(),
            });
        }
        const userGroup = grouped.get(entry.user_id)!;
        if (entry.subject_id) userGroup.subjects.add(entry.subject_id);
        if (entry.course_id) userGroup.courses.add(entry.course_id);
    });

    return Array.from(grouped.values());
};

const DashboardAnimateur: React.FC<DashboardAnimateurProps> = ({ dashboard }) => {
    const { getSubjectsActif, getCourses, getAllLessons, getAllEpics, getAllChapters } = useCourseApi();
    const [subjects, setSubjects] = useState<TSubject[]>([]);
    const [courses, setCourses] = useState<TCourse[]>([]);
    const [chapters, setChapters] = useState<TChapter[]>([]);
    const [epics, setEpics] = useState<TEpic[]>([]);
    const [lessons, setLessons] = useState<TLesson[]>([]);
    const [searchValue, setSearchValue] = useState<string>('');
    const [selectedSubject, setSelectedSubject] = useState<number | null>(null);
    const [selectedCourse, setSelectedCourse] = useState<number | null>(null);


    // Charger les données au montage
    useEffect(() => {
        Promise.all([
            getSubjectsActif(),
            getCourses(),
            getAllChapters(),
            getAllEpics(),
            getAllLessons(),
        ])
            .then(([subjectsResp, coursesResp, chaptersResp, epicsResp, lessonsResp]) => {
                setSubjects(subjectsResp.data);
                setCourses(coursesResp.data);
                setChapters(chaptersResp.data);
                setEpics(epicsResp.data);
                setLessons(lessonsResp.data);
            })
            .catch((err) => message.error(err.response?.data ?? err.message));
        // eslint-disable-next-line 
    }, []);

    // Regrouper les données par utilisateur
    const groupedData = React.useMemo(() => {
        return dashboard?.dashboard_animateur
            ? groupByUser(dashboard.dashboard_animateur)
            : [];
    }, [dashboard]);

    // Gestion des filtres
    const filteredSubjects = React.useMemo(() => {
        if (selectedCourse) {
            const course = courses.find((c) => c.id === selectedCourse);
            return course ? subjects.filter((s) => s.id === course.subject_id) : [];
        }
        return subjects;
    }, [selectedCourse, courses, subjects]);

    const filteredCourses = React.useMemo(() => {
        if (selectedSubject) {
            return courses.filter((course) => course.subject_id === selectedSubject);
        }
        return courses;
    }, [selectedSubject, courses]);

    const handleSubjectChange = (value: number | null) => {
        setSelectedSubject(value);
        if (!value) setSelectedCourse(null); // Réinitialise le filtre des cours si le filtre des parcours est désélectionné
    };

    const handleCourseChange = (value: number | null) => {
        setSelectedCourse(value);
        if (value) {
            const course = courses.find((c) => c.id === value);
            if (course) setSelectedSubject(course.subject_id); // Sélection automatique du parcours lié au cours
        }
    };

    // Filtrage des données utilisateur
    const filteredData = React.useMemo(() => {
        let data = groupedData;

        if (searchValue) {
            data = data.filter((group) =>
                `${group.user.lastname} ${group.user.firstname}`
                    .toLowerCase()
                    .includes(searchValue.toLowerCase())
            );
        }

        return data;
    }, [groupedData, searchValue]);

    return (
        <>
            <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
                <Space>
                    <Input.Search
                        placeholder="Rechercher un étudiant"
                        onSearch={(value) => setSearchValue(value)}
                        allowClear
                        style={{ width: 300 }}
                    />
                    <Select
                        placeholder="Sélectionnez un parcours"
                        style={{ width: 300 }}
                        onChange={handleSubjectChange}
                        allowClear
                        value={selectedSubject}
                    >
                        {filteredSubjects.map((subject) => (
                            <Option key={subject.id} value={subject.id}>
                                {subject.title}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Sélectionnez un cours"
                        style={{ width: 300 }}
                        onChange={handleCourseChange}
                        allowClear
                        value={selectedCourse}
                    >
                        {filteredCourses.map((course) => (
                            <Option key={course.id} value={course.id}>
                                {course.title}
                            </Option>
                        ))}
                    </Select>
                </Space>
                <Space>
                    <Card title="Total Étudiants">{groupedData.length}</Card>
                </Space>
            </Row>
            <AnimateurContent
                userData={filteredData}
                subjects={subjects} // Toujours transmettre tous les sujets
                courses={courses}   // Toujours transmettre tous les cours
                filteredSubjects={filteredSubjects} // Pour les filtres
                filteredCourses={filteredCourses}   // Pour les filtres
                chapters={chapters}
                epics={epics}
                lessons={lessons}
                selectedSubject={selectedSubject}
                selectedCourse={selectedCourse}
            />

        </>
    );
};

export default DashboardAnimateur;
