// commonGameFunctions.ts
import { TObstacle } from '../../type/obstacle';
import { TCharacter } from '../../type/character';
import { TScene } from '../../type/scene';
import { roundToNearest } from '../../../../utils/gen/calcul';


export function pureTeleportTo(character: TCharacter, scene: TScene, position: string) {
    let finalX = 0;
    let finalY = 0;

    switch (position) {
        case 'TOP_LEFT':
            finalX = 0;
            finalY = 0;
            break;
        case 'TOP':
            finalX = (scene.width - character.width) / 2;
            finalY = 0;
            break;
        case 'TOP_RIGHT':
            finalX = scene.width - character.width;
            finalY = 0;
            break;
        case 'CENTER_LEFT':
            finalX = 0;
            finalY = (scene.height - character.height) / 2;
            break;
        case 'CENTER':
            finalX = (scene.width - character.width) / 2;
            finalY = (scene.height - character.height) / 2;
            break;
        case 'CENTER_RIGHT':
            finalX = scene.width - character.width;
            finalY = (scene.height - character.height) / 2;
            break;
        case 'BOTTOM_LEFT':
            finalX = 0;
            finalY = scene.height - character.height;
            break;
        case 'BOTTOM':
            finalX = (scene.width - character.width) / 2;
            finalY = scene.height - character.height;
            break;
        case 'BOTTOM_RIGHT':
            finalX = scene.width - character.width;
            finalY = scene.height - character.height;
            break;
        default:
            // On retourne les mêmes coords s'il y a une position inconnue
            break;
    }

    return {
        x: finalX,
        y: finalY,
        scale: character.scale,
    };
}


const pureAdjustDistanceForAngle = (angle: number, baseDistance: number): number => {
    // Normaliser l'angle entre 0 et 360 degrés
    const normalizedAngle = ((angle % 360) + 360) % 360;

    // Angles diagonaux (45°, 135°, 225°, 315°)
    const diagonalAngles = [45, 135, 225, 315];

    if (diagonalAngles.includes(normalizedAngle)) {
        // Multiplier la distance par √2 pour les déplacements diagonaux
        return baseDistance * Math.SQRT2;
    } else {
        // Garder la distance de base pour les autres angles
        return baseDistance;
    }
};


export function pureCheckCollision(
    nextX: number,
    nextY: number,
    character: TCharacter,
    scene: TScene,
    obstacles: TObstacle[]
) {
    let isColliding = false;
    let adjustedX = nextX;
    let adjustedY = nextY;

    // Vérifier les collisions avec les obstacles
    for (const obstacle of obstacles) {
        if (
            nextX < obstacle.x + obstacle.width &&
            nextX + character.width > obstacle.x &&
            nextY < obstacle.y + obstacle.height &&
            nextY + character.height > obstacle.y
        ) {
            isColliding = true;

            const fromLeft = character.x + character.width <= obstacle.x;
            const fromRight = character.x >= obstacle.x + obstacle.width;
            const fromTop = character.y + character.height <= obstacle.y;
            const fromBottom = character.y >= obstacle.y + obstacle.height;

            if (fromLeft) {
                adjustedX = obstacle.x - character.width;
            } else if (fromRight) {
                adjustedX = obstacle.x + obstacle.width;
            } else if (fromTop) {
                adjustedY = obstacle.y - character.height;
            } else if (fromBottom) {
                adjustedY = obstacle.y + obstacle.height;
            }
            break;
        }
    }

    // Vérifier les collisions avec les limites de la scène
    if (nextX < 0) {
        adjustedX = 0;
        isColliding = true;
    } else if (nextX + character.width > scene.width) {
        adjustedX = scene.width - character.width;
        isColliding = true;
    }

    if (nextY < 0) {
        adjustedY = 0;
        isColliding = true;
    } else if (nextY + character.height > scene.height) {
        adjustedY = scene.height - character.height;
        isColliding = true;
    }

    return { isColliding, adjustedX, adjustedY };
}



export function pureMoveCharacter(
    character: TCharacter,
    angle: number,
    distance: number,
    scene: TScene,
    obstacles: TObstacle[]
) {
    const adjustedDistance = pureAdjustDistanceForAngle(angle, distance);
    const radians = (angle * Math.PI) / 180;
    const deltaX = adjustedDistance * Math.cos(radians);
    const deltaY = adjustedDistance * Math.sin(radians);

    const nextX = roundToNearest(character.x + deltaX);
    const nextY = roundToNearest(character.y + deltaY);

    const { isColliding, adjustedX, adjustedY } = pureCheckCollision(nextX, nextY, character, scene, obstacles);

    const finalX = isColliding ? adjustedX : nextX;
    const finalY = isColliding ? adjustedY : nextY;

    return {
        finalX: roundToNearest(finalX),
        finalY: roundToNearest(finalY),
        scale: deltaX >= 0 ? 1 : -1,
        isColliding,
        deltaX,
        deltaY,
    };
}
