import React, { useEffect, useState } from "react";
import { Card, Button, Typography, Space, Col, Row, message, Tag } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useUserApi } from "../../api/user/userApp";
import { TLessonAnswerUser, TLessonQuestion } from "../../models/courses/lesson";

const { Title, Text } = Typography;

// Interface pour les props du composant DragAndDrop
interface DragAndDropProps {
  question: TLessonQuestion; // La question actuelle avec ses réponses
  onNextLesson: () => void; // Fonction pour passer à la leçon suivante
}

// Composant principal de drag-and-drop pour les questions
const DragAndDrop: React.FC<DragAndDropProps> = ({ question, onNextLesson }) => {
  const { saveQuestionAnswers } = useUserApi(); // API pour sauvegarder les réponses
  const [onSave, setOnSave] = useState<boolean>(false);
  const shuffleArray = <T,>(array: T[]): T[] => {
    return array
      .map((item) => ({ item, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ item }) => item);
  };

  // État pour les éléments à glisser (drag items)
  const [dragItems, setDragItems] = useState(
    shuffleArray(
      question.answers
        .filter((answer) => answer.content !== "*") // Exclure les jokers
        .map((answer) => ({
          id: `${answer.id}`, // Identifiant unique de chaque réponse
          content: answer.content, // Contenu de la réponse
          isCorrect: answer.is_correct, // Indicateur si la réponse est correcte
        }))
    )
  );

  // État pour les emplacements de dépôt (drop slots)
  const [dropSlots, setDropSlots] = useState(
    question.answers
      .filter((answer) => answer.is_correct) // Créer des emplacements pour les réponses correctes uniquement
      .map(() => ({
        id: "", // Pas de réponse attribuée initialement
        content: "", // Contenu vide
      }))
  );

  // Gestion des tentatives et du blocage
  const [blockTime, setBlockTime] = useState<number | null>(null); // Temps de blocage en secondes
  const [timeRemaining, setTimeRemaining] = useState<number | null>(null); // Temps restant avant la fin du blocage
  const [remainingAttempts, setRemainingAttempts] = useState<number>(0); // Nombre de tentatives restantes
  const [incorrectAttempts, setIncorrectAttempts] = useState<number>(0); // Nombre de tentatives incorrectes accumulées

  // Calcul du nombre maximal de tentatives autorisées
  const calculateMaxAttempts = (totalAnswers: number, correctAnswers: number): number =>
    correctAnswers !== 1 ? Math.max(1, Math.ceil(totalAnswers / correctAnswers)) : 1;

  // Effet pour gérer le blocage et les tentatives
  useEffect(() => {
    const storedBlockTime = localStorage.getItem(`blockTime_${question.id}`); // Temps de blocage stocké
    const currentTime = Math.floor(Date.now() / 1000); // Heure actuelle en secondes

    if (storedBlockTime) {
      const remainingTime = parseInt(storedBlockTime, 10) - currentTime;
      if (remainingTime > 0) {
        setBlockTime(parseInt(storedBlockTime, 10));
        setTimeRemaining(remainingTime);
      } else {
        localStorage.removeItem(`blockTime_${question.id}`);
      }
    }

    const storedIncorrectAttempts = localStorage.getItem(`incorrectAttempts_${question.id}`);
    if (storedIncorrectAttempts) {
      setIncorrectAttempts(parseInt(storedIncorrectAttempts, 10));
    }

    const maxAttempts = calculateMaxAttempts(
      question.answers.length,
      question.answers.filter((a) => a.is_correct).length
    );

    const storedAttempts = localStorage.getItem(`remainingAttempts_${question.id}`);
    if (storedAttempts !== null && parseInt(storedAttempts, 10) !== 0) {
      setRemainingAttempts(parseInt(storedAttempts, 10));
    } else {
      setRemainingAttempts(maxAttempts);
      localStorage.setItem(`remainingAttempts_${question.id}`, maxAttempts.toString());

    }

    const timer = setInterval(() => {
      if (timeRemaining) {
        setTimeRemaining((prev) => (prev && prev > 0 ? prev - 1 : null));
        if (timeRemaining === 1) {
          setBlockTime(null);
          localStorage.removeItem(`blockTime_${question.id}`);
          setRemainingAttempts(maxAttempts);
        }
      }
    }, 1000);

    return () => clearInterval(timer); // Nettoyage de l'intervalle
    // eslint-disable-next-line
  }, [timeRemaining, question]);

  // Fonction déclenchée à la fin d'un drag-and-drop
  const handleDragEnd = (result: any) => {
    if (!result.destination) return; // Si aucun emplacement cible, ne rien faire

    const { destination } = result;

    if (destination.droppableId.startsWith("slot-")) {
      const slotIndex = parseInt(destination.droppableId.split("-")[1]);
      const draggedItem = dragItems.find((item) => item.id === result.draggableId);

      if (!draggedItem) return;

      const existingAnswer = dropSlots[slotIndex];
      if (existingAnswer && existingAnswer.id) {
        const fullAnswer = question.answers.find((answer) => answer.id === parseInt(existingAnswer.id));
        if (fullAnswer) {
          setDragItems((prevItems) => [
            ...prevItems,
            { ...existingAnswer, isCorrect: fullAnswer.is_correct },
          ]);
        }
      }

      const updatedSlots = [...dropSlots];
      updatedSlots[slotIndex] = draggedItem;
      setDropSlots(updatedSlots);

      setDragItems((prevItems) =>
        prevItems.filter((item) => item.id !== result.draggableId)
      );
    }
  };

  // Fonction pour retirer une réponse d'un emplacement
  const removeAnswerFromSlot = (slotIndex: number) => {
    const existingAnswer = dropSlots[slotIndex];
    if (!existingAnswer || !existingAnswer.id) return;

    const fullAnswer = question.answers.find((answer) => answer.id === parseInt(existingAnswer.id));
    if (fullAnswer) {
      setDragItems((prevItems) => [
        ...prevItems,
        { ...existingAnswer, isCorrect: fullAnswer.is_correct },
      ]);
    }

    const updatedSlots = [...dropSlots];
    updatedSlots[slotIndex] = { id: "", content: "" };
    setDropSlots(updatedSlots);
  };

  // Rendu des lignes de la question avec les emplacements de dépôt
  const renderQuestions = () => {
    const lines = question.content.split("\n"); // Divise la question en lignes
    return lines.map((line, lineIndex) => {
      const parts = line.split(/(%\d)/); // Sépare les parties avec des placeholders
      return (
        <div key={lineIndex} style={{ marginBottom: "1em", fontSize: "1rem" }}>
          {parts.map((part, index) => {
            if (/^%\d$/.test(part)) {
              const placeholderIndex = parseInt(part.replace("%", "")) - 1;
              return (
                <div
                  key={`slot-container-${placeholderIndex}`}
                  style={{
                    display: "inline-block",
                    position: "relative",
                    width: "13%",
                    margin: "0 0.5em",
                  }}
                >
                  <Droppable droppableId={`slot-${placeholderIndex}`}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "2.5em",
                          border: "0.2em solid #1890ff",
                          borderRadius: "0.5em",
                          backgroundColor: "#f9f9f9",
                          textAlign: "center",
                          fontWeight: 700,
                          overflow: "hidden",
                        }}
                      >
                        {dropSlots[placeholderIndex]?.content || ""}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  {dropSlots[placeholderIndex]?.content && (
                    <button
                      // Bouton pour supprimer une réponse insérée dans un emplacement
                      onClick={() => removeAnswerFromSlot(placeholderIndex)}
                      style={{
                        position: "absolute", // Position absolue pour placer le bouton dans l'interface
                        top: "-0.5em",
                        right: "-0.5em",
                        backgroundColor: "red", // Couleur rouge pour indiquer une action de suppression
                        color: "white", // Texte blanc pour le contraste
                        border: "none",
                        borderRadius: "50%", // Forme circulaire du bouton
                        width: "1.5em",
                        height: "1.5em",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "0.8em",
                        cursor: "pointer",
                        zIndex: 10, // Z-index élevé pour s'assurer qu'il est visible
                      }}
                    >
                      ✕ {/* Symbole "X" pour indiquer la suppression */}
                    </button>
                  )}
                </div>
              );
            }
            return <span key={`${lineIndex}-${index}`}>{part}</span>;
          })}
        </div>
      );
    });
  };


  const handleValidation = () => {
    if (blockTime) {
      // Vérifie si la question est bloquée, affiche un message avec le temps restant
      message.warning(
        `Veuillez réviser votre cours et réessayer dans ${Math.floor(timeRemaining! / 60)
          .toString()
          .padStart(2, "0")}:${(timeRemaining! % 60).toString().padStart(2, "0")}.`
      );
      return;
    }

    if (remainingAttempts <= 0) {
      // Si plus de tentatives disponibles, bloque la question et affiche un avertissement
      setIncorrectAttempts((prev) => prev + 1);
      message.warning(
        "Vous avez atteint le nombre maximal de tentatives pour cette question."
      );
      return;
    }

    const correctAnswers = question.answers.filter((answer) => answer.is_correct);
    const allCorrect = correctAnswers.every((correctAnswer, index) => {
      // Compare les réponses utilisateur avec les réponses correctes
      const slotContent = dropSlots[index]?.content.trim().toLowerCase();
      return (
        correctAnswer.content === "*" || // Accepte tous les contenus pour un joker
        (slotContent && slotContent === correctAnswer.content.trim().toLowerCase())
      );
    });

    if (allCorrect) {

      // Réinitialisation des tentatives au maximum
      const maxAttempts = calculateMaxAttempts(
        question.answers.length,
        question.answers.filter((a) => a.is_correct).length
      );
      setRemainingAttempts(maxAttempts);
      localStorage.setItem(`remainingAttempts_${question.id}`, maxAttempts.toString()); // Réinitialise la tentative
    } else {
      // Met à jour les tentatives restantes et gère les cas de blocage
      const updatedAttempts = remainingAttempts - 1;
      setRemainingAttempts(updatedAttempts);
      // Met à jour le localStorage avec la nouvelle valeur de tentative restante
      localStorage.setItem(`remainingAttempts_${question.id}`, updatedAttempts.toString());

      if (updatedAttempts <= 0) {
        const nextIncorrectAttempts = incorrectAttempts + 1;
        setIncorrectAttempts(nextIncorrectAttempts);

        const nextBlockTime = Math.floor(Date.now() / 1000) + nextIncorrectAttempts * 300;
        setBlockTime(nextBlockTime);
        setTimeRemaining(nextIncorrectAttempts * 300);
        localStorage.setItem(`blockTime_${question.id}`, nextBlockTime.toString());

        message.error("Question bloquée après trop de tentatives incorrectes.");
      } else {
        message.error(`Mauvaise réponse. Tentatives restantes : ${updatedAttempts}.`);
      }
    }
    setOnSave(true);
    const userAnswers: TLessonAnswerUser[] = dropSlots.map((slot) => ({
      id: 0,
      userapp_id: 0,
      question_id: question.id,
      answer_id: parseInt(slot.id),
      user_input: slot.content,
    }));

    saveQuestionAnswers(userAnswers)
      .then(() => {
        if (allCorrect) onNextLesson();
      })
      .catch((error) => {
        message.error(error.response?.data ?? error.message);
      })
      .finally(() => {
        setOnSave(false);
      });
  };

  const renderDragItems = () => (
    <Droppable droppableId="drag-items" direction="horizontal">
      {(provided) => (
        <div
          // Conteneur pour les éléments déplaçables
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{
            display: "flex",
            gap: "2%", // Espacement entre les éléments
            flexWrap: "wrap",
            justifyContent: "center", // Centre les éléments horizontalement
            padding: "2%", // Espacement intérieur
          }}
        >
          {dragItems.map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <div
                  // Style pour chaque élément déplaçable
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...provided.draggableProps.style,
                    padding: "0.5em 1em",
                    backgroundColor: "#1890ff", // Couleur de fond bleue
                    color: "#fff", // Texte blanc
                    borderRadius: "0.5em", // Coins arrondis
                  }}
                >
                  {item.content}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Card style={{ width: "70%", padding: "2%" }}>
          <Title level={4}>Question</Title>
          <Row justify="center" style={{ marginBottom: "2%" }}>
            <Col>
              <Text type="secondary">Faites glisser les réponses dans les cases.</Text>
              <Tag color="blue">Tentatives restantes : {remainingAttempts}</Tag>
            </Col>
          </Row>

          {blockTime !== null ? (
            <div style={{ color: "red", textAlign: "center", margin: "20px 0" }}>
              <Text strong>
                Prenez le temps de revoir le cours et revenez pour essayer dans{" "}
                {Math.floor(timeRemaining! / 60)
                  .toString()
                  .padStart(2, "0")}
                :
                {(timeRemaining! % 60).toString().padStart(2, "0")}.
              </Text>
            </div>
          ) : (
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              {renderQuestions()}
            </Space>
          )}
        </Card>

        <Card style={{ width: "70%", marginTop: "2%", padding: "2%" }}>
          <Text strong>Réponses disponibles</Text>
          {renderDragItems()}
        </Card>

        <Button
          type="primary"
          shape="round"
          size="large"
          onClick={handleValidation}
          style={{ marginTop: "3%" }}
          loading={onSave}
        >
          Soumettre
        </Button>
      </div>
    </DragDropContext>
  );
};

export default DragAndDrop;
