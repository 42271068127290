// src/AnimationView/engine/entities/Character.ts

import Phaser from "phaser";
import { CharacterConfig } from "../../../types/BlocklyTypes";
import { GameObject } from "./Object";
import {
  CharacterState,
  TAnimationConfig,
} from "../../../../../models/objects/sprite";

/**
 * Représente un personnage 2D (logique + affichage Phaser).
 */
export class Character {
  private adminMode: boolean;
  // --- Logique ---
  public id: string;
  private scene?: Phaser.Scene;
  public x: number;
  public y: number;
  public width: number;
  public height: number;
  public speedPxPerStep: number;
  public angleDirection: number;

  // --- Animation ---
  public currentState: CharacterState = "idle";

  //  Auto-pilote
  private autoPilotEnabled: boolean = false;
  private autoPilotTimer?: Phaser.Time.TimerEvent;

  public image_url: string;
  public animations: TAnimationConfig[];

  // --- Affichage Phaser ---
  public sprite?: Phaser.Types.Physics.Arcade.SpriteWithDynamicBody;

  // Objets portés
  private carriedObject: GameObject[];

  constructor(config: CharacterConfig) {
    this.adminMode = config.adminMode;
    this.id = config.id;
    this.x = config.x;
    this.y = config.y;
    this.width = config.width;
    this.height = config.height;
    this.speedPxPerStep = config.speedPxPerStep;
    this.angleDirection = 0;
    this.carriedObject = [];

    this.image_url = config.image_url;
    this.animations = config.animations;
  }

  /**
   * Crée le sprite Phaser dans la scène.
   * On utilise l’asset “idle” comme texture initiale.
   */
  public createPhaserSprite(scene: Phaser.Scene) {
    this.scene = scene;

    this.sprite = scene.physics.add.sprite(this.x, this.y, this.id);
    this.sprite.setOrigin(0.5, 0.5);
    this.sprite.setDisplaySize(this.width, this.height);

    // Collision avec les bords du monde
    this.sprite.setCollideWorldBounds(true);

    this.setAnimation("idle");

    // Admin : petit texte au-dessus
    if (this.adminMode) {
      const idText = scene.add.text(
        this.sprite.x,
        this.sprite.y - 15,
        this.id,
        {
          fontSize: "12px",
          color: "#ffffff",
          backgroundColor: "#00000088",
        }
      );
      idText.setOrigin(0.5, 1);

      // Pour que le texte suive le sprite
      scene.events.on("update", () => {
        if (this.sprite) {
          idText.x = this.sprite.x;
          idText.y = this.sprite.y - this.sprite.displayHeight / 2 - 2;
        }
      });
    }
  }

  /**
   * updatePhaserSprite : on recale x,y logiques si besoin
   */
  public updatePhaserSprite() {
    if (!this.sprite) return;
    this.x = this.sprite.x;
    this.y = this.sprite.y;
  }

  /**
   * setAnimation : change l’anim (states = "idle","walking","jumping","winning", etc.)
   */
  public setAnimation(state: CharacterState) {
    if (!this.sprite || !this.sprite.scene) return;
    this.currentState = state;
    this.sprite.play(`${state}_${this.id}`);
  }

  public setAngleDirection(angleDegree: number) {
    this.angleDirection = angleDegree;
    const isGoingLeft = this.angleDirection > 90 && this.angleDirection < 270;
    this.flipSprite(isGoingLeft);
  }
  public getAngleDirection(): number {
    return this.angleDirection;
  }
  public addAngle(additionalAngle: number) {
    this.angleDirection = (this.angleDirection + additionalAngle) % 360;
    this.setAngleDirection(this.angleDirection);
  }
  public flipAngle() {
    this.angleDirection = (this.angleDirection + 180) % 360;
    this.setAngleDirection(this.angleDirection);
  }

  /**
   * -------------------------
   * METHODES walk / jump
   * -------------------------
   */

  /**
   * Marche d’une distance (speedPxPerStep * unitNumber)
   * Renvoie une Promise résolue lorsque la distance est parcourue
   * ou qu'on a atteint un timeout.
   */
  public async walk(unitNumber: number): Promise<void> {
    // distance en pixels
    const distPx = this.speedPxPerStep * unitNumber;
    // Pas de collisions off
    return this.doMovement(distPx, 1, false, "walking");
  }

  /**
   * Saut : ex : distance * 2
   * Désactive collisions, puis les rétablit à la fin
   */
  public async jump(distance: number): Promise<void> {
    // On applique par ex. *2
    const distPx = this.speedPxPerStep * distance * 2;
    // collisions off
    return this.doMovement(distPx, 4, true, "jumping");
  }

  /**
   * -------------------------
   * METHODE FACTORISEE
   * -------------------------
   * Gère un déplacement "distancePx" en utilisant la velocity.
   * - multiplierVelocity : si on veut plus vite/lent
   * - collisionsOff : si on veut désactiver collisions
   * - animation : "walking", "jumping", etc.
   */
  private doMovement(
    distancePx: number,
    multiplierVelocity: number,
    collisionsOff: boolean,
    anim: CharacterState
  ): Promise<void> {
    if (!this.scene || !this.sprite) return Promise.resolve();

    // 1) Animation
    this.setAnimation(anim);

    // 2) Collisions ?
    if (collisionsOff) {
      this.sprite.body.checkCollision.none = true;
    }

    // 3) Calcul de la velocity
    const angleRad = (Math.PI / 180) * this.angleDirection;
    const velocityX =
      Math.cos(angleRad) * this.speedPxPerStep * multiplierVelocity;
    const velocityY =
      -Math.sin(angleRad) * this.speedPxPerStep * multiplierVelocity;

    // Flip
    const isGoingLeft = this.angleDirection > 90 && this.angleDirection < 270;
    this.flipSprite(isGoingLeft);

    // On commence
    this.sprite.setVelocity(velocityX, velocityY);

    // On définit un TIMEOUT (ex: 2000ms) pour éviter blocage
    // 1) Calculer la vitesse scalaire (en px par seconde).
    // (Attention, speedPxPerStep n’est pas toujours "px/s",
    //  parfois c’est "px/frame" si votre logic est frame-based.
    //  Ici on suppose “1 step = 1 second” ou similaire).
    let velocityScalar = this.speedPxPerStep * multiplierVelocity;

    // 2) Si velocityScalar = 0, on met un fallback (ex: 1).
    if (velocityScalar === 0) {
      velocityScalar = 1;
    }

    // 3) temps nécessaire = distancePx / velocityScalar * 1000 ms
    //  + par exemple 20% de marge.
    const neededMs = (distancePx / velocityScalar) * 1000 * 1.2;

    // 4) clamp => pas plus de 30 s
    const TIMEOUT_MS = Math.min(neededMs, 30000);

    return new Promise<void>((resolve) => {
      let distTravelled = 0;
      let prevX = this.sprite!.x;
      let prevY = this.sprite!.y;

      // 1) Update callback
      const onUpdate = () => {
        if (!this.sprite) {
          // sprite supprimé => on se retire
          this.scene?.events.off("update", onUpdate);
          resolve();
          return;
        }

        // Incrémente la distance parcourue
        const dx = this.sprite.x - prevX;
        const dy = this.sprite.y - prevY;
        distTravelled += Math.sqrt(dx * dx + dy * dy);
        prevX = this.sprite.x;
        prevY = this.sprite.y;

        // Vérif distance
        if (distTravelled >= distancePx) {
          // On a fini => on arrête
          finishMovement();
        }
      };

      // 2) Méthode finishMovement => stop velocity, off update, collisions on
      const finishMovement = () => {
        this.sprite!.setVelocity(0, 0);
        if (
          this.currentState === "walking" ||
          this.currentState === "jumping"
        ) {
          this.setAnimation("idle");
        }
        this.scene?.events.off("update", onUpdate);
        if (collisionsOff) {
          this.sprite!.body.checkCollision.none = false;
        }
        resolve();
      };

      // 3) On s'abonne à "update"
      this.scene!.events.on("update", onUpdate);

      // 4) Timeout
      this.scene!.time.delayedCall(TIMEOUT_MS, () => {
        // Si on n'a pas encore fini => on force l'arrêt
        finishMovement();
      });
    });
  }

  /**
   * ------------------------
   * ANIMATIONS / OUTILS
   * ------------------------
   */
  public playWinAnimation() {
    this.setAnimation("winning");
  }
  public playCollisionAnimation() {
    this.setAnimation("collision");
    setTimeout(() => this.setAnimation("idle"), 500);
  }

  private flipSprite(flip: boolean) {
    if (!this.sprite) return;
    if ("setFlipX" in this.sprite) {
      (this.sprite as Phaser.Physics.Arcade.Image).setFlipX(flip);
    }
  }

  /**
   * Quand on ramasse un objet.
   */
  public pickUpObject(obj: GameObject) {
    this.carriedObject?.push(obj);
  }
  public dropObject(id: string, x: number, y: number) {
    if (!this.carriedObject || this.carriedObject.length === 0) return;
    const index = this.carriedObject.findIndex((obj) => obj.id === id);
    if (index === -1) return;
    this.carriedObject[index].drop(x, y);
    this.carriedObject.splice(index, 1);
  }
  public dropAllObjects(x: number, y: number) {
    if (!this.carriedObject || this.carriedObject.length === 0) return;
    for (const obj of this.carriedObject) {
      obj.drop(x, y);
    }
    this.carriedObject = [];
  }

  /**
   * Faire "parler" le personnage avec une bulle de texte.
   */
  public say(message: string, duration: number = 2000) {
    if (!this.scene || !this.sprite) return;

    // 1) Créer un container positionné au-dessus du perso
    const container = this.scene.add.container(
      this.sprite.x,
      this.sprite.y - this.sprite.displayHeight / 2 - 10
    );

    // 2) Calculer la taille de la bulle
    const padding = 10;
    const bubbleWidth = Math.max(100, message.length * 8 + padding * 2);
    const bubbleHeight = 50;

    // 3) Créer la bulle (graphics)
    const bubble = this.scene.add.graphics();
    bubble.fillStyle(0xffffff, 1);
    bubble.lineStyle(2, 0x000000, 1);
    bubble.fillRoundedRect(
      -bubbleWidth / 2,
      -bubbleHeight,
      bubbleWidth,
      bubbleHeight,
      10
    );
    bubble.strokeRoundedRect(
      -bubbleWidth / 2,
      -bubbleHeight,
      bubbleWidth,
      bubbleHeight,
      10
    );
    // Le petit triangle (positionné sous la bulle)
    bubble.fillTriangle(-10, 0, 10, 0, 0, 10);
    bubble.strokeTriangle(-10, 0, 10, 0, 0, 10);

    // 4) Créer le texte
    const text = this.scene.add.text(
      -bubbleWidth / 2 + padding,
      -bubbleHeight / 2,
      message,
      {
        fontSize: "14px",
        color: "#000000",
        wordWrap: { width: bubbleWidth - padding * 2 },
      }
    );
    text.setOrigin(0, 0.5);

    // 5) Ajouter la bulle et le texte dans le container
    container.add([bubble, text]);

    // 6) Gérer le déplacement en continu (abonnement à "update")
    const updatePosition = () => {
      if (!this.sprite) return;
      // On recalcule la position du container par rapport au sprite
      container.x = this.sprite.x;
      container.y = this.sprite.y - this.sprite.displayHeight / 2 - 10;
    };
    this.scene.events.on("update", updatePosition);

    // 7) Détruire la bulle et le listener après "duration" ms
    this.scene.time.delayedCall(duration, () => {
      container.destroy();
      this.scene?.events.off("update", updatePosition);
    });
  }

  /**
   * Auto-pilote (errance aléatoire)
   */
  public startAutoPilot() {
    if (!this.scene) return;
    this.autoPilotEnabled = true;

    if (this.autoPilotTimer) {
      this.autoPilotTimer.remove(false);
    }
    this.autoPilotTimer = this.scene.time.addEvent({
      delay: 1000,
      callback: () => this.autoPilotMove(),
      loop: true,
    });
  }

  public stopAutoPilot() {
    this.autoPilotEnabled = false;
    if (this.scene && this.autoPilotTimer) {
      this.scene.time.removeEvent(this.autoPilotTimer);
      this.autoPilotTimer.remove(false);
      this.autoPilotTimer = undefined;
    }
    if (this.sprite) {
      this.sprite.setVelocity(0, 0);
    }
    this.setAnimation("idle");
  }

  private autoPilotMove() {
    if (!this.autoPilotEnabled || !this.sprite) return;
    const randomAngle = Phaser.Math.Between(0, 359);
    this.setAngleDirection(randomAngle);

    const randomDistance = Phaser.Math.Between(1, 3);
    this.walk(randomDistance).catch((err) => {
      console.error("AutoPilot walk error:", err);
    });
  }
}
