import React from 'react';
import { Card, Col, Row, Progress } from 'antd';
import { TAnimateurInfo } from '../../../models/user/Dashboard';
import { useTranslation } from 'react-i18next';
import { TChapter, TCourse, TEpic, TSubject } from '../../../models/courses/course';
import { TLesson } from '../../../models/courses/lesson';

interface AnimateurContentProps {
    userData: { user: TAnimateurInfo; subjects: Set<number>; courses: Set<number> }[];
    subjects: TSubject[];
    courses: TCourse[];
    filteredSubjects: TSubject[];
    filteredCourses: TCourse[];
    chapters: TChapter[];
    epics: TEpic[];
    lessons: TLesson[];
    selectedSubject: number | null;
    selectedCourse: number | null;
}


const getLessonPath = (
    lessonId: number | null,
    lessons: TLesson[],
    epics: TEpic[],
    chapters: TChapter[],
    courses: TCourse[],
    subjects: TSubject[],
): string | null => {
    if (!lessonId) return null;

    const lesson = lessons.find((l) => l.id === lessonId);
    if (!lesson) return null;

    const epic = epics.find((e) => e.id === lesson.epic_id);
    const chapter = epic ? chapters.find((c) => c.id === epic.chapter_id) : null;
    const course = chapter ? courses.find((c) => c.id === chapter.course_id) : null;
    const subject = course ? subjects.find((s) => s.id === course.subject_id) : null;

    if (!subject || !course || !chapter || !epic || !lesson) return null;

    return `${subject.title} / ${course.title} / ${chapter.name} / ${epic.name} / ${lesson.name}`;
};

const AnimateurContent: React.FC<AnimateurContentProps> = ({
    userData,
    selectedSubject,
    selectedCourse,
    subjects,
    courses,
    chapters,
    epics,
    lessons,
}) => {
    const { t } = useTranslation();

    if (userData.length === 0) {
        return <p>{t('text.No_user_selected')}</p>;
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                {userData.map(({ user, subjects: userSubjects, courses: userCourses }) => {
                    // Récupérer le chemin de la dernière leçon
                    const lessonPath = getLessonPath(
                        user.last_lesson_id,
                        lessons,
                        epics,
                        chapters,
                        courses, // Utilise tous les cours
                        subjects // Utilise tous les sujets
                    );

                    return (
                        <Col key={user.user_id} xs={24} sm={12} md={8} lg={6}>
                            <Card title={`${user.firstname} ${user.lastname}`} bordered style={{ height: '100%' }}>
                                {/* Progression du sujet (basée sur le filtre) */}
                                {selectedSubject && (
                                    <>
                                        <p style={{ marginBottom: '4px' }}>
                                            {t('text.Progression_sujet')} :
                                        </p>
                                        <Progress
                                            percent={Math.round(userSubjects.has(selectedSubject) ? user.subject_progress_percentage ?? 0 : 0)}
                                            size="small"
                                            status="active"
                                            style={{ width: '200px', marginBottom: '16px' }}
                                            strokeColor="blue"
                                        />
                                    </>
                                )}
                                {/* Progression du cours (basée sur le filtre) */}
                                {selectedCourse && (
                                    <>
                                        <p style={{ marginBottom: '4px' }}>
                                            {t('text.Progression_cours')} :
                                        </p>
                                        <Progress
                                            percent={Math.round(userCourses.has(selectedCourse) ? user.course_progress_percentage ?? 0 : 0)}
                                            size="small"
                                            status="active"
                                            style={{ width: '200px', marginBottom: '16px' }}
                                            strokeColor="green"
                                        />
                                    </>
                                )}
                                {/* Toujours afficher la dernière leçon */}
                                <p>
                                    {t('text.Derniere_leçon')} : {lessonPath ?? t('text.No_lesson')}
                                </p>
                            </Card>
                        </Col>
                    );
                })}
            </Row>
        </>
    );
};

export default AnimateurContent;


